// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgotPassword_forgotPassword__VZ6yx{
    position: relative;
    margin: 0px 0;
}
.forgotPassword_ArrowBack__umhdu{
    display: none!important;
}
@media only screen and (max-width: 768px){
    .forgotPassword_ArrowBack__umhdu{
        color: #383838;
        font-size: 28px!important;
        transform: scale(0.8);
        transition: 0.3s;
        margin: 0px 4px 0px -7px;
        display: inline-block!important;
     }
     .forgotPassword_ArrowBack__umhdu:hover{
         color: #F65B3C;
         transform: scale(1);
         transition: 0.3s;
     }
}`, "",{"version":3,"sources":["webpack://./src/components/frontend/forgotPassword/forgotPassword.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI;QACI,cAAc;QACd,yBAAyB;QACzB,qBAAqB;QACrB,gBAAgB;QAChB,wBAAwB;QACxB,+BAA+B;KAClC;KACA;SACI,cAAc;SACd,mBAAmB;SACnB,gBAAgB;KACpB;AACL","sourcesContent":[".forgotPassword{\r\n    position: relative;\r\n    margin: 0px 0;\r\n}\r\n.ArrowBack{\r\n    display: none!important;\r\n}\r\n@media only screen and (max-width: 768px){\r\n    .ArrowBack{\r\n        color: #383838;\r\n        font-size: 28px!important;\r\n        transform: scale(0.8);\r\n        transition: 0.3s;\r\n        margin: 0px 4px 0px -7px;\r\n        display: inline-block!important;\r\n     }\r\n     .ArrowBack:hover{\r\n         color: #F65B3C;\r\n         transform: scale(1);\r\n         transition: 0.3s;\r\n     }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPassword": `forgotPassword_forgotPassword__VZ6yx`,
	"ArrowBack": `forgotPassword_ArrowBack__umhdu`
};
export default ___CSS_LOADER_EXPORT___;
