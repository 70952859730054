	import { useQuery, useMutation, useQueryClient } from 'react-query';
	import { QUERIES } from '../api_query_key/query_key';
	import {
		AddproductCall,
		DeviceInfoCall,
		MasterDataCall,
		userLoginCall,
		GetAllSetUpdata,
		DeleteProdunct,
		AddRawMaterial,
		DeleteMaterial,
		AddBoilerfuel,
		DeleteBoiler,
		AddWasteGeneration,
		DeleteWaste,
		UpdateSetupStatus,
		ResetSetupData,
		userRegistrationCall,
		userResetPasswordRequestCall,
		userResetPasswordCall,
		editUserCall,
		getUserCall,
		startMonthlyEntry,
		monthlyAddproductCall,
		getMonthlyDataCall,
		monthlyAddRawMaterialCall,
		monthlyAddBoilerfuelResourceCall,
		monthlyAddWasteGeneratedCall,
		monthlyAddCommentCall,
		newUserVerificationRequest,
		emailVerification,
	} from '../../../services/FrontendDataServices';
	import AuthUser from '../authUser/authuser';
	import { API_Error } from './api_error_hook';
	import { v4 as uuidv4 } from 'uuid';
	import Platform from 'react-platform-js';
	import { useContext } from 'react';
	import { Context } from '../../../frontend_context/contextProvider';
	import { toast } from 'react-toastify';
    import { FrontendTokenExpiry } from '../authUser/frontendTokenExpiry';
	
	export const useLoginCallHook = () => {
		const { setToken } = AuthUser();
		return useMutation(QUERIES.LOGIN, userLoginCall, {
			onSuccess: (data) => {
				setToken(data?.data);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useRegistrationHook = () => {
		return useMutation(QUERIES.USER_REGISTRATION, userRegistrationCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useResetPasswordRequestHook = () => {
		return useMutation(
			QUERIES.USER_RESETREQ_PASSWORD,
			userResetPasswordRequestCall,
			{
				onSuccess: (data) => {
					let successMessage = data.data.message;
					toast.success(successMessage);
				},
				onError: (error) => {
					let errorData = error;
					API_Error(errorData);
				},
			}
		);
	};
	export const useResetPasswordHook = () => {
		return useMutation(QUERIES.USER_RESET_PASSWORD, userResetPasswordCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useMasterDataHook = () => {
		const { setMonthlyEntryStatus } = useContext(Context);
		return useQuery(QUERIES.MASTER_DATA, MasterDataCall, {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: 1,
			enabled:!FrontendTokenExpiry(localStorage.getItem('expiry'))?true:false,
			onSuccess: (data) => {
				let masterData = JSON.stringify(data.data);
				localStorage.setItem('masterData', masterData);
				let localData = JSON.parse(localStorage.getItem('masterData'));
				let AppMonthly = localData && localData.DASHBOARD_STATUS;
				let AppMonthlyStatus = AppMonthly && JSON.parse(AppMonthly);
				setMonthlyEntryStatus(AppMonthlyStatus);
			},
			// onError: (error) => {
			// 	let errorData = error;
			// 	API_Error(errorData);
			// },
		});
	};
	export const useDeviceDataHook = () => {
		let uuid = uuidv4();
		let localData = JSON.parse(localStorage.getItem('masterData'));
		let AppVers = localData && localData.VERSION_NUMBER;
		let AppVersion = JSON.parse(AppVers);
		let PayLoadData = {
			device_name: Platform.OS,
			manufacturer: Platform.Browser,
			model: Platform.BrowserVersion,
			appversion: AppVersion && AppVersion[0].versionnumber,
			osversion: Platform.OSVersion,
			platform: Platform.OS,
			udid: uuid,
		};
		let condition = localData == null ? false : true;
		return useQuery([QUERIES.DEVICE_CALL], () => DeviceInfoCall(PayLoadData), {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: 1,
			enabled: condition,
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};

	export const useAddProductHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.ADD_PRODUCT, AddproductCall, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useALLSetUpDataHook = () => {
		const {
			setProductDataList,
			setRawMaterialList,
			setBoilerfuelList,
			setWasteGenerateList,
		} = useContext(Context);
		return useQuery(QUERIES.GET_ALL_SETUP, GetAllSetUpdata, {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: 1,
			onSuccess: (data) => {
				let AllProData = JSON.parse(data.data[0].PRODUCT_LIST);
				let AllRawMaterial = JSON.parse(data.data[0].RAW_MATERIAL_LIST);
				let AllBoilerfuelLis = JSON.parse(data.data[0].BOILER_FUELS_LIST);
				let AllWasteLis = JSON.parse(data.data[0].WASTE_LIST);
				setProductDataList(AllProData);
				setRawMaterialList(AllRawMaterial);
				setBoilerfuelList(AllBoilerfuelLis);
				setWasteGenerateList(AllWasteLis);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useDeleteProductHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.DELETE_PRODUCT, DeleteProdunct, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};

	export const useAddMaterialHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.ADD_RAWMATERIAL, AddRawMaterial, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};

	export const useDeleteMaterialHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.DELETE_MATERIAL, DeleteMaterial, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useAddBoilerHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.ADD_BOILER_FUEL, AddBoilerfuel, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useDeleteBoilerHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.DELETE_BOILER_FUEL, DeleteBoiler, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useAddWasteHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.ADD_WASTE, AddWasteGeneration, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useDeleteWasteHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.DELETE_WASTE, DeleteWaste, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_ALL_SETUP);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useUpdateSetupStatusHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.UPDATE_STATUS, UpdateSetupStatus, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.MASTER_DATA);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useResetSetupHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.RESET_SETUP, ResetSetupData, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.MASTER_DATA);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useEditUserHook = () => {
		let queryClient = useQueryClient();
		return useMutation(QUERIES.EDIT_USER, editUserCall, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(QUERIES.GET_USER_DETAILS);
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useGetUserHook = () => {
		return useMutation(QUERIES.GET_USER_DETAILS, getUserCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				localStorage.setItem('userName', data?.data[0]?.name);
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useStartMonthlyEntryHook = () => {
		return useMutation(QUERIES.START_MONTHLY_ENTRY, startMonthlyEntry, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};

	export const useMonthlyAddProduct = () => {
		return useMutation(QUERIES.MONTH_ADD_PRODUCT, monthlyAddproductCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useGetMonthlydata = () => {
		const { setAllYearData } = useContext(Context);
		return useMutation(QUERIES.GET_MONTHLY_DATA, getMonthlyDataCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	//getMonthlylogdata

	export const useMonthlyLogAllData = ()=>{
		const {setAllLogYearData} = useContext(Context);
		return useMutation(QUERIES.MONTH_LOG_DATA,getMonthlyDataCall,{
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
				let monthlyEntryData = data?.data.monthArray;
				let monthlyEntry = JSON.stringify(monthlyEntryData);
				localStorage.setItem('monthlyEntryData', monthlyEntry);
				setAllLogYearData(monthlyEntryData);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		})
	}
	export const useMonthlyAddRawMaterial = () => {
		return useMutation(
			QUERIES.MONTH_ADD_RAW_MATERIAL,
			monthlyAddRawMaterialCall,
			{
				onSuccess: (data) => {
					let successMessage = data.data.message;
					toast.success(successMessage);
				},
				onError: (error) => {
					let errorData = error;
					API_Error(errorData);
				},
			}
		);
	};
	export const useMonthlyAddBoilerfuel = () => {
		return useMutation(
			QUERIES.MONTH_ADD_BOILER_ENERGY,
			monthlyAddBoilerfuelResourceCall,
			{
				onSuccess: (data) => {
					let successMessage = data.data.message;
					toast.success(successMessage);
				},
				onError: (error) => {
					let errorData = error;
					API_Error(errorData);
				},
			}
		);
	};
	export const useMonthlyAddWaste = () => {
		return useMutation(QUERIES.MONTH_ADD_WASTE, monthlyAddWasteGeneratedCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};
	export const useMonthlyComment = () => {
		return useMutation(QUERIES.MONTH_ADD_COMMENT, monthlyAddCommentCall, {
			onSuccess: (data) => {
				let successMessage = data.data.message;
				toast.success(successMessage);
			},
			onError: (error) => {
				let errorData = error;
				API_Error(errorData);
			},
		});
	};

	//new user email verification request api hook

	export const useNewUserEmailverificationRequest = ()=>{
		return useMutation(QUERIES.EMAIL_VERIFICATION_REQUEST, newUserVerificationRequest ,{
			 onSuccess:(data)=>{
				  let successMessage = data.data.message;
				  toast.success(successMessage)
			 },
			 onError:(error)=>{
				let errorData = error;
				API_Error(errorData);
			 }
		})
	}

	//email verification api hook

	export const useEmailVerificationRequest =()=>{
		return useMutation(QUERIES.EMAIL_CONFORMATION, emailVerification, {
			onSuccess:(data)=>{
				let successMessage = data.data.message;
				toast.success(successMessage)
			},
			onError:(error)=>{
				let errorData = error;
				API_Error(errorData);
			}
		})
	}