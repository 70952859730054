import React, { useState } from 'react';
import {
	Popover,
	List,
	ListItem,
	IconButton,
	Backdrop,
	Typography,
	Box,
	Button,
} from '@mui/material';
import profileBlack from '../../../assets/admin/images/profileBlack.png';
import profileGreen from '../../../assets/admin/images/profileGreen.png';
import profileDefault from '../../../assets/admin/images/profileDefault.png';
import { useNavigate } from 'react-router';

const AccountModal = () => {
	const [profileState, setProfileState] = useState('default');
	const [anchorEl, setAnchorEl] = useState(null);
	const [backdropOpen, setBackdropOpen] = useState(false);

	const navigate = useNavigate();

	const handleClose = () => {
		setAnchorEl(null);
		setProfileState('default');
	};

	const handleMouseEnter = () => {
		if (profileState !== 'active') {
			setProfileState('hover');
		}
	};
	const handleMouseLeave = () => {
		if (profileState !== 'active') {
			setProfileState('default');
		}
	};
	const handleProfileClick = (event) => {
		profileState !== 'active'
			? setProfileState('active')
			: setProfileState('default');

		setAnchorEl(event.currentTarget);
	};

	const handleManageAccountClick = () => {
		navigate('/admin/manage-account-settings');
		handleClose();
	};

	const handleLogOutClick = () => {
		setBackdropOpen(true);
		setAnchorEl(null);
	};

	const handleLogout = () => {
		localStorage.removeItem('adminToken');
		localStorage.removeItem('adminTokenExpiry');
		navigate('/admin/login');
	};

	const open = Boolean(anchorEl);
	const id = open ? 'account-popover' : undefined;
	return (
		<>
			<IconButton
				onClick={handleProfileClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<img
					src={
						profileState == 'default'
							? profileDefault
							: profileState == 'hover'
							? profileBlack
							: profileGreen
					}
					width="40px"
					height="40px"
				/>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				PaperProps={{
					sx: {
						marginLeft: '-38px', // Adjust the margin to move the Popover to the left
						minWidth: '206px',
					},
				}}
			>
				<List>
					<ListItem
						button
						sx={{
							'&:hover': {
								backgroundColor: '#e4f1d4',
							},
							padding: '2px 12px',
						}}
					>
						<Typography
							sx={{
								'&:hover': {
									color: 'text.primary',
									fontWeight: '700',
									textAlign: 'center',
								},

								fontSize: '15px',
							}}
							onClick={handleManageAccountClick}
						>
							Manage account settings
						</Typography>
					</ListItem>

					<ListItem
						button
						sx={{
							'&:hover': {
								backgroundColor: '#e4f1d4',
							},
							padding: '4px 12px',
						}}
					>
						<Typography
							sx={{
								'&:hover': {
									color: 'text.primary',
									fontWeight: '700',
								},
								fontSize: '14px',
							}}
							margin="auto"
							onClick={handleLogOutClick}
						>
							Log out
						</Typography>
					</ListItem>
				</List>
			</Popover>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
				onClick={() => setBackdropOpen(false)}
			>
				<Box
					sx={{
						border: '2px solid green',
						backgroundColor: 'white',
						borderRadius: '10px',
						padding: '20px',
					}}
				>
					<Typography fontSize="16px" color="text.primary">
						Are you sure you want to log out?
					</Typography>
					<Box display="flex" justifyContent="center" mt="15px" gap="20px">
						<Button
							variant="outlined"
							style={{ cursor: 'pointer', minWidth: '90px' }}
							onClick={() => setBackdropOpen(false)}
							color="error"
						>
							No
						</Button>
						<Button
							variant="outlined"
							style={{ cursor: 'pointer', minWidth: '90px' }}
							color="success"
							onClick={handleLogout}
						>
							Yes
						</Button>
					</Box>
				</Box>
			</Backdrop>
		</>
	);
};

export default AccountModal;
