import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const BlankProducts = ()=>{
    const {t} = useTranslation()
    return(
        <div className={style.section + ' ' + style.sec_1} style={{opacity:"0.4"}}>
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <h3><span>1</span>{t("monthlylogpage.ProductsHeadertext")}</h3>
                <p>{t("monthlylogpage.noProducts")}</p>
            </div>
        </div>
    </div>
    )
}
export default BlankProducts;