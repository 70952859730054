// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createNewAccount_registerWrapper__pAj8e{
    position: relative;
}
.createNewAccount_pswMatches__Wvlge{
    list-style-type: none;
    list-style-position: inside;
    margin: 0 0 15px 0;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/frontend/createNewAccount/createNewAccount.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".registerWrapper{\r\n    position: relative;\r\n}\r\n.pswMatches{\r\n    list-style-type: none;\r\n    list-style-position: inside;\r\n    margin: 0 0 15px 0;\r\n    padding: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerWrapper": `createNewAccount_registerWrapper__pAj8e`,
	"pswMatches": `createNewAccount_pswMatches__Wvlge`
};
export default ___CSS_LOADER_EXPORT___;
