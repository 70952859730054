export default function convertDateTime(dateTimeStr) {
	const dateObj = new Date(dateTimeStr);
	const day = dateObj.getDate().toString().padStart(2, '0');
	const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0, so add 1 to get the correct month
	const year = dateObj.getFullYear().toString();
	const hours = dateObj.getHours().toString().padStart(2, '0');
	const minutes = dateObj.getMinutes().toString().padStart(2, '0');
	const seconds = dateObj.getSeconds().toString().padStart(2, '0');

	return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}
