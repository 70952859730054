import { Typography } from '@mui/material';
import React from 'react';

const RequestError = ({ message }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '70vh',
			}}
		>
			<Typography color="error.main" fontSize="16px" fontWeight="bold">
				{message}
			</Typography>
		</div>
	);
};

export default RequestError;
