import React, { useState } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import {
	Backdrop,
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import { getFormattedDate } from '../utils/getFormattedData';
import html2canvas from 'html2canvas';
import downloadImageIcon from '../../../assets/admin/images/downloadImage.png';
import Loader from './Loader';

const LineChart = ({
	title,
	dataSetArray,
	labels,
	timeline,
	setTimeline,
	yaxislabel,
}) => {
	const data = {
		labels: labels,
		datasets: [...dataSetArray],
	};

	console.log('Labels', labels);
	console.log('DATAARRAY', dataSetArray);

	const [imageGenerating, setImageGenerating] = useState(false);
	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					maxTicksLimit: 7,
				},
				title: {
					display: true,
					text: 'Y axis',
					font: {
						color: 'blue',
						size: 15,
					},
				},
			},
			x: {
				ticks: {
					color: 'text.primary', // Set the font color for x-axis labels
					font: {
						weight: 'bold', // Set the font weight for x-axis labels
						size: 14,
					},
					maxTicksLimit: 12,
				},
				title: {
					display: true,
					text: 'X Axis Label',
				},
			},
		},
		plugins: {
			legend: {
				position: 'top', // Set the position of the legend
				align: 'start',
				labels: {
					boxWidth: 15,
					boxHeight: 15,
				},
			},
		},
	};
	const legendMargin = {
		id: 'legendMargin',
		beforeInit: function (chart) {
			const fitValue = chart.legend.fit;
			chart.legend.fit = function fit() {
				fitValue.bind(chart.legend)();
				return (this.height += 80);
			};
		},
	};

	const handleTimelineChange = (e) => {
		setTimeline(e.target.value);
		localStorage.setItem(title, e.target.value);
	};

	const downloadImage = () => {
		setImageGenerating(true);
		const componentRef = document.getElementById('line-chart');
		// Replace 'your-component-id' with the actual ID of your component

		// Convert the component's HTML to an image using html2canvas
		html2canvas(componentRef).then((canvas) => {
			// Create a temporary link and set its href to the image data URL
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/jpeg');
			link.download = `${title}_${timeline}.jpg`;

			// Simulate a click on the link to trigger the download
			link.click();
			setTimeout(() => {
				setImageGenerating(false);
			}, 1000);
		});
	};

	return (
		<>
			<Box style={{ margin: '0px 66px' }} textAlign="end">
				<img
					src={downloadImageIcon}
					width="60px"
					style={{ cursor: 'pointer' }}
					onClick={downloadImage}
				/>
			</Box>
			<Box
				style={{ margin: '0px 55px' }}
				borderRadius="10px"
				backgroundColor="#F2F2F2"
				width="90%"
				height="620px"
				padding="30px"
				id="line-chart"
			>
				{imageGenerating ? (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={imageGenerating}
					>
						<Box
							display="grid"
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress color="success" />
						</Box>
					</Backdrop>
				) : (
					<Box display="flex" justifyContent="space-between" alignItems="start">
						<Box marginBottom={2}>
							<Typography color="text.primary" fontWeight="bold" variant="h6">
								{title}
							</Typography>
							<Typography color="text.secondary" fontSize="14px">
								Last updated on {getFormattedDate()}
							</Typography>
						</Box>
						<FormControl
							sx={{ minWidth: '220px', backgroundColor: 'white', mt: 1 }}
						>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={timeline}
								size="small"
								onChange={handleTimelineChange}
							>
								<MenuItem value="past 6 months">past 6 months</MenuItem>
								<MenuItem value="past 1 year">past 1 year</MenuItem>
								<MenuItem value="past 2 years">past 2 years</MenuItem>
								<MenuItem value="past 5 years">past 5 years</MenuItem>
								<MenuItem value="past 10 years">past 10 years</MenuItem>
							</Select>
						</FormControl>
					</Box>
				)}
				{console.log(data)}
				<Line
					data={data}
					options={options}
					style={{ paddingBottom: '40px' }}
					plugins={[legendMargin]}
				/>
			</Box>
		</>
	);
};

export default LineChart;
