import style from '../style.module.css';
import StartLog from '../../../../assets/frontend/images/log_start.png';
import { Height } from '@mui/icons-material';
import { useEffect } from 'react';

const ProgressBar = (
     {
        completeProductSection, 
        completeEnerySection,
        completeRawMaterial,
        completeWasteGenerated,
        EnergyBoilerJson
   })=>{

    useEffect(()=>{
       // if(EnergyBoilerJson)
    },[])
    return(
        <div>
        <div className={style.progress_bar}>
        <div className={style.DupBorder_progressBar}></div>
        <div className={style.Dup_progressBar} style={{
                    width:completeProductSection===1&&completeEnerySection===0&&completeRawMaterial===0&&completeWasteGenerated===0?"25%":
                    completeProductSection===1 && completeRawMaterial ===1 && completeEnerySection===0&&completeWasteGenerated===0?"50%":
                    completeProductSection===1&&completeRawMaterial===1&&completeEnerySection===1&&completeWasteGenerated===0?"75%":
                    completeProductSection===1&&completeRawMaterial===1&&completeEnerySection===1&&completeWasteGenerated===1?"100%":"0%"
                }}></div>
            <ol className={style.progress_steps}>
                
                <li
                    className={style.progress_step}
                    style={{
                        width: "25%"
                    }}
                >
                    
                    <span className={style.startLog}><img src={StartLog} alt="REMS" /></span>
                    <span className={style.count + ' ' + style.highlight}></span>
                    <span className={style.description}>1</span>
                </li>
                <li
                    className={style.progress_step}
                    style={{
                        width:"25%"
                    }}
                >
                    <span className={style.count + ' ' + style.highlight}></span>
                    <span className={style.description}>2a</span>
                </li>
                <li
                    className={style.progress_step}
                    style={{
                        width:"25%"
                    }}
                >
                    <span className={style.count + ' ' + style.highlight}></span>
                    <span className={style.description}>2b</span>
                </li>
                <li
                    className={style.progress_step}
                    style={{
                        width:"25%"
                    }}
                >
                    <span className={style.count + ' ' + style.highlight}></span>
                    <span className={style.description}>3</span>
                </li>
            </ol>
        </div>

    </div>
    )
}
export default ProgressBar;