import React from 'react';
import { AppBar, Grid, Toolbar } from '@mui/material';
import logo from '../../../assets/admin/images/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
	return (
		<AppBar
			position="static"
			style={{ backgroundColor: '#F2F2F2', padding: '3px 25px' }}
		>
			<Toolbar>
				<Link
					style={{ textDecoration: 'none', color: 'inherit' }}
					to="/admin/login"
				>
					<Grid item>
						<img src={logo} height="60px" />
					</Grid>
				</Link>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
