import { axiosInstance } from './AdminDataServices';

export const login = async ({ email, password }) =>
	await axiosInstance.post('/login', {
		email: email,
		password: password,
	});

export const getGeneralStats = async () =>
	await axiosInstance.get('/get-general-status');

export const getUsersList = async () =>
	await axiosInstance.get('/get-user-manager-details');

export const changeUserStatus = async ({ user_id, status }) =>
	await axiosInstance.post('/update-user-status', { user_id, status });

export const getTotalWasteGenerated = async ({ queryKey }) => {
	const [, queryParams] = queryKey;
	const { days } = queryParams;
	return await axiosInstance.get(
		`/get-total-waste-by-category-month?days=${days}`
	);
};

export const getDisposalMethodData = async ({ queryKey }) => {
	const [, queryParams] = queryKey;
	const { days } = queryParams;
	return await axiosInstance.get(
		`/get-total-waste-disposed-by-month?days=${days}`
	);
};

export const getNetRevenueData = async ({ queryKey }) => {
	const [, queryParams] = queryKey;
	const { days } = queryParams;
	return await axiosInstance.get(
		`/get-net-revenue-waste-by-month?days=${days}`
	);
};

export const specificMaterialConsumption = async ({ queryKey }) => {
	const [, queryParams] = queryKey;
	const { days } = queryParams;
	return await axiosInstance.get(
		`/get-material-consumption-by-month?days=${days}`
	);
};

export const getDatabaseData = async ({ queryKey }) => {
	const [, queryParams] = queryKey;
	const { language_id } = queryParams;
	return await axiosInstance.get(
		`/get-database-manager-data?language_id=${language_id}`
	);
};

export const updateBoilerData = async (boilerObject) =>
	await axiosInstance.post('/update-boiler-fuel-master-data', boilerObject);

export const updateUnitData = async (unitObject) =>
	await axiosInstance.post('/update-unit-master-data', unitObject);

export const updateWasteData = async (wasteObject) =>
	await axiosInstance.post('/update-waste-master-data', wasteObject);

export const getAccountDetails = async () =>
	await axiosInstance.get('/edit-logged-user-data');

export const updateAdminAccountDetails = async (adminDetails) =>
	await axiosInstance.post('/update-logged-user-data', adminDetails);

export const forgotPassword = async (email) =>
	await axiosInstance.post('/forgotPassword', email);

export const resetPassword = async (emailAndToken) =>
	await axiosInstance.post('/reset-admin-password', emailAndToken);

export const getAdminUsers = async () =>
	await axiosInstance.get('/get-admin-users');

export const addAdminUser = async (adminUserData) =>
	await axiosInstance.post('/add-admin-user', adminUserData);

export const removeAdminUser = async (adminData) =>
	await axiosInstance.post('/delete-admin', adminData);
