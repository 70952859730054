import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './adminStyles';
import Login from './components/admin/pages/Login';
import Dashboard from './components/admin/pages/Dashboard';
import LoggedInHeader from './components/admin/components/LoggedInHeader';
import UserManager from './components/admin/pages/UserManager';
import VerifyAccount from './components/admin/pages/VerifyAccount';
import { useLocation } from 'react-router-dom';
import DatabaseManager from './components/admin/pages/DatabaseManager';
import AccountSettings from './components/admin/pages/AccountSettings';
import ResetPassword from './components/admin/pages/ResetPassword';
import ForgotPassword from './components/admin/pages/ForgotPassword';
import NotFoundAdmin from './components/frontend/pageNotFound/NotFoundAdmin';
import AdminManager from './components/admin/pages/AdminManager';

const AdminRoutes = () => {
	const location = useLocation();
	const pathname = location.pathname;

	return (
		<ThemeProvider theme={theme}>
			{pathname !== '/admin/login' &&
				pathname !== '/admin' &&
				pathname !== '/admin/reset-password' &&
				pathname !== '/admin/forgot-password' && <LoggedInHeader />}
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/not-found" element={<NotFoundAdmin />} />

				{localStorage.getItem('adminToken') ? (
					<>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/user-manager" element={<UserManager />} />

						<Route path="/database-manager" element={<DatabaseManager />} />
						<Route path="/admin-manager" element={<AdminManager />} />

						<Route path="/verify-account" element={<VerifyAccount />} />
						<Route
							path="/manage-account-settings"
							element={<AccountSettings />}
						/>
					</>
				) : (
					<Route
						path="*"
						element={<Navigate replace to="/admin/not-found" />}
					/>
				)}
			</Routes>
		</ThemeProvider>
	);
};

export default AdminRoutes;
