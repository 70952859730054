import React, { useRef, useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import { Box, useMediaQuery } from '@mui/material';
import { Line } from 'react-chartjs-2';

const PdfLineChart = ({ title, unit, dataSetArray, labels }) => {
	console.log(labels, dataSetArray);
	const isMobile = useMediaQuery('(max-width:600px)');

	const chartRef = useRef(null);
	const data = {
		labels: labels,
		datasets: [
			{
				label: title,
				data: [...dataSetArray],
				fill: false,
				pointBackgroundColor: ['#138C50'], // Array to specify point background colors
				borderColor: '#138C50', // Line color
				tension: 0.4,
				// backgroundColor: (context) => {
				// 	// Determine the background color based on the label (month)
				// 	if (context.dataset.data[context.dataIndex] < 10) {
				// 		// Customize the color conditionally (e.g., if data point is 80)
				// 		return 'red';
				// 	} else {
				// 		// Default color for other data points
				// 		return 'blue';
				// 	}
				// },
			},
		],
	};
	useEffect(() => {
		const chartElement = chartRef.current;

		// if (chartElement) {
		// 	const chartInstance = chartElement.chartInstance;

		// 	if (chartInstance) {
		// 		const ctx = chartInstance.ctx;
		// 		const dataset = chartInstance.data.datasets[0]; // Assuming only one dataset

		// 		// Find the index of the data point that meets your condition
		// 		const indexToHighlight = dataset.data.indexOf(122); // Change this condition as needed

		// 		if (indexToHighlight !== -1) {
		// 			const x =
		// 				chartInstance.scales['x-axis-0'].getPixelForValue(indexToHighlight);
		// 			const yTop = chartInstance.chartArea.top;
		// 			const yBottom = chartInstance.chartArea.bottom;

		// 			// Draw a vertical line
		// 			ctx.beginPath();
		// 			ctx.strokeStyle = 'red'; // Change the line color as needed
		// 			ctx.moveTo(x, yTop);
		// 			ctx.lineTo(x, yBottom);
		// 			ctx.stroke();
		// 		}
		// 	}
		// }
	}, []);
	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					maxTicksLimit: 10,
				},
			},
			x: {
				ticks: {
					color: 'text.primary', // Set the font color for x-axis labels
					font: {
						weight: 'bold', // Set the font weight for x-axis labels
						size: 14,
					},
					maxTicksLimit: 12,
				},
			},
		},
		plugins: {
			legend: {
				title: {
					display: true,
				},
				labels: {
					boxWidth: 0.1,
					boxHeight: 0.1,
				},
			},
		},
	};
	const legendMargin = {
		id: 'legendMargin',
		beforeInit: function (chart) {
			const fitValue = chart.legend.fit;
			chart.legend.fit = function fit() {
				fitValue.bind(chart.legend)();
				return (this.height += 80);
			};
		},
	};

	const preprocessData = (data) => {
		// Remove zero values from the data
		const preprocessedData = data.map((value, index) =>
			value === 0 ? null : data[index]
		);

		return preprocessedData;
	};

	data.datasets[0].data = preprocessData(data.datasets[0].data);

	return (
		<>
			{/* <Box
				borderRadius="10px"
				backgroundColor="#F2F2F2"
				width="100%"
				height="520px"
				padding="30px"
				id="line-chart"
				textAlign="center"
			>
				<Line
					data={data}
					options={options}
					style={{ paddingBottom: '40px', width: '100%', margin: 'auto' }}
					plugins={[legendMargin]}
				/>
			</Box> */}
			<div
				style={{
					textAlign: 'center',
					margin: '50px auto 40px',
					position: 'relative',
					width: isMobile ? '1000px' : '100%',
					height: '450px',
				}}
			>
				<h4
					style={{
						display: 'flex',
						alignItems: 'center',
						fontWeight: 'bold',
						justifyContent: 'center',
						position: 'absolute',
						top: '30px',
						left: isMobile ? '245px' : '295px',
						color: 'black',
					}}
				>
					<span style={{ fontSize: '16px', color: 'gray', fontWeight: 400 }}>
						y-axis = &nbsp;{' '}
					</span>{' '}
					{title} &nbsp;{' '}
					<span style={{ fontSize: '17px', color: 'gray', fontWeight: 400 }}>
						({unit})
					</span>
				</h4>
				{dataSetArray !== null && dataSetArray.length == 0 ? (
					<div
						style={{
							backgroundColor: '#F1F1F1',
							width: '100%',
							height: '450px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '10px',
						}}
					>
						<h5 style={{ color: '#A6A6A6', fontSize: '24px' }}>
							The graph for this indicator was not generated
						</h5>
					</div>
				) : (
					<Line
						// plugins={[legendMargin]}
						ref={chartRef}
						style={{
							backgroundColor: '#F1F1F1',
							padding: '50px 10px 20px 10px',
							borderRadius: '10px',
							width: '100%',
							height: '100%',
							margin: 'auto',
						}}
						data={data}
						options={{
							plugins: {
								title: {
									display: true,
								},
								legend: {
									display: false,
								},
							},
							scales: {
								y: {
									beginAtZero: true,
									ticks: {
										maxTicksLimit: 9,
									},
								},
								x: {
									ticks: {
										color: 'text.primary', // Set the font color for x-axis labels
										font: {
											weight: 'bold', // Set the font weight for x-axis labels
											size: 14,
											lineHeight: 2,
										},
										autoSkip: false, // Ensure no labels are skipped
										maxRotation: 28, // Adjust as needed for label spacing
										minRotation: 0, // You can Lastadjust this as well for better spacing
										maxTicksLimit: 12,
									},
								},
							},
							responsive: true,
							maintainAspectRatio: true,
						}}
					/>
				)}
			</div>
		</>
	);
};

export default PdfLineChart;
