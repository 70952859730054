export const QUERIES ={
    LOGIN:"loginValid",
    USER_REGISTRATION:'userRegistration',
    USER_RESETREQ_PASSWORD:'resetPasswordReq',
    USER_RESET_PASSWORD:'resetPassword',
    EDIT_USER:'editUser',
    GET_USER_DETAILS:'getUserDetails',
    MASTER_DATA:"masterData",
    DEVICE_CALL:"deviceCall",
    GET_ALL_SETUP:"getAllSetupdata",
    ADD_PRODUCT:"addProducts",
    DELETE_PRODUCT:"deleteProduct",
    ADD_RAWMATERIAL:"addRawMaterial",
    DELETE_MATERIAL:"deleteMaterial",
    ADD_BOILER_FUEL:"addBoilerFuel",
    DELETE_BOILER_FUEL:"deleteBoilerFuel",
    ADD_WASTE:"addWaste",
    DELETE_WASTE:"deleteWaste",
    UPDATE_STATUS:"updateStatus",
    RESET_SETUP:"resetSetup",
    START_MONTHLY_ENTRY:"startMonthlyEntry",
    MONTH_ADD_PRODUCT:"monthlyAddprod",
    GET_MONTHLY_DATA:"get_monthly_data",
    MONTH_ADD_RAW_MATERIAL:"monthlyAddRawMaterial",
    MONTH_ADD_BOILER_ENERGY:"monthlyAddBoilderResource",
    MONTH_ADD_WASTE:"monthlyAddWaste",
    MONTH_ADD_COMMENT:"monthlyAddComment",
    MONTH_LOG_DATA:"monthlyLogData",
    EMAIL_VERIFICATION_REQUEST:"email_ver_request",
    EMAIL_CONFORMATION:"email_conformation"
}