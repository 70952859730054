import React, { useState, useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import { Backdrop, Box, CircularProgress, useMediaQuery } from '@mui/material';
import { Line } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const OutputLineChart = ({
	title,
	dataSetArray,
	labels,
	generateImage,
	setGenerateImage,
}) => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const { t } = useTranslation();

	const data = {
		labels: labels,
		datasets: [
			{
				label: title,
				data: [...dataSetArray],
				tension: 0.4,
				pointBackgroundColor: ['#138C50'], // Array to specify point background colors
				borderColor: '#138C50', // Line color

				// pointBorderColor: [], // Array to specify point border colors
				// spanGaps: true, // Enable to create gaps for zero values
			},
		],
	};

	// const setPointColors = (data) => {
	// 	return data.map((value) =>
	// 		value === 0 ? 'rgba(0, 0, 0, 0)' : 'rgba(75,192,192,1)'
	// 	);
	// };
	// // Call the setPointColors function to determine point colors
	// data.datasets[0].pointBackgroundColor = setPointColors(data.datasets[0].data);
	// data.datasets[0].pointBorderColor = setPointColors(data.datasets[0].data);

	const units = {
		'Specific Waste Generation': '(kg/kg)',
		'Net Revenue from Waste': '(Ks.)',
		'Specific Energy Consumption': '(kWh/kg)',
		'Specific Material Consumption': '(kg/kg)',
	};

	const [imageGenerating, setImageGenerating] = useState(false);
	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					maxTicksLimit: 7,
				},
				title: {
					display: true,
					text: title + ' ' + units[title],
					font: {
						size: 15,
						weight: 'bold',
						color: 'black',
					},
				},
			},
			x: {
				ticks: {
					color: 'text.primary', // Set the font color for x-axis labels
					font: {
						weight: 'bold', // Set the font weight for x-axis labels
						size: 14,
					},
					maxTicksLimit: 12,
				},
				title: {
					display: false,
					text: 'X Axis Label',
				},
			},
		},
		plugins: {
			legend: {
				position: 'top', // Set the position of the legend
				align: 'center',
				labels: {
					boxWidth: 15,
					boxHeight: 15,
				},
			},
		},
	};
	const legendMargin = {
		id: 'legendMargin',
		beforeInit: function (chart) {
			const fitValue = chart.legend.fit;
			chart.legend.fit = function fit() {
				fitValue.bind(chart.legend)();
				return (this.height += 80);
			};
		},
	};

	const arbitraryLine = {
		id: 'arbitraryLine',
		beforeDatasetsDraw(chart, args, pluginOptions) {
			const {
				ctx,
				chartArea: { top, bottom, left, right, width, height },
				scales: { x, y },
			} = chart;

			ctx.save();

			ctx.beginPath();
			ctx.lineWidth = 2;
			ctx.strokeStyle = 'gray';
			ctx.moveTo(x.getPixelForValue(1), top);
			ctx.lineTo(x.getPixelForValue(1), bottom);
			ctx.stroke();
			ctx.restore();
		},
	};

	function getFormattedDate() {
		const today = new Date();
		const day = today.getDate().toString().padStart(2, '0'); // Get the day (with leading zero if needed)
		const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Get the month (with leading zero if needed)
		const year = today.getFullYear();

		return `${day}_${month}_${year}`;
	}

	useEffect(() => {
		if (generateImage) {
			setTimeout(() => {
				downloadImage();
				toast.success(
					t('alerts.OutputImageFor') +
						' ' +
						t(`${title}'`) +
						' ' +
						t('alerts.downloadedSuccessfully')
				);
				setGenerateImage(false);
			}, 1000);
		}
	}, []);

	const downloadImage = () => {
		setImageGenerating(true);
		const componentRef = document.getElementById('line-chart');
		// Replace 'your-component-id' with the actual ID of your component

		// Convert the component's HTML to an image using html2canvas
		html2canvas(componentRef).then((canvas) => {
			// Create a temporary link and set its href to the image data URL
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/jpeg');
			link.download = `${title}_${getFormattedDate()}.jpg`;

			// Simulate a click on the link to trigger the download
			link.click();

			setImageGenerating(false);
		});
	};

	const preprocessData = (data) => {
		// Remove zero values from the data
		const preprocessedData = data.map((value, index) =>
			value === 0 ? null : data[index]
		);

		return preprocessedData;
	};

	data.datasets[0].data = preprocessData(data.datasets[0].data);

	return (
		<>
			<Box
				borderRadius="10px"
				backgroundColor="#F2F2F2"
				width={isMobile ? '800px' : '100%'}
				height={isMobile ? '520px' : '520px'}
				padding="30px"
				id="line-chart"
				textAlign="center"
			>
				{imageGenerating ? (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={imageGenerating}
					>
						<Box
							display="grid"
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress color="success" />
						</Box>
					</Backdrop>
				) : (
					<></>
				)}
				<Line
					data={data}
					options={options}
					style={{
						paddingBottom: '40px',
						width: '100%',
						height: '100%',
						margin: 'auto',
					}}
					// plugins={[arbitraryLine]}
				/>
			</Box>
		</>
	);
};

export default OutputLineChart;
