import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import rpStyles from './resetPassword.module.css';
import Modal from 'react-bootstrap/Modal';
import sucessIcon from '../../../assets/frontend/images/sucess_checkmark.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoneIcon from '@mui/icons-material/Done';
import { useResetPasswordHook } from "../api_hooks/api_hooks";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    let tokenType = searchParams.get('token')
    const [showModal, setShowModal] = useState(false);
    const [passwordDetails, setPasswordDetails] = useState({
        Newpassword: '',
        confirmPassword: ''
    })
    const [passwordStrong, setPasswordStrong] = useState({
        totalcharCount: false,
        NumberCount: false,
        AlphaCount: false
    })
    const [passwordType, setPasswordType] = useState(true);
    const [ConformpasswordType, setConformpasswordType] = useState(true);
    const [ErrorValue, setErrorValue] = useState({})
    const toggleModal = (e) => {
        e.preventDefault()
        setShowModal(true);
    };
    const PasswordShow = () => {
        setPasswordType(!passwordType);
    }
    const conformPasswordShow = () => {
        setConformpasswordType(!ConformpasswordType)
    }
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        if (name === 'Newpassword') {
            let PassLength = value.length >= 8
            let numericList = /[0-9]/
            let alphabets = /[a-zA-Z]/
            let numericValue = numericList.test(value);
            let AlphaValue = alphabets.test(value);
            setPasswordStrong({
                ...passwordStrong,
                totalcharCount: PassLength,
                NumberCount: numericValue,
                AlphaCount: AlphaValue
            })
        }
        setPasswordDetails({ ...passwordDetails, [name]: value })

    }
    const { mutate: resetPassword, status } = useResetPasswordHook();
    const resetPasswordSubmit = (e) => {
        e.preventDefault();
        let ErrorValues = validationFunction();
        setErrorValue(ErrorValues)
        if (Object.keys(ErrorValues).length === 0) {
            let passData = {
                "token": tokenType,
                "new_password": btoa(passwordDetails.Newpassword)
            }
            resetPassword(passData);
        }
    }
    const validationFunction = () => {
        let error = {}
        if (!passwordDetails.Newpassword) {
            error.newpassword = t("resetPassword.validPassword")
        }
        if (!passwordDetails.confirmPassword || passwordDetails.confirmPassword !== passwordDetails.Newpassword) {
            error.confirmPass = t("resetPassword.validSamePass")
        }
        if(passwordDetails.Newpassword !==''){
            if (passwordStrong.NumberCount === false || passwordStrong.AlphaCount === false || passwordStrong.totalcharCount === false) {
                error.strong = "Password is not strong"
                toast.error(t("CreatenewAccount.invalidPassword"))
            }
        } 
        return error;
    }
    useEffect(() => {
        if (status === 'success') {
            setShowModal(true);
        }
        if (status === "error") {
            toast.error(t("resetPassword.tokenExpire"))
            setTimeout(() => {
                navigate('/forgot-password')
            }, 2000)
        }
    }, [status])
    return (
        <>
            <div className="mainWrapper">
                <div className={rpStyles.resetPassword}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <h4>{t("forgotpasswordpage.ResetYourPasswordHeaderText")}</h4>
                                <p className="mb-4">{t("forgotpasswordpage.ResetYourPasswordSubText")}</p>
                                <form
                                    onSubmit={resetPasswordSubmit}
                                >
                                    <div className="floting-form">
                                        <input
                                            type={passwordType === true ? "password" : "text"}
                                            name="Newpassword"
                                            // className="form-input"
                                            placeholder="none"
                                            value={passwordDetails.Newpassword}
                                            onChange={(e) => handlePasswordChange(e)}
                                            className={ErrorValue.newpassword ? 'form-input invalid' : 'form-input'}
                                        />
                                        <label htmlFor="newpassword" className="form-label fl-white">{t("resetPassword.newPassword")}</label>
                                        {passwordType === true ? <VisibilityOffIcon className='eyeIcon'
                                            onClick={PasswordShow} /> :
                                            <RemoveRedEyeIcon
                                                onClick={PasswordShow}
                                                className='eyeIcon'
                                            />}
                                    </div>
                                    <div className="text-danger errorText">{ErrorValue.newpassword}</div>


                                    <div className="floting-form">
                                        <input
                                            type={ConformpasswordType === true ? "password" : "text"}
                                            name="confirmPassword"
                                            // className="form-input"
                                            placeholder="none"
                                            value={passwordDetails.confirmPassword}
                                            onChange={(e) => handlePasswordChange(e)}
                                            className={ErrorValue.confirmPass ? 'form-input invalid' : 'form-input'}
                                        />
                                        <label htmlFor="confirmpassword" className="form-label fl-white">{t("resetPassword.confirmPassword")}</label>
                                        {ConformpasswordType === true ? <VisibilityOffIcon
                                            className='eyeIcon'
                                            onClick={conformPasswordShow} /> :
                                            <RemoveRedEyeIcon
                                                onClick={conformPasswordShow}
                                                className='eyeIcon'
                                            />}
                                    </div>
                                    <div className="text-danger errorText">{ErrorValue.confirmPass}</div>


                                    <small className="form-text text-muted  mb-5" >
                                        <ul className={rpStyles.pswMatches} style={{marginTop:"14px"}}>
                                            <li
                                                style={{
                                                    color: passwordStrong.totalcharCount === true ? "green" : "",
                                                    position: "relative"
                                                }}
                                            >{t("createnewaccountpage.CreateANewAccountPasswordCheckTextOne")}
                                                <DoneIcon
                                                    style={{
                                                        fontSize: 16,
                                                        position: "absolute",
                                                        right: 0,
                                                        color: passwordStrong.totalcharCount === true ? "green" : ""
                                                    }}
                                                />
                                            </li>
                                            <li
                                                style={{
                                                    position: "relative",
                                                    color: passwordStrong.NumberCount === true && passwordStrong.AlphaCount
                                                        ? "green" : ""
                                                }}
                                            >{t("createnewaccountpage.CreateANewAccountPasswordCheckTextTwo")}
                                                <DoneIcon
                                                    style={{
                                                        fontSize: 16,
                                                        position: "absolute",
                                                        right: 0,
                                                        color: passwordStrong.NumberCount === true && passwordStrong.AlphaCount ? "green" : ""
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </small>
                                    {/* <Link to="/resetPassword"><button className="btn btn-primary w-100" type="submit">Reset my password</button></Link> */}
                                    <button className="btn btn-primary w-100 mt-3" type="submit">{t("resetPassword.resetMyPassword")}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={toggleModal} className="reamModal" backdrop="static" size="sm"
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <img src={sucessIcon} alt="REMS" className="successIcon" />
                    <h5>{t("resetPassword.passwordHeading")}</h5>
                    <p>{t("resetPassword.passwordDesc")}</p>
                    <Link to="/"><button className="btn btn-primary w-100" type="submit">{t("createNewAccount.Gobacktohomepage")}</button></Link>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ResetPassword;