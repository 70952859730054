export const createWasteDataSet = (
	data,
	label,
	borderColor,
	backgroundColor
) => {
	return {
		label: label,
		data: data?.data
			?.filter((waste) => waste.waste_name === label)
			?.map((item) => item.waste_total),
		fill: false,
		borderColor: borderColor,
		backgroundColor: backgroundColor,
		tension: 0.1,
	};
};

export const dataset = (
	dataArray = [],
	label,
	borderColor,
	backgroundColor
) => {
	return {
		label: label,
		data: [...dataArray],
		fill: false,
		borderColor: borderColor,
		backgroundColor: backgroundColor,
		tension: 0.1,
	};
};
