export function createUniqueMonthsAgain(months) {
	const uniqueMonths = [...new Set(months)];

	// Create a custom sorting function
	const customSort = (a, b) => {
		const [monthA, yearA] = a.created_at_month.split(' ');
		const [monthB, yearB] = b.created_at_month.split(' ');

		if (yearA !== yearB) {
			// If years are different, maintain the original order
			return (
				months.indexOf(a.created_at_month) - months.indexOf(b.created_at_month)
			);
		} else {
			// If years are the same, sort by month
			const monthOrder = {
				Jan: 1,
				Feb: 2,
				Mar: 3,
				Apr: 4,
				May: 5,
				Jun: 6,
				Jul: 7,
				Aug: 8,
				Sep: 9,
				Oct: 10,
				Nov: 11,
				Dec: 12,
			};

			return monthOrder[monthA] - monthOrder[monthB];
		}
	};

	// Sort the months based on the custom sorting function
	uniqueMonths.sort(customSort);
	const sortedMOnths = customSorts(uniqueMonths);

	return sortedMOnths;
}

function customSorts(arr) {
	const months = {
		JAN: 0,
		FEB: 1,
		MAR: 2,
		APR: 3,
		MAY: 4,
		JUN: 5,
		JUL: 6,
		AUG: 7,
		SEP: 8,
		OCT: 9,
		NOV: 10,
		DEC: 11,
	};

	const sortedArray = arr.sort((a, b) => {
		const [aMonth, aYear] = a.created_at_month.split(' ');
		const [bMonth, bYear] = b.created_at_month.split(' ');

		const aMonthIndex = months[aMonth];
		const bMonthIndex = months[bMonth];

		if (aYear !== bYear) {
			return aYear.localeCompare(bYear);
		}

		return aMonthIndex - bMonthIndex;
	});

	return sortedArray;
}
