import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, List, ListItem, ListItemText } from '@mui/material';
import Switch from '../components/Switch';
import Search from '../components/Search';
import { useQuery } from 'react-query';
import { getUsersList } from '../../../services/AdminApis';
import Loader from '../components/Loader';
import RequestError from '../components/RequestError';
import convertDateTime from '../utils/formatDateAndTime';

const UserManager = () => {
	const usersRef = useRef(null);

	const { data, isLoading, isError, error, isFetched } = useQuery(
		'userList',
		getUsersList
	);

	const [users, setUsers] = useState(data);
	usersRef.current = { ...usersRef.current, 0: data };

	useEffect(() => {
		if (isFetched) {
			setUsers(data);
		}
	}, [isFetched, data]);

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	return (
		<Stack padding="40px 45px 20px">
			<Search users={users} setUsers={setUsers} usersRef={usersRef} />
			<Box>
				<List sx={{ pb: 0 }}>
					<ListItem style={{ display: 'flex' }} sx={{ pb: 0 }}>
						<ListItemText
							primary="Username"
							sx={{ flex: 1 }}
							primaryTypographyProps={{
								sx: { fontWeight: 'bold', color: 'text.primary' },
							}}
						/>
						<ListItemText
							primary="Email ID"
							sx={{ flex: 1.9 }}
							primaryTypographyProps={{
								sx: { fontWeight: 'bold', color: 'text.primary' },
							}}
						/>
						<ListItemText
							primary="Mobile number"
							sx={{ flex: 0.8 }}
							primaryTypographyProps={{
								sx: { fontWeight: 'bold', color: 'text.primary' },
							}}
						/>{' '}
						<ListItemText
							primary="Date of joining"
							sx={{ flex: 1 }}
							primaryTypographyProps={{
								sx: { fontWeight: 'bold', color: 'text.primary' },
							}}
						/>{' '}
						<ListItemText
							primary="Last activity"
							sx={{ flex: 1 }}
							primaryTypographyProps={{
								sx: { fontWeight: 'bold', color: 'text.primary' },
							}}
						/>{' '}
						<ListItemText
							primary="Status"
							sx={{ flex: 0.75 }}
							primaryTypographyProps={{
								sx: { fontWeight: 'bold', color: 'text.primary' },
							}}
						/>
					</ListItem>
				</List>
			</Box>
			<Box
				border="1px solid lightgray"
				borderRadius="10px"
				height="65.9vh"
				overflow="auto"
				sx={{
					'&::-webkit-scrollbar': {
						width: '0.1em',
						backgroundColor: 'transparent',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'transparent',
					},
				}}
			>
				<List sx={{ ml: 0.3 }}>
					{users?.map((user, index) => (
						<ListItem
							key={index}
							style={{
								display: 'flex',
								color: user.is_active ? 'inherit' : 'lightgray',
							}}
						>
							<ListItemText
								primary={user.name}
								sx={{ flex: 1, overflow: 'clip' }}
							/>
							<ListItemText primary={user.email} sx={{ flex: 1.9 }} />
							<ListItemText
								primary={`+${user.countrycode} ${user.phonenumber}`}
								sx={{ flex: 0.8 }}
							/>{' '}
							<ListItemText
								primary={convertDateTime(user.date_of_joining)}
								sx={{ flex: 1 }}
							/>{' '}
							<ListItemText
								primary={convertDateTime(user.last_activity)}
								sx={{ flex: 1 }}
							/>{' '}
							<ListItemText sx={{ flex: 0.75 }}>
								<Switch status={user.is_active} userId={user.id} />
							</ListItemText>
						</ListItem>
					))}
				</List>
			</Box>
		</Stack>
	);
};

export default UserManager;
