import { createContext, useState } from "react";
const Context = createContext();

const ContextProvider = ({children})=>{
    const [ProductDataList, setProductDataList] = useState([]);
    const [RawMaterialList,setRawMaterialList] = useState([]);
    const [BoilerfuelList,setBoilerfuelList] = useState([]);
    const [wasteGenerateList,setWasteGenerateList]= useState([]);
    const [monthlyEntryStatus,setMonthlyEntryStatus] = useState([])
    const [AllYearData,setAllYearData] =useState([]);
    const [AllLogYearData, setAllLogYearData] = useState([]);
    return(
        <Context.Provider
           value = {{
               setProductDataList,
               ProductDataList,
               setRawMaterialList,
               RawMaterialList,
               setBoilerfuelList,
               BoilerfuelList,
               setWasteGenerateList,
               wasteGenerateList,
               setMonthlyEntryStatus,
               monthlyEntryStatus,
               setAllYearData,
               AllYearData,
               setAllLogYearData,
               AllLogYearData
           }}
        >
            {children}
        </Context.Provider>
    )
}
export {Context,ContextProvider};