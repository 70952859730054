import React, { useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography, useMediaQuery } from '@mui/material';
import { translationObj } from './outputGraphsTranslation';

const CheckboxComponent = ({
	label,
	checkStatus,
	setCheckStatus,
	setSelectedGraphs,
}) => {
	const isMobile = useMediaQuery('(max-width:600px)');
	let langId = localStorage.getItem('lanId');

	const selectedGraphs = JSON.parse(localStorage.getItem('selectedGraphs'));
	useEffect(() => {
		setCheckStatus(
			localStorage.getItem('lanId') == 1
				? selectedGraphs[label]
				: selectedGraphs[translationObj[label]]
		);
	}, [checkStatus]);

	const handleChecked = () => {
		setCheckStatus(!checkStatus);
		// if (localStorage.getItem('lanId') == 2) {
		// 	selectedGraphs[translationObj[label]] =
		// 		!selectedGraphs[translationObj[label]];
		// } else {
		if (localStorage.getItem('lanId') == 1) {
			selectedGraphs[label] = !selectedGraphs[label];
		} else {
			selectedGraphs[translationObj[label]] =
				!selectedGraphs[translationObj[label]];
		}

		// }
		setSelectedGraphs(selectedGraphs);
		localStorage.setItem('selectedGraphs', JSON.stringify(selectedGraphs));
	};

	return (
		<FormGroup>
			<FormControlLabel
				control={
					<Checkbox
						checked={checkStatus}
						style={{
							color: checkStatus ? '#138C50' : 'inherit',
							'& .MuiSvgIcon-root': { borderColor: 'black' },
						}}
						onChange={handleChecked}
					/>
				}
				label={
					<Typography
						style={{
							color: label.includes('seperate')
								? checkStatus
									? '#138C50'
									: 'inherit'
								: '#138C50',
							lineHeight: langId == 1 ? 'auto' : 2,
						}}
					>
						{label}
					</Typography>
				}
			/>
		</FormGroup>
	);
};

export default CheckboxComponent;
