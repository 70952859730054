import { useState } from 'react';
import { useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';
export default function AuthUser() {
	let navigate = useNavigate();
	const getToken = () => {
		const tokenString = localStorage.getItem('token');
		const userToken = tokenString;
		return userToken;
	};

	const getUser = () => {
		const userString = localStorage.getItem('userInfo');
		const user_detail = JSON.parse(userString);
		return user_detail;
	};
	const getExpiry = () => {
		const userString = localStorage.getItem('expiry');
		const exeTime = JSON.parse(userString);
		return exeTime;
	};
	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState(getUser());
	const [expiryT, setExpiry] = useState(getExpiry());
	const saveToken = (TOKEN) => {
		localStorage.setItem('token', JSON.stringify(TOKEN.accessToken));
		let DECODE_TOKEN = jwtDecode(TOKEN.accessToken);
		localStorage.setItem('userInfo', JSON.stringify(DECODE_TOKEN));
		localStorage.setItem('expiry', DECODE_TOKEN.exp);
		setToken(token);
		setUser(user);
		if (DECODE_TOKEN != undefined) {
			navigate('/dashboard');
		} else {
			console.log('user invalid');
		}
	};
	const LocalstorageClear = () => {
		localStorage.removeItem('userInfo');
		localStorage.removeItem('token');
		localStorage.removeItem('expiry');
		localStorage.removeItem('masterData');
		localStorage.removeItem('monthlyEntryData');
		localStorage.removeItem('activeSetupTab');
		localStorage.removeItem('accordionSelection');
	};
	const logout = () => {
		LocalstorageClear();
		navigate('/');
	};

	return {
		setToken: saveToken,
		token,
		user,
		getUser,
		getToken,
		logout,
		expiryT,
		//expiryTime,
	};
}
