import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './setup.module.css';
import add from '../../../assets/frontend/images/add_green.png';
import Modal from 'react-bootstrap/Modal';
import remove from '../../../assets/frontend/images/remove.svg';
import { useMasterdata } from '../customHooks/masterDatahook';
import { useAddProductHook, useDeleteProductHook } from '../api_hooks/api_hooks';
import { Context } from '../../../frontend_context/contextProvider';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const Products = () => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    const { ProductDataList } = useContext(Context);
    const [show, setShow] = useState(false);
    const { unitList } = useMasterdata();
    const [unitListArray, setUnitListArray] = useState([]);
    const [ErrorList, setErrorList] = useState({})
    const [productValues, setProductValues] = useState({
        productName: '',
        conversionFactor: 1,
        unitId: 1
    })
    const [deleteIndex, setDeleteIndex] = useState();
    const productNameRef = useRef();
    const productNameLabelRef =useRef();
    const converRef = useRef();
    const handleClose = () => {
        setShow(false);
        setProductValues({
            productName: '',
            conversionFactor: 1,
            unitId: 1
        })
        setDisableConv(true)
    }
    const handleShow = () => {
        setDeleteIndex()
        if (ProductDataList == null) {
            setShow(true);
            setUnitListArray(unitList !== null && unitList)
        }
        else if (ProductDataList.length <= 4) {
            setShow(true);
            setUnitListArray(unitList !== null && unitList)
        }
        else {
            toast.warning(t("validationMSG.maxProductLimit"));
        }
    }
    const [disabledConv, setDisableConv] = useState(true);
    //Handle produnct select change
    const handleChange = (e) => {
        setDisableConv(false)
        if(e.target.value === '1'){
            setProductValues({
                ...productValues, unitId: e.target.value, conversionFactor: 1
            })
            setDisableConv(true)
        }
        else{
            setProductValues({
                ...productValues, unitId: e.target.value, conversionFactor: ''
            })
        }
    }
    //Handle produnct input change
    const HandleInputChanges = (e) => {
        const { name, value } = e.target;
        setProductValues({
            ...productValues, [name]: value
        })
        if(name === "productName"){
            if(value.length>0){
                productNameRef.current.style.border = "1px solid #138C50"
                productNameLabelRef.current.style.color="#138C50"
            }
            else{
                productNameRef.current.style.border = "1px solid #9E9E9E"
                productNameLabelRef.current.style.color="#383838"
            }
            
        }
        if(name ==='conversionFactor'){
            if(value.length>0){
                setErrorList({})
                converRef.current.style.border = "1px solid #138C50"
                converRef.current.style.color="#383838"
            }
            else{
                converRef.current.style.border = "1px solid #9E9E9E"
                converRef.current.style.color="#9E9E9E"
            }   
        }
    }
    //Api calling add product and AllSetup data
    const { mutate: AddProductFunc } = useAddProductHook();
    const addProduct = () => {
        let ErrorValues = ValidationFunction();
        setErrorList(ErrorValues)
        if (Object.keys(ErrorValues).length === 0) {
            let productData = {
                "product_name":productValues.productName,
                "conversion_factor": productValues.conversionFactor,
                "unit_id": productValues.unitId
            }
            AddProductFunc(productData)
            setShow(false);
            setProductValues(
                {
                    productName: '',
                    conversionFactor: 1,
                    unitId: 1
                })
            setDisableConv(true)
        }
    }
    //produnct validation
    const ValidationFunction = () => {
        let error = {}
        if (!productValues.productName) {
            error.pName = "Add product name"
        }
        if (!productValues.conversionFactor) {
            error.pCon = t("monthlylogpage.proConvFactorValid");
            converRef.current.style.border = "1px solid red"
            converRef.current.style.color="red"
        }
        return error;
    }
    //delete product box  show function
    const DeleteProductFunction = (e,index) => {
        e.stopPropagation();
        setDeleteIndex(index)
    }
    //delete Api call
    const { mutate: DeleteFunction } = useDeleteProductHook();
    //Delete function call with dta
    const produnctDelete = (e,p_id) => {
        e.stopPropagation();
        let data = {
            "product_id": p_id
        }
        DeleteFunction(data)
        setDeleteIndex();
    }
    //console.log("ProductDataList",ProductDataList);
    const disableRemovebtn =()=>{
        setDeleteIndex()
    }
    useEffect(()=>{
        document.body.addEventListener('click',disableRemovebtn)
    },[])
    return (
        <div className={style.setupWrapper}>
            <div className="">
                <div className="row">
                    <div className="col-md-6">
                        {langId === '1' ? <>
                            <p>Add the <b>products</b> (max. 5) that you manufacture and set up their unit conversions if they aren’t measured in kg.</p>
                        </> :
                            <>
                                <p>ကုန်ပစ္စည်း အများဆုံး ၅ မျိုး ဖြည့်နိုင်ပါသည်။ ၎င်းတို့ကို ကီလိုဂရမ်ဖြင့် တိုင်းတာခြင်းမရှိပါက ယူနစ်များ ပြောင်းလဲပြီး အများဆုံး <b>ကုန်ပစ္စည်း</b> ၅ ခု အထိ ဖြည့်သွင်းနိုင်ပါသည်။ </p>
                            </>}
                        <p><b>{t("generalText.NOTE")}</b> {t("setuppage.ProductsTabNoteText")}</p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <button
                            className={ProductDataList != null && ProductDataList.length > 4 ? "add_btn_disable buttonFlex" : "add_btn buttonFlex"}
                            onClick={handleShow}
                            type="button"
                            disabled={ProductDataList != null && ProductDataList.length > 4 ? true : false}
                        >
                            {ProductDataList == null ||(ProductDataList.length> 0 && ProductDataList.length !=5) ?<img src={add} alt="REMS" />:null}
                            {ProductDataList == null ||(ProductDataList.length> 0 && ProductDataList.length !=5) ?<div>{ProductDataList != null && ProductDataList.length > 0 ? <span style={{ fontSize: '14px', fontWeight: "400" }}>{"("}<b>{ProductDataList != null &&ProductDataList.length}</b>{"/5 "+t("setuppage.ProductsAdded")}{")"}</span> : t("setuppage.ProductsTabAddButton")}
                                </div>:<div className='py-2 py-md-0' style={{color:"#A6A6A6", fontSize:"11px"}}>{t("validationMSG.maxProductLimit")}</div>}
                            </button>

                        <Modal className="modalStyle setUpmodalInner" show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                            <CloseIcon className={style.closeIconPop} onClick={() => setShow(false)} />
                            <Modal.Header><h2 style={{ paddingTop: "10px" }}>{t("setuppage.AddProductPopUpHeaderText")}</h2></Modal.Header>

                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12 px-2">
                                        <p className='mb-0'>{t("setuppage.AddProductPopUpSubText")}</p>
                                    </div>
                                    <div className="col-12 mb-3 px-2">
                                        <form>
                                            <div className="floting-form">
                                                <input
                                                    type="text"
                                                    name="productName"
                                                    className="form-input"
                                                    placeholder="Product name"
                                                    autoComplete="off"
                                                    value={productValues.productName}
                                                    onChange={(e) => HandleInputChanges(e)}
                                                    style={{ paddingLeft: "6px" }}
                                                    ref = {productNameRef}
                                                />
                                                <label htmlFor="email" className="form-label fl-white"
                                                    ref={productNameLabelRef}
                                                    style={{ left: "4px",fontWeight:"600",transform:"scale(0.9)" }}
                                                >{t("setuppage.AddProductPopUpPlaceholder")}</label>

                                            </div>
                                            <div className='text-danger errorText'>{ErrorList.pName}</div>
                                        </form>
                                    </div>
                                    <div className="col-12 mb-4 px-2">
                                        <p>{t("setuppage.AddProductPopUpParaOne")}</p>
                                    </div>
                                    <div className="col-12 mb-4 pl-2">
                                        <div className="row">
                                            <div className="col-6 px-2">
                                                <input
                                                    type="number"
                                                    name="conversionFactor"
                                                    className={"form-control convPlace "+style.inputBgColor}
                                                    placeholder={t("generalText.conversionFactor")}
                                                    value={productValues.conversionFactor}
                                                    disabled={disabledConv}
                                                    onChange={(e) => HandleInputChanges(e)}
                                                    maxLength={6}
                                                    style={{ padding: "5px 8px", textAlign: "right" }}
                                                    ref={converRef}
                                                   
                                                />

                                            </div>

                                            <div className="col-3 px-0">
                                                <select
                                                    className={'form-select '+ style.selectHover}
                                                    value={productValues.unitId}
                                                    onChange={handleChange}
                                                    style={{ padding: "6px 8px",fontWeight:"600",fontSize:"13px" }}
                                                >
                                                    {unitListArray.length > 0 && unitListArray.map((item, index) => <option key={index} value={item.unit_id}>{item.unit_name}</option>)}
                                                </select>

                                            </div>

                                            <div className="col-3 pt-2"> <b>= 1 kg</b> </div>
                                            <div className='text-danger errorText'>{ErrorList.pCon}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center px-2">
                                        <button
                                            className={productValues.productName === '' ? "disable_primary w-100" : "btn_primary w-100"}
                                            type="button"
                                            onClick={addProduct}
                                            disabled={productValues.productName === '' ? true : false}
                                        >{t("setuppage.Saveproduct")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 px-4 px-md-1'>
                        <div className={style.productsList}>
                            {ProductDataList != null && ProductDataList.map((item, index) => <div className={style.card} key={index}>
                                {deleteIndex === index ? <div className={style.deletcard}><div className={style.deletcardHolder}>
                                    <div
                                        onClick={(e) => produnctDelete(e,item.product_id)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img src={remove} alt="REMS" /> {t("generalText.remove")}
                                    </div></div></div> :
                                    null}
                                <div>
                                    <h6>{item.product_name}</h6>
                                    <p style={{textTransform:"lowercase"}}>{item.conversion_factor} {item.unit_name} = 1 kg</p>
                                </div>
                                <button type="button" onClick={(e) => DeleteProductFunction(e,index)}></button>
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Products;