import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const RawMaterials = ({
    materialJson,
    RawMaterialInputChange,
    saveRawmaterial,
    completeRawMaterials,
    RawMaterialIncomplete
}) => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    return (
        <>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="separator"></div>
                </div>
            </div>

            <div className={style.section + ' ' + style.sec_2a}>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3><span>2a</span>{t("monthlylogpage.RawMaterialsHeaderText")}</h3>
                        <p>{t("monthlylogpage.RawMaterialsSubText")}</p>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-md-6 ">
                        <div className='row'>
                            {materialJson.length > 0 && materialJson.map((material, rawMaIndex) => <div key={rawMaIndex} className="col-md-8 mb-3">
                                <div>
                                    <label>{material.raw_material}</label>
                                </div>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name="quantity"
                                        onWheel={(e) => e.target.blur()}
                                        className="form-control monthlylogInput"
                                        placeholder={(completeRawMaterials === 1 && material.quantity === null) || (material.quantity === '') ? t("monthlylogpage.IncompleteField") : ''}
                                        value={material.quantity === null ? '' : material.quantity}
                                        onChange={(e) => RawMaterialInputChange(e, rawMaIndex, 'material')}
                                        style={{
                                            border: material.quantity === null && completeRawMaterials === 1 ? "1px solid #F65B3C" :
                                                material.quantity === '' && completeRawMaterials === 1 ? "1px solid #F65B3C" :
                                                    material.quantity === null && completeRawMaterials === 0 ? "1px solid #A6A6A6" :
                                                        material.quantity === '' && completeRawMaterials === 0 ? "1px solid red" :
                                                            "1px solid #138C50"
                                        }}
                                    />
                                    <span
                                        className="input-group-text spanBorderinput"
                                        style={{
                                            border: material.quantity === null && completeRawMaterials === 1 ? "1px solid #F65B3C" :
                                                material.quantity === '' && completeRawMaterials === 1 ? "1px solid #F65B3C" :
                                                    material.quantity === null && completeRawMaterials === 0 ? "1px solid #A6A6A6" :
                                                        material.quantity === '' && completeRawMaterials === 0 ? "1px solid red" :
                                                            "1px solid #138C50",
                                            padding: "8px 46px 8px 10px"
                                        }}
                                    >{material.unit_name}</span>
                                </div>
                            </div>)}
                            <div className="col-md-4 mb-3 pe-5 pe-md-0 paddingButtonRight"
                                style={{
                                    display: 'flex',
                                    alignItems: window.innerWidth>768?'end':'center',
                                    justifyContent: window.innerWidth>768?"start":"end",
                                }}>
                                {completeRawMaterials === 1 && RawMaterialIncomplete === 1 ? <p className='float-start d-block d-md-none form_msg'>{t("monthlylogpage.completedSection")}</p> : null}

                                <button
                                    className={
                                        completeRawMaterials == 0 ?
                                            "btn btn-primary mw-11 float-md-start float-end" : "btn btn-primary_disable mw-11 float-md-start float-end"}
                                    type="button"
                                    onClick={saveRawmaterial}
                                    style={{ fontSize: langId == 1 ? "13px" : "10px", padding: "6px 6px" }}
                                >{completeRawMaterials == 0 ? t("monthlylogpage.EnergyInputSaveButton") : t("monthlylogpage.SaveButton")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 d-none d-md-block position-relative'>
                        {completeRawMaterials === 1 && RawMaterialIncomplete === 1 ? <p className='form_msg' style={{height:langId!=='1'?36:"auto",display:"flex",alignItems:"baseline"}}>{t("monthlylogpage.completedSection")}</p> : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default RawMaterials;