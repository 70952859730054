export const HeaderUtil = [
    "createnewaccount",
    "privacy",
    "terms",
    "help",
    "about",
    "contact",
    "forgot-password",
    "resetpassword",
    "verify-your-account",
    "emailverify",
    "active-your-account"
]