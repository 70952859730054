
import StaticTab from './staticTab';
import style from './style.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, ListItem} from '@mui/material';
import { useTranslation } from 'react-i18next';

const Help = () => {
    let langId = localStorage.getItem("lanId");
    const { t } = useTranslation();
    const SubHeadingStyle = {
        styleOne: {
            color: "#138C50",
            fontWeight: "bold",
            fontSize: "17px"
        },
        styleTwo: {
            color: "#383838",
            fontWeight: "bold",
            fontSize: "15px"
        }
    }
    return (
        <div className={"py-4 "+style.height}>
              <div style={{marginTop:window.innerWidth >768?"78px":"90px"}}>
               <StaticTab/>
            </div>
            <div className={"container "+style.help}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography color={SubHeadingStyle.styleOne}>{t("helppage.account")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography color={SubHeadingStyle.styleTwo}>{t("helppage.account_1_1")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {/* <ListItem>{t("helppage.account_1_1_1")}</ListItem> */}
                                    <ListItem>{t("helppage.account_1_1_2")}</ListItem>
                                    <ListItem>{t("helppage.account_1_1_3")}</ListItem>
                                    <ListItem>{t("helppage.account_1_1_4")}</ListItem>
                                    <ListItem>{t("helppage.account_1_1_5")}</ListItem>
                                    <ListItem>{t("helppage.account_1_1_6")}</ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography color={SubHeadingStyle.styleTwo}>{t("helppage.account_1_2")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>{t("helppage.account_1_2_1")}</ListItem>
                                    <ListItem>{t("helppage.account_1_2_2")}</ListItem>
                                    <ListItem>{t("helppage.account_1_2_3")}</ListItem>
                                    <ListItem>{t("helppage.account_1_2_4")}</ListItem>
                                </List>
                                <Typography>
                                    {t("helppage.account_1_2_sub")}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography color={SubHeadingStyle.styleTwo}>{t("helppage.account_1_3")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>{t("helppage.account_1_3_1")}</ListItem>
                                    <ListItem>{t("helppage.account_1_3_2")}</ListItem>
                                    <ListItem>{t("helppage.account_1_3_3")}</ListItem>
                                    <ListItem>{t("helppage.account_1_3_4")}</ListItem>
                                    <ListItem>{t("helppage.account_1_3_5")}</ListItem>
                                    <ListItem>{t("helppage.account_1_3_6")}</ListItem>
                                    <ListItem>{t("helppage.account_1_3_7")}</ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}

                            >
                                <Typography color={SubHeadingStyle.styleTwo}>{t("helppage.account_1_4")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>{t("helppage.account_1_4_1")}</ListItem>
                                    <ListItem>{t("helppage.account_1_4_2")}</ListItem>
                                    <ListItem>{t("helppage.account_1_4_3")}</ListItem>
                                    <ListItem>{t("helppage.account_1_4_4")}</ListItem>
                                    <ListItem>{t("helppage.account_1_4_5")}</ListItem>
                                    <ListItem>{t("helppage.account_1_4_6")}</ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography color={SubHeadingStyle.styleTwo}>{t("helppage.account_1_5")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>{t("helppage.account_1_5_1")}</ListItem>
                                    <ListItem>{t("helppage.account_1_5_2")}</ListItem>
                                    <ListItem>{t("helppage.account_1_5_3")}</ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography color={SubHeadingStyle.styleOne}>{t("helppage.Homepage")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t("helppage.HomeSubOne")}
                        </Typography>
                        <Typography style={{ paddingTop: 10 }}>
                            {t("helppage.HomeSubTwo")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography color={SubHeadingStyle.styleOne}>{t("helppage.SetupAcc")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {langId === '1' ? <Typography>
                            The Set up stage has 3 tabs which can be freely cycled through – Products, Input Resources and Waste Generated. You can add a maximum 5 products, 12 raw materials, 2 boiler fuels and 5 types of waste to your monitoring list in each of these categories.
                        </Typography> :
                            <Typography>
                                တည်ဆောက်ခြင်း အဆင့်တွင် Products/ထုတ်ကုန်ပစ္စည်းများ၊ Input Resources/ ထည့်သွင်းထားသည့်အရင်းအမြစ်ပစ္စည်းများ နှင့် Waste Generated/စွန့်ပစ်ပစ္စည်းထုတ်လုပ်မှု ဟူ၍  အတားအဆီးမရှိ လည်ပတ်နိုင်သော အဆင့်(၃)ဆင့်ရှိပါသည်။ ကဏ္ဍတစ်ခုချင်းဆီတိုင်းတွင် အများဆုံး ထုတ်ကုန် ၅ခု၊ အကြမ်းထည်ပစ္စည်း ၁၂ ခု၊ ဘွိုင်လာလောင်စာ ၂ခု နှင့် စွန့်ပစ်ပစ္စည်းအမျိုးအစား ၅မျိုး စသည်တို့ကို သင်စစ်ဆေးမည့် စရင်းတွင် ထည့်နိုင်ပါသည်။
                            </Typography>
                        }
                        {langId === '1' ? <Typography style={{ paddingTop: 10 }}>
                            In order to remove any added entry, click/tap on the kebab menu (︙) on the card and click/tap on the  (<b style={{ color: "#138C50" }}>✖</b> REMOVE) overlay that pops up. Click/Tap elsewhere on the screen to close this action, in case you do not want to remove the entry. Once you think you have added all the products, input resources and wastes that you want to monitor, you can complete the Set up stage by clicking/tapping on the Finish Set up button.
                        </Typography> :
                            <Typography style={{ paddingTop: 10 }}>
                                ထပ်ဝင်လာသည် Tabများကို ဖယ်ရှားရန်အတွက် ကတ်ပေါ်ရှိ kebab menu (︙) ကို နှိပ်ပြီးနောက်
                                ထပ်ဆင့် ပေါ်လာသည့် Tab များအား (<b style={{ color: "#138C50" }}>✖</b> REMOVE)  နှိပ်ပါ။ အကယ်၍ ဝင်လာသည့် Tab ကို မဖယ်ရှားချင်ပါက၊ လုပ်ဆောင်ချက်ကို ပိတ်ရန် Screen ပေါ်ရှိ အခြားနေရာကို နှိပ်ပါ။ သင်စစ်ဆေးထားသော ထုတ်ကုန်ပစ္စည်းများ၊ ထည့်သွင်းထားသည့်အရင်းအမြစ်ပစ္စည်းများ နှင့် စွန့်ပစ်ပစ္စည်းများထည့်ပြီးသည်နှင့် Set up/တည်ဆောက်ပါ ခလုတ်ကို နှိပ်ပြီး
                                Set up/တည်ဆောက်ခြင်း အဆင့်ကို အဆုံးသတ်နိုင်ပါသည်။

                            </Typography>}
                        {langId === '1' ? <Typography style={{ paddingTop: 10 }}>
                            <b>Note :</b> Until you complete Set up, you can still keep moving freely between the Set up page and your homepage using the Back (←) button on the top left corner of your screen. This will not delete any of your saved entries and it will not finish Set up, and Monthly Entry will remain locked.
                        </Typography> :
                            <Typography style={{ paddingTop: 10 }}>
                                <b>မှတ်ချက် :</b> Setup/ တည်ဆောက်ခြင်း အဆင့် မပြီးမချင်း၊ သင့် Screen ၏ဘယ်ဘက်အပေါ်ထောင့်ရှိ Back (←) ခလုတ်ကို အသုံးပြု၍ Set upလုပ်ထားသည့် စာမျက်နှာနှင့် ပင်မစာမျက်နှာအကြား ချောချောချုချု သွားနိုင်သည်။ ၎င်းသည် သင်သိမ်းဆည်းထားသည့်အရာများအားလုံးကို ဖျက်မည်မဟုတ်သည့်အပြင် ၎င်းသည် Set up လုပ်ခြင်းကို အပြီးသတ်မည်မဟုတ်ပါ။ ထို့ပြင် လစဥ်ဝင်ရောက်နေသော အရာများကိုလည်း လုံခြုံစွာသိမ်းထားပေးပါသည်။
                            </Typography>}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography color={SubHeadingStyle.styleOne}>{t("helppage.MonthlyEntryAcc")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {langId === '1' ? <Typography>
                            <Typography>
                                The monitoring period will be for a <b>maximum of 60 months</b>, depending on the starting month & year that you choose for the first time that you first enter this stage. After the monitoring period has been set up, you can choose any month on the calendar list to start entering in data for.
                            </Typography>
                            <Typography style={{ paddingTop: 10 }}>
                                Each month has its own log, where you will have to add in the required quantities, which will depend on <b>Products, Input Resources</b> and <b>Waste Generated</b> that you identified during the <b>Set up</b> stage. There will also be a few extra fields that are standard for all users, regardless of what they added in the Set up stage.
                            </Typography>
                            <Typography style={{ paddingTop: 10 }}>
                                Empty fields will be marked as <b style={{ color: "red" }}>“Incomplete field!”</b> upon clicking/tapping <b>Save & Next</b>. Completed fields will have a green check mark and a message indicating the same.
                            </Typography>
                            <Typography style={{ paddingTop: 10 }}>
                                You can also add a comment for a month by clicking on the <b>Add comment</b> button located at the top right corner of your page.
                            </Typography>
                        </Typography> :
                            <Typography>
                                <Typography>
                                    ဤအဆင့်တွင် သင်စတင်ဝင်ရောက်သည့် ပထမဦးဆုံးအကြိမ်တွင် ရွေးချယ်သည့် ပထမဆုံးလနှင့် နှစ်ပေါ်မူတည်၍ စစ်ဆေးနေစဥ်ကာလ အများဆုံး လပေါင်း ၆၀ ကြာတတ်ပါသည်။
                                    စောင့်ကြည့်ကာလ ပြီးမြောက်သွားပါက၊ ဒေတာများ စတင်ထည့်သွင်းရန်  ပြက္ခဒိန်စာရင်းတွင် မည်သည့်လကိုမဆို သင်ရွေးချယ်လို့ရပါမည်။

                                </Typography>
                                <Typography style={{ paddingTop: 10 }}>
                                    Set up stage/ တည်ဆောက်ခြင်း အဆင့်တွင် ရွေးချယ်ဖော်ထုတ်ထားသော Products/ထုတ်ကုန်ပစ္စည်းများ၊ Input Resources/ ထည့်သွင်းထားသည့်အရင်းအမြစ်ပစ္စည်းများ နှင့် <b>Waste Generated</b>/ စွန့်ပစ်ပစ္စည်းထုတ်လုပ်မှု ပေါ်တွင် မူတည်ပြီး လိုအပ်သော ပမာဏများကို ဖြည့်ရန် ၎င်းတွင် ကိုယ်ပိုင်လစဥ်မှတ်တမ်းရှိသည်။
                                </Typography>
                                <Typography style={{ paddingTop: 10 }}>
                                    Set up stage/တည်ဆောက်ခြင်း အဆင့်  မှာ မည်သည့်ပါဝင်ပစ္စည်း ပါဝင်သည်ဖြစ်စေ သုံးစွဲသူများအတွက် အပိုကွက်လပ်များ ရှိသော်လည်း
                                    စံနှုန်းတစ်ခု အမြဲရှိပါသည်။ <b>Save & Next</b>/သိမ်းဆည်းပြီး ရှေ့ဆက်မည် ကိုနှိပ်ပြီး  လစ်လပ်နေသော ကွက်လပ်များကို <b style={{color:"red"}}>“Incomplete field”</b> “မပြည့်စုံသောကွက်လပ်များ” ဟု သတ်မှတ်နိုင်ပါသည်။  ပြည့်စုံသွားသောအကွက်များအတွက် အစိမ်းရောင်အမှတ် နှင့် စာသား လက်ခံရရှိပါမည်။

                                </Typography>
                                <Typography style={{ paddingTop: 10 }}>
                                    သင့်စာမျက်နှာ၏ ညာဘက်အပေါ်ထောင့်ရှိ <b>Add comment/</b> မှတ်ချက်ရေးရန် ခလုတ်ကိုနှိပ်ခြင်းဖြင့် တစ်လအတွက် မှတ်ချက်တစ်ခု ထည့်သွင်းနိုင်သည်။
                                </Typography>
                            </Typography>}

                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography color={SubHeadingStyle.styleOne}>{t("helppage.OutputAcc")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t("helppage.outputSubOne")}
                        </Typography>
                        <Typography style={{ marginTop: 20 }}>
                           {t("helppage.outputSubtwo")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}
export default Help;