import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import activateMail from '../../../assets/admin/images/activateMail.png';

const VerifyAccounts = () => {
	return (
		<Stack
			sx={{ height: '90vh', justifyContent: 'center', alignItems: 'center' }}
		>
			<Typography
				variant="h5"
				fontWeight="bold"
				sx={{ color: 'primary.main', mb: 6 }}
			>
				Verify your account.
			</Typography>
			<Box sx={{ mb: 3 }}>
				<img src={activateMail} width="120px" />
			</Box>
			<Typography
				fontSize="17px"
				fontWeight="bold"
				sx={{ color: 'text.primary' }}
				gutterBottom
			>
				Click on the verification link sent to
			</Typography>
			<Typography
				fontSize="15px"
				sx={{ color: 'text.primary', mb: 3 }}
				gutterBottom
			>
				johndoe123@gmail.com
			</Typography>
			<Box
				style={{
					height: '3.5px',
					width: '100px',
					backgroundColor: 'lightgray',
				}}
				sx={{ mb: 3 }}
			></Box>
			<Typography
				fontSize="14px"
				sx={{ color: 'text.primary', mb: 1.5 }}
				gutterBottom
			>
				If you haven't recieved the email yet-
			</Typography>
			<Typography
				fontSize="14px"
				fontWeight="bold"
				sx={{ color: 'text.primary' }}
				gutterBottom
			>
				Check your spam folder
			</Typography>
			<Typography fontSize="17px" sx={{ color: 'text.primary' }}>
				OR
			</Typography>
			<Typography
				fontSize="20px"
				fontWeight="bold"
				sx={{ color: 'text.primary', textDecoration: 'underline' }}
				gutterBottom
			>
				Resend
			</Typography>
		</Stack>
	);
};

export default VerifyAccounts;
