import axios from 'axios';
import { app_key, app_name, cmsUrl } from '../constant';
import { toast } from 'react-toastify';

export const axiosInstance = axios.create({
	baseURL: cmsUrl,
	// other configuration options
});

axiosInstance.interceptors.request.use(
	(config) => {
		if (localStorage.getItem('adminToken')) {
			config.headers['Authorization'] = `Bearer ${JSON.parse(
				localStorage.getItem('adminToken')
			)}`;
		}
		config.headers['Content-Type'] = 'application/json';
		config.headers['app-name'] = app_name;
		config.headers['app-key'] = app_key;
		config.headers['Accept-Language'] = app_name;

		return config;
	},
	(error) => {
		// Handle request error
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		// Process the response data before it is resolved
		// e.g., parse response, handle errors, etc.

		return response?.data;
	},
	(error) => {
		// Handle response error
		if (error?.response?.status && error.response.status == 400) {
			toast.error(error.response.data.error.message);
		}
		if (error?.response?.status && error.response.status == 401) {
			toast.error(error.response.data.error.message);
		}
		if (error?.response?.status && error.response.status == 500) {
			toast.error(error.response.data.error.message);
		}

		return Promise.reject(error);
	}
);
