
import style from './style.module.css'
import Logo from '../../../assets/frontend/images/logoabout.png';
import SwitchAsia from '../../../assets/frontend/images/switchasia.png';
import StaticTab from './staticTab';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
let LangId = localStorage.getItem("lanId")

const About = () => {
    const {t} = useTranslation();
    useEffect(()=>{
        window.scrollTo({
            top: 3,
            left: 0,
          });
    },[])
    return (
        <div className={"py-4 "+style.height}>
            <div style={{marginTop:window.innerWidth >768?"78px":"90px"}}>
             <StaticTab/>
            </div>
            <div className={"container "+style.aboutClass}>
                <p>
                    {t("aboutpage.AboutInfoText")}
                </p>
                <p>{t("aboutpage.AboutListInfoText")}</p>
                <ul>
                    <li><span>{t("aboutpage.a")}. </span>{t("aboutpage.AboutListOne")}</li>
                    <li><span>{t("aboutpage.b")}. </span>{t("aboutpage.AboutListTwo")}</li>
                    <li><span>{t("aboutpage.c")}. </span>{t("aboutpage.AboutListThree")}</li>
                </ul>
                {LangId==='1'?<div>
                    <p>The Ref-Track platform is provided by <b>Prevent Plastics</b> for the internal use by any enterprise.</p>
                    <p>Started in May 2020, Prevent Plastics is a four-year project funded by the European Union with the aim to promote sustainable production and consumption patterns in Myanmar through raised awareness and best practices on waste management. The project is a joint approach of sequa gGmbH, Myanmar Banks Association and STENUM Asia. </p>
                    <div>This platform has been developed as a part of the <b>Prevent Plastics</b> project and maintained with the financial support of the <span style={{color:"#138C50"}}>European Union’s SWITCH-Asia</span> grants program. The contents displayed on it can be under no circumstances be regarded as the reflecting the position of the European Union.</div>
                </div>:
                <div>
               
                <p>Ref-Track ကို လုပ်ငန်း၏ အတွင်းပိုင်း လုပ်ငန်းစဥ်များအတွက် အသုံးပြုရန် <b>Prevent Plastics</b> မှ ပံ့ပိုးထားပါသည်။"</p>
                <p>Prevent Plastics ဟာ European Union မှထောက်ပံ့ပေးထားတဲ့ လေးနှစ်တာစီမံကိန်း ဖြစ်ပြီး မြန်မာနိုင်ငံတွင် ရေရှည်တည်တံ့ခိုင်မြဲသော ထုတ်လုပ်မှုနှင့် စားသုံးမှုပုံစံများကို မြှင့်တင်ရန် ရည်ရွယ်ချက်ဖြင့် အမှိုက်စီမံခန့်ခွဲမှုဆိုင်ရာ အသိပညာပေးခြင်းနှင့် အကောင်းဆုံးအလေ့အကျင့်များကို တိုးတက်စေရန်အတွက် မေလ ၂၀၂၀ခုနှစ်မှ စတင်ခဲ့တဲ့ အဖွဲ့အစည်းတစ်ခုဖြစ်ပါတယ်။ အဆိုပါစီမံကိန်းဟာ sequa gGmbH၊ မြန်မာနိုင်ငံဘဏ်များအသင်းနှင့် STENUM Asia တို့၏ ပူးပေါင်ဆောင်ရွက်မှုတစ်ခုဖြစ်သည်။</p>
                <div>ဤနေရာဟာ Prevent Plastics Project၏ တစ်စိတ်တစ်ပိုင်းအဖြစ် ဖန်တီးထားပြီး European Union ရဲ့ SWITCH-Asia ထောက်ပံ့ကြေးအစီအစဉ်၏ ဘဏ္ဍာရေးပံ့ပိုးမှုဖြင့် ထိန်းသိမ်းထားသည်။ အထက်ဖော်ပြပါအကြောင်းအရာများဟာ <span style={{color:"#138C50"}}>European Union</span>၏ ရပ်တည်ချက်ကို ထင်ဟပ်စေသည့် အခြေအနေဟု သတ်မှတ်၍မရနိုင်ပါ။</div>
            </div>}
                <div className='pt-4'><img src={Logo} alt="logo" style={{width:"60px",marginRight:"10px"}}/><img src={SwitchAsia} alt="SwitchAsia" className='img-fluid' style={{maxWidth:"250px"}}/></div>
            </div>
        </div>
    )
}
export default About