import React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import { isError, useQuery } from 'react-query';
import { getGeneralStats } from '../../../services/AdminApis';
import { axiosInstance } from '../../../services/AdminDataServices';
import Loader from './Loader';
import RequestError from './RequestError';

const GeneralStats = () => {
	const { data, isLoading, isError, error } = useQuery(
		'generalStats',
		getGeneralStats
	);

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	return (
		<Box padding="45px 110px 6px">
			<Stack spacing={5}>
				<Grid container alignItems="center">
					<Stack sx={{ mr: 12, mb: 1 }}>
						<Typography fontWeight="bold" color="text.primary" fontSize="16px">
							View Rems general stats
						</Typography>
					</Stack>
				</Grid>
				<Box>
					<Typography fontSize="12px" color="text.primary">
						Total number of users subscribed to the platform
					</Typography>
					<Typography variant="h4" color="text.primary" fontWeight="700">
						{data && data[0]?.users_subscribed_count}
					</Typography>
				</Box>
				<Box>
					<Typography fontSize="12px" color="primary">
						Number of currently active users (in the past 3 months)
					</Typography>
					<Typography variant="h4" color="primary" fontWeight="700">
						{data && data[0]?.active_users_count}
					</Typography>
				</Box>
				<Box>
					<Typography fontSize="12px" color="error.main">
						Number of blocked users
					</Typography>
					<Typography variant="h4" color="error.main" fontWeight="700">
						{data && data[0]?.blocked_users_count}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

export default GeneralStats;
