import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from './components/frontend/header/header';
import Footer from './components/frontend/footer/footer';
import Landing from './components/frontend/landing/landing';
import AdminRoutes from './AdminRoutes';
import './App.css';
import ForgotPassword from './components/frontend/forgotPassword/forgotPassword';
import CreateNewAccount from './components/frontend/createNewAccount/createNewAccount';
import ResetPassword from './components/frontend/resetPassword/resetPassword';
import ActiveAccount from './components/frontend/activeAccount/activeAccount';
import Dashboard from './components/frontend/dashboard/dashboard';
import Setup from './components/frontend/setup/setup';
import { ContextProvider } from './frontend_context/contextProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FrontendTokenExpiry } from './components/frontend/authUser/frontendTokenExpiry';
import About from './components/frontend/staticPages/about';
import Contact from './components/frontend/staticPages/contact';
import Privacy from './components/frontend/staticPages/privacy';
import Terms from './components/frontend/staticPages/terms';
import Help from './components/frontend/staticPages/help';
import SetupMonitoring from './components/frontend/monthlyEntry/setupmonitoring';
import MonthlyEntry from './components/frontend/monthlyEntry/monthlyentry';
import MonthlyLog from './components/frontend/monthlyEntry/monthlylog';
import Output from './components/frontend/output/output';
import NotFound from './components/frontend/pageNotFound/pageNoteFound';
import Faq from './components/frontend/staticPages/faq';
import Pdf from './components/frontend/pdf/Pdf';
import ActiveNewAccount from './components/frontend/createNewAccount/activeYourAccount';
import EmailConformation from './components/frontend/createNewAccount/emailConform';

const App = () => {
let langId = localStorage.getItem('lanId');
const location = useLocation();
const currentPath = location.pathname;
if (localStorage.getItem('lan') === 'en') {
	localStorage.setItem('lanId', 1);
} else if (localStorage.getItem('lan') === null) {
	localStorage.setItem('lan', 'bu'); //default language set here
} else {
	localStorage.setItem('lanId', 2);
}
return (
	<div>
		<ContextProvider>
			{!currentPath.startsWith('/admin') && <Header />}
			<div style={{ lineHeight: langId == 2 ? '28px' : '26px' }}> 
				<Routes>
					<Route path="/" element={<Landing />} />
					<Route path="/createnewaccount" element={<CreateNewAccount />} /> 
					<Route path="/resetpassword" element={<ResetPassword />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/verify-your-account" element={<ActiveAccount />} />
					<Route path = "/active-your-account" element={<ActiveNewAccount/>} />
					<Route path = "/emailverify" element={<EmailConformation/>}/>
					<Route path="/about" element={<About />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/help" element={<Help />} />
					<Route path="faq" element={<Faq />} />
						<Route
							path="*"
							element={
								<Navigate to={!FrontendTokenExpiry(localStorage.getItem('expiry'))?"notFound":"/"} state={{ from: currentPath }} replace />
							}
						/>
					{!FrontendTokenExpiry(localStorage.getItem('expiry')) ? ( 
						<>
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/setup" element={<Setup />} />
							<Route
								path="/monthlyentry/setupmonitoring"
								element={<SetupMonitoring />}
							/>
							<Route
								path="/monthlyentry/monthlyentry-calendar"
								element={<MonthlyEntry />}
							/>
							<Route
								path="/monthlylog/:year/:month"
								element={<MonthlyLog />}
							/>
							<Route path="/output" element={<Output />} />
							<Route path="/pdf" element={<Pdf />} />

						</>
					) : (
						<Route path="*" element={<Navigate replace to="/" />} />
					)}

					{/*  Admin routes start here */}
					<Route path="/admin/*" element={<AdminRoutes />} />
					<Route path="/notFound" element={<NotFound />} />
						<Route
							path="*"
							element={
								<Navigate to="notFound" state={{ from: currentPath }} replace />
							}
						/>
				</Routes>
			</div>
			{currentPath === '/dashboard' || currentPath === '/' &&
				!currentPath.startsWith('/admin') ? (
				<Footer />
			) : null}
			<ToastContainer />
		</ContextProvider>
	</div>
 );
};
export default App;
