import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GeneralStats from '../components/GeneralStats';
import TotalWaste from '../components/TotalWaste';
import DisposalMethod from '../components/DisposalMethod';
import NetRevenue from '../components/NetRevenue';
import MaterialConsumption from '../components/MaterialConsumption';

const Dashboard = () => {
	const [currentTheme, setCurrentTheme] = useState('general');

	const leftMargins = {
		general: '57px',
		waste: '218px',
		disposal: '381px',
		net: '536px',
		specific: '718px',
	};

	return (
		<>
			<Box padding="45px 110px 6px">
				<Grid container>
					<Grid
						container
						alignItems="center"
						spacing={11}
						color="text.main"
						style={{ cursor: 'pointer' }}
					>
						<Grid item onClick={() => setCurrentTheme('general')}>
							<Typography
								fontSize="16px"
								textAlign="center"
								fontWeight={currentTheme == 'general' ? '700' : '400'}
								color={currentTheme == 'general' ? 'primary.main' : 'text.main'}
							>
								General <br />
								Stats
							</Typography>
						</Grid>
						<Grid item onClick={() => setCurrentTheme('waste')}>
							<Typography
								fontSize="16px"
								textAlign="center"
								fontWeight={currentTheme == 'waste' ? '700' : '400'}
								color={currentTheme == 'waste' ? 'primary.main' : 'text.main'}
								style={{ wordSpacing: '100vw' }}
							>
								Waste <br />
								Categories
							</Typography>
						</Grid>
						<Grid item onClick={() => setCurrentTheme('disposal')}>
							<Typography
								fontSize="16px"
								textAlign="center"
								fontWeight={currentTheme == 'disposal' ? '700' : '400'}
								color={
									currentTheme == 'disposal' ? 'primary.main' : 'text.main'
								}
							>
								Disposal <br />
								Methods
							</Typography>
						</Grid>
						<Grid item onClick={() => setCurrentTheme('net')}>
							<Typography
								fontSize="16px"
								textAlign="center"
								fontWeight={currentTheme == 'net' ? '700' : '400'}
								color={currentTheme == 'net' ? 'primary.main' : 'text.main'}
							>
								Net
								<br /> Revenue
							</Typography>
						</Grid>
						<Grid item onClick={() => setCurrentTheme('specific')}>
							<Typography
								fontSize="16px"
								fontWeight={currentTheme == 'specific' ? '700' : '400'}
								color={
									currentTheme == 'specific' ? 'primary.main' : 'text.main'
								}
								textAlign="center"
							>
								Specific Material <br />
								Consumption
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box
				style={{
					height: '3px',
					width: '90%',
					backgroundColor: '#F2F2F2',
					margin: '0px 60px',
				}}
			>
				<Box
					style={{
						height: '3px',
						width: '3.5%',
						backgroundColor: '#138C50',
						marginLeft: leftMargins[currentTheme],
						borderRadius: '50px',
					}}
				></Box>
			</Box>
			{currentTheme === 'general' && <GeneralStats />}
			{currentTheme === 'waste' && <TotalWaste />}
			{currentTheme === 'disposal' && <DisposalMethod />}
			{currentTheme === 'net' && <NetRevenue />}
			{currentTheme === 'specific' && <MaterialConsumption />}
		</>
	);
};

export default Dashboard;

//4.2%,15.75% 27.7% 38.9%
