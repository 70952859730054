import { Box, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import edit from '../../../assets/admin/images/edit.png';
import save from '../../../assets/admin/images/save.png';
import { useMutation, useQueryClient } from 'react-query';
import {
	updateBoilerData,
	updateUnitData,
	updateWasteData,
} from '../../../services/AdminApis';
import { toast } from 'react-toastify';
import Loader from './Loader';
import RequestError from './RequestError';
import dontEditIcon from '../../../assets/admin/images/Icon_edit_pencil-off.png';
import redChart from '../../../assets/admin/images/red - chart-line-3.png';
import blueChart from '../../../assets/admin/images/blue - chart-line-1.png';
import greenChart from '../../../assets/admin/images/green - chart-line.png';
import violetChart from '../../../assets/admin/images/violet - chart-line-4.png';
import yellowChart from '../../../assets/admin/images/yellow - chart-line-2.png';

const EditableComponent = ({
	value,
	label = 'default',
	theme,
	themeObject = {},
	object_key = '',
	id,
	editStatus,
}) => {
	const valueRef = useRef(null);
	const [fieldValue, setFieldValue] = useState(value);
	const [fieldState, setFieldState] = useState('none');
	const [readOnlyState, setReadOnlyState] = useState(true);

	const queryClient = useQueryClient();

	const boiler = useMutation('updateBoilerData', updateBoilerData, {
		retry: 0,
		onSuccess: () => {
			toast.success('Successfully updated Boiler fuel data.');
			queryClient.invalidateQueries('DatabaseData');
		},
	});

	const waste = useMutation('updateWasteData', updateWasteData, {
		retry: 0,
		onSuccess: () => {
			toast.success('Successfully updated Waste data.');
			queryClient.invalidateQueries('DatabaseData');
		},
	});

	const unit = useMutation('updateUnitData', updateUnitData, {
		retry: 0,
		onSuccess: () => {
			toast.success('Successfully updated Units data.');
			queryClient.invalidateQueries('DatabaseData');
		},
	});

	const handleMouseEnter = () => {
		if (fieldState !== 'save') {
			setFieldState('edit');
		}
	};

	const handleMouseLeave = () => {
		if (fieldState !== 'save') {
			setFieldState('none');
		}
	};

	const handleEditClick = () => {
		setFieldState('save');
		setReadOnlyState(false);
		valueRef?.current?.focus();
	};

	const handleSaveClick = async () => {
		setReadOnlyState(true);
		setFieldState('none');
		switch (theme) {
			case 'boiler':
				//  Remove the key "boiler_fuel_master"
				const { boiler_fuel_master, ...rest } = themeObject;
				const modifiedData = { ...rest, id: id, [object_key]: fieldValue };
				boiler.mutate(modifiedData);
				break;
			case 'waste':
				const modifiedWaste = {
					id: id,
					waste_name: fieldValue,
				};
				waste.mutate(modifiedWaste);
				break;
			case 'units':
				const modifiedUnits = {
					id: id,
					[object_key]: fieldValue,
				};

				unit.mutate(modifiedUnits);
				break;
			default:
				break;
		}
	};

	const wasteListTracking = [
		'E-waste',
		'Plastic',
		'Paper & Cartons',
		'Fabric Pieces',
		'Hazardous waste',
	];

	return (
		<Box
			style={{
				cursor: editStatus == 1 ? 'auto' : 'pointer',
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			display="flex"
			alignItems="center"
			justifyContent="start"
			height="30px"
			minWidth="100px"
			paddingLeft="3px"
		>
			{fieldValue == 'E-waste' && (
				<img
					src={greenChart}
					width="16.5px"
					style={{ marginLeft: '-18px', marginRight: '1px' }}
				/>
			)}
			{fieldValue == 'Plastic' && (
				<img
					src={blueChart}
					width="16.5px"
					style={{ marginLeft: '-18px', marginRight: '1px' }}
				/>
			)}
			{fieldValue == 'Paper & Cartons' && (
				<img
					src={yellowChart}
					width="16.5px"
					style={{ marginLeft: '-18px', marginRight: '1px' }}
				/>
			)}
			{fieldValue == 'Fabric Pieces' && (
				<img
					src={violetChart}
					width="16.5px"
					style={{ marginLeft: '-18px', marginRight: '1px' }}
				/>
			)}
			{fieldValue == 'Hazardous waste' && (
				<img
					src={redChart}
					width="16.5px"
					style={{ marginLeft: '-18px', marginRight: '1px' }}
				/>
			)}
			{!editStatus == 1 && label == 'waste type' && (
				<span style={{ marginRight: '8px' }}></span>
			)}
			<TextField
				variant="standard"
				value={fieldValue}
				onChange={(e) => setFieldValue(e.target.value)}
				inputRef={valueRef}
				InputProps={{ readOnly: readOnlyState, disableUnderline: true }}
				style={{ marginLeft: editStatus == 1 ? '12px' : '0px' }}
				sx={{
					width:
						label == 'conversion' || label == 'units' || label == 'unit List'
							? '50px'
							: '220px',
				}}
			></TextField>{' '}
			{editStatus == 1 ? (
				<img src={dontEditIcon} width="20px" onClick={() => {}} />
			) : fieldState === 'none' ? null : fieldState === 'edit' ? (
				<img src={edit} width="30px" onClick={handleEditClick} />
			) : (
				<img src={save} width="15px" height="15px" onClick={handleSaveClick} />
			)}
		</Box>
	);
};

export default EditableComponent;
