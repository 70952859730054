import React, { useEffect, useRef, useState } from 'react';
import fpStyles from './forgotPassword.module.css';
import {useNavigate } from 'react-router-dom';
import { useResetPasswordRequestHook } from '../api_hooks/api_hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ForgotPassword = () => {
    const {t} = useTranslation();
    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    // let typeEmail = searchParams.get('email')
    const [email,setEmail] = useState('')
    const emailRef = useRef();
    const emailLabelRef=useRef();
    const [ErrorD, setErrorD] = useState(0);
    const { mutate: resendRequest,isSuccess} = useResetPasswordRequestHook();
    const handleEmailChange =(e)=>{
        const emailType = e.target.value;
        setEmail(emailType)
   }
    const HandleVerification = (e) => {
        e.preventDefault()
        const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!email){
            toast.error(t("forgotpasswordpage.emailEmpty"));
            setErrorD(1);
            return;
        }
        if(!regex_email.test(email)){
            toast.error(t("forgotpasswordpage.validEmailid"));
            setErrorD(1);
            return;
        }
      
        let emailData = {
            email: email
        }
        resendRequest(emailData)
    }
   useEffect(()=>{
    if(isSuccess === true){
        navigate(`/verify-your-account?email=${email}`)
    }
   },[isSuccess])
    return (
        <div className="mainWrapper">
            <div className={fpStyles.forgotPassword} style={{marginTop:window.innerWidth>768?"70px":"0px"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-5 col-xl-4">
                            <h4><ArrowBackIcon className={fpStyles.ArrowBack} onClick={()=>navigate(-1)}/>{t("forgotpasswordpage.ResetYourPasswordHeaderText")}</h4>
                            <p className="mb-4">{t("forgotpasswordpage.ResetYourPasswordSubText")}</p>
                            <form
                                onSubmit={HandleVerification}
                            >
                                <div className="floting-form mb-5">
                                    <input 
                                       className={ErrorD == 1 ? 'form-input invalid' : 'form-input'}
                                       placeholder="none"
                                       value = {email}
                                       onChange={(e)=>handleEmailChange(e)}
                                       ref={emailRef}
                                    />
                                    <label ref={emailLabelRef} htmlFor="email" className="form-label fl-white">{t("forgotpasswordpage.ResetYourPasswordEmailPlaceholder")}</label>
                                </div>
                                <button
                                    className="btn btn-primary w-100"
                                    type="submit"
                                >{t("forgotpasswordpage.ResetYourPasswordSendButton")}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ForgotPassword;