import { toast } from 'react-toastify';

export const API_Error = (error) =>{
    let ErrorData = error;
    const NoInternetCode = ErrorData.code;
    if (NoInternetCode !== "ERR_NETWORK") {
        let statusCode = ErrorData.response.status;
        if (statusCode === 400) {
            let errorText = ErrorData.response.data.error.message;
            toast.error(errorText);
        }
        if (statusCode === 401) {
            let errorText = ErrorData.response.data.error.message;
            toast.error(errorText);
        }
    }
    else {
        let errorText = ErrorData.message;
        toast.error(errorText);
    }
}