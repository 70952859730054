import { useTranslation } from 'react-i18next';
import StaticTab from './staticTab';
import style from './style.module.css';
import { useEffect } from 'react';

const Terms = () => {
    let langId = localStorage.getItem("lanId");
    const {t} = useTranslation();
    useEffect(()=>{
        window.scrollTo({
            top: 3,
            left: 0,
          });
    },[])
    return (
        <div className={"py-4 "+style.height}>
            <div style={{marginTop:window.innerWidth >768?"78px":"90px"}}>
               <StaticTab/>
            </div>
            <div className={"container "+style.terms}>
                <ul>
                    <li><b>{t("termspage.AcceptanceHeaderText")}</b>
                        <p className='pt-3'>{t("termspage.AcceptanceSubText")}<b>{t("termspage.AcceptSubtexttwo")}</b></p>
                    </li>
                    <li><b>{t("termspage.OrganizersHeaderText")}</b>
                        {langId === '1'?<p className='pt-3'>The Ref-Track digital platform is produced under the Prevent Plastics project funded by the European Union Switch Asia Grants Programme. It is designed and maintained by SUSTENT Consulting Private Limited on behalf of Prevent Plastics. For any concerns or grievances related to this digital platform you can write to: <span style={{color:"blue"}}>info@sustent.in</span> or <span style={{color:"blue"}}>julie@preventplastics.org</span> </p>:
                            <p className='pt-3'>Ref-Track Digital Platform/ဒစ်ဂျစ်တယ်ပလက်ဖောင်းကို European Union Switch Asia Grants Programme မှ ထောက်ပံ့သော Prevent Plastics စီမံကိန်းမှ တင်ဆက်ထားခြင်းဖြစ်သည်။ ၎င်းကို Prevent Plastics ကိုယ်စား SUSTENT Consulting Private Limited မှ ဒီဇိုင်းရေးဆွဲ ထိန်းသိမ်းထားပါသည်။ ဤဒစ်ဂျစ်တယ်ပလက်ဖောင်းနှင့် ပတ်သက်သည့် စိတ်ဝင်စား၍ သို့မဟုတ် မကျေနပ်ချက်များတိုင်ကြားရန် အတွက် သင်သည် <span style={{color:"blue"}}>info@sustent.in</span> သို့မဟုတ် <span style={{color:"blue"}}>julie@preventplastics.org</span> သို့ ပေးပို့နိုင်ပါသည်။</p>}
                    </li>
                    <li><b>{t("termspage.DigitalPlatformHeaderText")}</b>
                        <p className='pt-3'>{t("termspage.DigitalPlatformSubText")}</p>
                        <p>{t("termspage.DigiPlaSubText")}</p>
                    </li>
                    <li><b>{t("termspage.AccessHeaderText")}</b>
                        <p className='pt-3'>{t("termspage.AccessSubText")}</p>
                    </li>
                    <li><b>{t("termspage.FeesHeaderText")}</b>
                        <p className='pt-3'>{t("termspage.FeesSubText")}</p>
                    </li>
                    <li><b>{t("termspage.IntellectualPropertyRightsHeaderText")}</b>
                        <p>{t("termspage.IntellectualPropertyRightsSubText")}</p>
                    </li>
                    <li><b>{t("termspage.SubmissionsofUserContentHeaderText")}</b>
                        <p>{t("termspage.SubmissionsofUserContentSubText")}</p>
                        <p>{t("termspage.SubmissionSubtexttwo")}</p>
                    </li>
                    <li><b>{t("termspage.GeneralDisclaimerHeaderText")}</b>
                        <p>{t("termspage.GeneralDisclaimerSubText")}</p>
                        <p>{t("termspage.GeneralDisclaimerSubTextTwo")}</p>
                    </li>
                    <li><b>{t("termspage.TermHeaderText")}</b>
                        <p>{t("termspage.TermSubText")}</p>
                    </li>
                    <li><b>{t("termspage.AssignmentHeaderText")}</b>
                        <p>{t("termspage.AssignmentSubText")}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Terms;