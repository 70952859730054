import { Box, Typography, Stack } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import activateMail from '../../../assets/admin/images/activateMail.png';
import { useMutation } from 'react-query';
import { forgotPassword } from '../../../services/AdminApis';
import { toast } from 'react-toastify';
import Header from '../components/Header';

const ForgotPassword = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const email = queryParams.get('email');

	const { mutate } = useMutation('forgotPassword', forgotPassword, {
		onSuccess: (data) => {
			toast.info(
				'Request for password reset has been sent to your email address.'
			);
		},
	});

	const handleSubmitEmail = () => {
		mutate({ email: email });
	};

	return (
		<>
			<Header />
			<Stack
				display="flex"
				justifyContent="center"
				height="85vh"
				alignItems="center"
			>
				<Typography
					variant="h5"
					color="primary"
					fontWeight="bold"
					style={{ fontFamily: 'Roboto' }}
					gutterBottom
				>
					Verify your account
				</Typography>

				<Box margin="35px auto">
					<img src={activateMail} width="120px" />
				</Box>
				<Typography
					color="text.primary"
					gutterBottom
					fontWeight="bold"
					fontSize="18px"
				>
					Click on the verification link sent to
				</Typography>
				<Typography
					color="text.primary"
					variant="subtitle1"
					style={{ marginBottom: '30px' }}
				>
					{email}
				</Typography>
				<Box
					style={{
						width: '120px',
						backgroundColor: 'lightgray',
						borderRadius: '10px',
						height: '3px',
						marginBottom: '30px',
					}}
				></Box>
				<Typography color="text.primary" variant="subtitle1">
					If you haven't received the email yet
				</Typography>
				<Typography color="text.primary" variant="subtitle1" fontWeight="bold">
					Check your spam folder
				</Typography>
				<Typography color="text.primary" variant="subtitle1">
					OR
				</Typography>
				<Typography
					fontWeight="bold"
					style={{ cursor: 'pointer' }}
					color="primary"
					sx={{ '&:hover': { textDecoration: 'underline' } }}
					onClick={handleSubmitEmail}
				>
					Resend
				</Typography>
			</Stack>
		</>
	);
};

export default ForgotPassword;
