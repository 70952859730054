import { Box, TextField, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { updateAdminAccountDetails } from '../../../services/AdminApis';
import { toast } from 'react-toastify';
import { useQueryClient, useMutation } from 'react-query';
import Loader from './Loader';

const AccountDetailsUpdate = ({ value, userObject, label, postLabel }) => {
	if (label == 'Password') {
		value = atob(value);
	}

	const queryClient = useQueryClient();

	const [fieldValue, setFieldValue] = useState(value);
	const [readOnlyState, setReadOnlyState] = useState(true);
	const inputRef = useRef(null);

	useEffect(() => {
		setFieldValue(value);
	}, [value]);

	const { data, isLoading, isError, error, mutate } = useMutation(
		'updateAccountDetails',
		updateAdminAccountDetails,
		{
			retry: 0,
			onSuccess: () => {
				toast.success('Successfully updated Admin account details.');
				queryClient.invalidateQueries('accountDetails');
				setReadOnlyState(true);
			},
		}
	);

	const handleEditSave = () => {
		if (readOnlyState) {
			inputRef?.current?.focus();

			setReadOnlyState(false);
			return;
		} else {
			if (!fieldValue.trim().length > 0) {
				toast.error('Field cannot be empty.');
				return;
			}

			mutate({
				user_id: userObject.id,
				user_name: userObject.name,
				email: userObject.email,
				upassword: userObject.password,
				[postLabel]: fieldValue,
			});
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			gap="50px"
			marginBottom={2}
			sx={{ display: label == 'Password' ? 'flex' : 'none' }}
		>
			<Typography variant="subtitle1" color="text.main" sx={{ width: '120px' }}>
				{label}
			</Typography>
			<Typography>:</Typography>
			<TextField
				variant="standard"
				value={fieldValue}
				inputRef={inputRef}
				onChange={(e) => setFieldValue(e.target.value)}
				InputProps={{ readOnly: readOnlyState, disableUnderline: true }}
				sx={{
					width: '280px',
				}}
			></TextField>
			<Typography
				variant="subtitle1"
				color={readOnlyState ? 'text.main' : 'primary'}
				fontWeight="bold"
				style={{
					cursor: 'pointer',
					textDecoration: readOnlyState ? 'none' : 'underline',
				}}
				onClick={handleEditSave}
			>
				{readOnlyState ? 'Edit' : 'Save'}
			</Typography>
		</Box>
	);
};

export default AccountDetailsUpdate;
