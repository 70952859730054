import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { TextField, useMediaQuery } from '@mui/material';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: '#138C50',
						borderWidth: '1px',
					},
				},
				notchedOutline: {
					borderColor: '#A6A6A6',
					color: '#A6A6A6',
				},
			},
		},
	},
});

const Input = ({ name, setName }) => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const { t } = useTranslation();

	return (
		<ThemeProvider theme={theme}>
			<TextField
				id="outlined-basic"
				label={t('output.CompanyName')}
				variant="outlined"
				size="small"
				value={name}
				onChange={(e) => setName(e.target.value)}
				sx={{ minWidth: isMobile ? '98%' : '300px' }}
				InputLabelProps={{
					sx: {
						color: '#A6A6A6',
						[`&.${inputLabelClasses.shrink}`]: {
							color: '#138C50',
						},
					},
				}}
			/>
		</ThemeProvider>
	);
};

export default Input;
