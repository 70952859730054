import style from './style.module.css';
import StaticTab from './staticTab';
import { useTranslation } from 'react-i18next';
const Contact = () => {
    const {t} = useTranslation();
    return (
        <div className={"py-4 "+style.height} >
             <div style={{marginTop:window.innerWidth >768?"78px":"90px"}}>
               <StaticTab/>
            </div>
            <div className={"container "+style.contact}>
                <p>{t("contactUs.paraOne")}</p>
                <div className='row'>
                <div className='col-sm-12 col-md-8 col-lg-6'>
                    <div className='row pt-4'>
                        <div className='col-12 col-md-6'>
                            <div
                              style={{color:"#138C50",fontWeight:"bold"}}
                            >NAW JULIE AUNG</div>
                            <div>{t("contactUs.tech")}</div>
                            <div className='pt-4'>{t("contactUs.email")}:</div>
                            <div className={style.email}>julie@preventplastics.org</div>
                        </div>

                        <div className='col-12 col-md-6' style={{paddingTop:window.innerWidth<768?"40px":0}}>
                            <div
                             style={{color:"#138C50",fontWeight:"bold"}}
                            >NAING YE AUNG</div>
                            <div>{t("contactUs.tech")}</div>
                            <div className='pt-4'>{t("contactUs.email")}:</div>
                            <div className={style.email}>naing@preventplastics.org</div>
                        </div>
                        </div>
                    </div>
            </div>
            </div>
          
        </div>
    )
}
export default Contact;