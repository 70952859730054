// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPassword_resetPassword__7P3dw{
    position: relative;
    margin: 70px 0;
}
.resetPassword_pswMatches__7V50a{
    list-style-type: none;
    list-style-position: inside;
    margin: 0 0 15px 0;
    padding: 0;
}
.resetPassword_ArrowBack__bZp2F{
    display: none!important;
}
@media only screen and (max-width: 768px){
    .resetPassword_resetPassword__7P3dw{
        margin: 0px;
    }
    .resetPassword_ArrowBack__bZp2F{
        color: #383838;
        font-size: 28px!important;
        transform: scale(0.8);
        transition: 0.3s;
        margin: 0px 4px 0px -7px;
        display: inline-block!important;
     }
     .resetPassword_ArrowBack__bZp2F:hover{
         color: #F65B3C;
         transform: scale(1);
         transition: 0.3s;
     }
}
`, "",{"version":3,"sources":["webpack://./src/components/frontend/resetPassword/resetPassword.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,uBAAuB;AAC3B;AACA;IACI;QACI,WAAW;IACf;IACA;QACI,cAAc;QACd,yBAAyB;QACzB,qBAAqB;QACrB,gBAAgB;QAChB,wBAAwB;QACxB,+BAA+B;KAClC;KACA;SACI,cAAc;SACd,mBAAmB;SACnB,gBAAgB;KACpB;AACL","sourcesContent":[".resetPassword{\r\n    position: relative;\r\n    margin: 70px 0;\r\n}\r\n.pswMatches{\r\n    list-style-type: none;\r\n    list-style-position: inside;\r\n    margin: 0 0 15px 0;\r\n    padding: 0;\r\n}\r\n.ArrowBack{\r\n    display: none!important;\r\n}\r\n@media only screen and (max-width: 768px){\r\n    .resetPassword{\r\n        margin: 0px;\r\n    }\r\n    .ArrowBack{\r\n        color: #383838;\r\n        font-size: 28px!important;\r\n        transform: scale(0.8);\r\n        transition: 0.3s;\r\n        margin: 0px 4px 0px -7px;\r\n        display: inline-block!important;\r\n     }\r\n     .ArrowBack:hover{\r\n         color: #F65B3C;\r\n         transform: scale(1);\r\n         transition: 0.3s;\r\n     }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPassword": `resetPassword_resetPassword__7P3dw`,
	"pswMatches": `resetPassword_pswMatches__7V50a`,
	"ArrowBack": `resetPassword_ArrowBack__bZp2F`
};
export default ___CSS_LOADER_EXPORT___;
