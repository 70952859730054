import React, { useEffect } from 'react';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import logo from '../../../assets/admin/images/logo.png';

import { Link, useLocation } from 'react-router-dom';
import AccountModal from './AccountModal';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { useState } from 'react';

const LoggedInHeader = () => {
	const location = useLocation();
	const [currentAdminName, setCurrentAdminName] = useState('');

	useEffect(() => {
		if (localStorage.getItem('adminToken')) {
			try {
				const creds = jwtDecode(JSON.parse(localStorage.getItem('adminToken')));
				setCurrentAdminName(creds?.user_name);
			} catch (error) {
				toast.error('Error parsing current admin info');
			}
		}
	}, []);

	const isSuperAdmin = () => {
		if (
			currentAdminName === 'SUPERADMIN1' ||
			currentAdminName === 'SUPERADMIN2'
		) {
			return true;
		}
		return false;
	};

	return (
		<AppBar
			position="static"
			style={{ backgroundColor: '#F2F2F2', padding: '3px 25px' }}
		>
			<Toolbar>
				<Grid
					container
					alignItems="center"
					spacing={9}
					color="#A6A6A6"
					style={{ cursor: 'pointer' }}
				>
					<Grid item>
						<Link
							style={{ textDecoration: 'none', color: 'inherit' }}
							to="/admin/dashboard"
						>
							<img src={logo} height="60px" alt="logo" />
						</Link>
					</Grid>
					<Grid item>
						<Link
							style={{ textDecoration: 'none', color: 'inherit' }}
							to="/admin/dashboard"
						>
							<Typography
								fontWeight={
									location.pathname.includes('dashboard') ? '700' : '400'
								}
								fontSize="18px"
								color={
									location.pathname.includes('dashboard')
										? 'primary.main'
										: 'text.main'
								}
							>
								Dashboard
							</Typography>
						</Link>
					</Grid>
					<Grid item>
						<Link
							style={{ textDecoration: 'none', color: 'inherit' }}
							to="/admin/user-manager"
						>
							<Typography
								fontSize="18px"
								fontWeight={
									location.pathname.includes('user-manager') ? '700' : '400'
								}
								color={
									location.pathname.includes('user-manager')
										? 'primary.main'
										: 'text.main'
								}
							>
								User manager
							</Typography>
						</Link>
					</Grid>
					{isSuperAdmin() && (
						<>
							<Grid item>
								<Link
									style={{ textDecoration: 'none', color: 'inherit' }}
									to="/admin/database-manager"
								>
									<Typography
										fontWeight={
											location.pathname.includes('database-manager')
												? '700'
												: '400'
										}
										fontSize="18px"
										color={
											location.pathname.includes('database-manager')
												? 'primary.main'
												: 'text.main'
										}
									>
										Database manager
									</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link
									style={{ textDecoration: 'none', color: 'inherit' }}
									to="/admin/admin-manager"
								>
									<Typography
										fontWeight={
											location.pathname.includes('admin-manager')
												? '700'
												: '400'
										}
										fontSize="18px"
										color={
											location.pathname.includes('admin-manager')
												? 'primary.main'
												: 'text.main'
										}
									>
										Admin manager
									</Typography>
								</Link>
							</Grid>
						</>
					)}
					<Grid item marginLeft="auto">
						<AccountModal />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default LoggedInHeader;
