import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './dashboard.module.css';
import setup from '../../../assets/frontend/images/setup_white_icon.png';
import locked from '../../../assets/frontend/images/locked_white.png';
import setupComplete from '../../../assets/frontend/images/sucess_checkmark.png';
import monthlyEntry from '../../../assets/frontend/images/monthlyentry_green_icon.png';
import Output from '../../../assets/frontend/images/output_white.png';
import {
useDeviceDataHook,
useMasterDataHook,
useResetSetupHook,
} from '../api_hooks/api_hooks';
import Modal from 'react-bootstrap/Modal';
import { Oval } from 'react-loader-spinner';
import { useContext } from 'react';
import { Context } from '../../../frontend_context/contextProvider';
import { useTranslation } from 'react-i18next';
import EastIcon from '@mui/icons-material/East';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import alertcircle from '../../../assets/frontend/images/alert_circle.svg';

const Dashboard = () => {
let location = useLocation();
let currentPath = location.pathname;
let langId = localStorage.getItem('lanId');
const { t } = useTranslation();
const [dashStatus, setDashStatus] = useState();
const [isOpen, setIsOpen] = useState(false);
const [Resetpopup, setResetpopup] = useState(false);
const { monthlyEntryStatus } = useContext(Context);
const toggleDropdown = () => {
	setIsOpen(!isOpen);
};
const { status, data, isLoading: masterdataLoading } = useMasterDataHook();
useDeviceDataHook();
let userInfoArray = localStorage.getItem('userInfo');
const [userData, setUserData] = useState(
	userInfoArray != null ? JSON.parse(userInfoArray) : []
);
const userDataLoad = () => {
	setUserData(JSON.parse(userInfoArray));
};
const { mutate: resetData, isLoading: masterResetLoading } =
	useResetSetupHook();
const ResetStatus = (status) => {
	if (status === 'yes') {
		localStorage.removeItem('monthlyEntryData');
		localStorage.removeItem('selectedTimeline');
		localStorage.removeItem('pdfOutput');
		localStorage.removeItem('companyName');
		localStorage.removeItem('outputTimeline');
		localStorage.removeItem('selectedTimeline');
		localStorage.removeItem('pdfOutput');
		localStorage.removeItem('companyName');
		localStorage.removeItem('outputTimeline');
		localStorage.removeItem('activeSetupTab');
		localStorage.removeItem('accordionSelection');
		resetData();
		setResetpopup(false);
	} else {
		setResetpopup(false);
	}
};
const resetAllData = () => {
	setResetpopup(true);
};
useEffect(() => {
	window.scrollTo({
		top: 3,
		left: 0,
	});
	if (status === 'success') {
		let localData =
			data.data.DASHBOARD_STATUS !== null
				? JSON.parse(data.data.DASHBOARD_STATUS)
				: null;
		let DashStatus = localData;
		setDashStatus(DashStatus !== null ? DashStatus : null);
	}
	userDataLoad();
}, [status, data, monthlyEntryStatus]);

return (
	<div className={'mainWrapper ' + style.dashboard} 
	  style={{paddingTop:currentPath==="/dashboard"&&window.innerWidth>1700&&"154px"}}
	  >
		<div className="container">
			<div className={style.main_title}>
				<div
					className="row align-items-center"
					style={{ position: window.innerWidth < 768 ? 'relative' : 'unset' }}
				>
					<div className="col-12 col-md-9">
						<p>
							{t('dashboardpage.WelcomeText')},{' '}
							{userData !== null ? userData.name : ''}.
						</p>
						<h2 style={{
							fontSize:(langId === '2'&&window.innerWidth<768)&&'22px'
						}}>{t('dashboardpage.RefTrackToolHeaderText')}</h2>
					</div>
					<div
						className="col-3 text-end"
						style={{
							position: window.innerWidth > 768 ? 'relative' : 'unset',
						}}
					>
						<div className={'tooltipwrapper ' + style.tooltipDash}
							style={{zIndex:isOpen===true?"100":"0"}}
						>
							<button
								onClick={toggleDropdown}
								className="toolTip tooltipDashBoard"
								style={{ position: 'relative', bottom: '4px' }}
							>
								<HelpCenterIcon
									style={{ color: isOpen === true && '#138C50'}}
									className='dashHover'
								/>
							</button>
							{isOpen && (
								<div
									className="backShadowSetup"
									onClick={() => setIsOpen(false)}
								></div>
							)}
							{isOpen && (
								<div className="tooltipShow" style={{lineHeight:langId === "1"?"20px":"unset"}}>
									{dashStatus !== undefined &&
										dashStatus[0].dashboard_status ===
											'setup_not_started' && (
											<>
												<h5>{t('dashboardpage.TootipsPopupHeaderText')}</h5>
												<p>{t('dashboardpage.setUptoolparaOne')}</p>
												{langId === '1' ? (
													<p>
														<b>
															The{' '}
															<span>
																1<sup>st</sup>
															</span>
															stage is <span>"Set up"</span>.
														</b>
													</p>
												) : (
													<p>
														ပထမဆုံးအဆင့်သည် "တည်ဆောက်ခြင်း
														<span>"Set up"</span> ဖြစ်ပါသည်။
													</p>
												)}
												<ul>
													<li style={{ fontWeight: '400', fontSize: '12px' }}>
														<EastIcon
															style={{ fontSize: '13px', marginRight: '4px' }}
														/>{' '}
														{t('dashboardpage.stepToolOne')}
													</li>
													<li
														style={{
															display: 'flex',
															alignItems: 'baseline',
															fontWeight: '400',
															fontSize: '12px',
														}}
													>
														<EastIcon
															style={{ fontSize: '13px', marginRight: '4px' }}
														/>
														{langId === '1' ? (
															<div>
																Finish <b>"Set up"</b> to unlock{' '}
																<b>"Monthly Entry"</b>.
															</div>
														) : (
															<div>
																လစဉ် ထည့်သွင်းမှု အဆင့်ရောက်ရန် "Set up"
																တည်ဆောက်ခြင်း ကို အပြီးသတ်ပါ။
															</div>
														)}
													</li>
												</ul>
												<hr
													style={{
														width: '14px',
														borderTop: '2px solid #383838',
														margin: '24px 0px',
													}}
												></hr>
												{langId === '1' ? (
													<p>
														<span>{t('generalText.NOTE')}</span>The{' '}
														<b>Reset All Data</b> button gets activated once
														you complete <b>Set up</b>. You can use it to
														reset all of your data and start over.
													</p>
												) : (
													<p>
														<span>{t('generalText.NOTE')}</span>
														<b>Set up</b> တည်ဆောက်ခြင်း" အဆင့် ပြီးသည်နှင့်
														"ဒေတာအားလုံးကို ပြန်လည်သတ်မှတ်မည်"
														ခလုတ်ပေါ်လာပါမည်။ သင့်ဒေတာအားလုံးကို
														ပြန်လည်သတ်မှတ်ပြီး ပြန်လည်စတင်ရန် ၎င်းကို
														သင်အသုံးပြုနိုင်ပါသည်။
													</p>
												)}
											</>
										)}
									{dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'setup_completed' && (
											<>
												<h5>{t('dashboardpage.TootipsPopupHeaderText')}</h5>
												{langId === '1' ? (
													<p>
														<b>
															The <span>2nd</span>stage is{' '}
															<span>"Monthly Entry"</span>.
														</b>
													</p>
												) : (
													<p>
														<span>ဒုတိယအဆင့်မှာ</span> လစဉ်ဝင်ခွင့် ဖြစ်သည်။
													</p>
												)}
												{langId === '1' ? (
													<>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															Add the required quantities for each parameter
															identified in the <b>Set up</b> stage on a
															monthly basis.
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															Once unlocked, <b>Monthly</b> Entry will remain
															accessible indefinitely.
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															Try and complete <b>at least 3 months</b> of
															data to unlock the next stage, Output.{' '}
														</p>
														<hr
															style={{
																width: '14px',
																borderTop: '2px solid #383838',
																margin: '24px 0px',
															}}
														></hr>
														<p>
															<span>{t('generalText.NOTE')}</span>The{' '}
															<b>Reset All Data</b> button gets activated once
															you complete <b>Set up</b>. You can use it to
															reset all of your data and start over.
														</p>
													</>
												) : (
													<>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															<b>Set up</b> အဆင့်တွင် လစဉ်သတ်မှတ်ထားသော
															ကန့်သတ်ဘောင်တစ်ခုစီအတွက် လိုအပ်သောပမာဏကို
															ထည့်ပါ။
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															ထိုအဆင့်ကို ရောက်သွားသည်နှင့်{' '}
															<b>လစဉ်ဝင်ရောက်မှု</b> ကို ရက်အကန့်အသတ်မရှိ
															ဆက်လက်အသုံးပြုနိုင်မည်ဖြစ်သည်။
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															နောက်ထပ်အဆင့် <b>Output</b> ရလဒ်" ကိုရောက်ရန်
															အနည်းဆုံး 3 လဒေတာ/အချက်အလက်များ ကို ကြိုးစားပြီး
															ဖြည့်ပါ။
														</p>
														<hr
															style={{
																width: '14px',
																borderTop: '2px solid #383838',
																margin: '24px 0px',
															}}
														></hr>
														<p>
															<span>{t('generalText.NOTE')}</span>
															<b>
																ဒေတာ/အချက်အလက် အားလုံးကို ပြန်လည်သတ်မှတ်ရန်
															</b>{' '}
															ခလုတ်ကို သင့်ဒေတာ/အချက်အလက် အားလုံးကို
															ပြန်လည်သတ်မှတ်ပြီး "တည်ဆောက်ခြင်း" အဆင့်မှ
															ပြန်လည်စတင်ရန် အသုံးပြုနိုင်သည်။
														</p>
													</>
												)}
											</>
										)}
									{dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'output_enabled' && (
											<>
												<h5>{t('dashboardpage.TootipsPopupHeaderText')}</h5>
												{langId === '1' ? (
													<p>
														<b>
															The <span>3rd</span> stage is{' '}
															<span>Output</span>
														</b>
													</p>
												) : (
													<p>
														<span>တတိယအဆင့်သည်</span> <b>ရလဒ်" ဖြစ်သည်။</b>
													</p>
												)}
												{langId === '1' ? (
													<>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															Generate outputs in the form of graphs and
															reports and gather insights from the output.
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															It is unlocked after <b>at least 3 months</b> of
															data is completed in the <b>Monthly Entry</b>{' '}
															stage.
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															Keep generating new and updated outputs every
															time you update your <b>Monthly Entry</b> data.
														</p>
														<hr
															style={{
																width: '14px',
																borderTop: '2px solid #383838',
																margin: '24px 0px',
															}}
														></hr>
														<p>
															<span>{t('generalText.NOTE')}</span>The{' '}
															<b>Reset All Data</b> button can be used to
															reset all of your data and start over again from
															the "Set up" stage.
														</p>
													</>
												) : (
													<>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															ဂရပ်များနှင့် အစီရင်ခံစာများပုံစံြဖင့်
															ရလဒ်များကို ဖန်တီးြပီး ရလဒ်များကို စုပါ။
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															<b>လစဉ်ဝင်ရောက်မှု</b> အဆင့်တွင် အနည်းဆုံး ၃
															လဒေတာကို ပြီးမြောက်ပြီးမှ အဆင့်သစ်ကိုရောက်သည်။
														</p>
														<p>
															<EastIcon
																style={{
																	fontSize: '13px',
																	marginRight: '4px',
																}}
															/>
															သင်၏ <b>လစဉ်ထည့်သွင်းမှု</b> ဒေတာကို
															ပြုပြင်မွမ်းမံ့တိုင်း အသစ်နှင့်
															မွမ်းမံထားပြီးသော ရလဒ်များကို ဆက်လက်ဖန်တီးပါ။
														</p>
														<hr
															style={{
																width: '14px',
																borderTop: '2px solid #383838',
																margin: '24px 0px',
															}}
														></hr>
														<p>
															<span>{t('generalText.NOTE')}</span>
															<b>ဒေတာအားလုံးကို ပြန်လည်သတ်မှတ်မည်</b> ခလုတ်ကို
															သင့်ဒေတာအားလုံးကို ပြန်လည်သတ်မှတ်ပြီး
															"တည်ဆောက်ခြင်း""Set up" အဆင့်မှ ပြန်လည်စတင်ရန်
															အသုံးပြုနိုင်သည်။
														</p>
													</>
												)}
											</>
										)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{masterdataLoading === false && masterResetLoading === false ? (
				<div>
					{dashStatus !== null && (
						<div className="row">
							{dashStatus !== undefined &&
							dashStatus[0].dashboard_status === 'setup_not_started' ? (
								<div className="col-md-4">
									<div
										className={style.stageHolder + ' ' + style.setupSucess}
									>
										<h1 className={style.steps}>{t('dashboardpage.one')}</h1>
										<Link to="/setup" className={style.stages}>
											<div>
											<img
												src={setup}
												alt="REMS"
												className={style.setupIcon}
											/>
											<p className={style.setupTitle}>
												{t('dashboardpage.SetUpText')}
											</p>
											</div>
										</Link>
										<div className={style.setupDesc}>
											<p>{t('dashboardpage.IdentifyTheParameterText')}</p>
											<h6>
												{t('generalText.NOTE')}{' '}
												{t('dashboardpage.SetUpNote')}
											</h6>
										</div>
									</div>
								</div>
							) : (
								<div className="col-md-4">
									<div
										className={style.stageHolder + ' ' + style.SetupComplete}
									>
										<h1 className={style.steps}>{t('dashboardpage.one')}</h1>
										<Link className={style.stages}>
											<div>
											<img
												src={setupComplete}
												alt="REMS"
												className={style.setupIcon}
											/>
											<p className={style.setupTitle} style={{textAlign:window.innerWidth<768?"left":"center"}}>
											   {t('dashboardpage.SetUpText')}<span style={{
												    display:window.innerWidth<768?"block":"contents",
													lineHeight:window.innerWidth<768?"14px":"unset"
											   }}> {t('dashboardpage.SetUpCompletedText')}</span>
											</p>
											</div>
										</Link>
										<div className={window.innerWidth>767?style.setupDesc:style.setupDesc+' '+style.setupDescAfter} style={{padding:window.innerWidth>767?"0px 0px":"16px 0px"}}>
											{window.innerWidth>767?<><p style={{color:"#F2F2F2"}}>{t('dashboardpage.IdentifyTheParameterText')}</p>
											<h6 style={{color:"#F2F2F2"}}>
												{t('generalText.NOTE')}{' '}
												{t('dashboardpage.SetUpNote')}
											</h6>
											</>:null}
										</div>
									</div>
								</div>
							)}

							{dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'setup_completed' && (
									<div className="col-md-4">
										<div
											className={style.stageHolder + ' ' + style.setupSucess}
										>
											<div className={style.unlocked}>
												{t('generalText.unLocked')}!
											</div>
											<h1 className={style.steps}>
												{t('dashboardpage.two')}
											</h1>
											<Link
												to={
													monthlyEntryStatus.length !== 0 ||
													monthlyEntryStatus == null ||
													monthlyEntryStatus[0].monthly_entry_status !== 1
														? '/monthlyentry/setupmonitoring'
														: '/monthlyentry/monthlyentry-calendar'
												}
												className={style.stages}
											>
												<div>
												<img
													src={Output}
													alt="REMS"
													className={style.setupIcon}
												/>
												<p className={style.setupTitle}>
													{t('generalText.monthlyEntry')}
												</p>
												</div>
											</Link>
											<div className={style.setupDesc}>
												<p>{t('dashboardpage.monthlyEntryDesc')}</p>
											</div>
										</div>
									</div>
								)}
							{dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'setup_not_started' && (
									<div className="col-md-4">
										<div
											className={style.stageHolder + ' ' + style.setupLocked}
										>
											<h1 className={style.steps}>
												{t('dashboardpage.two')}
											</h1>
											<Link className={style.stages}>
												<div>
												<img
													src={locked}
													alt="REMS"
													className={style.setupIcon}
												/>
												<p className={style.setupTitle}>
													{t('generalText.monthlyEntry')}
												</p>
												</div>
											</Link>
											<div className={style.setupDesc}>
												<p>{t('dashboardpage.monthlyEntryDesc')}</p>
											</div>
										</div>
									</div>
								)}

							{dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'output_enabled' && (
									<div className="col-md-4">
										<div
											className={style.stageHolder + ' ' + style.monthlyEntry}
										>
											<h1 className={style.steps}>
												{t('dashboardpage.two')}
											</h1>
											<Link
												to="/monthlyentry/monthlyentry-calendar"
												className={style.stages}
											>
												<div>
												<img
													src={monthlyEntry}
													alt="REMS"
													className={style.setupIcon}
												/>
												<p className={style.setupTitle}>
													{t('generalText.monthlyEntry')}
												</p>
												</div>
											</Link>
											<div className={style.setupDesc}>
												<p style={{ color: '#383838' }}>
													{t('dashboardpage.monthlyEntryDesc')}
												</p>
											</div>
										</div>
									</div>
								)}
							{/* unloack Output */}
							{dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'output_enabled' && (
									<div className="col-md-4">
										<div
											className={style.stageHolder + ' ' + style.setupSucess}
										>
											<div className={style.unlocked}>
												{t('generalText.unLocked')}!
											</div>
											<h1 className={style.steps}>
												{t('dashboardpage.three')}
											</h1>
											<Link to="/output" className={style.stages}>
												<div>
												<img
													src={Output}
													alt="REMS"
													className={style.setupIcon}
												/>
												<p className={style.setupTitle}>
													{t('generalText.outPut')}
												</p>
												</div>
											</Link>
											<div className={style.setupDesc}>
												<p>{t('dashboardpage.outPutDesc')}</p>
											</div>
										</div>
									</div>
								)}
							{(dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'setup_not_started') ||
							(dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'setup_completed') ? (
								<div className="col-md-4">
									<div
										className={style.stageHolder + ' ' + style.setupLocked}
									>
										<h1 className={style.steps}>
											{t('dashboardpage.three')}
										</h1>
										<Link className={style.stages}>
											<div>
											<img
												src={locked}
												alt="REMS"
												className={style.setupIcon}
											/>
											<p className={style.setupTitle}>
												{t('generalText.outPut')}
											</p>
											</div>
										</Link>
										<div className={style.setupDesc}>
											<p>{t('dashboardpage.outPutDesc')}</p>
										</div>
									</div>
								</div>
							) : null}
						</div>
					)}
				</div>
			) : (
				<Oval
					height={80}
					width={80}
					color="#4fa94d"
					wrapperStyle={{
						justifyContent: 'center',
					}}
					wrapperClass=""
					visible={true}
					ariaLabel="oval-loading"
					secondaryColor="#4fa94d"
					strokeWidth={2}
					strokeWidthSecondary={2}
				/>
			)}
			{/* <div className="row">
		<div className="col-md-4">
			<div className={style.stageHolder + ' ' + style.SetupComplete}>
				<h1 className={style.steps}>1</h1>
				<Link to="/" className={style.stages}>
					<img src={setupComplete} alt="REMS" className={style.setupIcon} />
					<p className={style.setupTitle}>Set up (Complete)</p>
				</Link>
				<div className={style.setupDesc}>
					<p>Identify the parameters that will help you track the indicators on your output.</p>
					<h6>NOTE : This is a one-time requirement.</h6>
				</div>
			</div>
		</div>
		
		<div className="col-md-4">
			<div className={style.stageHolder + ' ' + style.setupSucess}>
				<div className={style.unlocked}>Unlocked!</div>
				<h1 className={style.steps}>3</h1>
				<Link to="/" className={style.stages}>
					<img src={Output} alt="REMS" className={style.setupIcon} />
					<p className={style.setupTitle}>Output</p>
				</Link>
				<div className={style.setupDesc}>
					<p>You can generate your output, once you have at least 3 months of data.</p>
				</div>
			</div>
		</div>
	</div> */}

			{dashStatus !== null && (
				<div className="row" style={{paddingTop:window.innerWidth>768?"40px":"20px"}}>
					<div className="col-md-12 text-center text-md-start resetAllCenter">
						<button
							type="button"
							className={(dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'setup_completed') ||
							(dashStatus !== undefined &&
								dashStatus[0].dashboard_status === 'output_enabled')
								?"btn_outline_danger":"btn_outline_danger_hover"}
							onClick={resetAllData}
							disabled={
								(dashStatus !== undefined &&
									dashStatus[0].dashboard_status === 'setup_completed') ||
								(dashStatus !== undefined &&
									dashStatus[0].dashboard_status === 'output_enabled')
									? false
									: true
							}
							style={{
								color:
									(dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'setup_completed') ||
									(dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'output_enabled')
										? 'red'
										: '#D9D9D9',
								opacity:
									(dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'setup_completed') ||
									(dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'output_enabled')
										? '1'
										: '0.5',
								textDecoration:
									(dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'setup_completed') ||
									(dashStatus !== undefined &&
										dashStatus[0].dashboard_status === 'output_enabled')
										? 'undeline'
										: 'none',

								padding: '10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{t('dashboardpage.ResetButtonText')}
						</button>
					</div>
				</div>
			)}
			<Modal
				className="modalStyle text-center resetSetUp"
				show={Resetpopup}
				onHide={() => setResetpopup(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header></Modal.Header>
				<Modal.Body>
					<div className="mb-4 mt-1">
						{/* <h6 className="mb-4 text-left">
							{t('dashboardpage.wipeDataPopup')}
							<br />({t('dashboardpage.SetUpText')} {t('dashboardpage.and')}{' '}
							{t('generalText.monthlyEntry')})?
						</h6> */}
						 <div className="col-12 mb-3">
                                        <img src={alertcircle} alt="REMS" className={style.alert} />
                                        <h2 className='mb-2'>{t("dashboardpage.wipeDataPopup")}</h2>
                                        {langId === '1' ? <p><b className='text-primary'>NOTE:</b> This will delete all of your input data as well as the output generated, and you will have to start over from <b>Set up</b> again.</p> :
                                            <p><b className='text-primary'>{t("generalText.NOTE")}</b> ၎င်းသည် သင်၏ ထည့်သွင်းမှုဒေတာ/အချက်အလက်များ အားလုံးကို လည်းကောင်း၊ ထုတ်ပေးသည့် ရလဒ်ကို ဖျက်ပစ်မည်ဖြစ်ပြီး 
											<b> Set up</b>/တည်ဆောက်ခြင်းကို အစမှပြန်စရမည်ဖြစ်ပါသည်။</p>}
                                    </div>
						<div className="text-center pt-2" style={{display:"flex",justifyContent:"space-around"}}>
							<button
								className="btn btn-primary"
								style={{minWidth:"106px"}}
								onClick={() => ResetStatus('yes')}
							>
								{t('generalText.yes')}
							</button>
							<button
							    style={{minWidth:"106px"}}
								className="btn btn-light"
								onClick={() => ResetStatus('no')}
							>
								{t('generalText.no')}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div style={{paddingTop:window.innerWidth>768?"40px":"40px"}}>
				<ul className={style.staticPageLink} style={{margin:'0px',marginLeft:window.innerWidth>768?"0px":"17px"}}>
					<li>
						<Link to="/about">{t('landingpage.About')}</Link>
					</li>
					<li>
						<Link to="/privacy">{t('landingpage.PrivacyText')}</Link>
					</li>
					<li>
						<Link to="/terms">{t('landingpage.TermsText')}</Link>
					</li>
				</ul>
			</div>
		</div>
	</div>
);
};
export default Dashboard;
