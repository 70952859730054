// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#section2 > div:nth-child(1) > div > canvas {
	width: 100% !important;
}
#section2 > div:nth-child(2) > div > canvas {
	width: 100% !important;
}
#section3 > div:nth-child(1) > div > canvas {
	width: 100% !important;
}
#section3 > div:nth-child(2) > div > canvas {
	width: 100% !important;
}

#section4 > div:nth-child(1) > div > canvas {
	width: 100% !important;
}
#section4 > div:nth-child(2) > div > canvas {
	width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/frontend/pdf/style.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;AACvB;AACA;CACC,sBAAsB;AACvB;AACA;CACC,sBAAsB;AACvB;AACA;CACC,sBAAsB;AACvB;;AAEA;CACC,sBAAsB;AACvB;AACA;CACC,sBAAsB;AACvB","sourcesContent":["#section2 > div:nth-child(1) > div > canvas {\r\n\twidth: 100% !important;\r\n}\r\n#section2 > div:nth-child(2) > div > canvas {\r\n\twidth: 100% !important;\r\n}\r\n#section3 > div:nth-child(1) > div > canvas {\r\n\twidth: 100% !important;\r\n}\r\n#section3 > div:nth-child(2) > div > canvas {\r\n\twidth: 100% !important;\r\n}\r\n\r\n#section4 > div:nth-child(1) > div > canvas {\r\n\twidth: 100% !important;\r\n}\r\n#section4 > div:nth-child(2) > div > canvas {\r\n\twidth: 100% !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
