import { useState, useEffect } from "react";


export const useMasterdata = () => {
   // const [lang,setlang] =useState(localStorage.getItem('lan')!=null?localStorage.getItem('lan'):localStorage.setItem('lan','en'))
   //const [lanId,setLangId] = useState();
   let master_data = JSON.parse(localStorage.getItem('masterData'));
   const [masterData, setMasterData] = useState(master_data);
   const [boilerList,setBoilerList] = useState([]);
   const [dashBoardStatus,setDashboardStatus] = useState([]);
   const [unitList,setUnitList] =useState([]);
   const [VersionNumber,setVersionNumber]=useState([]);
   const [WasteList,setWasteList] = useState([]);
   const [monthlyEntryStatus,setMonthlyEntrtStatus] =useState([]);
   const MasterList = () => {
      let boilerFuelList = masterData&&masterData.BOILER_FUELS_LIST;
      let dashStatus = masterData&&masterData.DASHBOARD_STATUS;
      let uList= masterData&&masterData.UNIT_LIST;
      let vNumber= masterData&&masterData.VERSION_NUMBER;
      let wasteList= masterData&&masterData.WASTE_LIST;
      setBoilerList(JSON.parse(boilerFuelList));
      setDashboardStatus(JSON.parse(dashStatus));
      setUnitList(JSON.parse(uList));
      setVersionNumber(JSON.parse(vNumber));
      setWasteList(JSON.parse(wasteList));

   }

   useEffect(() => {
      MasterList()
   }, [])
   return {
      boilerList,
      dashBoardStatus,
      unitList,
      VersionNumber,
      WasteList,
      setMasterData,
   }
};