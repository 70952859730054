import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const BlankRawMaterials = () => {
    const {t} = useTranslation()
    return (
            <div className={style.section + ' ' + style.sec_2a} style={{opacity:"0.4"}}>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3><span>2a</span>{t("monthlylogpage.RawMaterialsHeaderText")}</h3>
                        <p>{t("monthlylogpage.noRawMaterial")}</p>
                    </div>
                </div>
           </div>
    )
}
export default BlankRawMaterials;