import React, { useState, useEffect } from 'react';
import LineChart from './LineChart';
import { dataset } from '../utils/dataset';
import Loader from './Loader';
import RequestError from './RequestError';
import { getDisposalMethodData } from '../../../services/AdminApis';
import { useQuery } from 'react-query';
import { createUniqueMonths } from '../utils/getUniqueMonths';
import { monthsToDays } from '../utils/monthsToDays';
import { createUniqueMonthsAgain } from '../utils/newUniqueMonths';

const DisposalMethod = () => {
	const [timeline, setTimeline] = useState('past 6 months');

	useEffect(() => {
		if (
			localStorage.getItem(
				'Total waste disposed by each disposal method for all users by month'
			)
		) {
			setTimeline(
				localStorage.getItem(
					'Total waste disposed by each disposal method for all users by month'
				)
			);
		}
	}, []);

	const memoizedQueryKey = ['disposalMethod', { days: monthsToDays[timeline] }];

	const { data, isLoading, isError, error } = useQuery(
		memoizedQueryKey,
		getDisposalMethodData,
		{ retry: 0 }
	);

	if (isLoading) {
		return <Loader />;
	}
	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	// Step 1: Sort the data based on created_at_month in ascending order
	const sortedData = createUniqueMonthsAgain(data?.data);

	// Step 2: Extract unique months and years
	const uniqueMonths = Array.from(
		new Set(sortedData.map((item) => item.created_at_month))
	);

	// Step 3: Create arrays for each type of data
	const openBurningTotal = [];
	const landFillingTotal = [];
	const recyclingTotal = [];

	uniqueMonths.forEach((month) => {
		const matchingData = sortedData.find(
			(item) => item.created_at_month === month
		);

		if (matchingData) {
			openBurningTotal.push(matchingData.open_burning_total);
			landFillingTotal.push(matchingData.land_filling_total);
			recyclingTotal.push(matchingData.recycling_total);
		} else {
			openBurningTotal.push(null);
			landFillingTotal.push(null);
			recyclingTotal.push(null);
		}
	});

	const disposalMethodDatasetArray = [
		dataset(openBurningTotal, 'Open burning', '#f65b3c', '#f65b3c'),
		dataset(landFillingTotal, 'Land filling', '#ffb300', '#ffb300'),
		dataset(recyclingTotal, 'Recycling', '#138c50', '#138c50'),
	];

	return (
		<LineChart
			title="Total waste disposed by each disposal method for all users by month"
			dataSetArray={disposalMethodDatasetArray}
			labels={uniqueMonths}
			timeline={timeline}
			setTimeline={setTimeline}
		/>
	);
};

export default DisposalMethod;
