import React, { useEffect } from 'react';
import { insights } from './InsightsContent';
import PreventPlasticLogo from '../../../assets/frontend/images/logo.png';
// import SwitchAsiaLogo from '../../../assets/frontend/images/switchasia.png';
import SwitchAsiaLogo from '../../../assets/frontend/images/UPDATED_switch-asia_logo.png';
import { useState } from 'react';
import PdfLineChart from '../../admin/components/PdfLineChart';
import PieChart from '../../admin/components/PieChart';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import NotGenerated from './NotGenerated';
import './style.css';
import { useMediaQuery } from '@mui/material';

const Pdf = ({ generateOutput, setGenerateOutput, selectedGraphs }) => {
	const [output, setOutput] = useState({});
	const [comments, setComments] = useState([]);
	const isMobile = useMediaQuery('(max-width:600px)');

	useEffect(() => {
		if (generateOutput) {
			handleGeneratePDF();
		}
		if (localStorage.getItem('pdfOutput')) {
			setOutput(JSON.parse(localStorage.getItem('pdfOutput')));
			const data = JSON.parse(localStorage.getItem('pdfOutput'))?.comments;
			let response = [];
			for (const key in data) {
				if (data.hasOwnProperty(key)) {
					const monthYear = key;
					const comment = data[key];

					// Extract the month and year components
					const [month, year] = monthYear.split(' ');

					// Create an object with "month" and "comment" properties and push it into the response array
					response.push({ month: month.toUpperCase(), year, comment });
				}
			}
			setComments(response);
		}
	}, []);

	const handleGeneratePDF = async () => {
		const pdf = new jsPDF('l', 'px');

		const section1 = document.getElementById('section1');
		const section2 = document.getElementById('section2');
		const section3 = document.getElementById('section3');
		const section4 = document.getElementById('section4');

		const addPageNumber = (pageNumber, pageCount) => {
			pdf.setFontSize(9); // Set your desired font size
			pdf.setTextColor('black'); // Set your desired text color (RGB)
			// Add page number text to the top left corner of the page
			pdf.text(`Page ${pageNumber} `, 10, 10);

			pdf.setFontSize(9); // Set your desired font size
			pdf.setTextColor('gray'); // Set your desired text color (RGB)
			// Add page number text to the top left corner of the page
			pdf.text(`of ${pageCount}`, 34, 10);
			if (pageNumber == 1) {
				pdf.addImage(PreventPlasticLogo, 'png', 40, 355, 40, 45); // position: x, y, width,
				pdf.addImage(SwitchAsiaLogo, 'png', 90, 355, 115, 50); // position: x, y, width, height
			}
		};

		const addSectionToPDF = (section, pageNumber, pageCount) =>
			new Promise((resolve) => {
				if (section) {
					setTimeout(() => {
						html2canvas(section, {
							scale: isMobile ? 3 : 2, // Lower scale to reduce resolution
							useCORS: true,
							onclone: (documentClone) => {
								// Modifications to cloned document if needed
							},
						}).then((canvas) => {
							// Use JPEG format and reduce quality to reduce file size
							let imgData;
							if (pageNumber == 1) {
								imgData = canvas.toDataURL('image/jpeg', 1);
								// imgData = canvas.toDataURL('image/jpeg', 0.75);
							} else {
								imgData = canvas.toDataURL('image/jpeg', 0.75);
							}

							// Adjust the width and height as necessary
							pdf.addImage(
								imgData,
								'JPEG',
								40,
								40,
								pdf.internal.pageSize.getWidth() - 80,
								pdf.internal.pageSize.getHeight() - 80
							);

							if (pageNumber <= pageCount) {
								addPageNumber(pageNumber, pageCount);

								pdf.addPage();
							}
							resolve();
						});
					}, 1000); // Delay to ensure rendering is done
				} else {
					resolve();
				}
			});

		// ... rest of the generatePDF function

		const generatePDF = async () => {
			await addSectionToPDF(section1, 1, 4);
			await addSectionToPDF(section2, 2, 4);
			await addSectionToPDF(section3, 3, 4);
			await addSectionToPDF(section4, 4, 4);

			const totalPages = pdf.internal.getNumberOfPages();
			// Delete the last page which was white empty page
			pdf.deletePage(totalPages);

			pdf.save(
				`${localStorage.getItem(
					'companyName'
				)}_Ref-Track_Full_Report_${getCurrentDate()}`
			);
			setGenerateOutput(false);
		};
		generatePDF();
	};

	function getCurrentDate() {
		const today = new Date();
		const day = String(today.getDate()).padStart(2, '0'); // Get day and pad with '0' if needed
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Get month (January is 0) and pad with '0' if needed
		const year = today.getFullYear();

		// Combine day, month, and year with '/' delimiter
		const formattedDate = `${day}/${month}/${year}`;

		return formattedDate;
	}

	return (
		<div className="mainWrapper">
			{/* <button onClick={handleGeneratePDF}>Generate pdf</button> */}
			<section
				id="section1"
				style={{
					display: 'flex',

					gap: isMobile ? '100px' : '120px',
				}}
			>
				<div style={{ flex: 1 }}>
					<h2
						style={{ fontWeight: 'bold', fontSize: isMobile ? '31px' : '34px' }}
					>
						Ref-Track Full Report
					</h2>
					<p>
						Generated on <b>{getCurrentDate()}</b> by &nbsp;
						<b>{localStorage.getItem('userName')}</b>,&nbsp;
						<b>{localStorage.getItem('companyName')}</b>
					</p>
					<div style={{ marginTop: '140px', color: 'gray' }}>
						<p style={{ fontWeight: 'bold', fontSize: '18px' }}>Disclaimer</p>
						<p style={{ fontSize: '13px', lineHeight: 1.4 }}>
							The <b>Ref-track</b> platform is provided by{' '}
							<b>Prevent Plastics</b> for the internal use by any enterprise.
							The name <b>Prevent Plastics</b> shall not be used in any
							representation, advertising, publicity or other manner whatsoever
							to endorse or promote any entity that adopts or uses this
							calculator. The results from the Ref-track tool are generated
							entirely based on resource consumption and production data
							provided by the user. There is no verification or other check
							performed by Prevent Plastics. Therefore, these results from the
							Ref-track calculator can not be used as an indication of
							validation or third-party assessment by <b>Prevent Plastics</b>.
						</p>
						<p style={{ fontSize: '13px', lineHeight: 1.4 }}>
							This platform has been developed as a part of the{' '}
							<b>Prevent Plastics </b>
							project and funded by the European Union. The contents displayed
							on it can be under no circumstances be regarded as the reflecting
							the position of the European Union. The results from this
							Ref-track tool are generated entirely based on resource
							consumption and production data provided by the user. The user is
							encouraged to review input data in case of abnormal results.
						</p>
						<p
							style={{
								fontWeight: 'bold',
								marginTop: '50px',
								fontSize: '18px',
							}}
						>
							Designed and funded by
						</p>
						{/* <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
							<img
								src={PreventPlasticLogo}
								alt="Prevent Plastics"
								width="70px"
							/>
							<img
								src={SwitchAsiaLogo}
								alt="Switch Asia Logo"
								width="200px"
								height="50px"
							/>
						</div> */}
					</div>
				</div>

				<div style={{ flex: 1.2 }}>
					<h6 style={{ fontWeight: 'bold' }}>Insights/Index</h6>
					{insights.map((insight, index) => (
						<p key={index}>
							<div
								style={{
									marginBottom: '5px',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<div>
									<b style={{ color: '#138C50' }}>{insight.name}</b> :{' '}
									<b style={{ color: '#383838' }}>{insight.title}</b>
								</div>
								<span style={{ fontSize: '12px', color: '#138C50' }}>
									{insight.page}
								</span>
							</div>
							<span
								style={{
									color: '#383838',
									display: 'block',
									lineHeight: 1.3,
									fontSize: '13px',
								}}
							>
								{insight.text}
							</span>
						</p>
					))}
				</div>
			</section>
			<section id="section2">
				{Object.keys(output).length > 0 &&
				selectedGraphs['Specific Waste Generation'] ? (
					<div style={{ height: '450px', minHeight: '450px' }}>
						<PdfLineChart
							title="Specific Waste Generation"
							unit="kg/kg"
							dataSetArray={output?.specific_waste_generation?.dataset}
							labels={output?.specific_waste_generation?.uniqueMonths}
						/>
					</div>
				) : (
					<NotGenerated title="Specific Waste Generation" unit="kg/kg" />
				)}
				{Object.keys(output).length > 0 &&
				selectedGraphs['Net Revenue from Waste'] ? (
					<div style={{ height: '450px', minHeight: '450px' }}>
						<PdfLineChart
							title="Net Revenue from Waste"
							unit="Ks."
							dataSetArray={output?.specific_revenue_generation?.dataset}
							labels={output?.specific_revenue_generation?.uniqueMonths}
						/>
					</div>
				) : (
					<NotGenerated title="Net Revenue from Waste" unit="Ks." />
				)}
				{comments?.length > 0 && (
					<div
						style={{
							marginTop: '25px',
							display: 'flex',
							alignItems: 'start',
							justifyContent: 'start',
							gap: '10px',
						}}
					>
						<div>
							<b>Comments: &nbsp;</b>
						</div>
						<div>
							{comments.map((comment, index) => (
								<span key={index} style={{ marginRight: '20px' }}>
									<b>
										{comment?.month} &nbsp; {comment?.year} : &nbsp;
									</b>
									{comment?.comment} .
								</span>
							))}
						</div>
					</div>
				)}
			</section>
			<section id="section3">
				{Object.keys(output).length > 0 &&
				selectedGraphs['Specific Energy Consumption'] ? (
					<div style={{ height: '450px', minHeight: '450px' }}>
						<PdfLineChart
							title="Specific Energy Consumption"
							unit="kWh/kg"
							dataSetArray={output?.specific_energy_generation?.dataset}
							labels={output?.specific_energy_generation?.uniqueMonths}
						/>
					</div>
				) : (
					<NotGenerated title="Specific Energy Consumption" unit="kWh/kg" />
				)}
				{Object.keys(output).length > 0 &&
				selectedGraphs['Specific Material Consumption'] ? (
					<div style={{ height: '450px', minHeight: '450px' }}>
						<PdfLineChart
							title="Specific Material Consumption"
							unit="kg/kg"
							dataSetArray={output?.specific_material_consumption?.dataset}
							labels={output?.specific_material_consumption?.uniqueMonths}
						/>
					</div>
				) : (
					<NotGenerated title="Specific Material Consumption" unit="kg/kg" />
				)}
				{comments?.length > 0 && (
					<div
						style={{
							marginTop: '25px',
							display: 'flex',
							alignItems: 'start',
							justifyContent: 'start',
							gap: '10px',
						}}
					>
						<div>
							<b>Comments: &nbsp;</b>
						</div>
						<div>
							{comments.map((comment, index) => (
								<span key={index} style={{ marginRight: '20px' }}>
									<b>
										{comment?.month} &nbsp; {comment?.year} : &nbsp;
									</b>
									{comment?.comment} .
								</span>
							))}
						</div>
					</div>
				)}
			</section>
			<section style={{ display: 'flex', marginBottom: '30px' }} id="section4">
				<div
					style={{
						width: isMobile ? '550px' : '550px',
					}}
				>
					<div
						style={{
							backgroundColor: '#F1F1F1',
							minHeight: '350px',
							marginBottom: '60px',
							width: isMobile ? '500px' : '530px',
							height: isMobile ? '360px' : '350px',
							textAlign: 'center',
							orderRadius: '8px',
						}}
					>
						{/* <h4>Share of energy from renewables (%)</h4> */}

						<PieChart
							title="Share of energy from renewables (%)"
							percentageOne={
								output?.share_of_energy_from_renewables == null ||
								output?.share_of_energy_from_renewables == undefined ||
								output?.share_of_energy_from_renewables == NaN
									? 0
									: output?.share_of_energy_from_renewables
							}
							labelOne="Energy from renewables"
							labelTwo="Energy not from renewables"
							percentageTwo={
								100 -
								(output?.share_of_energy_from_renewables == null ||
								output?.share_of_energy_from_renewables == undefined ||
								output?.share_of_energy_from_renewables == NaN
									? 0
									: output?.share_of_energy_from_renewables)
							}
						/>
					</div>

					<div
						style={{
							backgroundColor: '#F1F1F1',
							minHeight: '350px',

							// width: isMobile ? '400px' : '530px',
							width: isMobile ? '500px' : '530px',
							height: isMobile ? '360px' : '350px',
							textAlign: 'center',
							orderRadius: '8px',
						}}
					>
						<PieChart
							title="Share of Waste being recycled (%)"
							percentageOne={
								output?.share_of_waste_being_recycled == null ||
								output?.share_of_waste_being_recycled == undefined ||
								output?.share_of_waste_being_recycled == NaN
									? 0
									: output?.share_of_waste_being_recycled
							}
							labelOne="Waste being recycled"
							labelTwo="Waste not being recycled"
							percentageTwo={
								100 -
								(output?.share_of_waste_being_recycled == null ||
								output?.share_of_waste_being_recycled == undefined ||
								output?.share_of_waste_being_recycled == NaN
									? 0
									: output?.share_of_waste_being_recycled)
							}
						/>
					</div>
				</div>
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignContent: 'center',
							justifyContent: 'center',
							gap: '20px',
							// padding: isMobile
							// 	? '100px 5px 100px 100px'
							// 	: '100px 40px 100px 100px',
							padding: '100px 40px 100px 100px',
						}}
					>
						<h2>About the project</h2>
						<p style={{ fontSize: isMobile ? '15px' : '16px' }}>
							The Ref-Track platform is provided by <b>Prevent Plastics</b> for
							the internal use by any enterprise.
						</p>
						<p style={{ fontSize: isMobile ? '15px' : '16px' }}>
							Started in May 2020, Prevent Plastics is a four-year project
							funded by the European Union with the aim to promote sustainable
							production and consumption patterns in Myanmar through raised
							awareness and best practices on waste management. The project is a
							joint approach of sequa gGmbH, Myanmar Banks Association and
							STENUM Asia.{' '}
						</p>
						<p style={{ fontSize: isMobile ? '15px' : '16px' }}>
							This platform has been developed as a part of the{' '}
							<b>Prevent Plastics</b> project and maintained with the financial
							support of the{' '}
							<span style={{ color: '#138C50' }}>
								European Union’s SWITCH-Asia
							</span>{' '}
							grants program. The contents displayed on it can be under no
							circumstances be regarded as the reflecting the position of the
							European Union.
						</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Pdf;
