import React from 'react';
import { CircularProgress, Box, useMediaQuery } from '@mui/material';

const Loader = () => {
	const isMobile = useMediaQuery('(max-width:600px)');

	return (
		<Box
			display="grid"
			alignItems={isMobile ? 'start' : 'center'}
			justifyContent={isMobile ? 'start' : 'center'}
			height="80vh"
			marginTop={isMobile ? '-830px' : 'auto'}
			marginRight={isMobile ? '650px' : 'auto'}
		>
			<CircularProgress color="success" />
		</Box>
	);
};

export default Loader;
