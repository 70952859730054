export const HeaderProfileHide = [
    "/",
    "/createnewaccount",
    "/forgot-password",
    "/resetpassword",
    "/verify-your-account",
    "/about",
    "/contact",
    "/help",
    "/privacy",
    "/terms",
    "/emailverify",
    "/active-your-account"
];

export const monthList = [
    {
        id:1,
        month:'january'
    },
    {
        id:2,
        month:'february'
    },
    {
        id:3,
        month:'march'
    },
    {
        id:4,
        month:'april'
    },
    {
        id:5,
        month:'may'
    },
    {
        id:6,
        month:'june'
    },
    {
        id:7,
        month:'july'
    },
    {
        id:8,
        month:'august'
    },
    {
        id:9,
        month:'september'
    },
    {
        id:10,
        month:'october'
    },
    {
        id:11,
        month:'november'
    },
    {
        id:12,
        month:'december'
    }
];

export const yearList = [
   {
    id:1,
    year:2020
   },
   {
    id:2,
    year:2021
   },
   {
    id:3,
    year:2022
   },
   {
    id:4,
    year:2023
   },
   {
    id:5,
    year:2024
   },
   {
    id:6,
    year:2025
   },
   {
    id:7,
    year:2026
   },
   {
    id:8,
    year:2027
   },
   {
    id:9,
    year:2028
   },
   {
    id:10,
    year:2029
   },
   {
    id:11,
    year:2030
   }
];
    

export const monthlyArray = [
    {
        year:2020,
        monthlyentry:[{
            month:'march',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },{
            month:'april',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'june',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'july',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'august',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        }],
    },
    {
        year:2021,
        monthlyentry:[{
            month:'march',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },{
            month:'april',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'may',
            monthly_json:[
                {
                    "productJson": [
                        {
                            "id": 1,
                            "product_name": "milkshake",
                            "conversion_factor": 0.93,
                            "unit_name": "metre",
                            "quantity": 54.5
                        },
                        {
                            "id": 2,
                            "product_name": "milkshake",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 555.22
                        },
                        {
                            "id": 3,
                            "product_name": "milkshake3",
                            "conversion_factor": 445,
                            "unit_name": "metre",
                            "quantity": 550
                        }
                    ],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'june',
            monthly_json:[
                {
                    products:[],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'july',
            monthly_json:[
                {
                    products:[],
                    resources:[],
                    waste:[]
                }
            ]
        },
        {
            month:'august',
            monthly_json:[
                {
                    products:[],
                    resources:[],
                    waste:[]
                }
            ]
        }],
    }
]