import React, { useContext, useState, useEffect } from 'react';
import style from './setup.module.css';
import add from '../../../assets/frontend/images/add_green.png';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import alertcircle from '../../../assets/frontend/images/alert_circle.svg';
import { Context } from '../../../frontend_context/contextProvider';
import { useAddWasteHook, useDeleteWasteHook, useUpdateSetupStatusHook } from '../api_hooks/api_hooks';
import { useMasterdata } from '../customHooks/masterDatahook';
import { toast } from 'react-toastify';
import remove from '../../../assets/frontend/images/remove.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const WasteGenerated = () => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { wasteGenerateList } = useContext(Context);
    const { WasteList } = useMasterdata();
    const [wasteType, setWasteType] = useState([])
    const [wastevalues, setwastevalues] = useState({
        wasteId: ''
    })
    const [disabledConv, setDisableConv] = useState(true);
    const [currentUnit, setCurrentUnit] = useState('')
    const [show, setShow] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState();
    const [webUnderMaintanence, SetwebUnderMaintanence] = useState(false);
    const handleClose = () => {
        setShow(false);
        setwastevalues({
            wasteId: ''
        })
        setCurrentUnit('')
    }
    const handleShow = () => {
        setDeleteIndex()
        if (wasteGenerateList == null) {
            setWasteType(WasteList)
            setShow(true);
        }
        else if (wasteGenerateList.length < 5) {
            setWasteType(WasteList)
            setShow(true);
        }
        else {
            toast.warning(t("validationMSG.maxWasteGen"))
        }
    }

    const [finishSetup, setFinishSetup] = useState(false);
    const handleCloseFinishSetup = () => setFinishSetup(false);
    const handleShowFinishSetup = () => {
        setFinishSetup(true);
    }
    const handleChange = (e) => {
        let val = e.target.value
        var options = e.target.options;
        var Id = options[options.selectedIndex].id;
        setDisableConv(false)
        setwastevalues({
            wasteId: val
        })
        setCurrentUnit(Id)
    }
    const { mutate: addWaste } = useAddWasteHook();
    const addWasteFunction = () => {
        let W_data = {
            "waste_id": wastevalues.wasteId
        }
        addWaste(W_data);
        setwastevalues({
            wasteId: ''
        })
        setCurrentUnit('');
        setDisableConv(true);
        setShow(false);
    }
    const wasteDeleteFunction = (e,index) => {
        e.stopPropagation();
        setDeleteIndex(index)
    }
    const { mutate: deleteWaste } = useDeleteWasteHook()
    const wasteDelete = (e,wasteId) => {
        e.stopPropagation();
        let Wdata = {
            "waste_id": wasteId
        }
        deleteWaste(Wdata);
        setDeleteIndex();
    }
    const { mutate: UpdateStatus } = useUpdateSetupStatusHook();
    const changeSetupStatus = () => {
        // let status ={
        //     "dashboard_status":"setup_completed"
        // }
        UpdateStatus();
        navigate('/dashboard')
    }
    const disableRemovebtn =()=>{
        setDeleteIndex()
    }
    useEffect(()=>{
        document.body.addEventListener('click',disableRemovebtn)
    },[])
    return (
        <div className={style.setupWrapper}>
            <div>
                <div className="row">
                    <div className="col-md-6">
                        {langId === '1' ? <p>Add the <b>types of waste</b> (max. 5) that you generate from the list provided. Relevant units will be automatically selected for you depending on what you choose.</p> :
                            <p>ဖော်ပြပါ စာရင်းမှ သင်နဲ့ ဆိုင်သော <b>အမှိုက်အမျိုးအစားကို</b> ဖြည့်သွင်းပါ (အများဆုံး ၅ မျိုး)။
                                ယူနစ်များကို သင့်လျော်သလို အပ်စပ်‌ပေးသွားပါမည်။</p>}
                        <p><b>{t("generalText.NOTE")}</b> {t("setuppage.WasteGeneratedTabNoteText")}</p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <button
                            className={wasteGenerateList != null && wasteGenerateList.length > 4 ? "add_btn_disable buttonFlex" : "add_btn buttonFlex"}
                            onClick={handleShow}
                            type="button"
                            disabled={wasteGenerateList != null && wasteGenerateList.length > 4 ? true : false}
                            >
                            {wasteGenerateList == null ||(wasteGenerateList.length> 0 && wasteGenerateList.length !=5) ?<img src={add} alt="REMS" />:null}
                            {wasteGenerateList == null ||(wasteGenerateList.length> 0 && wasteGenerateList.length !=5) ?<div>{wasteGenerateList != null && wasteGenerateList.length > 0 ? <span style={{ fontSize: '14px', fontWeight: "400" }}>{"("}<b>{wasteGenerateList != null && wasteGenerateList.length}</b>{"/5 " + t("setuppage.wasteAdded")}{")"}</span> : t("setuppage.WasteGeneratedTabAddTypeButton")}</div>:
                            <div className='py-2 py-md-0' style={{color:"#A6A6A6", fontSize:"11px"}}>{t("validationMSG.maxWasteGen")}</div>}
                        </button>
                        <Modal className="modalStyle setUpmodalInner" show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                            <CloseIcon className={style.closeIconPop} onClick={() => setShow(false)} />
                            <Modal.Header><h2 style={{ paddingTop: "10px" }}>{t("setuppage.identifyWastetext")}</h2></Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12 px-2">
                                        <p>{t("setuppage.wastePopParagraph")}</p>
                                    </div>
                                    <div className="col-12 mb-4 px-2">
                                        <select
                                            className={'form-select ' + style.selectHover}
                                            style={{ padding: "8px 6px", fontWeight: "600", fontSize: "11px", textAlign: "left" }}
                                            value={wastevalues.wasteId}
                                            onChange={(e) => handleChange(e)}>
                                            <option value="" disabled style={{ fontSize: "14px" }}>{t("setuppage.selectWaste")}</option>
                                            {wasteType != null && wasteType.map((item, index) => <option style={{ fontSize: "14px" }} key={index} value={item.waste_id} id={item.unit_name}>{item.waste_name}</option>)}

                                        </select>
                                    </div>
                                    <div className="col-12 px-2">
                                        <p>{t("setuppage.wastePopParagraphTwo")}</p>
                                    </div>
                                    <div className='col-12 mb-4 px-2'>
                                        <div className={currentUnit === '' ? style.unitBox : style.unitBoxActive}>{currentUnit === '' ? t("generalText.unit") : currentUnit}</div>
                                    </div>
                                    <div className="col-md-12 text-center px-2">
                                        <button
                                            className={disabledConv === false ? "btn_primary w-100" : "disable_primarysetUp"}
                                            type="button"
                                            onClick={addWasteFunction}
                                            disabled={disabledConv}
                                        >{t("setuppage.saveWasteButton")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mb-5 px-4 px-md-1'>
                        <div className={style.productsList} style={{ display: "flex", flexWrap: "wrap" }}>
                            {wasteGenerateList != null && wasteGenerateList.map((item, index) => <div key={index} className={style.card}>
                                {deleteIndex === index ? <div className={style.deletcard}><div className={style.deletcardHolder}>
                                    <div
                                        onClick={(e) => wasteDelete(e,item.waste_id)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img src={remove} alt="REMS" />{t("generalText.remove")}
                                    </div></div></div> :
                                    null}
                                <div>
                                    <h6>{item.waste_name}</h6>
                                    <p style={{textTransform:"lowercase"}}>(in {item.unit_name})</p>
                                </div>
                                <button
                                    type="button"
                                    onClick={(e) => wasteDeleteFunction(e,index)}
                                ></button>
                            </div>)}
                        </div>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className='card'>
                            <div className={'card-body ' + style.checkoutDWE}>
                                <h5>{t("setuppage.Didyouknow")}</h5>
                                {langId === '1' ? <p><b>Industrial symbiosis</b> is an association between two or more industrial facilities or companies in which the wastes or by-products of one become the raw materials for the other, in a linear cascading approach.</p> :
                                    <p><b>စက်မှုလုပ်ငန်းအချင်းချင်း</b> အပြန်အလှန်အကျိူးပြုဓလေ့ဆိုသည်မှာ
                                        လုပ်ငန်းတစ်ခုထက်ပိုသော စက်ရုံံ၊ အလုပ်ရုံ၊ ကုမ္ပဏီတို့မှ အမှိုက်တွေဟာ အခြားလုပ်ငန်းအတွက် ကုန်ကြမ်း ဖြစ်စေကာ အစဉ်အဆက် မှီခိုနိုင်ခြင်းကို ဆိုလိုပါသည်။
                                    </p>}
                                <p style={{ marginBottom: "0px" }}>{t("setuppage.WasteGeneratedTabBiolerSubTexttwo")}</p>
                                {langId === '1' ? <ul style={{ lineHeight: "20px" }}>
                                    <li><b>1. Economic- </b>by lowering the cost of operations</li>
                                    <li><b>2. Environmental- </b>via pollution (waste) abatement</li>
                                </ul> :
                                    <ul style={{ lineHeight: "20px" }}>
                                        <li><b>၁။ စီးပွားရေးအရ</b>- ဈေးနှုန်းလျှော့ချနိုင်ပါတယ်။</li>
                                        <li><b>၂။ သဘာ၀ပတ်ဝန်းကျင်အတွက်လည်း</b>- အမှိုက်နဲ့ညစ်ညမ်းမှု လျှော့ချနိုင်ပါတယ်။ </li>
                                    </ul>}
                                <Link onClick ={()=>SetwebUnderMaintanence(true)}>{t("setuppage.WasteGeneratedTabCheckOutText")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <button
                            className={wasteGenerateList != null && wasteGenerateList.length > 0 ? "btn_primary" : "disable_primary"}
                            onClick={handleShowFinishSetup}
                            type="button"
                            disabled={wasteGenerateList != null && wasteGenerateList.length > 0 ? false : true}
                            style={{ 
                                paddingLeft: "60px", paddingRight: "60px",
                                minWidth:window.innerWidth<768?"100%":"130px"
                            }}
                        >{t("setuppage.WasteGeneratedTabFinishButton")}</button>

                        <Modal className="modalStyle text-center finishSetup"
                            show={finishSetup} onHide={handleCloseFinishSetup}
                            size="sm" aria-labelledby="contained-modal-title-vcenter"

                            centered>
                            <Modal.Header></Modal.Header>
                            <Modal.Body
                                style={{ maxWidth: "390px" }}
                            >
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <img src={alertcircle} alt="REMS" className={style.alert} />
                                        <h2 className='mb-2'>{t("setuppage.areWantToFinishConformText")}</h2>
                                        {langId === '1' ? <p><b className='text-primary'>NOTE:</b> The <span style={{ fontWeight: '600' }}>Set up</span> stage is a one-time requirement and will be inaccessible once completed.</p> :
                                            <p><b className='text-primary'>{t("generalText.NOTE")}</b> တပ်ဆင်ခြင်း အဆင့်သည် တစ်ကြိမ် လိုအပ်ချက်ဖြစ်ပြီး ပြီးသည်နှင့် တစ်ပြိုင်နက် ဝင်ရောက်၍ မရနိုင်ပါ။</p>}
                                    </div>

                                    <div
                                        className="col-md-12 mb-3 text-center"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around"
                                        }}
                                    >
                                        <button
                                            className={"btn_primary me-3 " + style.primarySm}
                                            type="button"
                                            onClick={changeSetupStatus}
                                        >{t("setuppage.yesFinishSetup")}</button>
                                        <button className={"btn btn-light " + style.primarySm} type="button" onClick={handleCloseFinishSetup}>{t("setuppage.noGoBack")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal className="modalStyle conformPerPopup" show={webUnderMaintanence} onHide={() =>SetwebUnderMaintanence(false)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <span>{langId === '1'?"This site is under maintenance for now!":"ဤဆိုက်ကို လောလောဆယ် ထိန်းသိမ်းထားသည်!"}</span>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WasteGenerated;