export const baseUrl = process.env.REACT_APP_API_URL;
export const cmsUrl = process.env.REACT_APP_CMS_API_URL;
export const app_key = process.env.REACT_APP_KEY;
export const app_name = process.env.REACT_APP_NAME;
export const site_key = process.env.REACT_APP_SITE_KEY;

const baseLink = 'app/v1/';

export const apiPath = {
	//Frontend Api's
	userLogin: baseLink + 'security/login',
	userRegistration: baseLink + 'user/registration',
	resetPasswordRequest: baseLink + 'security/resetpasswordrequest',
	resetPassword: baseLink + 'security/resetpassword',
	masterData: baseLink + 'user/masterlist',
	deviceInfo: baseLink + 'user/deviceinfo',
	getAllsetup: baseLink + 'setup/getsetupdata',
	addProducts: baseLink + 'setup/addproduct',
	deleteProduct: baseLink + 'setup/deleteproduct',
	addRawMaterials: baseLink + 'setup/addrawmaterial',
	deleteMaterial: baseLink + 'setup/deletematetial',
	addBoilerfuel: baseLink + 'setup/addboilerfuelresource',
	deleteBoiler: baseLink + 'setup/deleteboilerfuel',
	addWaste: baseLink + 'setup/addwaste',
	deleteWaste: baseLink + 'setup/deletewaste',
	updateSetupStatus: baseLink + 'setup/updatesetupfinishstatus',
	resetSetUpdata: baseLink + 'setup/resetsetupdata',
	editUser: baseLink + 'user/updateprofile',
	getUser: baseLink + 'user/getloggeduserdetails',
	startMonthlyEntryPeriod: baseLink + 'monthly/startmonthlyentry',
	monthlyAddproduct: baseLink + 'monthly/addproduct',
	getMonthlyData: baseLink + 'monthly/data',
	monthlyAddRawMaterial: baseLink + 'monthly/addrawmaterial',
	addBoilerfuelResource: baseLink + 'monthly/addboilerfuelresource',
	addMonthlyWaste: baseLink + 'monthly/addwaste',
	addMontlyComment: baseLink + 'monthly/addcomment',
	generateOutput: baseLink + 'monthly/generateOutput',
	getInputLog: baseLink + 'monthly/getinputlog',
	newUserVerificationReq: baseLink + 'security/emailverificationrequest',
	emailVerification: baseLink + 'security/emailverify',
};
