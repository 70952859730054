// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__8eZQN{
    background: #F2F2F2;
    padding:6px 20px; 
}
.footer_footer__8eZQN p{
    color: #383838;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
    text-align: right;
}
.footer_footer__8eZQN p{
    text-align: left;
    position: relative;
    padding-top: 26px;
    max-width: 444px;
}
.footer_footer__8eZQN p::before{
    position: absolute;
    content: '';
    width: 12px;
    height: 2px;
    background-color: #00000069;
    top: 8px;
    left: 0;
}
@media only screen and (max-width: 768px) {
    .footer_footer__8eZQN{
        background: #F2F2F2;
        padding:10px 10px; 
    }
   
}`, "",{"version":3,"sources":["webpack://./src/components/frontend/footer/footer.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;IACT,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,WAAW;IACX,2BAA2B;IAC3B,QAAQ;IACR,OAAO;AACX;AACA;IACI;QACI,mBAAmB;QACnB,iBAAiB;IACrB;;AAEJ","sourcesContent":[".footer{\r\n    background: #F2F2F2;\r\n    padding:6px 20px; \r\n}\r\n.footer p{\r\n    color: #383838;\r\n    font-size: 12px;\r\n    line-height: 18px;\r\n    font-weight: 400;\r\n    margin: 0;\r\n    text-align: right;\r\n}\r\n.footer p{\r\n    text-align: left;\r\n    position: relative;\r\n    padding-top: 26px;\r\n    max-width: 444px;\r\n}\r\n.footer p::before{\r\n    position: absolute;\r\n    content: '';\r\n    width: 12px;\r\n    height: 2px;\r\n    background-color: #00000069;\r\n    top: 8px;\r\n    left: 0;\r\n}\r\n@media only screen and (max-width: 768px) {\r\n    .footer{\r\n        background: #F2F2F2;\r\n        padding:10px 10px; \r\n    }\r\n   \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__8eZQN`
};
export default ___CSS_LOADER_EXPORT___;
