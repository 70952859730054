import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import landingStyle from './landing.module.css';
import aboutImg from '../../../assets/frontend/images/about-img.jpg';
import loadMore from '../../../assets/frontend/images/load_more.png';
import playIcon from '../../../assets/frontend/images/play_white.png';
import ReactPlayer from 'react-player';
import remsVideo from '../../../assets/frontend/videos/sample.mp4';
import { useTranslation } from "react-i18next";
import { useLoginCallHook, useResetPasswordHook } from "../api_hooks/api_hooks";
import { ThreeDots } from 'react-loader-spinner';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from "react-toastify";
import { FrontendTokenExpiry } from "../authUser/frontendTokenExpiry";

const Landing = () => {
    const { t } = useTranslation();
    let langId = localStorage.getItem('lanId')
    const navigate = useNavigate();
    const [playing, setPlaying] = useState(false);
    const [playingImg, setPlayingImg] = useState(true);
    const playerRef = useRef(null);
    const usernameRef = useRef();
    const passwordRef = useRef();
    const userLabelRef = useRef();
    const passLabelRef = useRef();
    const handlePlayPause = () => {
        setPlaying(!playing);
        setPlayingImg(!playingImg);
    };
    const [loginValues, setLoginvalues] = useState({
        userName: '',
        password: ''
    });
    const [loginInputError, setLoginInputError] = useState({});
    const [passwordType, setPasswordType] = useState(true);
    const [ToggleLoad, setToggleLoad] = useState(false);
    const { mutate: loginCall, isLoading: isLoadingUser, } = useLoginCallHook();
    const LoginInputFunction = (e) => {
        const { name, value } = e.target;
        if(name == "userName"){
            if(value.length>0){
                usernameRef.current.style.border = "1px solid #138C50"
                userLabelRef.current.style.color="#138C50"
            }
            else{
                usernameRef.current.style.border = "1px solid red"
                userLabelRef.current.style.color="red"
            }
            
        }
        if(name == "password"){
            if(value.length>0){
                passwordRef.current.style.border = "1px solid #138C50"
                passLabelRef.current.style.color="#138C50"
            }
            else{
                passwordRef.current.style.border = "1px solid red"
                passLabelRef.current.style.color="red"
            }
            
        }
        setLoginvalues({ ...loginValues, [name]: value })
    }
    const PasswordShow = () => {
        setPasswordType(!passwordType);
    }
    const toggleLoadMore = () => {
        setToggleLoad(!ToggleLoad)
    }
    const loginSubmit = (e) => {
        e.preventDefault();
        let ErrorValues = LoginValidation(loginValues)
        setLoginInputError(ErrorValues);
        if (Object.keys(ErrorValues).length === 0) {
            let logindata = {
                username: loginValues.userName,
                password: btoa(unescape(encodeURIComponent(loginValues.password)))
            }
            loginCall(logindata);
        }
    }

    const LoginValidation = (values) => {
        let error = {}
        const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        //const regex_phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
        let phnumber = parseInt(values.userName)
        if (values.userName === '') {
            toast.error(t('landingpage.emailEmpty'));
            error.username = "Please enter email id or phone number."
            usernameRef.current.style.border = "1px solid red"
        }
        else if (!regex_email.test(values.userName) && !phnumber) {
            toast.error(t("landingpage.validEmail"));
            error.username = "Please enter valid email id or phone number."
        }
        if (!values.password) {
            toast.error(t("landingpage.passwordEmpty"));
            error.password = "Please enter password"
            passwordRef.current.style.border = "1px solid red"
        }
        return error;
    }

    const handleForgotPassword = (e) => {
        e.preventDefault();
        navigate(`/forgot-password`)
    }
    const handlescroll =()=>{
        let targetVideo = document.querySelector('.myplayer')
        const observer = new IntersectionObserver((entries)=>{
             setPlaying(false);
             setPlayingImg(true)
        },{
            threshold:0.8,
        })
        observer.observe(targetVideo)
    } 
    useEffect(() => {
        handlescroll();
        if (!FrontendTokenExpiry(localStorage.getItem("expiry"))) {
            navigate('/dashboard')
        }
        else {
            navigate('/')
        }
    }, [])
    return (
        <>
            <div className='mainWrapper'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1
                                className={landingStyle.Bigtext}
                                style={{
                                    fontSize: langId == 1 && window.innerWidth > 768 ? "80px" : langId == 1 && window.innerWidth < 768 ? "56px" :
                                        langId == 2 && window.innerWidth > 768 ? "56px" : "40px",
                                    paddingBottom: langId == 1 ? "0px" : "20px"
                                }}
                            >{t("landingpage.LogoText")}</h1>
                            {/* <h1 className={landingStyle.titleText}></h1> */}
                        </div>
                        <div className={landingStyle.videoWrapper}>
                        <div className='player-wrapper'>
                                <ReactPlayer
                                    url="https://www.youtube.com/embed/zau0fgjTy7U?si=aO-ztAVBUCq80kJQ"
                                    controls={true}
                                    ref={playerRef}
                                    playing={playing}
                                    width='100%'
                                    height='100%'
                                    className='react-player'
                                    loop={true}
                                />
                                {playingImg == true&&<button 
                                   className={landingStyle.play} 
                                   onClick={handlePlayPause}
                                   style={{
                                       backgroundColor:playingImg == true?"rgb(0 0 0 / 30%)":"rgb(0 0 0 / 0%)"
                                   }}
                                >
                                    {playingImg && <><img src={playIcon} alt="REMS" /></>}
                                    {playingImg == true ? <span>Watch video</span> : null}
                                </button>}
                            </div>
                        </div>
                    </div>
                    <div className="row my-lg-5 mob-30">
                        <div className="col-md-5 col-lg-4 pe-lg-5 mob-30 aboutSection">
                            <h4>{t("landingpage.About")}</h4>
                            <div className="card" style={{ border: "1px solid #A6A6A6", borderRadius: "11px" }}>
                                <div className="card-body">
                                    <img className="img-fluid card-img" src={aboutImg} alt="REMS" />
                                    <p className="my-2 descriptionFont">{t("landingpage.AboutText")}</p>
                                    <a href="/about" className="btn_primary my-2 mt-lg-3">{langId === "1" ? "Learn more" : "ပိုမိုသိရှိရန်"}</a>
                                </div>
                            </div>
                        </div>
                        <div className="myplayer col-md-7 col-lg-8 ps-lg-5">
                            <h4>{t("landingpage.FrequentlyAskedQuestions")}</h4>
                            <div className="accordion" id="accordionFAQ" style={{ border: "1px solid #A6A6A6", borderRadius: "8px" }}>
                                <div className="accordion-item accordionLanding">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faqOne" aria-expanded="true">{t("faqContent.faqContent_1")}
                                    </button>
                                    <div id="faqOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>{t("faqContent.faqContent_1_1")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item accordionLanding">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faqTwo" aria-expanded="false" >{t("faqContent.faqContent_2")}
                                    </button>
                                    <div id="faqTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>{t("faqContent.faqContent_2_1")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item accordionLanding">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faqThree" aria-expanded="false" >{t("faqContent.faqContent_3")}
                                    </button>
                                    <div id="faqThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>{t("faqContent.faqContent_3_1")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item accordionLanding">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faqFour" aria-expanded="false" >{t("faqContent.faqContent_4")}
                                    </button>
                                    <div id="faqFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>{t("faqContent.faqContent_4_1")}</p>
                                        </div>
                                    </div>
                                </div>
                                {ToggleLoad === true && <>
                                    <div className="accordion-item accordionLanding">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#faq_5" aria-expanded="false" >{t("faqContent.faqContent_5")}
                                        </button>
                                        <div id="faq_5" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>{t("faqContent.faqContent_5_1")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordionLanding">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#faq_6" aria-expanded="false" >{t("faqContent.faqContent_6")}
                                        </button>
                                        <div id="faq_6" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>{t("faqContent.faqContent_6_1")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordionLanding">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#faq_7" aria-expanded="false" >{t("faqContent.faqContent_7")}
                                        </button>
                                        <div id="faq_7" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>{t("faqContent.faqContent_7_1")}</p>
                                                <ul>
                                                    <li style={{ listStyle: "disc" }}><p style={{ marginBottom: "6px" }}>{t("faqContent.faqContent_7_1_li_1")}</p></li>
                                                    <li style={{ listStyle: "disc" }}><p style={{ marginBottom: "6px" }}>{t("faqContent.faqContent_7_1_li_2")}</p></li>
                                                    <li style={{ listStyle: "disc" }}><p style={{ marginBottom: "6px" }}>{t("faqContent.faqContent_7_1_li_3")}</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordionLanding">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#faq_8" aria-expanded="false" >{t("faqContent.faqContent_8")}
                                        </button>
                                        <div id="faq_8" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p className="pt-3">{t("faqContent.faqContent_8_1_p1")}</p>
                                                <p className="pt-3">{t("faqContent.faqContent_8_1_p2")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordionLanding">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#faq_9" aria-expanded="false" >{t("faqContent.faqContent_9")}
                                        </button>
                                        <div id="faq_9" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>{t("faqContent.faqContent_9_1")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordionLanding">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#faq_10" aria-expanded="false" >{t("faqContent.faqContent_10")}
                                        </button>
                                        <div id="faq_10" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>{t("faqContent.faqContent_10_1")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div style={{textAlign:window.innerWidth<767?"right":"center"}}>
                                <Link>
                                    <button
                                        type="button"
                                        className={landingStyle.btn_loadMore}
                                        onClick={() => toggleLoadMore()}
                                        style={{
                                            fontSize:window.innerWidth<767?"13px":"15px",
                                            marginTop:window.innerWidth<767?"4px":"15px"
                                          
                                        }}

                                    >{ToggleLoad === true ? t("landingpage.LoadLess") : t("landingpage.LoadMore")}
                                        <img
                                            src={loadMore}
                                            alt="Ref-Track"
                                            style={{
                                                transform: ToggleLoad === true ? "rotate(180deg)" : "rotate(0deg)",
                                                marginLeft:window.innerWidth<767?"6px":"10px",
                                            }}
                                        />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="card" style={{ border: "1px solid #A6A6A6", borderRadius: "11px" }}>
                                <div className="card-body text-center">
                                    <h6 className="queries-text mt-3">{t("landingpage.Queries")}</h6>
                                    <a href="/contact" className="btn_primary my-3">{t("landingpage.ContactUsButton")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={landingStyle.loginWrapper} id="loginSection">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-lg-4">
                            <h4 className={landingStyle.hSize} style={{fontSize:langId === '1'?"24px":"22px"}}>{t("landingpage.Login")}</h4>
                            <p className="mb-4 secondSubLog">{t("landingpage.LoginText")}</p>
                            <form autoComplete="on">
                                <div className="floting-form">
                                    <input
                                        type="text"
                                        name="userName"
                                        className="form-input"
                                        placeholder={t("landingpage.LoginEmailInput")}
                                        value={loginValues.userName}
                                        onChange={(e) => LoginInputFunction(e)}
                                        ref={usernameRef}
                                        autoComplete="on"
                                    />
                                    <label 
                                       htmlFor="email" 
                                       className="form-label"
                                       ref={userLabelRef}
                                    >{t("landingpage.LoginEmailInput")}</label>
                                </div>
                                {/* <div className="text-danger errorText">{loginInputError.username}</div> */}
                                <div className="floting-form mb-1">
                                    <input
                                        type={passwordType === true ? "password" : "text"}
                                        name="password"
                                        className="form-input"
                                        placeholder={t("landingpage.PasswordInput")}
                                        value={loginValues.password}
                                        onChange={(e) => LoginInputFunction(e)}
                                        style={{
                                            paddingRight: "3rem"
                                        }}
                                        ref={passwordRef}
                                        autoComplete="on"
                                    />
                                    <span className="eyePos">
                                        {passwordType === true ? <VisibilityOffIcon onClick={PasswordShow} /> :
                                            <RemoveRedEyeIcon onClick={PasswordShow} />}
                                    </span>
                                    <label 
                                      htmlFor="password" 
                                      className="form-label"
                                      ref={passLabelRef}
                                    >{t("landingpage.PasswordInput")}</label>

                                </div>
                                {/* <div className="text-danger errorText">{loginInputError.password}</div> */}
                                <div className={landingStyle.forgotpsw}>
                                    <Link
                                        className="resetbutton"
                                        onClick={handleForgotPassword}
                                    >{t("landingpage.ForgotPasswordText")}</Link>
                                </div>
                                <div className="d-grid">
                                    <button
                                        className="btn btn-primary w-100"
                                        onClick={loginSubmit}
                                    >{t("landingpage.LoginButton")}
                                    </button>
                                    <span style={{ marginTop: "8px" }}>{isLoadingUser && <ThreeDots
                                        height="14"
                                        width="60"
                                        radius="6"
                                        color="#24B270"
                                        ariaLabel="three-dots-loading"
                                        visible={true}
                                    />}</span>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={landingStyle.loginWith}> </div>
                                        <div style={{ fontWeight: "500" }}>{t("landingpage.ORText")}</div>
                                        <div className={landingStyle.loginWith}></div>
                                    </div>

                                    <Link to="/createnewaccount">
                                        <button className="btn btn-light w-100" type="button">{t("landingpage.CreateANewAccountButton")}</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 text-center">
                            <ul className="footerLinks">
                                <li><Link to="/help">{t("landingpage.HelpText")}</Link></li>
                                <li><Link to="/privacy">{t("landingpage.PrivacyText")}</Link></li>
                                <li><Link to="/terms">{t("landingpage.TermsText")}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Landing;