export const ReplaceMonthProduct = (AllData,params,productData)=>{
        let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
        let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
        filterMonth[0].monthly_json[0].productJson = productData;
        let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
        filterexceptedYear.push(filterYear[0])
       return filterexceptedYear
}
export const ReplaceMonthMaterial = (AllData,params,MaterialJsonData)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].materialJson = MaterialJsonData;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceProductActive = (AllData,params,active)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].product_active = active;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceRawMaterialActive = (AllData,params,active)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].material_active = active;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceEnergyActive =(AllData,params,active)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].resource_active = active;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthBoiler = (AllData,params,boilerJsonData)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].boilerJson = boilerJsonData;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthGridEnrgy = (AllData,params,value)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].grid_electricity = value;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthElectricityRenew = (AllData,params,value)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].electricity_from_renewables = value;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthDieselDGset = (AllData,params,value)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].diesel_from_DG_set = value;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceWasteActive =(AllData,params,active)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].waste_active = active;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthWasteGenerated = (AllData,params,wastegeneratedData)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].wasteJson = wastegeneratedData;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthlyComment = (AllData,params,value)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].comment = value;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceWasteCompleted =(AllData,params,active)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].waste_active = active;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthNetRevenue = (AllData,params,value)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].net_revenue_from_waste_generation = value;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceMonthTotalwaste = (AllData,params,value)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].total_disposal_cost = value;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}
export const ReplaceStartStatus = (AllData,params,status)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].monthly_data_added_status = status;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}

export const ReplaceProductMonthlyStatus = (AllData,params,status)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].product_monthly_status = status;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}

export const ReplaceMaterialMonthlyStatus = (AllData,params,status)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].material_monthly_status = status;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}

export const ReplaceResourceMonthlyStatus = (AllData,params,status)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].resource_monthly_status = status;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}

export const ReplaceWasteMonthlyStatus = (AllData,params,status)=>{
    let filterYear = AllData.length>0&&AllData.filter((item)=>item.year == params.year);
    let filterMonth = filterYear[0].monthlyentry.filter((itemMonth)=>itemMonth.month ==params.month )
    filterMonth[0].monthly_json[0].waste_monthly_status = status;
    let filterexceptedYear = AllData.length>0&&AllData.filter((item)=>item.year != params.year);
    filterexceptedYear.push(filterYear[0])
   return filterexceptedYear
}