import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// Import your font files
import InterBlack from './assets/fonts/Inter-Black.ttf';
import InterBold from './assets/fonts/Inter-Bold.ttf';
import InterExtraBold from './assets/fonts/Inter-ExtraBold.ttf';
import InterLight from './assets/fonts/Inter-Light.ttf';
import InterRegular from './assets/fonts/Inter-Regular.ttf';
import InterSemiBold from './assets/fonts/Inter-SemiBold.ttf';
import InterThin from './assets/fonts/Inter-Thin.ttf';

// Create your custom theme
const theme = createTheme({
	typography: {
		fontFamily: 'Inter', // Use the font family name for the imported fonts
	},
	palette: {
		primary: {
			main: '#138C50', // Specify your primary color
		},
		secondary: {
			main: '#F2F2F2', // Specify your secondary color
		},
		tertiary: {
			main: '#383838',
		},
		text: {
			primary: '#383838', // Default text color
			secondary: '#A6A6A6', // Secondary text color
		},
		error: {
			main: '#F65B3C',
		},
		warning: {
			main: '#383838',
		},
		background: {
			default: '#0000008A',
		},
		success: {
			main: '#138C50', // Success color
		},
		action: {
			active: '#138C50', // Active color for interactive elements
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'@global': {
					'@font-face': [
						{
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 400,
							src: `url(${InterRegular}) format('truetype')`,
						},
						{
							fontFamily: 'Inter',
							fontStyle: 'bold',
							fontWeight: 700,
							src: `url(${InterBold}) format('truetype')`,
						},
						{
							fontFamily: 'Inter',
							fontStyle: 'italic',
							fontWeight: 700,
							src: `url(${InterBold}) format('truetype')`,
						},
						// Add more font face declarations for other font weights and styles
					],
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				notchedOutline: {
					borderColor: 'gray',
				},
				root: {
					[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: 'yellow',
					},
					[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: 'yellow',
					},
				},
			},
		},
	},
});

export default theme;
