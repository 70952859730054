import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import excel from '../../../assets/frontend/images/excel-box-green.png';
import pdf from '../../../assets/frontend/images/pdf-box-green.png';
import download from '../../../assets/frontend/images/download_icon.png';
import closeIcon from '../../../assets/frontend/images/close.png';
import timersand from '../../../assets/frontend/images/timer_sand.png';
import toolTip from '../../../assets/frontend/images/tooltip_black.png';
import backIcon from '../../../assets/frontend/images/back_icon.png';
import backIconRed from '../../../assets/frontend/images/arrow-left-red.png';
import tooltipGreen from '../../../assets/frontend/images/tooltip-green.png';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CustomizeOutputModal from './CustomizeOutputModal';
import OutputLineChart from '../../admin/components/OutputLineChart';
import Pdf from '../pdf/Pdf';
import { Backdrop, useMediaQuery } from '@mui/material';
import { useGetUserHook } from '../api_hooks/api_hooks';
import Loader from '../../admin/components/Loader';
import { useTranslation } from 'react-i18next';
import GenerateExcel from './GenerateExcel';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import inputLogDisabled from '../../../assets/frontend/images/inputLogDisabled.jpg';
import reportBoxGray from '../../../assets/frontend/images/Rectangle_deactivated_state.png';
import pdfBoxGray from '../../../assets/frontend/images/Icon_PDF_GREY.png';
import excelBoxGray from '../../../assets/frontend/images/Icon_excel_GREY.png';

const Output = () => {
	let langId = localStorage.getItem('lanId');
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width: 600px)');
	const [showModal2, setShowModal2] = useState(false);
	const handleClose2 = () => setShowModal2(false);
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [oldOutput, setOldOutput] = useState({});
	const [generateImage, setGenerateImage] = useState(false);
	const [currentImageLabel, setCurrentImageLabel] = useState(
		'specific_waste_generation'
	);
	const [currentTitle, setCurrentTitle] = useState('Specific Waste Generation');
	const [generateOutput, setGenerateOutput] = useState(false);
	const [generateExcel, setGenerateExcel] = useState(false);
	const [selectedGraphs, setSelectedGraphs] = useState({
		'Specific Waste Generation': true,
		'Net Revenue from Waste': true,
		'Specific Energy Consumption': true,
		'Specific Material Consumption': true,
		'Full Report(.pdf)': true,
		'Input Log(.xls)': true,
		'I want separate timelines for each output.': false,
	});
	const [loader, setLoader] = useState(false);
	const [tooltipDisplay, setTooltipDisplay] = useState(false);
	const [backIconState, setBackIconState] = useState(false);
	const {
		mutate: getUser,
		status,
		data: userData,
		isLoading: profileLoading,
	} = useGetUserHook();

	useEffect(() => {
		if (localStorage.getItem('pdfOutput')) {
			setOldOutput(JSON.parse(localStorage.getItem('pdfOutput')));
		}
		if (!localStorage.getItem('outputTimeline')) {
			localStorage.setItem('outputTimeline', {});
		}
		getUser({});

		if (!localStorage.getItem('selectedGraphs')) {
			const obj = {
				'Specific Waste Generation': true,
				'Net Revenue from Waste': true,
				'Specific Energy Consumption': true,
				'Specific Material Consumption': true,
				'Full Report(.pdf)': true,
				'Input Log(.xls)': true,
				'I want separate timelines for each output.': false,
			};
			localStorage.setItem('selectedGraphs', JSON.stringify(obj));
		}
	}, []);

	const handleClickOpen = () => {
		setTooltipDisplay(true);
		setOpen(true);
	};

	const handleClosed = () => {
		setTooltipDisplay(false);
		setOpen(false);
	};

	const handleGenerateImage = (label, title) => {
		setGenerateImage(true);
		setCurrentImageLabel(label);
		setCurrentTitle(title);
	};

	const handleGeneratePdf = () => {
		setGenerateOutput(true);
	};

	const handleMouseLeave = () => {
		if (!open) {
			setTooltipDisplay(false);
		}
	};

	return (
		<div
			className={'mainWrapper' + ' ' + style.output}
			style={{ padding: isMobile ? '90px 6px' : '110px 130px' }}
		>
			{generateOutput ? (
				<Backdrop
					sx={{
						color: '#fff',
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={generateOutput}
					// onClick={() => setGenerateOutput(false)}
				>
					<Loader />
				</Backdrop>
			) : (
				<div className="container">
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							position: 'relative',
						}}
					>
						<img
							src={backIconState ? backIconRed : backIcon}
							width={isMobile ? '15px' : '20px'}
							style={{
								marginLeft: '-7%',
								position: 'absolute',
								left: isMobile ? 20 : -30,
								top: isMobile ? 9 : 6,
								cursor: 'pointer',
							}}
							onClick={() => navigate(-1)}
							className="back"
							onMouseEnter={() => setBackIconState(true)}
							onMouseLeave={() => setBackIconState(false)}
						/>

						<h2
							style={{
								fontWeight: '700',
								fontSize: isMobile ? '20px' : '30px',
								marginLeft: isMobile ? '31px' : '0px',
								lineHeight: langId == 1 ? 'auto' : 2,
							}}
						>
							{t('output.Output')}
						</h2>

						{/* <Button
							color="primary"
							onClick={handleClickOpen}
							onMouseEnter={() => setTooltipDisplay(true)}
							onMouseLeave={handleMouseLeave}
							style={{
								backgroundColor: tooltipDisplay ? 'white' : 'transparent',
								width: '50px',
								height: '50px',
								borderRadius: tooltipDisplay ? '50%' : 'none',
								border: '1px solid gray',
								zIndex: 1000,
								position: 'relative',
							}}
						>
							{tooltipDisplay ? (
								<img
									src={tooltipGreen}
									alt="output tooltip"
									width={isMobile ? '20px' : '25px'}
									style={{
										marginBottom: isMobile ? '7px' : '0px',
										marginLeft: isMobile ? '35px' : '0px',
										zIndex: 1000,
										position: 'relative',
									}}
								/>
							) : (
								<img
									src={toolTip}
									alt="output tooltip"
									width={isMobile ? '20px' : '25px'}
									style={{
										marginBottom: isMobile ? '7px' : '0px',
										marginLeft: isMobile ? '35px' : '0px',
									}}
								/>
							)}
						</Button> */}

						<div
							className={'tooltipwrapper ' + style.tooltipRight}
							style={{ zIndex: open === true ? '100' : '0' }}
						>
							<button
								onClick={() => setOpen(!open)}
								className="toolTip tooltipDashBoard"
							>
								<HelpCenterIcon
									style={{
										color: open === true ? '#138C50' : '#383838',
										marginBottom: isMobile ? '7.5px' : '1px',
									}}
								/>
							</button>
							{open && (
								<div
									className="backShadowSetup"
									onClick={() => setOpen(false)}
								></div>
							)}
							{open && (
								<div
									className="tooltipShow"
									style={{
										maxHeight: '413px',
										lineHeight: langId === '1' ? '20px' : 'unset',
									}}
								>
									<h4
										style={{
											color: 'black',
											fontSize: '20px',
											lineHeight: langId == 1 ? 'auto' : 2,
										}}
									>
										{t('output.TOOLTIPS')}
									</h4>
									<p
										style={{
											color: '#383838',
											fontFamily: 'Inter',
											lineHeight: langId == 1 ? 'auto' : 2,
										}}
									>
										<img
											src={backIcon}
											width="10px"
											style={{ transform: 'rotate(180deg)' }}
										/>{' '}
										{t('output.ClickOn')} <b>{t('output.GenerateOutput')}</b>{' '}
										{t('output.button')}.
									</p>
									<p style={{ lineHeight: langId == 1 ? 'auto' : 2 }}>
										<img
											src={backIcon}
											width="10px"
											style={{ transform: 'rotate(180deg)' }}
										/>{' '}
										{t('output.CustomizeUsingPopup')}{' '}
										<b>{t('output.GenerateMyOutput')}</b> {t('output.button')}.{' '}
									</p>
									<p style={{ lineHeight: langId == 1 ? 'auto' : 2 }}>
										<img
											src={backIcon}
											width="10px"
											style={{ transform: 'rotate(180deg)' }}
										/>{' '}
										{t('output.OnceThisIs')}
									</p>
									<p
										style={{
											marginTop: '15px',
											marginLeft: '2px',
											color: 'gray',
										}}
									>
										__
									</p>
									{/* <p>
									<b>{t('output.NOTE')} </b>
									{t('output.GeneratingNewOutputs')}.
								</p> */}
								</div>
							)}
						</div>
						{/* <Dialog
							open={open}
							onClose={handleClosed}
							maxWidth="xs"
							PaperProps={{
								style: {
									position: 'absolute',
									top: '19%',
									right: isMobile ? '8%' : '11%',
									// left: isMobile ? '0.001%' : '1%',
									width: '300px',
									paddingTop: isMobile ? '10px' : 'inherit',
									paddingRight: isMobile ? '5px' : 'inherit',
									zIndex: 100,
								},
							}}
						>
							<DialogContent> */}
						{/* Your dialog content goes here */}
						{/* <h4 style={{ color: 'black' }}>{t('output.TOOLTIPS')}</h4>
								<p>
									<img
										src={backIcon}
										width="10px"
										style={{ transform: 'rotate(180deg)' }}
									/>{' '}
									{t('output.ClickOn')} <b>{t('output.GenerateOutput')}</b>{' '}
									{t('output.button')}
								</p>
								<p>
									<img
										src={backIcon}
										width="10px"
										style={{ transform: 'rotate(180deg)' }}
									/>{' '}
									{t('output.CustomizeUsingPopup')}{' '}
									<b>{t('output.GenerateMyOutput')}</b> {t('output.button')}.{' '}
								</p>
								<p>
									<img
										src={backIcon}
										width="10px"
										style={{ transform: 'rotate(180deg)' }}
									/>{' '}
									{t('output.OnceThisIs')}
								</p> */}
						{/* <p>
									<b>{t('output.NOTE')} </b>
									{t('output.GeneratingNewOutputs')}.
								</p> */}
						{/* </DialogContent>
						</Dialog> */}
					</div>
					{!isMobile && (
						<div
							style={{
								width: '100%',
								borderRadius: '10px',
								height: '3px',
								backgroundColor: '#F2F2F2',
								marginBottom: '20px',
							}}
						></div>
					)}
					<p
						style={{
							width: isMobile ? '100%' : '52%',
							lineHeight: 1.5,
							marginBottom: '18px',
							lineHeight: langId == 1 ? 'auto' : 2,
						}}
					>
						{t('output.GenerateOutputtogatherinsight')}
					</p>

					<div className="row mb-4">
						<div className="col-md-5 col-lg-4 text-center">
							<CustomizeOutputModal
								setOldOutput={setOldOutput}
								setSelectedGraphs={setSelectedGraphs}
							/>
							{!(Object.keys(oldOutput).length > 0) ? (
								<p
									className="opacity-50 mt-1 text-center"
									style={{
										marginLeft: isMobile ? '1px' : '50px',
										lineHeight: langId == 1 ? 'auto' : 2,
									}}
								>
									{t('output.GenerateYourVeryFirstOutput')}!
								</p>
							) : (
								<p
									className="opacity-50 mt-1  text-center"
									style={{
										fontSize: isMobile ? '12px' : '10px',
										marginLeft: isMobile ? '0px' : '42px',
										// lineHeight: langId == 1 ? 'auto' : 2,
									}}
								>
									{t('output.LastOutput')} &nbsp;
									{localStorage.getItem('lastOutputDate')}&nbsp;
									{t('output.at')}&nbsp;
									{localStorage.getItem('lastOutputTime')}
								</p>
							)}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-md-12 mb-2">
							<h5
								style={{
									lineHeight: langId == 1 ? 'auto' : 2,
								}}
							>
								{t('output.DownloadIndividualGraphs')}
							</h5>
						</div>
						<div className="col-6 col-md-3">
							{Object.keys(oldOutput).length > 0 &&
							oldOutput?.specific_waste_generation !== null &&
							selectedGraphs['Specific Waste Generation'] ? (
								<div
									className={style.download_card + ' ' + style.downlodFile}
									onClick={() =>
										handleGenerateImage(
											'specific_waste_generation',
											'Specific Waste Generation'
										)
									}
								>
									<h6 style={{ fontSize: '12px' }}>
										{t('output.SpecificWaste')}
									</h6>
									<div className={style.graph}></div>
								</div>
							) : (
								<div className={style.download_card}>
									<h6 style={{ fontSize: '12px' }}>
										{t('output.SpecificWaste')}
									</h6>

									<p className={style.notfound}>{t('output.NotGenerated')}.</p>
								</div>
							)}
						</div>
						<div className="col-6 col-md-3">
							{Object.keys(oldOutput).length > 0 &&
							oldOutput?.specific_revenue_generation !== null &&
							selectedGraphs['Net Revenue from Waste'] ? (
								<div
									className={style.download_card + ' ' + style.downlodFile}
									onClick={() =>
										handleGenerateImage(
											'specific_revenue_generation',
											'Net Revenue from Waste'
										)
									}
								>
									<h6 style={{ fontSize: '12px' }}>{t('output.NetRevenue')}</h6>

									<div className={style.graph}></div>
								</div>
							) : (
								<div className={style.download_card}>
									<h6 style={{ fontSize: '12px' }}>{t('output.NetRevenue')}</h6>
									<p className={style.notfound}>{t('output.NotGenerated')}.</p>
								</div>
							)}
						</div>

						<div className="col-6 col-md-3">
							{Object.keys(oldOutput).length > 0 &&
							oldOutput?.specific_energy_generation !== null &&
							selectedGraphs['Specific Energy Consumption'] ? (
								<div
									className={style.download_card + ' ' + style.downlodFile}
									onClick={() =>
										handleGenerateImage(
											'specific_energy_generation',
											'Specific Energy Consumption'
										)
									}
								>
									<h6 style={{ fontSize: '12px' }}>
										{t('output.SpecificEnergy')}
									</h6>
									<div className={style.graph}></div>
								</div>
							) : (
								<div className={style.download_card}>
									<h6 style={{ fontSize: '12px' }}>
										{t('output.SpecificEnergy')}
									</h6>
									<p className={style.notfound}>{t('output.NotGenerated')}.</p>
								</div>
							)}
						</div>

						<div className="col-6 col-md-3">
							{Object.keys(oldOutput).length > 0 &&
							oldOutput?.specific_material_consumption !== null &&
							selectedGraphs['Specific Material Consumption'] ? (
								<div
									className={style.download_card + ' ' + style.downlodFile}
									onClick={() =>
										handleGenerateImage(
											'specific_material_consumption',
											'Specific Material Consumption'
										)
									}
								>
									<h6 style={{ fontSize: '12px' }}>
										{t('output.SpecificMaterial')}
									</h6>
									<div className={style.graph}></div>
								</div>
							) : (
								<div className={style.download_card}>
									<h6 style={{ fontSize: '12px' }}>
										{t('output.SpecificMaterial')}
									</h6>
									<p className={style.notfound}>{t('output.NotGenerated')}.</p>
								</div>
							)}
						</div>
					</div>

					<div className="row mb-4">
						<div className="col-md-12 mb-2">
							<h5 style={{ lineHeight: langId == 1 ? 'auto' : 2 }}>
								{t('output.DownloadReports')}
							</h5>
						</div>

						<div className="col-6 col-md-3">
							<div
								className={
									selectedGraphs['Input Log(.xls)'] &&
									localStorage.getItem('InputLogData')
										? style.download_reports
										: style.download_reports_inactive
								}
								style={{
									opacity:
										selectedGraphs['Input Log(.xls)'] &&
										localStorage.getItem('InputLogData')
											? 1
											: 0.3,
								}}
							>
								<div
									className={style.report_wraper}
									onClick={
										selectedGraphs['Input Log(.xls)'] &&
										localStorage.getItem('InputLogData')
											? () => setGenerateExcel(true)
											: () => {}
									}
								>
									<img
										className={style.download_icon}
										src={download}
										alt="Excel File"
									/>
									<h6 style={{ color: '#383838' }}>
										<img
											src={
												selectedGraphs['Input Log(.xls)'] &&
												localStorage.getItem('InputLogData')
													? excel
													: excelBoxGray
											}
											alt="Excel File"
										/>{' '}
										{t('output.InputLog')}
									</h6>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div
								className={
									Object.keys(oldOutput).length > 0 &&
									selectedGraphs['Full Report(.pdf)']
										? style.download_reports
										: style.download_reports_inactive
								}
								style={{
									opacity:
										Object.keys(oldOutput).length > 0 &&
										selectedGraphs['Full Report(.pdf)']
											? 1
											: 0.3,
								}}
							>
								<div
									className={style.report_wraper}
									onClick={
										Object.keys(oldOutput).length > 0 &&
										selectedGraphs['Full Report(.pdf)']
											? handleGeneratePdf
											: () => {}
									}
								>
									<img
										className={style.download_icon}
										src={download}
										alt="REMS"
									/>
									<h6 style={{ color: '#383838' }}>
										<img
											src={
												Object.keys(oldOutput).length > 0 &&
												selectedGraphs['Full Report(.pdf)']
													? pdf
													: pdfBoxGray
											}
											alt="REMS"
										/>{' '}
										{t('output.FullReport')}
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<Modal
				className="modalStyle text-center"
				show={showModal2}
				onHide={handleClose2}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header></Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12 mb-3">
							<img src={timersand} alt="REMS" className={style.timer_sand} />
							<h2
								className="mb-2"
								style={{ lineHeight: langId == 1 ? 'auto' : 2 }}
							>
								{t('output.PleaseWait')}
							</h2>
							<p style={{ lineHeight: langId == 1 ? 'auto' : 2 }}>
								{t('output.DependingOnNumber')}
							</p>
							<p style={{ lineHeight: langId == 1 ? 'auto' : 2 }}>
								<b>{t('output.PleaseDoNotNavigate')}</b>
							</p>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div
				id="offscreen-container"
				style={{
					marginTop: '500px',
					display: generateImage ? 'block' : 'none',
				}}
			>
				{generateImage && (
					<OutputLineChart
						title={currentTitle}
						labels={oldOutput?.[currentImageLabel]?.uniqueMonths}
						dataSetArray={oldOutput?.[currentImageLabel]?.dataset}
						generateImage={generateImage}
						setGenerateImage={setGenerateImage}
					/>
				)}
			</div>
			{generateOutput && (
				<div style={{ marginTop: '1000px' }}>
					<Pdf
						generateOutput={generateOutput}
						setGenerateOutput={setGenerateOutput}
						selectedGraphs={selectedGraphs}
					/>
				</div>
			)}
			{generateExcel && (
				<div style={{ marginTop: '50px' }}>
					<GenerateExcel setGenerateExcel={setGenerateExcel} />
				</div>
			)}
		</div>
	);
};
export default Output;
