import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const Energy = ({
    BoilerJsonData,
    boilerInputChange,
    otherEnergyInputChange,
    gridElectricity,
    ElectricityRenewables,
    dieselDGset,
    saveEnergy,
    completeEnerySection,
    EnergyIncomplete,
}) => {

    let langId = localStorage.getItem("lanId")
    const {t} = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="separator"></div>
                </div>
            </div>

            <div className={style.section + ' ' + style.sec_2b}>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3 style={{ opacity: BoilerJsonData.length > 0 ? "1" : "1" }}><span>2b</span>{t("monthlylogpage.EnergyInputHeaderText")}</h3>
                        <p style={{ opacity: BoilerJsonData.length > 0 ? "1" : "0.4" }}>{BoilerJsonData.length > 0 ?t("monthlylogpage.EnergyInputSubTextOne"):
                         t("monthlylogpage.doYouHaveEnergy")}</p>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-md-6 ">
                        {BoilerJsonData.length > 0 ? <div>
                            {BoilerJsonData.map((item, index) => <div className='row mb3' key={index}>
                                <div className='col-12'>
                                    <label>{item.boiler_fuel_name}</label>
                                </div>
                                <div className="col-md-8 mb-3">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            name="quantity"
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control monthlylogInput"
                                            placeholder={completeEnerySection === 1 && item.quantity === null || item.quantity === '' ? t("monthlylogpage.IncompleteField") : ''}
                                            value={item.quantity == null ? '' : item.quantity}
                                            onChange={(e) => boilerInputChange(e, index)}
                                            style={{
                                                border: item.quantity === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    item.quantity === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                        item.quantity === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                        item.quantity === '' && completeEnerySection === 0 ? "1px solid red" :
                                                            "1px solid #138C50"
                                            }}
                                        />
                                        <span
                                            className="input-group-text spanBorderinput"
                                            style={{
                                                border: item.quantity === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    item.quantity === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                        item.quantity === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                        item.quantity === '' && completeEnerySection === 0 ? "1px solid red" :
                                                            "1px solid #138C50",
                                                            padding:"8px 46px 8px 10px"
                                            }}
                                        >{item.unit_name}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3"></div>
                            </div>)}
                        </div> : null}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="separatorSM"></div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <p>{t("monthlylogpage.EnergyInputSubTextTwo")}</p>
                            </div>
                        </div>

                        <div className='row mb3'>
                            <div className='col-12'>
                                <label>{t("monthlylogpage.Gridelectricity")}</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name="grid_electricity"
                                        onWheel={(e) => e.target.blur()}
                                        className="form-control monthlylogInput"
                                        placeholder={completeEnerySection === 1 && gridElectricity === null || gridElectricity === '' ? t("monthlylogpage.IncompleteField") : ''}
                                        value={gridElectricity == null ? '' : gridElectricity}
                                        onChange={(e) => otherEnergyInputChange(e)}
                                        style={{
                                            border: gridElectricity === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                gridElectricity === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    gridElectricity === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                    gridElectricity === '' && completeEnerySection === 0 ? "1px solid red" :
                                                        "1px solid #138C50"
                                        }}
                                    />
                                    <span
                                        className="input-group-text spanBorderinput"
                                        style={{
                                            border: gridElectricity === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                gridElectricity === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    gridElectricity === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                    gridElectricity === '' && completeEnerySection === 0 ? "1px solid red" :
                                                        "1px solid #138C50",
                                                        padding:"8px 46px 8px 10px"
                                        }}
                                    >kWh</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3"></div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label>{t("monthlylogpage.Electricityfromrenewables")}</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name="electricity_from_renewables"
                                        onWheel={(e) => e.target.blur()}
                                        className="form-control monthlylogInput"
                                        placeholder={completeEnerySection === 1 && ElectricityRenewables === null || ElectricityRenewables === '' ? t("monthlylogpage.IncompleteField") : ''}
                                        value={ElectricityRenewables == null ? '' : ElectricityRenewables}
                                        onChange={(e) => otherEnergyInputChange(e)}
                                        style={{
                                            border: ElectricityRenewables === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                ElectricityRenewables === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    ElectricityRenewables === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                    ElectricityRenewables === '' && completeEnerySection === 0?"1px solid red":
                                                        "1px solid #138C50"
                                        }}
                                    />
                                    <span
                                        className="input-group-text spanBorderinput"
                                        style={{
                                            border: ElectricityRenewables === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                ElectricityRenewables === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    ElectricityRenewables === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                    ElectricityRenewables === '' && completeEnerySection === 0?"1px solid red":
                                                        "1px solid #138C50",
                                                        padding:"8px 46px 8px 10px"
                                        }}
                                    >kWh</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label>{t("monthlylogpage.DieselforDGset")}</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name="diesel_from_DG_set"
                                        onWheel={(e) => e.target.blur()}
                                        className="form-control monthlylogInput"
                                        placeholder={completeEnerySection === 1 && dieselDGset === null || dieselDGset === '' ? t("monthlylogpage.IncompleteField") : ''}
                                        value={dieselDGset == null ? '' : dieselDGset}
                                        onChange={(e) => otherEnergyInputChange(e)}
                                        style={{
                                            border: dieselDGset === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                dieselDGset === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    dieselDGset === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                    dieselDGset === '' && completeEnerySection === 0?"1px solid red":
                                                        "1px solid #138C50"
                                        }}
                                    />
                                    <span
                                        className="input-group-text spanBorderinput"
                                        style={{
                                            border: dieselDGset === null && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                dieselDGset === '' && completeEnerySection === 1 ? "1px solid #F65B3C" :
                                                    dieselDGset === null && completeEnerySection === 0 ? "1px solid #A6A6A6" :
                                                    dieselDGset === '' && completeEnerySection === 0?"1px solid red":
                                                        "1px solid #138C50",
                                                        padding:"8px 46px 8px 10px"
                                        }}
                                    >litre</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3 pe-5 pe-md-0 paddingButtonRight" 
                                style={{ 
                                    display: 'flex', 
                                    alignItems: window.innerWidth>768?'end':'center',
                                    justifyContent: window.innerWidth>768?"start":"end",
                                    position:"relative",
                                }}>
                                {completeEnerySection === 1 && EnergyIncomplete === 1 ? <p className='float-start d-block d-md-none form_msg'>{t("monthlylogpage.completedSection")}</p> : null
                                }
                                <button
                                    className={
                                        completeEnerySection == 0 ?
                                            "btn btn-primary mw-11 float-md-start float-end" : "btn btn-primary_disable mw-11 float-md-start float-end"}
                                    type="button"
                                    onClick={saveEnergy}
                                    style={{fontSize:langId == 1?"13px":"10px",padding:"6px 6px"}}
                                >{completeEnerySection == 0 ? t("monthlylogpage.EnergyInputSaveButton") : t("monthlylogpage.SaveButton")}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 d-none d-md-block position-relative'>
                        {completeEnerySection === 1 && EnergyIncomplete === 1 ? <p className='form_msg' style={{height:langId!=='1'?36:"auto",display:"flex",alignItems:"baseline"}}>{t("monthlylogpage.completedSection")}</p> : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Energy;