import React, { useEffect, useState } from 'react';
import style from './setup.module.css'
import { Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Products from '../setup/products';
import InputResources from '../setup/inputresources';
import WasteGenerated from '../setup/wastegenerated';
import { useALLSetUpDataHook } from '../api_hooks/api_hooks';
import { Oval } from 'react-loader-spinner';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useTranslation } from 'react-i18next';


const Setup = () => {
    const {t} = useTranslation();
    let langId = localStorage.getItem("lanId")
    const [isOpen, setIsOpen] = useState(false);
    const [TabKey,setTabKey] = useState(localStorage.getItem("activeSetupTab")===null?'products':localStorage.getItem("activeSetupTab"));
    const { isLoading: setUpLoading } = useALLSetUpDataHook();
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const setUpTabActiveFunction=(active)=>{
        let activeTab = active.target.dataset.rrUiEventKey
        localStorage.setItem('activeSetupTab',activeTab)
        setTabKey(activeTab);
    }
    return (
        <div className={'mainWrapper '+style.setup}>
            {setUpLoading === false ? <div className="container" style={{ position: window.innerWidth<768?"relative":"unset" }}>
                <div className="row">
                    <div className="col-8">
                        <h1 className='back'><Link to="/dashboard" className={style.arrowBackpos}><ArrowBackIcon className={style.ArrowBack}/></Link>{t("setuppage.SetupText")}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12" style={{ position: window.innerWidth>768?"relative":"unset" }}>
                        <Tabs defaultActiveKey={TabKey} className='setupTab' onClick={(key)=>setUpTabActiveFunction(key)}>
                            <Tab eventKey="products" title={t("setuppage.ProductsTabHeaderText")}>
                                <Products />
                            </Tab>
                            <Tab eventKey="ir" title={t("setuppage.InputResourcesTabHeadertext")}>
                                <InputResources />
                            </Tab>
                            <Tab eventKey="wg" title={t("setuppage.WasteGeneratedTabHeaderText")}>
                                <WasteGenerated />
                            </Tab>
                        </Tabs>
                        <div className='tooltipSetupRight d-md-block' style={{top:window.innerWidth<768?"0":"22px"}}>
                            <div className='tooltipwrapper' 
                              style={{zIndex:isOpen===true?"100":"0"}}
                            >
                                <button onClick={toggleDropdown} className={window.innerWidth>767?"toolTip tooltipDashBoard hoverSetup":"toolTip tooltipDashBoard"} >
                                   <HelpCenterIcon  style={{color:isOpen === true ?"#138C50":"#383838"}}/>
                                </button>
                                {isOpen && <div className="backShadowSetup" onClick={()=>setIsOpen(false)}></div>}
                                {isOpen && (
                                    <div>
                                    {TabKey==="products"&&<div className='tooltipShow' style={{lineHeight:langId === "1"?"20px":"unset"}}>
                                        {langId === '1' ?<><h5>TOOLTIPS</h5>
                                        <span style={{fontWeight:"600",color:"#138c50"}}>Products</span>
                                        <div className='fontSizeToltipSetupDesc'>
                                            <div style={{marginTop:"10px"}}><span><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/></span><span>Tap on <b>Add a product.</b></span></div>
                                            <p style={{marginTop:"10px"}}>
                                              <EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>Add a product through the popup that opens, type in your product name, set up unit conversions (if necessary) and click on <b>Save product</b> to add it to the monitoring list.
                                            </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>To remove an entry from the monitoring list, click on the (︙) icon on any card and then click on <b>REMOVE</b>. Click elsewhere on the screen to cancel this action. </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>This section is skippable.</p>
                                        </div>
                                        <p><span>NOTE:</span>You will be able to add/remove entries as long as Set up is not finished.</p>
                                        </>:<><h5>ကိရိယာ အသုံးပြုနည်းများ</h5>
                                        <span style={{fontWeight:"600",color:"#138c50"}}>ထုတ်ကုန်များ</span>
                                        <div className='fontSizeToltipSetupDesc'>
                                            <div style={{marginTop:"10px"}}><span><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/></span><span><b>"ထုတ်ကုန်ထည့်ရန်"</b> ကိုနှိပ်ပါ။</span></div>
                                            <p style={{marginTop:"10px"}}>
                                              <EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>ဖွင့်ထားသော ပေါ့ပ်အပ်မှတစ်ဆင့် ထုတ်ကုန်တစ်ခုကို ထည့်ပါ၊ သင့်ထုတ်ကုန်အမည်ကို ရိုက်ထည့်ပါ၊ လိုအပ်ပါက ယူနစ်ပြောင်းလဲခြင်းများကို စနစ်ထည့်သွင်းပါ။စောင့်ကြည့်စာရင်းတွင် ထည့်ရန် <b>"ထုတ်ကုန်ကို သိမ်းဆည်းရန်"</b> ကို နှိပ်ပါ။
                                            </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>စောင့်ကြည့်ရေးစာရင်းကို ဖယ်ရှားရန်ကဒ်ရှိ (︙) အိုင်ကွန်ကိုနှိပ်ပြီး <b>"ဖယ်ရှားရန်"</b> ကိုနှိပ်ပါ။ ဤလုပ်ဆောင်ချက်ကို ပယ်ဖျက်ရန် စခရင်ပေါ်ရှိ အခြားနေရာကို နှိပ်ပါ။</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>"ဤအပိုင်းကို ကျော်နိုင်သည်။"</p>
                                        </div>
                                        <p><span>မှတ်ချက်- </span>မှတ်ချက်- "Set up" "တည်ဆောက်ခြင်း" မပြီးသရွေ့ ထည့်သွင်း/ဖယ်ရှားနိုင်မည်ဖြစ်သည်။</p>
                                        </>}
                                    </div>}
                                    {TabKey==="ir"&&<div className='tooltipShow' style={{lineHeight:langId === "1"?"20px":"unset"}}>
                                    {langId === '1' ?<><h5>TOOLTIPS</h5>
                                        <span style={{fontWeight:"600",color:"#138c50"}}>Input resources</span>
                                        <div className='fontSizeToltipSetupDesc'>
                                            <div style={{marginTop:"10px"}}><span><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/></span><span>Tap on <b>Add a raw material.</b></span></div>
                                            <p style={{marginTop:"10px"}}>
                                              <EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>Add a raw material through the popup that opens, type in your raw material name, set up unit conversions (if necessary) and click on <b>Save raw material</b> to add it to the monitoring list. 
                                            </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>Tap on <b>Add a boiler fuel.</b> </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>For adding boiler fuels, choose your fuel from a drop-down menu on the popup that opens and click on <b>Save boiler fuel</b> to add it to the monitoring list. </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>Raw materials and Boiler fuels can be added in any order. </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>To remove an entry from the monitoring list, click on the (︙) icon on any card and then click on <b>REMOVE</b>. Click elsewhere on the screen to cancel this action. </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>This section is also skippable.</p>
                                        </div>
                                        <p><span>NOTE:</span>You will be able to add/remove entries as long as Set up is not finished.</p>
                                        </>:<><h5>ကိရိယာ အသုံးပြုနည်းများ</h5>
                                        <span style={{fontWeight:"600",color:"#138c50"}}>"အရင်းအမြစ်များကို ထည့်သွင်းပါ။"</span>
                                        <div className='fontSizeToltipSetupDesc'>
                                            <div style={{marginTop:"10px"}}><span><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/></span><span><b>"ကုန်ကြမ်းထည့်ရန်"</b> ကိုနှိပ်ပါ။</span></div>
                                            <p style={{marginTop:"10px"}}>
                                              <EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>ပေါ်လာသော ပေါ့ပ်အပ်မှတစ်ဆင့် ကုန်ကြမ်းတစ်ခုကို ထည့်ပါ၊ သင့်ကုန်ကြမ်းအမည်ကို ရိုက်ထည့်ပါ၊ ယူနစ်ကူးပြောင်းမှုများ (လိုအပ်ပါက) စနစ်ထည့်သွင်းပြီး စောင့်ကြည့်စာရင်းတွင် ထည့်ရန် <b>"ကုန်ကြမ်းသိမ်းဆည်းရန်"</b> ကို နှိပ်ပါ။
                                            </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/><b>"ဘွိုင်လာဆီထည့်ပါ"</b> ကိုနှိပ်ပါ။</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>ဘွိုင်လာလောင်စာများ ပေါင်းထည့်ရန်အတွက်၊ ပေါ်လာသည့်  ဇယားတစ်ခုမှ သင့်လောင်စာဆီအား ရွေးချယ်ပြီး စောင့်ကြည့်စာရင်းတွင် ထည့်ရန် <b>"ဘွိုင်လာလောင်စာ သိမ်းဆည်းရန်"</b> ကို နှိပ်ပါ။</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>ကုန်ကြမ်းများနှင့် ဘွိုင်လာလောင်စာများကို ကြိုက်သလိုထည့်နိုင်သည်။</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>စောင့်ကြည့်စရင်း၏ အဝင်‌နေရာ ကိုဖယ်ရှားရန်၊ ကတ်ပေါ်ရှိ (︙) အိုင်ကွန်ကိုနှိပ်ပြီး <b>"ဖယ်ရှားရန်"</b> ကိုနှိပ်ပါ။ ဤလုပ်ဆောင်ချက်ကို ပယ်ဖျက်ရန် စခရင်ပေါ်ရှိ အခြားနေရာကို နှိပ်ပါ။</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>ဤအပိုင်းကိုလည်း ကျော်နိုင်သည်။</p>
                                        </div>
                                        <p><span>မှတ်ချက်-</span><b>"Set up"</b> တည်ဆောက်ခြင်း အဆင့်မပြီးသရွေ့ ထည့်သွင်း/ဖယ်ရှားနိုင်မည်ဖြစ်သည်။</p>
                                        </>} 
                                    </div>}
                                    {TabKey==="wg"&&<div className='tooltipShow' style={{lineHeight:langId === "1"?"20px":"unset"}}>
                                    {langId === '1' ?<><h5>TOOLTIPS</h5>
                                        <span style={{fontWeight:"600",color:"#138c50"}}>Waste Generated</span>
                                        <div className='fontSizeToltipSetupDesc'>
                                            <div style={{marginTop:"10px"}}><span><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/></span><span>Tap on <b>Add a type of waste. </b></span></div>
                                            <p style={{marginTop:"10px"}}>
                                              <EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>Add a new type of waste through the popup that opens by choosing from the drop-down list given and click on <b>Save waste</b> to add it to the monitoring list.
                                            </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>To remove an entry from the monitoring list, click on the (︙) icon on any card and then click on <b>REMOVE</b>. Click elsewhere on the screen to cancel this action.</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>This section is mandatory for finishing the <b>Set up</b> stage.</p>
                                        </div>
                                        <p><span>NOTE:</span>You will be able to add/remove entries as long as Set up is not finished.</p>
                                        </>:<><h5>ကိရိယာ အသုံးပြုနည်းများ</h5>
                                        <span style={{fontWeight:"600",color:"#138c50"}}>စွန့်ပစ်ပစ္စည်းထုတ်လုပ်မှု</span>
                                        <div className='fontSizeToltipSetupDesc'>
                                            <div style={{marginTop:"10px"}}><span><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/></span><span><b>"အမှိုက်အမျိုးအစားထည့်ရန်"</b> ကိုနှိပ်ပါ။</span></div>
                                            <p style={{marginTop:"10px"}}>
                                              <EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>ပေးထားသည့် ကျလာသောစရင်း မှရွေးချယ်ပြီး <b>Save waste</b> အမှိုက်သိမ်းပါ  ကို နှိပ်ခြင်းဖြင့် ပွင့်လာသော ပေါ့ပ်အပ်မှတစ်ဆင့် အမှိုက်အမျိုးအစားအသစ်ကို ပေါင်းထည့်ကာ ၎င်းကို စောင့်ကြည့်စာရင်းတွင်ထည့်ရန် <b>Save waste</b> အမှိုက်သိမ်းပါ ကိုနှိပ်ပါ။
                                            </p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/>"စောင့်ကြည့်ရေးစာရင်းကို ဖယ်ရှားရန်ကဒ်ရှိ (︙) အိုင်ကွန်ကိုနှိပ်ပြီး <b>ဖယ်ရှားရန်</b> ကိုနှိပ်ပါ။ ဤလုပ်ဆောင်ချက်ကို ပယ်ဖျက်ရန် စခရင်ပေါ်ရှိ အခြားနေရာကို နှိပ်ပါ။"</p>
                                            <p><EastIcon style={{fontSize:"15px",marginRight:"3px"}}/><b>Set up</b> တည်ဆောက်ခြင်း အဆင့်ကို အပြီးသတ်ရန်အတွက် ဤအပိုင်း လိုအပ်ပါသည်။</p>
                                        </div>
                                        <p><span>မှတ်ချက် :</span>Set up တည်ဆောက်ခြင်း မပြီးသရွေ့ ထည့်သွင်း/ဖယ်ရှားနိုင်မည်ဖြစ်သည်။</p>
                                        </>}
                                    </div>}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Oval
                height={80}
                width={80}
                color="#4fa94d"
                wrapperStyle={{
                    justifyContent: "center"
                }}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />}
        </div>
    );
}
export default Setup;