export const outputHelperObj = {
	start_date_waste: 'Specific Waste Generation',
	end_date_waste: 'Specific Waste Generation',
	start_date_energy: 'Specific Energy Consumption',
	end_date_energy: 'Specific Energy Consumption',
	start_date_material: 'Specific Material Consumption',
	end_date_material: 'Specific Material Consumption',
	start_date_revenue: 'Net Revenue from Waste',
	end_date_revenue: 'Net Revenue from Waste',
	start_date_full_report: 'Full Report(.pdf)',
	'end_date_full_report ': 'Full Report(.pdf)',
	start_date_input_log: 'Input Log(.xls)',
	end_date_input_log: 'Input Log(.xls)',
	company_name: '',
};

export const months = {
	January: 0,
	February: 1,
	March: 2,
	April: 3,
	May: 4,
	June: 5,
	July: 6,
	August: 7,
	September: 8,
	October: 9,
	November: 10,
	December: 11,
};
