import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import style from './style.module.css';

const Faq = () => {
    const {t} = useTranslation();
    return (
        <div className="container py-4 ">
             <div><Link to="/"><ArrowBackIcon className={style.arrowBack} style={{color:"#000"}}/></Link></div>
            <ul>
                <li>
                    <b>{t("faqContent.faqContent_1")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_1_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_2")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_2_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_3")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_3_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_4")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_4_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_5")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_5_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_6")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_6_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_7")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_7_1")}</p>
                    <ul>
                        <li style={{listStyle:"disc"}}><p style={{marginBottom:"6px"}}>{t("faqContent.faqContent_7_1_li_1")}</p></li>
                        <li style={{listStyle:"disc"}}><p style={{marginBottom:"6px"}}>{t("faqContent.faqContent_7_1_li_2")}</p></li>
                        <li style={{listStyle:"disc"}}><p style={{marginBottom:"6px"}}>{t("faqContent.faqContent_7_1_li_3")}</p></li>
                    </ul>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_8")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_8_1_p1")}</p>
                    <p className="pt-3">{t("faqContent.faqContent_8_1_p2")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_9")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_9_1")}</p>
                </li>
                <li>
                    <b>{t("faqContent.faqContent_10")}</b>
                    <p className="pt-3">{t("faqContent.faqContent_10_1")}</p>
                </li>
            </ul>
        </div>
    )
}
export default Faq;