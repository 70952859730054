import React from 'react';
import aaStyles from '../activeAccount/activeAccount.module.css';
import { useSearchParams } from 'react-router-dom';
import activateMail from '../../../assets/frontend/images/activate_mail.png';
import { useNewUserEmailverificationRequest, useResetPasswordRequestHook } from '../api_hooks/api_hooks';
import { useTranslation } from 'react-i18next';

const ActiveNewAccount = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams()
    let typeEmail = searchParams.get('email')
    const { mutate: resendRequest } = useNewUserEmailverificationRequest();
    const resendFunction = () => {
        let email = {
            email: typeEmail
        }
        resendRequest(email)
    }

    return (
        <div className="mainWrapper">
            <div className={aaStyles.activateAccount} style={{ marginTop: window.innerWidth > 768 ? "70px" : "0px" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-5 col-xl-4 text-center">
                            <h4 style={{ fontSize: window.innerWidth > 768 ? "22px" : "20px" }}>{t("createNewAccount.HeaderText")}</h4>
                            <img src={activateMail} alt="REMS" />
                            <h5>{t("createNewAccount.ButtonText")} <span>{typeEmail}</span></h5>

                            <div className={aaStyles.separater}></div>
                            <p>{t("createNewAccount.InfoText")} <span>{t("createNewAccount.checkSpamFolder")}</span></p>
                            <p style={{ marginBottom: "0px" }}>{t("landingpage.ORText")}</p>
                            <button
                                className={aaStyles.reset}
                                onClick={resendFunction}
                            >{t("createNewAccount.ResendLinkText")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ActiveNewAccount;