import React from 'react';
import ImageNotfound from '../../../assets/frontend/images/404image.png';
import ImageBtn from '../../../assets/frontend/images/Go_Home.png';
import { useNavigate } from 'react-router-dom';
import Style from './notfound.module.css';
import Logo from '../../../assets/frontend/images/logo.png'
import { Link } from 'react-router-dom';
import { FrontendTokenExpiry } from '../authUser/frontendTokenExpiry';

const NotFound = () => {
const navigate = useNavigate();
function handleGoBack() {
	navigate('/');
}
return (
	<>
	<div className={Style.header}>
	<div className="container-fluid">
		<div className="row align-items-center">
			<div className="col-3">
				<Link to={!FrontendTokenExpiry(localStorage.getItem('expiry'))?"/dashboard":"/"}><img src={Logo} alt="Ref-track" className={Style.logo}/></Link>
			</div>
		</div>
		</div>
		</div>
		<div className="container" style={{textAlign:"center",height:"calc(100vh - 103px)",paddingTop:window.innerWidth<768?"86px":"150px"}}>
			<div style={{ paddingLeft: '20px' }}>
				<img src={ImageNotfound} alt="notfound"
					style={{
					width: "118px",
					marginBottom: "34px",
					objectFit: "contain"
					}}
				/>
				<div
					style={{ fontSize: '72px', color: '#383838',fontWeight:"600"}}
				>
					404
				</div>
				<div
					style={{
						fontSize: '20px',
						color: '#383838',
						marginTop: '60px',
						marginBottom: '30px',
					}}
				>
					<p style={{marginBottom:"0px",fontWeight: '600'}}>This page was not found</p>
					<p style={{marginBottom:"0px",fontWeight: '600'}}> ဤစာမျက်နှာကို ရှာမတွေ့ပါ။</p>
				</div>
				<button
					onClick={handleGoBack}
					style={{
						border: "none",
						marginTop: "40px",
						background: "#24B270",
						minWidth: "216px",
						padding: "6px 4px",
						borderRadius: "10px"
					}}
				>
					<img src={ImageBtn} alt="home" style={{width:"40px"}}/>
				</button>
			</div>
		</div>
		</>
);
};

export default NotFound;
