export const insights = [
	{
		name: 'Graph 1',
		title: 'Specific Waste Generation',
		text: `This indicator portrays how much waste is generated for producing each unit of the product. A decrease in waste generation indicates the company's successful efforts in implementing waste reduction measures and improving its sustainability practices, leading to more efficient resource utilization, and minimized environmental impact.`,
		page: 'Page 2',
	},
	{
		name: 'Graph 2',
		title: ' Net Revenue from Waste',
		text: `This indicator displays the company's net revenue generated through selling of waste to recyclers or to other enterprises to use as raw materials. An upward trend in the graph indicates the company's successful monetization of waste materials, contributing to both financial gains and environmental sustainability.`,
		page: 'Page 2',
	},
	{
		name: 'Graph 3',
		title: 'Specific Energy Consumption',
		text: `This indicator portrays the amount of energy used for producing each unit of the product. It is used to identify the potential improvements in energy efficiency. A consistent downward trend indicates the company's successful implementation of energy efficiency measures, resulting in reduced energy consumption and improved sustainability performance.`,
		page: 'Page 3',
	},
	{
		name: 'Graph 4',
		title: 'Specific Material Consumption',
		text: `This indicator depicts how much material is consumed for producing each unit of the product. A consistent downward trajectory indicates the successful implementation of resource optimization strategies, leading to reduced material usage and improved efficiency in the company's operations.`,
		page: 'Page 3',
	},

	{
		name: 'Pie Chart 1',
		title: 'Share of Waste being Recycled',
		text: `This indicator represents the proportion of waste being recycled by the enterprise. A higher percentage of waste being recycled reflects a positive shift towards sustainable waste management practices. This progress underscores the increasing awareness and implementation of recycling initiatives, promoting a circular economy, and alleviating the burden on natural resources.`,
		page: 'Page 4',
	},
	{
		name: 'Pie Chart 2',
		title: 'Share of Energy from Renewables',
		text: `This indicator illustrates the proportion of energy being derived from renewable sources. A higher percentage of share of energy from renewables signifies a notable transition towards cleaner and more sustainable energy production, which reduces dependence on fossil fuels and helps mitigate the carbon emissions of the enterprise.`,
		page: 'Page 4',
	},
];
