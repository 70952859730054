export const translationObj = {
	'အတိအကျ အမှိုက်ထုတ်လွှတ': 'Specific Waste Generation',
	'အမှိုက်ထွက်ရှိမှုမှ အသားတင် ဝင်ငွေ': 'Net Revenue from Waste',
	'အတိအကျ စွမ်းအင်သုံးစွဲမှု ': 'Specific Energy Consumption',
	'အတိအကျ ကုန်ကြမ်းသုံးစွဲမှု ': 'Specific Material Consumption',
	'တင်ပြချက်အပြည့်အစုံ (.pdf)': 'Full Report(.pdf)',
	'ထည့်သွင်းမှု မှတ်တမ်း ': 'Input Log(.xls)',
	'ထုတ်လုပ်မှုတစ်ခုစီအတွက် သီးခြားအချိန်ဇယားကို လိုချင်ပါသည်။':
		'I want separate timelines for each output.',
};
