// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 768px) {
	.css-1d3ddih-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
		padding: 7px 12px 7px 5px !important;
	}
	.css-10d36jd-MuiSvgIcon-root-MuiSelect-icon {
		right: -2px !important;
	}
	.css-ypiqx9-MuiDialogContent-root {
		padding: 0px 4px 20px 15px !important;
	}
	.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
		padding: 16px 15px !important;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/frontend/output/select.css"],"names":[],"mappings":"AAAA;CACC;EACC,oCAAoC;CACrC;CACA;EACC,sBAAsB;CACvB;CACA;EACC,qCAAqC;CACtC;CACA;EACC,6BAA6B;CAC9B;AACD","sourcesContent":["@media only screen and (max-width: 768px) {\r\n\t.css-1d3ddih-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {\r\n\t\tpadding: 7px 12px 7px 5px !important;\r\n\t}\r\n\t.css-10d36jd-MuiSvgIcon-root-MuiSelect-icon {\r\n\t\tright: -2px !important;\r\n\t}\r\n\t.css-ypiqx9-MuiDialogContent-root {\r\n\t\tpadding: 0px 4px 20px 15px !important;\r\n\t}\r\n\t.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {\r\n\t\tpadding: 16px 15px !important;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
