import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import backIcon from '../../../assets/frontend/images/back_icon.png';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { monthList, yearList } from '../utility/utility';
import {
	useMasterDataHook,
	useStartMonthlyEntryHook,
} from '../api_hooks/api_hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SetUpClockIcon from '../../../assets/frontend/images/Setmonitoringperiodicon.png';
import { useTranslation } from 'react-i18next';

const SetupMonitoring = () => {
	let langId = localStorage.getItem('lanId');
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const handleShow = () => setShowModal(true);
	let localData = JSON.parse(localStorage.getItem('masterData'));
	let AppMonthly = localData && localData.DASHBOARD_STATUS;
	let AppMonthlyStatus = JSON.parse(AppMonthly);
	const [period, setPeriod] = useState({
		startMonth: '',
		startYear: '',
	});
	const handleMonthChange = (e) => {
		const Month = e.target.value;
		setPeriod({ ...period, startMonth: Month });
	};
	const handleYearChange = (e) => {
		const year = e.target.value;
		setPeriod({ ...period, startYear: year });
	};
	const {
		mutate: startMonthly,
		isLoading: setMonthlyLoading,
		status,
		data,
	} = useStartMonthlyEntryHook();
	const setMonthlyEntry = (statusBtn) => {
		if (statusBtn === 'yes') {
			if (period.startMonth !== '' && period.startYear !== '') {
				let startData = {
					start_month: period.startMonth,
					start_year: period.startYear,
				};
				startMonthly(startData);
				if (setMonthlyLoading === false) {
					setShowModal(false);
				}
			} else {
				toast.error('mandatory field cannot be left blank');
			}
		} else {
			setShowModal(false);
		}
	};
	useEffect(() => {
		if (status === 'success') {
			let monthlyEntryData = data.data.monthArray;
			let monthlyEntry = JSON.stringify(monthlyEntryData);
			localStorage.setItem('monthlyEntryData', monthlyEntry);
			navigate('/monthlyentry/monthlyentry-calendar');
		}
		if (
			AppMonthlyStatus != null &&
			AppMonthlyStatus[0].monthly_entry_status == 1
		) {
			navigate('/monthlyentry/monthlyentry-calendar');
		}
	}, [status, data]);
	return (
		<div className={'mainWrapper' + ' ' + style.monthlyEntry}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h1 className="back">
							<Link to="/dashboard">
								<img src={backIcon} alt="REMS" />
							</Link>
							{t('setupmonitoringpage.Headertext')}
						</h1>
					</div>
					<div className="col-md-12 mb-3">
						{langId === '1' ? (
							<p
								style={{
									fontSize: window.innerWidth > 768 ? '14px' : '13px',
									lineHeight: window.innerWidth > 768 ? '22px' : '23px',
								}}
							>
								You will be able to monitor your parameters for a maximum of{' '}
								<b>60 months</b> from the starting year and starting month that
								you choose here.
							</p>
						) : (
							<p
								style={{
									fontSize: window.innerWidth > 768 ? '14px' : '13px',
								}}
							>
								အများဆုံး သင်စီစစ်မည့် ဒေတာများကို <b>လ ၆၀</b> မှ တနှစ်အတွင်း
								အစအဆုံးကို စစ်ဆေးနိုင်မည်ဖြစ်သည်။
							</p>
						)}
					</div>
				</div>
				<div
					className="row mb-5"
					style={{ paddingBottom: window.innerWidth < 768 ? '46px' : '0px' }}
				>
					<div className="col-6 col-md-3 col-lg-2 mb-3">
						<select
							className="form-select"
							onChange={(e) => handleMonthChange(e)}
							value={period.startMonth}
							style={{
								textTransform: 'capitalize',
								fontWeight: window.innerWidth > 768 ? '400' : '600',
								fontSize: window.innerWidth > 768 ? '14px' : '13px',
							}}
						>
							<option hidden>
								{t('setupmonitoringpage.DropDownStartingMonth')}
							</option>
							{monthList.map((item, index) => (
								<option
									key={index}
									value={item.month}
									style={{ textTransform: 'capitalize' }}
								>
									{item.month}
								</option>
							))}
						</select>
					</div>
					<div className="col-6 col-md-3 col-lg-2 mb-3">
						<select
							className="form-select"
							onChange={(e) => handleYearChange(e)}
							value={period.startYear}
							style={{
								textTransform: 'capitalize',
								fontWeight: window.innerWidth > 768 ? '400' : '600',
								fontSize: window.innerWidth > 768 ? '14px' : '13px',
							}}
						>
							<option hidden>
								{t('setupmonitoringpage.DropDownStartingYear')}
							</option>
							{yearList.map((item, index) => (
								<option key={index} value={item.year}>
									{item.year}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 col-lg-4">
						<button
							type="button"
							className={
								period.startMonth !== '' && period.startYear !== ''
									? 'btn_primary w-100'
									: 'disable_primary w-100'
							}
							onClick={handleShow}
							disabled={
								period.startMonth !== '' && period.startYear !== ''
									? false
									: true
							}
						>
							{t('setupmonitoringpage.SaveSelectionButton')}
						</button>
					</div>
				</div>
			</div>
			<Modal
				className="modalStyle text-center setupMonitorPopup"
				show={showModal}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				size="sm"
			>
				<Modal.Body>
					<div className="mb-4 mt-1 pt-4">
						<p>
							<img
								src={SetUpClockIcon}
								style={{ maxWidth: window.innerWidth > 768 ? '60px' : '42px' }}
								alt="setupMonitor_image"
							/>
						</p>
						<h5 className="mb-3" style={{ fontSize: '19px' }}>
							{t('setupmonitoringpage.conformMonitoring')}
						</h5>
						<p className=" mb-4">
							<span className="text-primary">
								<b>{t('generalText.NOTE')} </b>
							</span>{' '}
							{t('setupmonitoringpage.note')}
						</p>
						<div className="justify-content-between d-flex">
							<button
								className="btn btn-primary"
								onClick={() => setMonthlyEntry('yes')}
								style={{ width: '44%' }}
							>
								{t('generalText.yesConform')}
							</button>
							<button
								className="btn btn-light"
								onClick={() => setMonthlyEntry('no')}
								style={{ width: '44%' }}
							>
								{t('generalText.noGoBack')}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default SetupMonitoring;
