import React, { useContext, useState } from 'react';
import style from './style.module.css';
import backIcon from '../../../assets/frontend/images/back_icon.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useGetMonthlydata, useMasterDataHook, useMonthlyLogAllData } from '../api_hooks/api_hooks';
import { useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { Context } from '../../../frontend_context/contextProvider';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useTranslation } from 'react-i18next';
import EastIcon from '@mui/icons-material/East';

const MonthlyEntry = () => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    //const { AllYearData } = useContext(Context)
    let AllmonthlyData = localStorage.getItem('monthlyEntryData');
    let localData = JSON.parse(localStorage.getItem("masterData"));
    let AppMonthly = localData && localData.DASHBOARD_STATUS;
    let AppMonthlyStatus = JSON.parse(AppMonthly);
    //const [YearDataCollection, setYearDataCollection] = useState(AllYearData);
    //const [accordionSelect,setAccordionSelect] = useState(localStorage.getItem('accordionSelection')===null?1:localStorage.getItem('accordionSelection'))
    useMasterDataHook();
    const { mutate: getmonthlyData, isLoading, data} = useGetMonthlydata();
    const {mutate:getAllMonthData} = useMonthlyLogAllData();
    const getMonthlyDataFun = () => {
        getmonthlyData()
        if (AllmonthlyData == null) {
            getAllMonthData();
        }
    }
    const [expanded, setExpanded] = useState(localStorage.getItem('accordionSelection')===null?"panel+0":localStorage.getItem('accordionSelection'));
    useEffect(() => {
        if(isLoading === false){
            getMonthlyDataFun();
        }
    }, [])
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    const handleChange = (panel) => (event, newExpanded) => {
        localStorage.setItem('accordionSelection',panel)
        setExpanded(newExpanded ? panel : false)
    }
    return (
        <div className={'mainWrapper ' + style.monthlyEntry}>
            <div className="container">
                <div className="main_title mb-4" style={{ position: "relative" }}>
                    <div className='row align-items-center' >
                        <div className="col-9">
                            <h1 className='back'><Link to={AppMonthlyStatus != null && AppMonthlyStatus[0].monthly_entry_status === 0 ? "/monthlyentry/setupmonitoring" : "/dashboard"}><img src={backIcon} alt="REMS" /></Link>{t("monthlyentrycalendarpage.HeaderText")}</h1>
                        </div>
                        <div className={'tooltipwrapper '+style.tooltipRight} style={{ zIndex: isOpen ===true?"100":"0" }}>
                            <button onClick={toggleDropdown} className="toolTip tooltipDashBoard" >
                                <HelpCenterIcon style={{ color: isOpen === true && "#138C50"}} />
                            </button>
                            {isOpen && <div className="backShadowSetup" onClick={() => setIsOpen(false)}></div>}
                            {isOpen && (
                                <div className='tooltipShow' style={{lineHeight:langId === "1"?"20px":"unset",right:"-6px"}}>
                                    <h5 style={{ marginBottom: "20px" }}>{t("dashboardpage.TootipsPopupHeaderText")}</h5>
                                    {langId === '1' ? <>
                                        <p><EastIcon style={{ fontSize: "13px", marginRight: "4px" }} />Choose a month to log in the required quantities, from the <b>60-month monitoring period</b>. You can choose to start with any month within the monitoring period.</p>
                                        <p><EastIcon style={{ fontSize: "13px", marginRight: "4px" }} />You can come back any time and edit any month's data. Remember to save every single time after editing the data to let the system know of the changes.</p>
                                        <hr style={{ width: "14px", borderTop: "2px solid #383838", margin: "24px 0px" }}></hr>
                                        <p><span>NOTE:</span>If you have your data on a daily basis, please convert it to a monthly basis and then enter it. Colour code Unattemped Complete Incomplete</p>
                                    </> :
                                        <>
                                            <p><EastIcon style={{ fontSize: "13px", marginRight: "4px" }} />လတစ်လကို ရွေးချယ်ပြီး လိုအပ်သော ပမာဏများကို ဖြည့်စွက်ပြီးဝင်ရောက်ပြီးမှ <b>စောင့်ကြည့်ကာလ လပေါင်း ၆၀</b> စတင်ပါသည်။ စောင့်ကြည့်ရေးကာလအတွင်း မည်သည့်လမှ စတင်ရန် သင်ရွေးချယ်နိုင်ပါသည်။
                                            </p>
                                            <p><EastIcon style={{ fontSize: "13px", marginRight: "4px" }} />"အချိန်မရွေး မည်သည့်လ၏ဒေတာကိုမဆို ပြန်လာပြင်ဆင်နိုင်ပါသည်။ ဒေတာကို ပြင်ဆင်ပြီးနောက် အပြောင်းအလဲများကို စနစ်အား အသိပေးရန် အကြိမ်တိုင်း သိမ်းဆည်းရန် မမေ့ပါနှင့်။"
                                            </p>
                                            <hr style={{ width: "14px", borderTop: "2px solid #383838", margin: "24px 0px" }}></hr>
                                            <p><span>မှတ်ချက် :</span>သင့်တွင် နေ့စဉ်ဒေတာရှိပါက၊ ၎င်းကို လစဉ်‌‌‌ဒေတာအဖြစ်သို့ ပြောင်းပြီး ထည့်သွင်းပါ။</p>
                                        </>}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            {langId === '1' ? <>
                                <p><b>Choose a month</b> to start entering in the required data against that particular month.</p>
                                <p><b>NOTE:</b> {t("monthlyentrycalendarpage.NoteText")}</p>
                            </> :
                                <>
                                    <p>လိုအပ်သော ဒေတာ ထည့်သွင်းရန် စတင်သည့် <b>လကို ရွေးချယ်ပါ။</b></p>
                                    <p><b>သတိပေးချက်:</b> {t("monthlyentrycalendarpage.NoteText")}</p>
                                </>}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className={style.calenderWidth}>
                        {data?.data.monthArray !== false && data?.data.monthArray.sort().map((item, indexOne) => <Accordion
                            className={style.accArrow}
                            key={indexOne} style={{ background: expanded === `panel+${indexOne}` ? "#D9D9D9" : "#F5F5F5", marginTop: '0px' }}
                            expanded={expanded === `panel+${indexOne}`} onChange={handleChange(`panel+${indexOne}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography style={{ fontSize: "20px", fontWeight: '500' }}>{item.year}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0 }}>
                                <div className={style.MonthList}>
                                    {item.monthlyentry.map((month, indexTwo) => {
                                        let ProductStatusValue = 0
                                        let RawmaterialValue = 0
                                        let wasteStatusValue = 0
                                        let boilerStatusValue = 0
                                        let dieselStatusValue = 0
                                        let electricityRenewableStatus = 0
                                        let gridElectricityStatus = 0
                                        let netRevenueStatus = 0
                                        let totalDisposalStatus = 0
                                        if (month) {

                                            return <div key={indexTwo} className={style.monthDiv}>
                                                <Link to={`/monthlylog/${item.year}/${month.month}`} style={{ position: "relative" }}>
                                                    {/* <div className={style.tickLabel}></div> */}

                                                    {/* <p style={{color:"red",fontSize:"12px"}}>Incomplete!</p> */}
                                                    {month.monthly_json[0].productJson.map((item) => {
                                                        if (!item.quantity&&item.quantity!==0) {
                                                            ProductStatusValue = 1
                                                        }

                                                    }

                                                    )}
                                                    {month.monthly_json[0].materialJson.map((item) => {
                                                        if (!item.quantity&&item.quantity!==0) {
                                                            RawmaterialValue = 1
                                                        }
                                                    }

                                                    )}
                                                    {month.monthly_json[0].wasteJson.map((item) => {
                                                        if ((!item.quantity&&item.quantity!==0) || (!item.open_burning&&item.open_burning!==0) || (!item.land_filling&&item.land_filling!==0) || (!item.recycling&&item.recycling!==0)) {
                                                            wasteStatusValue = 1
                                                        }
                                                    })}
                                                    {month.monthly_json[0].boilerJson.map((item) => {
                                                        if (!item.quantity&&item.quantity!==0) {
                                                            boilerStatusValue = 1
                                                        }
                                                    })}
                                                    {function () {
                                                        if (!month.monthly_json[0].diesel_from_DG_set&&month.monthly_json[0].diesel_from_DG_set!==0) {
                                                            dieselStatusValue = 1
                                                        }
                                                    }.call()}
                                                    {function () {
                                                        if (!month.monthly_json[0].electricity_from_renewables&&month.monthly_json[0].electricity_from_renewables!==0) {
                                                            electricityRenewableStatus = 1
                                                        }
                                                    }.call()}
                                                    {function () {
                                                        if (!month.monthly_json[0].grid_electricity&&month.monthly_json[0].grid_electricity!==0) {
                                                            gridElectricityStatus = 1;
                                                        }

                                                    }.call()}
                                                    {function () {
                                                        if (!month.monthly_json[0].net_revenue_from_waste_generation&&month.monthly_json[0].net_revenue_from_waste_generation!==0) {
                                                            netRevenueStatus = 1;
                                                        }
                                                    }.call()}
                                                    {function () {
                                                        if (!month.monthly_json[0].total_disposal_cost&&month.monthly_json[0].total_disposal_cost!==0) {
                                                            totalDisposalStatus = 1;
                                                        }
                                                    }.call()}
                                                    {function () {
                                                        if ((ProductStatusValue === 1 || RawmaterialValue === 1 || wasteStatusValue === 1 || boilerStatusValue === 1 || dieselStatusValue === 1 ||
                                                            electricityRenewableStatus === 1 || gridElectricityStatus === 1 || netRevenueStatus === 1 || totalDisposalStatus === 1) && month.monthly_json[0].monthly_data_added_status === 1) {
                                                            return <div className={style.incompleteClass}>
                                                                <p style={{ fontWeight: '600', fontSize: "16px" }}>{month.month.substr(0, 3)}</p>
                                                                <p style={{ color: "red", fontSize: "12px" }}>{t("generalText.Incomplete")}!</p>
                                                            </div>
                                                        }
                                                        if ((ProductStatusValue === 1 || RawmaterialValue === 1 || wasteStatusValue === 1 || boilerStatusValue === 1 || dieselStatusValue === 1 ||
                                                            electricityRenewableStatus === 1 || gridElectricityStatus === 1 || netRevenueStatus === 1 || totalDisposalStatus === 1) &&
                                                            month.monthly_json[0].monthly_data_added_status === 0) {

                                                            return <div className={style.NoteStarted}>
                                                                <p style={{ fontWeight: '600', fontSize: "16px" }}>{month.month.substr(0, 3)}</p>
                                                            </div>
                                                        }
                                                        else {
                                                            return <div className={style.completeClass}>
                                                                <div className={style.tickLabel}></div>
                                                                <p style={{ fontWeight: '600', fontSize: "16px" }}>{month.month.substr(0, 3)}</p>
                                                            </div>
                                                        }
                                                    }.call()}

                                                </Link>
                                            </div>

                                        }

                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>)}
                        {isLoading ? <Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{
                                justifyContent: "center"
                            }}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MonthlyEntry;