import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';
import { getFormattedDateHyphen } from '../../admin/utils/getFormattedData';
import { useTranslation } from 'react-i18next';

const GenerateExcel = ({ setGenerateExcel }) => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	useEffect(() => {
		if (localStorage.getItem('InputLogData')) {
			setData(JSON.parse(localStorage.getItem('InputLogData')));
		} else {
			toast.info(
				'There is no data to generate Excel file. Please generate the output again by checking the input log checkbox'
			);
		}
	}, []);

	useEffect(() => {
		if (data[0] !== undefined) {
			generateExcelFile();
		}
	}, [data]);

	const generateExcelFile = () => {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Data');

		// Define headers for your Excel file
		// Define the Date header
		worksheet.columns = [{ header: 'Date', key: 'Date', width: 20 }];

		// Define dynamic headers based on the response data
		const headers = [];
		try {
			const productList = JSON.parse(data[0]?.productList);
			if (Array.isArray(productList)) {
				productList.forEach((product) => {
					const header = {
						header: `${product.product_name} (${product.unit_name})`,
						key: product.product_name,
						width: 20,
					};
					headers.push(header);
				});
			}

			// Extract headers from materialList
			const materialList = JSON.parse(data[0]?.materialList);
			if (Array.isArray(materialList)) {
				materialList.forEach((material) => {
					const header = {
						header: `${material.raw_material} (${material.unit_name})`,
						key: material.raw_material,
						width: 20,
					};
					headers.push(header);
				});
			}

			// Extract headers from boilerResourceList
			const boilerResourceList = JSON.parse(data[0]?.boilerResourceList);
			if (Array.isArray(boilerResourceList)) {
				boilerResourceList.forEach((resource) => {
					const header = {
						header: `${resource.boiler_fuel} (${resource.unit_name})`,
						key: resource.boiler_fuel,
						width: 20,
					};
					headers.push(header);
				});
			}

			// Other  Resources list headers
			let headerOne = {
				header: `Grid Electricity (kWh)`,
				key: 'Grid Electricity (kWh)',
				width: 20,
			};
			headers.push(headerOne);
			let headerTwo = {
				header: `Energy from renewables (kWh)`,
				key: 'Energy from Renewables (kWh)',
				width: 20,
			};
			headers.push(headerTwo);
			let headerThree = {
				header: `Diesel for DG set (litre)`,
				key: 'Diesel for DG set (litre)',
				width: 20,
			};
			headers.push(headerThree);

			// Extract headers from wasteList
			const wasteList = JSON.parse(data[0]?.wasteList);
			if (Array.isArray(wasteList)) {
				wasteList.forEach((waste, index) => {
					let header = {
						header: `${waste.waste_name} (${waste.unit_name})`,
						key: `Waste ${index + 1} Quantity`,
						width: 20,
					};
					headers.push(header);
					header = {
						header: `Amount of ${waste.waste_name} recycled (%)`,
						key: `Amount_of_${waste.waste_name}_recycled`,
						width: 20,
					};
					headers.push(header);
				});
			}

			let header = {
				header: `Total revenue from waste disposal (Ks.)`,
				key: 'Total Revenue from Waste',
				width: 20,
			};

			headers.push(header);

			header = {
				header: `Cost of waste disposal (Ks.)`,
				key: 'Total Disposal Cost',
				width: 20,
			};
			headers.push(header);

			// Add all headers to the worksheet columns
			worksheet.columns = [...worksheet.columns, ...headers];
		} catch (error) {
			toast.error('Error generating Excel file.');
		}
		// Extract headers from productList

		//data
		const apiResponse = [...data];

		// Create an array to store the transformed data
		const excelData = [];

		// Iterate through the API response
		apiResponse.forEach((data) => {
			// Transform the date format to Jan-20
			const createdAtMonth = new Date(data?.created_at_month);
			const dateFormatted = new Intl.DateTimeFormat('en-US', {
				year: '2-digit',
				month: 'short',
			})
				.format(createdAtMonth)
				.replace(/ /g, '-');

			// Initialize an object for the Excel row data
			const excelRow = {
				Date: dateFormatted,
			};

			// Process productList, materialList, and boilerResourceList
			['productList', 'materialList', 'boilerResourceList'].forEach(
				(listKey) => {
					const listData = JSON.parse(data[listKey]);
					if (Array.isArray(listData) && listData.length > 0) {
						// Initialize an object to store values from the list
						const listValues = {};
						let key =
							listKey == 'productList'
								? 'product_name'
								: listKey == 'materialList'
								? 'raw_material'
								: 'boiler_fuel';
						// Iterate through items in the list and assign unique keys
						listData.forEach((item, index) => {
							listValues[item[key]] =
								item.product_quantity ||
								item.material_quantity ||
								item.boiler_quantity ||
								'';
						});

						// Assign the list values to the Excel row
						Object.assign(excelRow, listValues);
					} else {
						// Set an empty value if the list is empty
						excelRow[listKey] = '';
					}
				}
			);

			// Process otherResourceList

			const otherResources = JSON.parse(data?.otherResourceList);
			excelRow['Grid Electricity (kWh)'] =
				Number(otherResources[0]?.grid_electricity) || '';
			excelRow['Energy from Renewables (kWh)'] =
				Number(otherResources[0]?.electricity_from_renewables) || '';
			excelRow['Diesel for DG set (litre)'] =
				Number(otherResources[0]?.diesel_from_DG_set) || '';

			// Process wasteList
			const wasteList = JSON.parse(data?.wasteList);
			wasteList.forEach((waste, index) => {
				excelRow[`Waste ${index + 1} Quantity`] = waste.quantity || '';
				excelRow[`Amount_of_${waste.waste_name}_recycled`] =
					waste.recycling || '';
			});

			// Process wasteDisposalList
			const disposalList = JSON.parse(data.wasteDisposalList);
			excelRow['Total Revenue from Waste'] =
				disposalList[0]?.total_revenue_generated_from_waste || '';
			excelRow['Total Disposal Cost'] =
				disposalList[0]?.total_disposal_cost || '';

			// Push the transformed data into the excelData array
			excelData.push(excelRow);
		});

		// Iterate through your data and add rows to the worksheet
		// Populate the worksheet with data
		excelData.forEach((data) => {
			worksheet.addRow(data);
		});
		worksheet.eachRow({ includeEmpty: true }, (row) => {
			row.eachCell((cell) => {
				cell.alignment = { horizontal: 'left' };
			});
		});

		// To make the "Date" column bold
		worksheet.getColumn('Date').eachCell((cell, rowNumber) => {
			if (rowNumber > 1) {
				cell.style.font = { bold: true };
			}
		});

		// Generate the Excel file
		workbook.xlsx.writeBuffer().then((buffer) => {
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			saveAs(
				blob,
				`${localStorage.getItem(
					'companyName'
				)}_input_log_data_${getFormattedDateHyphen()}.xlsx`
			);
		});
		toast.success(t('alerts.Excelfile'));
		setGenerateExcel(false);
	};

	return <div></div>;
};

export default GenerateExcel;
