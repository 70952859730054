import React, { useState } from 'react';
import {
	Stack,
	TextField,
	Typography,
	Box,
	Button,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { resetPassword } from '../../../services/AdminApis';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import RequestError from '../components/RequestError';

const ResetPassword = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmPassword: false,
	});
	const [confirmPassword, setConfirmPassword] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const { mutate, error, isError } = useMutation(
		'resetPassword',
		resetPassword,
		{
			onSuccess: (data) => {
				setTimeout(() => {
					setLoading(false);
					toast.success(
						'Password reset successfully. Please login with your new password.'
					);
					navigate('/admin/login');
				}, 1900);
			},
			onError: () => {
				console.log(error);
			},
		}
	);

	const handleResetPassword = () => {
		if (!password) {
			toast.error('Please enter a password.');
			return;
		}
		if (!confirmPassword) {
			toast.error('Please enter confirm password.');
			return;
		}
		setLoading(true);
		mutate({ admin_password: password, token: token });
	};

	useEffect(() => {
		localStorage.removeItem('adminToken');
		localStorage.removeItem('adminTokenExpiry');
	}, []);

	const handleTogglePassword = (passwordType) => {
		setShowPassword((prevShowPassword) => {
			return {
				...prevShowPassword,
				[passwordType]: !prevShowPassword[passwordType],
			};
		});
	};

	if (loading) {
		return <Loader />;
	}
	if (isError) {
		console.log('error');
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	return (
		<>
			<Header />
			<Box
				sx={{ height: '88vh' }}
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Stack>
					<Typography
						variant="h6"
						fontWeight="bold"
						sx={{ color: 'text.primary' }}
						gutterBottom
					>
						Reset your password
					</Typography>
					<TextField
						id="outlined-basic-password"
						label="New password"
						variant="outlined"
						size="small"
						type={showPassword.password ? 'text' : 'password'}
						sx={{ width: '350px', mb: 2 }}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => handleTogglePassword('password')}
										edge="end"
									>
										{!showPassword.password ? (
											<VisibilityOff style={{ color: '#a6a6a6' }} />
										) : (
											<Visibility style={{ color: '#138C50' }} />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						id="outlined-basic-confirm-password"
						label="Confirm password"
						variant="outlined"
						size="small"
						type={showPassword.confirmPassword ? 'text' : 'password'}
						sx={{ width: '350px' }}
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => handleTogglePassword('confirmPassword')}
										edge="end"
									>
										{!showPassword.confirmPassword ? (
											<VisibilityOff style={{ color: '#a6a6a6' }} />
										) : (
											<Visibility style={{ color: '#138C50' }} />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<Button
						sx={{ mt: 4, textTransform: 'capitalize', borderRadius: '5px' }}
						variant="contained"
						onClick={handleResetPassword}
					>
						Reset Password
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default ResetPassword;
