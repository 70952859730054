import React from 'react';
import PdfLineChart from '../../admin/components/PdfLineChart';

const NotGenerated = ({ title, unit }) => {
	return (
		<div style={{ height: '400px', minHeight: '400px' }}>
			<PdfLineChart title={title} unit={unit} dataSetArray={[]} labels={[]} />
		</div>
	);
};

export default NotGenerated;
