import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import loadingImage from '../../../assets/frontend/images/loading.png';
import { useTranslation } from 'react-i18next';

const LoadingDialog = ({ loadingOpen, setLoadingOpen }) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('(max-width: 600px)');

	return (
		<div>
			<Dialog open={loadingOpen} onClose={() => setLoadingOpen(false)}>
				<DialogContent sx={{ pr: isMobile ? 2 : 3 }}>
					<DialogContentText>
						<Stack spacing={3} justifyContent="center">
							<Box textAlign="center" sx={{ mt: isMobile ? 1 : 0.2 }}>
								<img src={loadingImage} />
							</Box>
							<p
								style={{
									textAlign: 'center',
									fontWeight: 'bold',
									fontSize: '18px',
									color: 'black',
								}}
							>
								{t('output.PleaseWaitPopupText')}
								<br /> {t('output.YourOutput')}
							</p>

							<Typography
								textAlign="center"
								variant="subtitle2"
								sx={{ mt: isMobile ? 1.5 : 2 }}
							>
								{t('output.DependingOnNumberOfOutputs')}{' '}
								{isMobile ? null : <br />}
								{t('output.Tobeprocessed')}
								{isMobile ? null : <br />} {t('output.MinutesTobeGenerated')}
							</Typography>
							<Typography
								fontWeight="bold"
								fontSize="15px"
								color="#383838"
								textAlign="center"
								sx={{ mt: isMobile ? 1 : 2 }}
							>
								{t('output.PleaseWaitPopupSubTextTwo')}
							</Typography>
						</Stack>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default LoadingDialog;
