import React from 'react';
import { Typography, Box } from '@mui/material';
import backButton from '../../../assets/admin/images/leftArrow.png';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { getAccountDetails } from '../../../services/AdminApis';
import RequestError from '../components/RequestError';
import Loader from '../components/Loader';
import AccountDetailsUpdate from '../components/AccountDetailsUpdate';

const AccountSettings = () => {
	const navigate = useNavigate();

	const { data, isLoading, isError, error } = useQuery(
		'accountDetails',
		getAccountDetails
	);

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	const adminLabels = ['name', 'email', 'password'];
	const postLabels = ['user_name', 'email', 'upassword'];

	return (
		<Box padding="45px 65px 6px">
			<Box
				sx={{ mb: 3 }}
				style={{ display: 'flex' }}
				display="flex"
				alignItems="center"
				gap="20px"
			>
				<Box sx={{ mb: 2.2, cursor: 'pointer' }} onClick={() => navigate(-1)}>
					<img src={backButton} width="20px" alt="backbutton" />
				</Box>

				<Typography
					variant="h5"
					fontWeight="bold"
					sx={{ color: 'text.primary', marginBottom: '20px' }}
				>
					Manage account settings
				</Typography>
			</Box>
			{data &&
				['Username', 'Email ID', 'Password'].map((item, index) => (
					<AccountDetailsUpdate
						value={data[0][adminLabels[index]]}
						userObject={data[0]}
						key={index}
						label={item}
						postLabel={postLabels[index]}
					/>
				))}
		</Box>
	);
};

export default AccountSettings;
