import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Addcomment from '../../../assets/frontend/images/add_comment_icon.png';
import Prev from '../../../assets/frontend/images/prev.png';
import Next from '../../../assets/frontend/images/next.png';
import { CurrentMonthData } from './currentMonthData';
import {
    ReplaceMonthProduct,
    ReplaceMonthMaterial,
    ReplaceRawMaterialActive,
    ReplaceEnergyActive,
    ReplaceMonthBoiler,
    ReplaceMonthGridEnrgy,
    ReplaceMonthElectricityRenew,
    ReplaceMonthDieselDGset,
    ReplaceMonthWasteGenerated,
    ReplaceMonthlyComment,
    ReplaceWasteCompleted,
    ReplaceMonthNetRevenue,
    ReplaceMonthTotalwaste,
    ReplaceProductActive,
    ReplaceStartStatus,
    ReplaceProductMonthlyStatus,
    ReplaceMaterialMonthlyStatus,
    ReplaceResourceMonthlyStatus,
    ReplaceWasteMonthlyStatus

} from './replaceMonthdata';
import CurrentProductList from './products/currentProductList';
import { useMonthlyAddBoilerfuel, useMonthlyAddProduct, useMonthlyAddRawMaterial, useMonthlyAddWaste, useMonthlyComment } from '../api_hooks/api_hooks';
import RawMaterials from './rawMaterials/rawMaterial';
import Energy from './energy/energy';
import WasteGenerated from './wasteGenerated/wastegenerated';
import { CurrentYearData } from './currentYear';
import BlankProducts from './products/blankProducts';
import BlankRawMaterials from './rawMaterials/blankRawMaterial';
import ProgressBar from './monthProgressView/monthProgress';
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import EastIcon from '@mui/icons-material/East';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const MonthlyLog = () => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    const paramsValue = useParams();
    const [ProductIncomplete, setProductIncomplete] = useState('');
    const [RawMaterialIncomplete, setRawMaterialIncomplete] = useState('')
    const [EnergyIncomplete, setEnergyIncomplete] = useState('')
    const [WasteIncomplete, setWasteIncomplete] = useState('')
    const [params, setParams] = useState(paramsValue)
    const rawRef = useRef(null);
    const EnergyRef = useRef(null);
    const WasteRef = useRef(null);
    const TopRef = useRef(null);
    const [show, setShow] = useState(false);
    const [conformPopup, setConformPopup] = useState(false);
    const navigate = useNavigate()
    let monthlyData = localStorage.getItem('monthlyEntryData')
    let MonthlyArray = JSON.parse(monthlyData)
    const [AllData, setData] = useState(MonthlyArray != null ? MonthlyArray : [])
    let currentYear = AllData.length > 0 && CurrentYearData(AllData, params)
    const [ProdunctJson, setProductJson] = useState([])
    const [RawMaterialJson, setRawmaterilaJson] = useState([])
    const [EnergyBoilerJson, setEnergyBoilerJson] = useState([])
    const [gridElectricity, setGridElectricity] = useState('')
    const [ElectricityRenewables, setElectricityRenewables] = useState('')
    const [dieselDGset, setDieselDGset] = useState('')
    const [WasteGeneratedJson, setWasteGeneratedJson] = useState([]);
    const [monthlyComments, setMonthlyComments] = useState(null)
    const [netWasteRevenue, setNetWasteRevenue] = useState('')
    const [totalDisposalCost, setTotalDisposalCost] = useState('')
    let currentMonthdata = AllData.length > 0 && CurrentMonthData(AllData, params);
    let CurrentStatus = AllData.length > 0 && currentMonthdata !== false && currentMonthdata.length !== 0 && currentMonthdata[0].monthly_json[0].monthly_data_added_status;
    //hide show modules variable

    const [completeProductSection, setCompleteProductSection] = useState(0);
    const [completeRawMaterial, setCompleteRawMaterialSection] = useState(0);
    const [completeEnerySection, setCompleteEnerySection] = useState(0);
    const [completeWasteGenerated, setCompleteWasteGenerated] = useState(0);

    const [HelpOpen, setHelpOpen] = useState(false);
    const [conformPrevPopup, SetConformPrevPopup] = useState(false)
    const [CalenderConformPopup, setCalenderConformPopup] = useState(false);
    const [percentageError, setPercentageError] = useState(false)
    const [clickBlur, setClickBlur] = useState(true)
    let MonthCollectionSet = currentYear && currentYear[0].monthlyentry.length;

    const ProductInCompleteFunction = () => {
        if (ProdunctJson.length > 0) {
            let productJsonData = ProdunctJson;
            let result = productJsonData.filter((item) => item.quantity === null || item.quantity === '')
            if (result.length > 0) {
                setProductIncomplete(0)
                let MonthlyStatus = AllData.length > 0 && ReplaceProductMonthlyStatus(AllData, params, 0)
                localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
            }
            else {
                setProductIncomplete(1)
                let MonthlyStatus = AllData.length > 0 && ReplaceProductMonthlyStatus(AllData, params, 1)
                localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
            }
        }
        else {
            setProductIncomplete(1)
        }

    }

    const RawmaterialIncompleteFunction = () => {
        if (RawMaterialJson.length > 0) {


            let result = RawMaterialJson.filter((item) => item.quantity === null || item.quantity === '')
            if (result.length > 0) {
                setRawMaterialIncomplete(0)
                let MonthlyStatus = AllData.length > 0 && ReplaceMaterialMonthlyStatus(AllData, params, 0)
                localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
            }
            else {
                setRawMaterialIncomplete(1)
                let MonthlyStatus = AllData.length > 0 && ReplaceMaterialMonthlyStatus(AllData, params, 1)
                localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
            }

        }
        else {
            setRawMaterialIncomplete(1)
        }
    }
    const EnergyIncompleteFunction = () => {
        let result = EnergyBoilerJson.length > 0 && EnergyBoilerJson.filter((item) => item.quantity === null || item.quantity === '')
        if (result.length > 0 || !gridElectricity || !ElectricityRenewables || !dieselDGset) {
            setEnergyIncomplete(0)
            let MonthlyStatus = AllData.length > 0 && ReplaceResourceMonthlyStatus(AllData, params, 0)
            localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
        }
        else {
            setEnergyIncomplete(1)
            let MonthlyStatus = AllData.length > 0 && ReplaceResourceMonthlyStatus(AllData, params, 1)
            localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
        }
    }
    const WasteIncompleteFunction = () => {
        let resultQuantity = WasteGeneratedJson.length > 0 && WasteGeneratedJson.filter((item) => item.quantity === null || item.quantity === '')
        let resultBurning = WasteGeneratedJson.length > 0 && WasteGeneratedJson.filter((item) => item.open_burning === null || item.open_burning === '')
        let resultLandFilling = WasteGeneratedJson.length > 0 && WasteGeneratedJson.filter((item) => item.land_filling === null || item.land_filling === '')
        let resultRecycling = WasteGeneratedJson.length > 0 && WasteGeneratedJson.filter((item) => item.recycling === null || item.recycling === '')
        if (resultQuantity.length > 0 || resultBurning.length > 0 || resultLandFilling.length > 0 || resultRecycling.length > 0 || !netWasteRevenue || !totalDisposalCost) {
            setWasteIncomplete(0)
            let MonthlyStatus = AllData.length > 0 && ReplaceWasteMonthlyStatus(AllData, params, 0)
            localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
        }
        else {
            setWasteIncomplete(1)
            let MonthlyStatus = AllData.length > 0 && ReplaceWasteMonthlyStatus(AllData, params, 1)
            localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStatus))
        }
    }
    const productInputChange = (e, index) => {
        const { name, value } = e.target;
        let productData = [...ProdunctJson];
        productData[index][name] = value;
        setProductJson(productData)
        let ReplaceProduct = AllData.length > 0 && ReplaceMonthProduct(AllData, params, productData)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceProduct))
    }
    const MaterialInputChange = (e, index) => {
        const { name, value } = e.target;
        let MaterialJsonData = [...RawMaterialJson];
        MaterialJsonData[index][name] = value;
        setRawmaterilaJson(MaterialJsonData)
        let ReplaceMaterial = AllData.length > 0 && ReplaceMonthMaterial(AllData, params, MaterialJsonData)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceMaterial))
    }
    const BoilerInputChange = (e, index) => {
        const { name, value } = e.target;
        let boilerJsonData = [...EnergyBoilerJson];
        boilerJsonData[index][name] = value;
        setEnergyBoilerJson(boilerJsonData);
        let ReplaceMaterial = AllData.length > 0 && ReplaceMonthBoiler(AllData, params, boilerJsonData)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceMaterial))
    }
    const OtherEnergyInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'grid_electricity') {
            setGridElectricity(value)
            let ReplaceMaterial = AllData.length > 0 && ReplaceMonthGridEnrgy(AllData, params, value)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceMaterial))
        }
        if (name === 'electricity_from_renewables') {
            setElectricityRenewables(value)
            let ReplaceMaterial = AllData.length > 0 && ReplaceMonthElectricityRenew(AllData, params, value)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceMaterial))
        }
        if (name === 'diesel_from_DG_set') {
            setDieselDGset(value)
            let ReplaceMaterial = AllData.length > 0 && ReplaceMonthDieselDGset(AllData, params, value)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceMaterial))
        }
    }
    const otherWasteGeneratedInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'net_revenue_from_waste_generation') {
            setNetWasteRevenue(value)
            let ReplaceWaste = AllData.length > 0 && ReplaceMonthNetRevenue(AllData, params, value)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceWaste))
        }
        if (name === 'total_disposal_cost') {
            setTotalDisposalCost(value)
            let ReplaceWaste = AllData.length > 0 && ReplaceMonthTotalwaste(AllData, params, value)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceWaste))
        }
    }
    //waste generated input change
    const wasteGeneratedInputChange = (e, index) => {
        const { name, value } = e.target;
        if (name !== 'quantity') {
            if (value <= 100) {
                let wastegeneratedData = [...WasteGeneratedJson];
                wastegeneratedData[index][name] = value;
                setWasteGeneratedJson(wastegeneratedData);
                let ReplaceWaste = AllData.length > 0 && ReplaceMonthWasteGenerated(AllData, params, wastegeneratedData)
                localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceWaste))
            }
            else {
                setPercentageError(true)
            }
        }
        else {
            let wastegeneratedData = [...WasteGeneratedJson];
            wastegeneratedData[index][name] = value;
            setWasteGeneratedJson(wastegeneratedData);
            let ReplaceWaste = AllData.length > 0 && ReplaceMonthWasteGenerated(AllData, params, wastegeneratedData)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceWaste))
        }

    }

    //add product api call 
    let createdMonth = currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].created_at_month
    const { mutate: addMonthlyproduct, status: ProductStatus } = useMonthlyAddProduct();
    const [proStatus, setproStatus] = useState(ProductStatus);
    const { mutate: addRawMaterial } = useMonthlyAddRawMaterial();
    const { mutate: addBoilerFuiel } = useMonthlyAddBoilerfuel();
    const { mutate: addMonthlyWasteGenerated } = useMonthlyAddWaste();
    const { mutate: addMonthlycommentFunc } = useMonthlyComment();
    //save products
    const helpPopup = () => {
        setHelpOpen(!HelpOpen)
    }
    const saveProduct = () => {
        ProductInCompleteFunction();
        let productRestructure = ProdunctJson;
        
        let Result = productRestructure.map((item) => {
            if (item.quantity === null || item.quantity === '') {
                let data = {
                    id: item.id,
                    product_name: item.product_name,
                    unit_name: item.unit_name,
                    conversion_factor:item.conversion_factor
                }
                return data;
            }
            else {
                let data = {
                    id: item.id,
                    product_name: item.product_name,
                    quantity:item.quantity === '' || item.quantity === null ?'':item.quantity,
                    unit_name: item.unit_name,
                    conversion_factor:item.conversion_factor
                }
                return data;
            }

        })
        let totalArray = ProdunctJson.map((item) => item.quantity)
        let result = totalArray.map((item) => item);
        var totalproductionValue = result.map(function (number) {
            return parseInt(number);
        });
        let totalproduction = totalproductionValue.reduce((partialSum, a) => partialSum + a, 0)
        let productUpdateData = {
            "created_at_month": createdMonth,
            "total_production": totalproduction,
            "product_monthly_status": currentMonthdata[0].monthly_json[0].product_monthly_status,
            "productJson": Result
        }
        addMonthlyproduct(productUpdateData)
        activeProduct()
        MonthlyEntryStartStatus();
    }
    //save Raw Material

    const saveRawmaterial = () => {
        RawmaterialIncompleteFunction();
        let RawMaterialRestructure = RawMaterialJson;
        let Result = RawMaterialRestructure.map((item) => {
            if (item.quantity === null || item.quantity === '') {
                let data = {
                    id: item.id,
                    raw_material: item.raw_material,
                    unit_name: item.unit_name,
                    conversion_factor:item.conversion_factor
                }
                return data;
            }
            else {
                let data = {
                    id: item.id,
                    raw_material: item.raw_material,
                    quantity:item.quantity === '' || item.quantity === null ?'':item.quantity,
                    unit_name: item.unit_name,
                    conversion_factor:item.conversion_factor
                }
                return data;
            }
        })
        let totalArray = RawMaterialJson.map((item) => item.quantity)
        let result = totalArray.map((item) => item);
        var totalMaterial_ConsumptionValue = result.map(function (number) {
            return parseInt(number);
        });
        let totalMaterial_Consumption = totalMaterial_ConsumptionValue.reduce((partialSum, a) => partialSum + a, 0)
        let materialUpdateData = {
            "created_at_month": createdMonth,
            "total_material_consumption": totalMaterial_Consumption,
            "material_monthly_status": currentMonthdata[0].monthly_json[0].material_monthly_status,
            "materialJson": Result
        }
        addRawMaterial(materialUpdateData)
        activeRawMaterial();
        if (CurrentStatus === 0) {
            MonthlyEntryStartStatus();
        }

    }

    //save Energy
    const saveEnergy = () => {
        EnergyIncompleteFunction();
        let BoilerJson = EnergyBoilerJson;
        let Result = BoilerJson.map((item) => {
            if (item.quantity === null || item.quantity === '') {
                let data = {
                    boiler_fuel_id: item.boiler_fuel_id,
                    boiler_fuel_name: item.boiler_fuel_name,
                    unit_name: item.unit_name,
                    conversion_factor:item.conversion_factor
                }
                return data;
            }
            else {
                let data = {
                    boiler_fuel_id: item.boiler_fuel_id,
                    boiler_fuel_name: item.boiler_fuel_name,
                    quantity:item.quantity === '' || item.quantity === null ?'':item.quantity,
                    unit_name: item.unit_name,
                    conversion_factor:item.conversion_factor
                }
                return data;
            }
        })
        // let result = totalArray.map((item) => item);
        // var totalMaterial_ConsumptionValue = result.map(function (number) {
        //     return parseInt(number);
        // });
        // let Energy_Consumption = totalMaterial_ConsumptionValue.reduce((partialSum, a) => partialSum + a, 0)
        // let totalEnergy = Energy_Consumption + gridElectricity + ElectricityRenewables + dieselDGset
        let boilderUpdateData = {
            "created_at_month": createdMonth,
            "grid_electricity": gridElectricity == '' ? null : gridElectricity,
            "electricity_from_renewables": ElectricityRenewables == '' ? null : ElectricityRenewables,
            "diesel_from_DG_set": dieselDGset == '' ? null : dieselDGset,
            "energy_consumption": 0,
            "share_of_energy_from_renewables": 0,
            "resource_monthly_status": currentMonthdata[0].monthly_json[0].resource_monthly_status,
            "boilerJson": Result
        }
        addBoilerFuiel(boilderUpdateData);
        activeEnergyModule();

        if (CurrentStatus === 0) {
            MonthlyEntryStartStatus();
        }
    }
    //save waste generated
    const saveWasteGenerated = () => {
        WasteIncompleteFunction();
        let WasteData = WasteGeneratedJson;
        let Result = WasteData.map((item) => {
            if (item.quantity === null || item.quantity === '') {
                let data = {
                    land_filling: item.land_filling === '' || item.land_filling === null ? '' : item.land_filling,
                    open_burning: item.open_burning === '' || item.open_burning === null ? '' : item.open_burning,
                    recycling: item.recycling === '' || item.recycling === null ? '' : item.recycling,
                    unit_name: item.unit_name,
                    waste_id: item.waste_id,
                    waste_name: item.waste_name
                }
                return data;
            }
            else {
                let data = {
                    land_filling: item.land_filling === '' || item.land_filling === null ? '' : item.land_filling,
                    open_burning: item.open_burning === '' || item.open_burning === null ? '' : item.open_burning,
                    quantity: item.quantity,
                    recycling: item.recycling === '' || item.recycling === null ? '' : item.recycling,
                    unit_name: item.unit_name,
                    waste_id: item.waste_id,
                    waste_name: item.waste_name
                }
                return data;
            }
        })
        let wasteUpdateData = {
            "created_at_month": createdMonth,
            "total_revenue_generated_from_waste": 0,
            "total_disposal_cost": totalDisposalCost == '' ? null : totalDisposalCost,
            "total_waste_generated": 0,
            "specific_waste_generation": 0,
            "net_revenue_from_waste_generation": netWasteRevenue == '' ? null : netWasteRevenue,
            "waste_monthly_status": currentMonthdata[0].monthly_json[0].waste_monthly_status,
            "wasteJson": Result
        }
        addMonthlyWasteGenerated(wasteUpdateData)
        wasteGeneratedComplete();
        if (CurrentStatus === 0) {
            MonthlyEntryStartStatus();
        }
    }
    //save current comment
    const saveComment = () => {
        if (!monthlyComments) {
            toast.error(t("monthlylogpage.commentValidation"))
        }
        else {
            let commentUpdateData = {
                "created_at_month": createdMonth,
                "comment": monthlyComments
            }
            addMonthlycommentFunc(commentUpdateData)
            setShow(false)
            if (CurrentStatus === 0) {
                MonthlyEntryStartStatus();
            }
        }
    }
    //Monthly comments
    const MonthlyCommentInputChange = (e) => {
        const { value } = e.target;
        if (value.length <= 200) {
            setMonthlyComments(value)
            let ReplaceComment = AllData.length > 0 && ReplaceMonthlyComment(AllData, params, value)
            localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceComment))
        }
    }
    //active product section
    const activeProduct = () => {
        let active = 1;
        let ReplaceData = AllData.length > 0 && ReplaceProductActive(AllData, params, active)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceData));
        setTimeout(() => {
            rawRef != null && rawRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', top: '10' })
        }, 100)
    }
    //active raw Material section
    const activeRawMaterial = () => {
        let active = 1;
        let ReplaceData = AllData.length > 0 && ReplaceRawMaterialActive(AllData, params, active)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceData));
        setTimeout(() => {
            EnergyRef != null && EnergyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', top: '10' })
        }, 100);

        // if (RawMaterialJson.length === 0) {
        //     let ReplaceData = AllData.length > 0 && ReplaceEnergyActive(AllData, params, active)
        //     localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceData));
        // }

    }
    const activeEnergyModule = () => {
        let active = 1;
        let ReplaceData = AllData.length > 0 && ReplaceEnergyActive(AllData, params, active)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceData));
        setTimeout(() => {
            WasteRef != null && WasteRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', top: '10' })
        }, 100)
    }

    // const wasteActiveModule = () => {
    //     let active = 1;
    //     let ReplaceData = AllData.length > 0 && ReplaceWasteActive(AllData, params, active)
    //     localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceData));
    //     if(EnergyBoilerJson.length > 0){
    //         setTimeout(() => {
    //             WasteRef != null && WasteRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', top: '10' })
    //         }, 100);
    //     }
    // }
    const wasteGeneratedComplete = () => {
        let active = 1;
        let ReplaceData = AllData.length > 0 && ReplaceWasteCompleted(AllData, params, active)
        localStorage.setItem("monthlyEntryData", JSON.stringify(ReplaceData));
    }
    //next button
    const [currentIdx, setCurrentIdx] = useState(0);
    const confromMonthNext = () => {
        if (ProductIncomplete == 1 && RawMaterialIncomplete == 1 && EnergyIncomplete == 1 && WasteIncomplete == 1) {
            setConformPopup(false);
            nextMonth();
        }
        else {
            setConformPopup(true);
        }
    }
    const confromPrevMonth = () => {
        if (ProductIncomplete == 1 && RawMaterialIncomplete == 1 && EnergyIncomplete == 1 && WasteIncomplete == 1) {
            SetConformPrevPopup(false);
            prevMonth();
        }
        else {
            SetConformPrevPopup(true);
        }
    }
    const calenderNavigationFunction = () => {
        if (ProductIncomplete == 1 && RawMaterialIncomplete == 1 && EnergyIncomplete == 1 && WasteIncomplete == 1) {
            setTimeout(() => {
                navigate("/monthlyentry/monthlyentry-calendar")
            }, 10)
            // setCalenderConformPopup(false);
        }
        else {
            setCalenderConformPopup(true);
        }
    }

    const confromMonthNextStatus = (status) => {
        if (status === 'yes') {
            nextMonth();
            setConformPopup(false)
        }
        else {
            setConformPopup(false)
        }
    }
    const confromMonthPrevStatus = (status) => {
        if (status === 'yes') {
            prevMonth();
            SetConformPrevPopup(false)
        }
        else {
            SetConformPrevPopup(false)
        }
    }

    const calenderNavigationStatus = (status) => {
        if (status === 'yes') {
            navigate("/monthlyentry/monthlyentry-calendar")
            setCalenderConformPopup(false)
        }
        else {
            setCalenderConformPopup(false)
        }
    }
    const nextMonth = () => {
        setProductIncomplete('')
        setRawMaterialIncomplete('')
        setEnergyIncomplete('')
        setWasteIncomplete('')
        let nextIndex = currentIdx + 1
        let MonthCollection = currentYear[0].monthlyentry;
        if (MonthCollection.length > nextIndex) {
            let filterNextValue = MonthCollection.filter((i, index) => index == nextIndex);
            let month = filterNextValue[0].month;
            navigate(`/monthlylog/${params.year}/${month}`)
            setCurrentIdx(nextIndex)

            setTimeout(() => {
                TopRef != null && TopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', top: '10' })
            }, 300)
        }
        if (MonthCollection.length === nextIndex) {
            let filterNextValue = MonthCollection.filter((i, index) => index == 0);
            let month = filterNextValue[0].month;
            navigate(`/monthlylog/${params.year}/${month}`)
            setCurrentIdx(0)
        }
    }
    //Prev button
    const prevMonth = () => {
        setProductIncomplete('')
        setRawMaterialIncomplete('')
        setEnergyIncomplete('')
        setWasteIncomplete('')
        let prevIndex = currentIdx - 1
        let MonthCollection = currentYear[0].monthlyentry
        if (prevIndex > -1) {
            let filterNextValue = MonthCollection.filter((i, index) => index == prevIndex);
            let month = filterNextValue[0].month;
            navigate(`/monthlylog/${params.year}/${month}`)
            setCurrentIdx(prevIndex)
            setTimeout(() => {
                TopRef != null && TopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', top: '10' })
            }, 100)
        }

    }
    const getCurrentIndex = () => {
        let MonthCollection = currentYear != false && currentYear[0].monthlyentry
        MonthCollection != false && MonthCollection.filter((item, index) => {
            if (item.month == params.month) {
                setCurrentIdx(index)
            }
        })
    }
    //comment popup close
    const handleClose = () => {
        setShow(false);
    }
    //initial active deactive modules
    const InitialActiveDeactiveModule = () => {
        let currentMonthResult = currentMonthdata.length > 0 && currentMonthdata[0].monthly_json;
        setCompleteProductSection(currentMonthResult.length > 0 && currentMonthResult[0].product_active)
        setCompleteRawMaterialSection(currentMonthResult.length > 0 && currentMonthResult[0].material_active)
        setCompleteEnerySection(currentMonthResult.length > 0 && currentMonthResult[0].resource_active)
        setCompleteWasteGenerated(currentMonthResult.length > 0 && currentMonthResult[0].waste_active)
    }
    //montly entry started status
    const MonthlyEntryStartStatus = () => {
        let status = 1;
        let MonthlyStartStatus = AllData.length > 0 && ReplaceStartStatus(AllData, params, status)
        localStorage.setItem("monthlyEntryData", JSON.stringify(MonthlyStartStatus))
    }
    useEffect(() => {
        getCurrentIndex();
        InitialActiveDeactiveModule();
        if (completeProductSection === 1 && ProductIncomplete === '') {
            ProductInCompleteFunction();
        }
        else if (completeRawMaterial === 1 && RawMaterialIncomplete === '') {
            RawmaterialIncompleteFunction();
        }
        else if (completeEnerySection === 1 && EnergyIncomplete === '') {
            EnergyIncompleteFunction();
        }
        else if (completeWasteGenerated === 1 && WasteIncomplete === '') {
            WasteIncompleteFunction()
        }
        if (paramsValue.month) {
            if (currentMonthdata.length > 0) {
                setProductJson(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].productJson)
                setRawmaterilaJson(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].materialJson)
                setCompleteProductSection(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].product_active);
                setCompleteRawMaterialSection(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].material_active)
                setEnergyBoilerJson(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].boilerJson)
                setGridElectricity(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].grid_electricity)
                setElectricityRenewables(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].electricity_from_renewables)
                setDieselDGset(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].diesel_from_DG_set)
                setWasteGeneratedJson(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].wasteJson)
                setMonthlyComments(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].comment)
                setNetWasteRevenue(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].net_revenue_from_waste_generation)
                setTotalDisposalCost(currentMonthdata.length > 0 && currentMonthdata[0].monthly_json[0].total_disposal_cost)
            }
        }
        if (currentMonthdata === false || currentMonthdata.length === 0) {
            toast.error(langId === '1'?"No year and month found!":"နှစ် နှင့် လ ဖြည့်သွင်းထားခြင်း မရှိပါ!")
            navigate("/monthlyentry/monthlyentry-calendar")
        }
        setParams(paramsValue)
    }, [ProdunctJson, paramsValue, currentMonthdata, RawMaterialJson, proStatus, EnergyBoilerJson, WasteGeneratedJson])
    return (
        <div className={'mainWrapper ' + style.monthlyLog} style={{ paddingTop: window.innerWidth < 768 && "70px", position: 'relative' }}>
            <div className="container monthlylogSetup">
                <div className="sec_0">
                    <div className={"row" + " " + style.progressBgset}>
                        <div className="col-md-3 d-none d-md-block">
                            <Link>
                                <button
                                    className={style.caleButton}
                                    style={{ textTransform: "uppercase" }}
                                    onClick={calenderNavigationFunction}
                                >
                                    {params.month.substring(0, 3)}<span>{params.year}</span>
                                </button></Link>
                        </div>
                        <div className="col-md-5 mb-md-0" style={{ marginBottom: window.innerWidth < 768 ? "38px" : "60px" }}>
                            <ProgressBar
                                completeProductSection={completeProductSection}
                                completeRawMaterial={completeRawMaterial}
                                completeEnerySection={completeEnerySection}
                                productBlankValue={ProdunctJson}
                                rawMaterialBlankValue={RawMaterialJson}
                                completeWasteGenerated={completeWasteGenerated}
                                EnergyBoilerJson={EnergyBoilerJson}
                            />
                        </div>
                        <div className="col-6 d-block d-md-none">
                            <Link><button
                                className={style.caleButton}
                                style={{ textTransform: "uppercase" }}
                                onClick={calenderNavigationFunction}
                            >{params.month.substring(0, 3)}<span>{params.year}</span></button></Link>
                        </div>
                        <div className="col-6 col-md-3 text-end" style={{ position: "relative" }}>
                            {/* <EastIcon/> */}
                            <div style={{
                                position: "absolute", left: window.innerWidth > 768 ? "25%" : "unset",
                                bottom: window.innerWidth < 768 ? "-76px" : "unset",
                                right: window.innerWidth < 768 ? "50px" : "unset"
                            }}>
                                <div className='tooltipwrapper' style={{ zIndex: HelpOpen === true ? "100" : "1" }}>
                                    {window.innerWidth > 768 && <button onClick={helpPopup} className="toolTip tooltipDashBoard" >
                                        <HelpCenterIcon 
                                            style={{ color: HelpOpen === true && "#138C50"}} 
                                        />
                                    </button>}
                                    {HelpOpen && <div className="backShadowSetup" onClick={() => setHelpOpen(false)}></div>}
                                    {HelpOpen && (
                                        <div>
                                            <div className='tooltipShow tooltipMonthllog' style={{ lineHeight: langId === "1" ? "20px" : "unset",right:"0px" }}>
                                                {langId === '1' ? <><h5>TOOLTIPS</h5>

                                                    <div className='fontSizeToltipSetupDesc'>
                                                        <p style={{ marginTop: "10px" }}>
                                                            <EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />Start logging in the required quantities from the first sections - "Products".
                                                        </p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} /> Any tabs that were skipped in the <b>Set up</b> stage will not appear here. </p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} /><b>Save</b> every single time after logging in the required quantities for each section. Else, the values won't be saved to the system. More sections will be revealed as you start saving for each section.</p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />Track your progress using the <b>progress bar</b> at the top.</p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />After reaching the end of a monthly log, you can go back by clicking on the <b>Month</b> button at the top left corner. </p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />You can also cycle between different months using the <b>Last/Next Month</b> buttons at the bottom.</p>
                                                    </div>
                                                    <hr style={{ width: "14px", borderTop: "2px solid #383838", margin: "24px 0px" }}></hr>
                                                    <p><span>NOTE:</span>Use the <b>Add comment</b> button at the top right corner to add any comments for this particular month. Comments have a word-limit of 10 and appear in the output report generated.</p>
                                                </> : <><h5>ကိရိယာ အသုံးပြုနည်းများ</h5>
                                                    <div className='fontSizeToltipSetupDesc'>
                                                        <p style={{ marginTop: "10px" }}>
                                                            <EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />လိုအပ်သော ပမာဏများကို စတင်ဝင်ရောက်ရန် ပထမကဏ္ဍမျာတွင် - "ထုတ်ကုန်များ" ကိုရွေးချယ်ပါ။
                                                        </p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} /><b>Set up</b> တည်ဆောက်ခြင်း အဆင့်တွင် ကျော်သွားသော မည်သည့် tab မဆို ဤနေရာတွင် ပေါ်လာမည်မဟုတ်ပါ။</p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />"အပိုင်းတစ်ခုစီအတွက် လိုအပ်သော ပမာဏများကို မှတ်တမ်းသွင်းပြီးနောက် အချိန်တိုင်း သိမ်းဆည်းပါ။
                                                            မဟုတ်ပါက တန်ဖိုးများကို စနစ်တွင် သိမ်းဆည်းမည်မဟုတ်ပါ။
                                                            ကဏ္ဍတစ်ခုစီအတွက် သင်စတင်သိမ်းဆည်းစဉ်တွင် နောက်ထပ်အပိုင်းများကို ထုတ်ဖော်ပါမည်။"
                                                        </p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />ထိပ်ဆုံးနေရာတွင်ရှိသော တိုးတက်မှုတိုင်းတာသည့် <b>ဇယားကို အသုံးပြု၍ </b> သင်၏တိုးတက်မှုကို ခြေရာခံတိုင်းတာနိုင်ပါသည်။</p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />လစဉ်မှတ်တမ်းတစ်ခု၏အဆုံးသို့ရောက်ရှိပြီးနောက်၊ ဘယ်ဘက်အပေါ်ထောင့်ရှိ လ <b>Month</b> ခလုတ်ကိုနှိပ်ခြင်းဖြင့် ပြန်သွားနိုင်ပါသည်။</p>
                                                        <p><EastIcon style={{ fontSize: "15px", marginRight: "3px" }} />အောက်ခြေရှိ <b>နောက်ဆုံး/နောက်လ</b> ခလုတ်များကို အသုံးပြု၍ မတူညီသောလများကြားတွင်လည်း လည်ပတ်နိုင်သည်။</p>
                                                    </div>
                                                    <hr style={{ width: "14px", borderTop: "2px solid #383838", margin: "24px 0px" }}></hr>
                                                    <p><span>မှတ်ချက်: </span>ဤလအတွက် မည်သည့်မှတ်ချက်မဆို ထည့်ရန် ညာဘက်အပေါ်ထောင့်ရှိ <b>မှတ်ချက်ထည့်ရန်</b> ခလုတ်ကို အသုံးပြုပါ။
                                                        မှတ်ချက်များသည် <b>စကားလုံးကန့်သတ်ချက် 10</b> ရှိပြီး ရလဒ်အစီရင်ခံစာတွင် ပေါ်လာသည်။</p>
                                                </>}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button
                                className={style.commButton}
                                onClick={() => setShow(true)}
                            ><img src={Addcomment} alt="REMS" /><span style={{ fontSize: langId === '1' ? "12px" : "11px" }}>{t("monthlylogpage.AddYourCommentButton")}</span></button>
                        </div>
                    </div>
                </div>
                <div className={style.monthlyScroll} style={{ position: "relative" }}>
                    {window.innerWidth < 768 && <button onClick={helpPopup} className="toolTip tooltipDashBoard"
                        style={{ position: "absolute", top: "10px", right: "0px", zIndex: "999" }}
                    >
                        <HelpCenterIcon style={{ color: HelpOpen == true && "#138C50"}} />
                    </button>}
                    {ProdunctJson.length > 0 ? <div ref={TopRef}>
                        <CurrentProductList
                            ProdunctJson={ProdunctJson}
                            productInputChange={productInputChange}
                            saveProduct={saveProduct}
                            completeProductSection={completeProductSection}
                            ProductIncomplete={ProductIncomplete}
                        /> </div> : <div ref={TopRef}><BlankProducts /></div>}

                    {completeProductSection === 1 ? <div>{RawMaterialJson.length > 0 ?
                        <div
                            ref={rawRef}
                        >
                            <RawMaterials
                                materialJson={RawMaterialJson}
                                RawMaterialInputChange={MaterialInputChange}
                                saveRawmaterial={saveRawmaterial}
                                completeRawMaterials={completeRawMaterial}
                                RawMaterialIncomplete={RawMaterialIncomplete}
                            /></div> : <div ref={rawRef}><BlankRawMaterials /></div>}
                    </div> : RawMaterialJson.length === 0 ? <div ref={rawRef}><BlankRawMaterials /></div> : null}
                    {completeRawMaterial === 1 && completeProductSection === 1 ?
                        <div ref={EnergyRef}>
                            <Energy
                                BoilerJsonData={EnergyBoilerJson}
                                boilerInputChange={BoilerInputChange}
                                otherEnergyInputChange={OtherEnergyInputChange}
                                gridElectricity={gridElectricity}
                                ElectricityRenewables={ElectricityRenewables}
                                dieselDGset={dieselDGset}
                                saveEnergy={saveEnergy}
                                completeEnerySection={completeEnerySection}
                                EnergyIncomplete={EnergyIncomplete}
                                completeWasteGenerated={completeWasteGenerated}
                            /> </div> : null}

                    {completeEnerySection === 1 ?
                        <div ref={WasteRef}>
                            <WasteGenerated
                                WasteGeneratedJson={WasteGeneratedJson}
                                wasteGeneratedInputChange={wasteGeneratedInputChange}
                                saveWasteGenerated={saveWasteGenerated}
                                otherWasteGeneratedInput={otherWasteGeneratedInputChange}
                                netWasteRevenue={netWasteRevenue}
                                totalDisposalCost={totalDisposalCost}
                                completeWasteGenerated={completeWasteGenerated}
                                WasteIncomplete={WasteIncomplete}
                            />
                        </div> : null}
                </div>
                <div className={style.section + ' ' + style.sec_4 + ' ' + style.nextPrevPos}>
                    <div className="row">
                        <div className="col-6 text-start">
                            <button
                                className={"btn btn-light" + ' ' + style.prev}
                                type="button"
                                onClick={confromPrevMonth}
                                disabled={currentIdx == 0 ? true : false}
                            >
                                <img src={Prev} alt="REMS" />
                                {t("monthlylogpage.LastMonthButton")}</button>
                        </div>
                        <div className="col-6 text-end">
                            <button
                                className={"btn btn-light " + style.next}
                                style={{ backgroundColor: "#F2F2F2" }}
                                disabled={MonthCollectionSet <= 1 ? true : false}
                                type="button"
                                onClick={confromMonthNext}
                            >{t("monthlylogpage.NextMonthButton")}<img src={Next} alt="REMS" /></button>
                        </div>
                    </div>
                </div>

            </div>
            <Modal className="modalStyle commentPopup" show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton><h2>{t("monthlylogpage.CommnetPopUpheader")}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="row" style={{ position: "relative" }}>

                                <textarea
                                    name="conversionFactor"
                                    className="form-control commetTextarea"
                                    rows="4"
                                    cols="50"
                                    placeholder={t("monthlylogpage.commentPlaceHolder")}
                                    value={monthlyComments == null ? '' : monthlyComments}
                                    onChange={(e) => MonthlyCommentInputChange(e)}
                                    style={{
                                        fontSize: "11px",
                                        paddingBottom: "4px",
                                        backgroundColor: "#F2F2F2",
                                        border: "none",
                                        height: "115px"
                                    }}
                                    onBlur={() => setClickBlur(true)}
                                    onClick={() => setClickBlur(false)}
                                />
                                {monthlyComments === ''||monthlyComments === null || clickBlur === true ? <div
                                    style={{
                                        position: "absolute",
                                        bottom: "0px",
                                        fontSize: "11px",
                                        paddingBottom: "8px",
                                        fontWeight: 600,
                                        left: "-3px",
                                        color: "#383838"
                                    }}
                                > {t("monthlylogpage.clickTostart")}</div>:null}

                            </div>
                            <div style={{ fontSize: "12px", marginTop: "3px",position:"relative",right:"2px" }}>{t("monthlylogpage.wordLimit")} {monthlyComments != null ? <b>{200 - monthlyComments.length}</b> : <b>200</b>}</div>
                        </div>
                        <div className="col-md-12 text-center">
                            <button
                                className="btn_primary"
                                type="button"
                                onClick={saveComment}
                            >{t("monthlylogpage.saveComment")}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modalStyle conformPopup" show={conformPopup} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header style={{ padding: "26px 24px 20px 24px" }}><h2 style={{ textAlign: "center" }}>{t("monthlylogpage.nextMonthPopHeader")}</h2></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {langId === '1' ? <>
                            <div style={{ textAlign: "center", fontSize: "13px", marginBottom: "10px" }}><span style={{ color: "#138c50", fontWeight: "500" }}>{t("generalText.NOTE")}</span>  You still have incomplete fields and/or sections that have not been attempted, for this month.
                                <p>You can still come back anytime and complete your entry. This month will be marked as <span style={{ color: "red" }}>"incomplete"</span> for your reference.</p></div>
                        </> :
                            <>
                                <div style={{ textAlign: "center", fontSize: "13px", marginBottom: "10px" }}><span style={{ color: "#138c50", fontWeight: "500" }}>{t("generalText.NOTE")}</span> ယခုလအတွက် မဖြည့်စွက်ရသေးသော ‌နေရာများနှင့်/သို့မဟုတ် ကဏ္ဍများ သင့်တွင် မပြည့်စုံသေးပါက
                                    သင် အချိန်မရွေးပြန်လာပြီး သင်၏ဝင်ရောက်မှုကို အပြီးသတ်နိုင်ပါသည်။သင့်အညွှန်းတွင် <span style={{ color: "red" }}>"မပြည့်စုံပါ"</span> ဟု မှတ်ချက်ရေးထားနိုင်ပါသည်။"</div>
                            </>}
                        <div className="text-center">
                            <div className={style.conformNextBtn}>
                                <button
                                    className="btn_primary"
                                    type="button"
                                    onClick={() => confromMonthNextStatus('yes')}
                                >{t("generalText.yes")}</button>
                                <button
                                    style={{ width: "45%" }}
                                    className="btn btn-light"
                                    type="button"
                                    onClick={() => confromMonthNextStatus('no')}
                                >{t("generalText.no")}</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modalStyle conformPopup" show={conformPrevPopup} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header style={{ padding: "26px 24px 20px 24px" }}><h2 style={{ textAlign: "center" }}>{t("monthlylogpage.PrevMonthPopHeader")}</h2></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {langId === '1' ? <>
                            <div style={{ textAlign: "center", fontSize: "13px", marginBottom: "10px" }}><span style={{ color: "#138c50", fontWeight: "500" }}>{t("generalText.NOTE")}</span>  You still have incomplete fields and/or sections that have not been attempted, for this month.
                                <p>You can still come back anytime and complete your entry. This month will be marked as <span style={{ color: "red" }}>"incomplete"</span> for your reference.</p></div>
                        </> :
                            <>
                                <div style={{ textAlign: "center", fontSize: "13px", marginBottom: "10px" }}><span style={{ color: "#138c50", fontWeight: "500" }}>{t("generalText.NOTE")}</span> ယခုလအတွက် မဖြည့်စွက်ရသေးသော ‌နေရာများနှင့်/သို့မဟုတ် ကဏ္ဍများ သင့်တွင် မပြည့်စုံသေးပါက
                                    သင် အချိန်မရွေးပြန်လာပြီး သင်၏ဝင်ရောက်မှုကို အပြီးသတ်နိုင်ပါသည်။သင့်အညွှန်းတွင် <span style={{ color: "red" }}>"မပြည့်စုံပါ"</span> ဟု မှတ်ချက်ရေးထားနိုင်ပါသည်။"</div>
                            </>}
                        <div className="text-center">
                            <div className={style.conformNextBtn}>
                                <button
                                    className="btn_primary"
                                    type="button"
                                    onClick={() => confromMonthPrevStatus('yes')}
                                >Yes</button>
                                <button
                                    style={{ width: "45%" }}
                                    className="btn btn-light"
                                    type="button"
                                    onClick={() => confromMonthPrevStatus('no')}
                                >No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modalStyle conformPopup" show={CalenderConformPopup} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header style={{ padding: "26px 24px 20px 24px" }}><h2 style={{ textAlign: "center" }}>{t("monthlylogpage.gobackCalenderHeader")}</h2></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {langId === '1' ? <>
                            <div style={{ textAlign: "center", fontSize: "13px", marginBottom: "10px" }}><span style={{ color: "#138c50", fontWeight: "500" }}>{t("generalText.NOTE")}</span>  You still have incomplete fields and/or sections that have not been attempted, for this month.
                                <p>You can still come back anytime and complete your entry. This month will be marked as <span style={{ color: "red" }}>"incomplete"</span> for your reference.</p></div>
                        </> :
                            <>
                                <div style={{ textAlign: "center", fontSize: "13px", marginBottom: "10px" }}><span style={{ color: "#138c50", fontWeight: "500" }}>{t("generalText.NOTE")}</span> ယခုလအတွက် မဖြည့်စွက်ရသေးသော ‌နေရာများနှင့်/သို့မဟုတ် ကဏ္ဍများ သင့်တွင် မပြည့်စုံသေးပါက
                                    သင် အချိန်မရွေးပြန်လာပြီး သင်၏ဝင်ရောက်မှုကို အပြီးသတ်နိုင်ပါသည်။သင့်အညွှန်းတွင် <span style={{ color: "red" }}>"မပြည့်စုံပါ"</span> ဟု မှတ်ချက်ရေးထားနိုင်ပါသည်။"</div>
                            </>}
                        <div className="text-center">
                            <div className={style.conformNextBtn}>
                                <button
                                    className="btn_primary"
                                    type="button"
                                    onClick={() => calenderNavigationStatus('yes')}
                                >{t("generalText.yes")}</button>
                                <button
                                    style={{ width: "45%" }}
                                    className="btn btn-light"
                                    type="button"
                                    onClick={() => calenderNavigationStatus('no')}
                                >{t("generalText.no")}</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="modalStyle conformPerPopup" show={percentageError} onHide={() => setPercentageError(false)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div>
                        <b>{t("generalText.NOTE")}</b><span> {t("monthlylogpage.Percentage")}</span>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default MonthlyLog;