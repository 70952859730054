import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckboxComponent from './Checkbox';
import SelectRows from './SelectRows';
import Input from './Input';
import { useMutation } from 'react-query';
import {
	generateOutput,
	getInputLog,
} from '../../../services/FrontendDataServices';
import LoadingDialog from './LoadingDialog';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Pdf from '../pdf/Pdf';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { outputHelperObj } from './util';
import './style.css';
import closeBlack from '../../../assets/frontend/images/close-black.png';
import closeRed from '../../../assets/frontend/images/close-red.png';

const CustomizeOutputModal = ({ setOldOutput, setSelectedGraphs }) => {
	let langId = localStorage.getItem('lanId');

	const isMobile = useMediaQuery('(max-width:600px)');
	const [open, setOpen] = useState(false);
	const [singleTimeline, setSingleTimeline] = useState(false);
	const [companyName, setCompanyName] = useState('');
	// setStartYear(startingTimline[0]?.start_year);
	// setStartMonth(capitalizeString(startingTimline[0]?.start_month));

	const [apiTimeline, setApiTimeline] = useState({
		start_date_waste: '2023-06-01 00:00:00',
		end_date_waste: '2023-06-01 00:00:00',
		start_date_energy: '2023-06-01 00:00:00',
		end_date_energy: '2023-06-01 00:00:00',
		start_date_material: '2023-06-01 00:00:00',
		end_date_material: '2023-08-01 00:00:00',
		start_date_revenue: '2023-06-01 00:00:00',
		end_date_revenue: '2023-06-01 00:00:00',
		start_date_full_report: '2023-06-01 00:00:00',
		'end_date_full_report ': '2023-06-01 00:00:00',
		start_date_input_log: '2023-06-01 00:00:00',
		end_date_input_log: '2023-06-01 00:00:00',
		company_name: companyName,
	});
	const { t } = useTranslation();

	const [specificWaste, setSpecificWaste] = useState(true);
	const [netRevenue, setNetRevenue] = useState(true);
	const [specificEnergy, setSpecificEnergy] = useState(true);
	const [specificMaterial, setSpecificMaterial] = useState(true);
	const [fullReport, setFullReport] = useState(true);
	const [inputLog, setInputLog] = useState(true);
	const [loadingOpen, setLoadingOpen] = useState(false);
	const [closeButtonState, setCloseButtonState] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('selectedTimeline')) {
			localStorage.setItem('selectedTimeline', JSON.stringify(apiTimeline));
		}
	}, [apiTimeline]);

	const { mutate: inputLogMutation } = useMutation('inputLog', getInputLog, {
		onSuccess: (data) => {
			localStorage.setItem('InputLogData', JSON.stringify(data?.data));
		},
		onError: (error) => {
			toast.error('There was an error fetching the input log.');
		},
	});

	const { isLoading, mutate, onSuccess } = useMutation(
		'generateOutput',
		generateOutput,
		{
			onSuccess: (data) => {
				// Create the modifiedObject with processed data for all keys

				const modifiedObject = {
					specific_waste_generation: processData(
						'specific_waste_generation',
						'total_waste_generated',
						data?.data[0]
					),
					specific_energy_generation: processData(
						'specific_energy_generation',
						'energy_consumption',
						data?.data[0]
					),
					specific_material_consumption: processData(
						'specific_material_consumption',
						'total_material_consumption',
						data?.data[0]
					),
					specific_revenue_generation: processData(
						'specific_revenue_generation',
						'net_revenue_from_waste_generation',
						data?.data[0]
					),
					share_of_waste_being_recycled:
						data?.data[1][0]?.share_of_waste_being_recycled * 100,
					share_of_energy_from_renewables:
						data?.data[2][0]?.share_of_energy_from_renewables * 100,

					comments: processApiResponse(data?.data[0][0]?.MONTHLY_COMMENT),
				};
				setOldOutput(modifiedObject);

				localStorage.setItem('pdfOutput', JSON.stringify(modifiedObject));
			},
			onError: (error) => {
				console.log(error);

				toast.error('Something went wrong. Please try again.');
			},
		}
	);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	function isValidDateRange(startDate, endDate) {
		const start = new Date(startDate);
		const end = new Date(endDate);

		// Calculate the difference in months
		const diffInMonths =
			(end.getFullYear() - start.getFullYear()) * 12 +
			(end.getMonth() - start.getMonth());

		// Check if the difference is at least three months
		return diffInMonths >= 2;
	}

	function validateDateRanges(inputData) {
		for (let key in inputData) {
			if (inputData.hasOwnProperty(key) && key.includes('start_date_')) {
				const startKey = key;
				let endKey = key.replace('start_date_', 'end_date_');

				// Skip the company_name parameter
				if (startKey === 'start_date_company_name') {
					continue;
				}
				if (endKey == 'end_date_full_report') {
					endKey = 'end_date_full_report ';
				}

				const startDate = inputData[startKey];
				const endDate = inputData[endKey];
				if (
					JSON.parse(localStorage.getItem('selectedGraphs'))[
						outputHelperObj[startKey]
					]
				) {
					if (!isValidDateRange(startDate, endDate)) {
						return false; // If any date range is invalid, stop and return false
					}
				}
			}
		}

		return true; // All date ranges are valid
	}

	function getFormattedDateTime() {
		const now = new Date();
		const day = now.getDate();
		const month = now.getMonth() + 1; // Months are zero-based, so add 1
		const year = now.getFullYear();
		let hours = now.getHours();
		const minutes = now.getMinutes();
		let ampm = 'AM'; // Default to AM

		// Determine AM or PM
		if (hours >= 12) {
			ampm = 'PM';
			if (hours > 12) {
				hours -= 12;
			}
		}

		// Ensure 2-digit formatting for day, month, hours, and minutes
		const formattedDay = day.toString().padStart(2, '0');
		const formattedMonth = month.toString().padStart(2, '0');
		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');

		// Combine the formatted parts into the desired format
		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;
		const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

		return `${formattedDate} ${formattedTime}`;
	}

	const handleGenerateOutput = () => {
		localStorage.setItem(
			'lastOutputDate',
			getFormattedDateTime()?.split(' ')[0]
		);
		localStorage.setItem(
			'lastOutputTime',
			getFormattedDateTime()?.split(' ')[1] +
				' ' +
				getFormattedDateTime()?.split(' ')[2]
		);

		let apiTimeLineCopy = {};
		if (apiTimeline.end_date_input_log.includes('NaN')) {
			apiTimeLineCopy = {
				...apiTimeline,
				end_date_input_log: apiTimeline['end_date_full_report '],
			};
		} else {
			apiTimeLineCopy = { ...apiTimeline };
		}
		if (validateDateRanges(apiTimeLineCopy)) {
			setLoadingOpen(true);
			localStorage.setItem('selectedTimeline', JSON.stringify(apiTimeLineCopy));
			localStorage.setItem('companyName', companyName);

			setTimeout(() => {
				setLoadingOpen(false);
			}, 2000);
			mutate(apiTimeLineCopy);
			inputLogMutation({
				start_date_input_log: apiTimeLineCopy['start_date_input_log'],
				end_date_input_log: apiTimeLineCopy['end_date_input_log'],
			});
			setOpen(false);
		} else {
			toast.error(
				'Please make sure that the difference between the starting and ending month in timeline selection is at least three months.'
			);
		}
	};

	function isNullOrUndefined(value) {
		return value === null || value === undefined;
	}

	function sortMonthsAndValues(months, values) {
		const monthToNumber = {
			Jan: 0,
			Feb: 1,
			Mar: 2,
			Apr: 3,
			May: 4,
			Jun: 5,
			Jul: 6,
			Aug: 7,
			Sep: 8,
			Oct: 9,
			Nov: 10,
			Dec: 11,
		};

		// Create an array of objects with the original index, month, and value
		const monthValuePairs = months.map((month, index) => ({
			index,
			month,
			value: values[index],
		}));

		// Custom sorting function to compare the months and values
		function compareMonthsAndValues(a, b) {
			const [aMonth, aYear] = a.month.split(' ');
			const [bMonth, bYear] = b.month.split(' ');

			if (aYear !== bYear) {
				return aYear - bYear;
			} else if (monthToNumber[aMonth] !== monthToNumber[bMonth]) {
				return monthToNumber[aMonth] - monthToNumber[bMonth];
			} else {
				return a.index - b.index;
			}
		}

		// Sort the array of objects using the custom compare function
		monthValuePairs.sort(compareMonthsAndValues);

		// Extract the sorted months and values into separate arrays
		const sortedMonths = monthValuePairs.map((item) => item.month);
		const sortedValues = monthValuePairs.map((item) => item.value);

		return { sortedMonths, sortedValues };
	}
	// // Process data for specific keys
	// function processData(key, sub_key, inputJSON) {
	// 	const jsonData = JSON.parse(inputJSON[0][key]);
	// 	const uniqueMonths = [];
	// 	const dataset = [];
	// 	const existingMonths = {};

	// 	// Initialize existingMonths with the months present in jsonData
	// 	if (jsonData !== null) {
	// 		jsonData?.forEach((item) => {
	// 			if (!isNullOrUndefined(item.created_at_month)) {
	// 				const date = new Date(item.created_at_month);
	// 				const monthYear = date.toLocaleString('default', {
	// 					month: 'short',
	// 					year: '2-digit',
	// 				});
	// 				uniqueMonths.push(monthYear);
	// 				dataset.push(item[sub_key]);
	// 				existingMonths[monthYear] = true;
	// 			}
	// 		});
	// 	}

	// 	// Generate 60 months starting from the first month encountered in inputJSON
	// 	const firstMonth = uniqueMonths[0];
	// 	if (firstMonth) {
	// 		const [month, year] = firstMonth.split(' ');
	// 		let currentDate = new Date(`20${year}-${month}-01`);
	// 		for (let i = 0; i < 60; i++) {
	// 			const monthYear = currentDate.toLocaleString('default', {
	// 				month: 'short',
	// 				year: '2-digit',
	// 			});

	// 			// If the month is not in existingMonths, add it with a value of 0
	// 			if (!existingMonths[monthYear]) {
	// 				uniqueMonths.push(monthYear);
	// 				dataset.push(0);
	// 			}

	// 			// Increment the date by one month
	// 			currentDate.setMonth(currentDate.getMonth() + 1);
	// 		}
	// 	}

	// 	const { sortedMonths, sortedValues } = sortMonthsAndValues(
	// 		uniqueMonths,
	// 		dataset
	// 	);

	// 	return {
	// 		uniqueMonths: sortedMonths,
	// 		dataset: sortedValues,
	// 	};
	// }

	function processData(key, sub_key, inputJSON) {
		const jsonData = JSON.parse(inputJSON[0][key]);
		let uniqueMonths = [];
		let dataset = [];
		const existingMonths = {};

		// Initialize existingMonths with the months present in jsonData
		if (jsonData !== null) {
			jsonData.forEach((item) => {
				if (item.created_at_month) {
					const date = new Date(item.created_at_month);
					const monthYear = date.toLocaleString('default', {
						month: 'short',
						year: '2-digit',
					});
					uniqueMonths.push(monthYear);
					dataset.push(item[sub_key]);
					existingMonths[monthYear] = true;
				}
			});
		}

		// Sort and eliminate duplicates from uniqueMonths
		uniqueMonths = [
			...new Set(
				uniqueMonths.sort((a, b) => {
					return (
						new Date(`20${a.split(' ')[1]}-${a.split(' ')[0]}-01`) -
						new Date(`20${b.split(' ')[1]}-${b.split(' ')[0]}-01`)
					);
				})
			),
		];

		// Generate additional months to ensure at least 12 months of data
		if (uniqueMonths.length > 0) {
			const firstMonth = uniqueMonths[0];
			const [startMonth, startYear] = firstMonth.split(' ');
			let currentDate = new Date(`20${startYear}-${startMonth}-01`);

			for (let i = 0; i < 12; i++) {
				const monthYear = currentDate.toLocaleString('default', {
					month: 'short',
					year: '2-digit',
				});

				// Add additional months if they are not present in existingMonths
				if (!existingMonths[monthYear]) {
					uniqueMonths.push(monthYear);
					dataset.push(0);
				}

				// Increment the date by one month
				currentDate.setMonth(currentDate.getMonth() + 1);
			}
		}

		// Sort the arrays again to ensure chronological order
		const sortedData = uniqueMonths
			.map((monthYear, index) => ({
				monthYear,
				value: dataset[index] || 0,
			}))
			.sort((a, b) => {
				return (
					new Date(
						`20${a.monthYear.split(' ')[1]}-${a.monthYear.split(' ')[0]}-01`
					) -
					new Date(
						`20${b.monthYear.split(' ')[1]}-${b.monthYear.split(' ')[0]}-01`
					)
				);
			});

		return {
			uniqueMonths: sortedData.map((data) => data.monthYear),
			dataset: sortedData.map((data) => data.value),
		};
	}

	function processApiResponse(apiResponse) {
		// Check if the input is null or empty
		if (!apiResponse || apiResponse.trim() === '') {
			return {};
		}

		// Parse the JSON data in the API response
		const comments = JSON.parse(apiResponse);

		// Define a dictionary to store the results
		const result = {};

		// Iterate through the comments
		for (const commentInfo of comments) {
			const createdDate = new Date(commentInfo.created_at_month);
			const monthYear =
				createdDate.toLocaleString('default', { month: 'short' }) +
				' ' +
				createdDate.getFullYear().toString().slice(-2);
			const comment = commentInfo.comment;

			// Add the comment to the result dictionary using the formatted month and year as the key
			result[monthYear] = comment;
		}

		return result;
	}

	// Example usage:

	// const data = processApiResponse(apiResponse);

	return (
		<div>
			{' '}
			<Button
				variant="contained"
				style={{
					backgroundColor: '#138C50',
					textTransform: 'none',
					borderRadius: isMobile ? '4px' : '7px',
				}}
				sx={{
					px: isMobile ? 2 : 7,
					width: isMobile ? 'auto' : '350px',
					py: 1.3,
					'&:hover': {
						textDecoration: 'underline',
						opacity: 0.9,
					},
				}}
				onClick={handleClickOpen}
			>
				{t('output.GenerateOutputWithLatestData')}
			</Button>
			<Dialog
				maxWidth={isMobile ? '100%' : 'md'}
				fullWidth={true}
				width="100%"
				scroll="paper"
				open={open}
				onClose={handleClose}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<div style={{ height: '20px' }}></div>

				<DialogTitle
					id="alert-dialog-title"
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<h4
						style={{ color: '#383838', lineHeight: langId == 1 ? 'auto' : 1.3 }}
					>
						{t('output.CustomizeYourOutput')}
					</h4>
					<span
						style={{
							cursor: 'pointer',
							position: 'absolute',
							top: '-5px',
							right: '6px',
							fontSize: '23px',
						}}
						className="closeButton"
						onClick={handleClose}
						onMouseEnter={() => setCloseButtonState(true)}
						onMouseLeave={() => setCloseButtonState(false)}
					>
						<img
							src={
								isMobile ? closeBlack : closeButtonState ? closeRed : closeBlack
							}
							width="15px"
							height="15px"
						/>
					</span>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<p
							style={{
								color: '#383838',
								lineHeight: langId == 1 ? 'auto' : 2,
							}}
						>
							{t('output.SelectTheOutput')}
						</p>
						<SelectRows
							startKey="all"
							endKey="all"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={!singleTimeline}
						/>
						<h5
							style={{
								marginTop: isMobile ? '18px' : '10px',
								fontSize: isMobile ? '14px' : '18px',
								marginLeft: '2px',
								marginBottom: '10px',
								lineHeight: langId == 1 ? 'auto' : 2,
							}}
						>
							{t('output.CheckTheFollowing')}
						</h5>
						<CheckboxComponent
							checkStatus={singleTimeline}
							setCheckStatus={setSingleTimeline}
							label={t('output.IWantSeperate')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<div
							style={{
								width: '100px',
								height: '3px',
								margin: '20px 2px',
								borderRadius: '10px',
								backgroundColor: '#E0E0E0',
							}}
						></div>
						<h5
							style={{
								marginLeft: '2px',
								opacity: singleTimeline ? 1 : 0.3,
								fontSize: isMobile ? '14px' : '18px',
							}}
						>
							{t('output.NowYouCan')}
						</h5>
						<CheckboxComponent
							checkStatus={specificWaste}
							setCheckStatus={setSpecificWaste}
							label={t('output.SpecificWaste')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<SelectRows
							startKey="start_date_waste"
							endKey="end_date_waste"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={singleTimeline && specificWaste}
						/>
						<CheckboxComponent
							checkStatus={netRevenue}
							setCheckStatus={setNetRevenue}
							label={t('output.NetRevenue')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<SelectRows
							startKey="start_date_revenue"
							endKey="end_date_revenue"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={singleTimeline && netRevenue}
						/>
						<CheckboxComponent
							checkStatus={specificEnergy}
							setCheckStatus={setSpecificEnergy}
							label={t('output.SpecificEnergy')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<SelectRows
							startKey="start_date_energy"
							endKey="end_date_energy"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={singleTimeline && specificEnergy}
						/>
						<CheckboxComponent
							checkStatus={specificMaterial}
							setCheckStatus={setSpecificMaterial}
							label={t('output.SpecificMaterial')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<SelectRows
							startKey="start_date_material"
							endKey="end_date_material"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={singleTimeline && specificMaterial}
						/>
						<div
							style={{
								width: '65px',
								height: '5px',
								margin: '20px 2px',
								borderRadius: '10px',
								backgroundColor: '#E0E0E0',
							}}
						></div>
						<CheckboxComponent
							checkStatus={fullReport}
							setCheckStatus={setFullReport}
							label={t('output.FullReport(jpg)')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<SelectRows
							startKey="start_date_full_report"
							endKey="end_date_full_report"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={singleTimeline && fullReport}
						/>
						<CheckboxComponent
							checkStatus={inputLog}
							setCheckStatus={setInputLog}
							label={t('output.InputLog(xls)')}
							setSelectedGraphs={setSelectedGraphs}
						/>
						<SelectRows
							startKey="start_date_input_log"
							endKey="end_date_input_log"
							apiTimeline={apiTimeline}
							setApiTimeline={setApiTimeline}
							visibility={singleTimeline && inputLog}
						/>
						<div
							style={{
								width: '100px',
								height: '3px',
								margin: '20px 2px',
								borderRadius: '10px',
								backgroundColor: '#E0E0E0',
							}}
						></div>
						<h5 style={{ lineHeight: langId == 1 ? 'auto' : 2 }}>
							{t('output.CompanyName')}
						</h5>
						<p
							style={{
								paddingRight: '7.5px',
								lineHeight: langId == 1 ? 'auto' : 2,
							}}
						>
							{t('output.YouCanAlso')}
						</p>
						<Input name={companyName} setName={setCompanyName} />
					</DialogContentText>
					<Button
						variant="contained"
						style={{
							backgroundColor: '#138C50',
							textTransform: 'capitalize',
							fontWeight: '600',
							width: isMobile ? '98%' : 'auto',
						}}
						sx={{
							pl: isMobile ? 2 : 10.4,
							mt: 4,
							pr: isMobile ? 2 : 10.4,
							lineHeight: langId == 1 ? 'auto' : 2,
						}}
						onClick={handleGenerateOutput}
					>
						{t('output.GenerateMyOutput')}
					</Button>
					<div style={{ height: '100px' }}></div>
				</DialogContent>
			</Dialog>
			{loadingOpen && (
				<LoadingDialog
					loadingOpen={loadingOpen}
					setLoadingOpen={setLoadingOpen}
				/>
			)}
		</div>
	);
};

export default CustomizeOutputModal;
