import style from './style.module.css';
import {Link, useLocation} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

const StaticTab =()=>{
    const {t} = useTranslation();
    const location = useLocation();
    const currentPath = location.pathname;
    return(
        <div className="container">
            <div><Link to="/"><ArrowBackIcon className={style.arrowBack} style={{color:"#000"}}/></Link></div>
            <ul className={style.tabWrapper}>
                <li className={currentPath === '/about'?style.active:''}><Link to="/about">{t("landingpage.About")}</Link></li>
                <li className={currentPath === '/contact'?style.active:''}><Link to="/contact">{t("landingpage.ContactUsButton")}</Link></li>
                <li className={currentPath === '/help'?style.active:''}><Link to="/help">{t("landingpage.HelpText")}</Link></li>
                <li className={currentPath === '/privacy'?style.active:''}><Link to="/privacy">{t("landingpage.PrivacyText")}</Link></li>
                <li className={currentPath === '/terms'?style.active:''}><Link to="/terms">{t("landingpage.TermsText")}</Link></li>
            </ul>
        </div>
    )
}
export default StaticTab;