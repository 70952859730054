// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activeAccount_activateAccount__A1Zy5{
    position: relative;
    margin:0px 0;
}
.activeAccount_activateAccount__A1Zy5 p{
    font-size: 12px;
    margin: 0 0 5px 0;
    line-height: 19px;
}
.activeAccount_activateAccount__A1Zy5 p span{
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin-top: 4px;
}
.activeAccount_activateAccount__A1Zy5 img{
    width: 100px;
    max-width: 100%;
    margin: 30px 0;
}
.activeAccount_activateAccount__A1Zy5 h5{
    font-weight: 600;
    font-size: 17px;
    margin: 0;
}
.activeAccount_activateAccount__A1Zy5 h5 span{
    font-size: 12px;
    display: block;
    margin-top: 8px;
}
.activeAccount_separater__\\+k721{
    width: 100px;
    margin: 30px auto;
    background-color: #D9D9D9;
    height: 3px;
}
.activeAccount_reset__udf7G{
    color:#138C50 ;
    font-weight: 700;
    text-transform: uppercase; 
    text-decoration: none;
    border: none;
    background:none;
    outline: none;
}
.activeAccount_reset__udf7G:hover{
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/frontend/activeAccount/activeAccount.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB;AACA;IACI,YAAY;IACZ,eAAe;IACf,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,SAAS;AACb;AACA;IACI,eAAe;IACf,cAAc;IACd,eAAe;AACnB;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,aAAa;AACjB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".activateAccount{\r\n    position: relative;\r\n    margin:0px 0;\r\n}\r\n.activateAccount p{\r\n    font-size: 12px;\r\n    margin: 0 0 5px 0;\r\n    line-height: 19px;\r\n}\r\n.activateAccount p span{\r\n    font-size: 12px;\r\n    font-weight: 600;\r\n    display: block;\r\n    margin-top: 4px;\r\n}\r\n.activateAccount img{\r\n    width: 100px;\r\n    max-width: 100%;\r\n    margin: 30px 0;\r\n}\r\n.activateAccount h5{\r\n    font-weight: 600;\r\n    font-size: 17px;\r\n    margin: 0;\r\n}\r\n.activateAccount h5 span{\r\n    font-size: 12px;\r\n    display: block;\r\n    margin-top: 8px;\r\n}\r\n.separater{\r\n    width: 100px;\r\n    margin: 30px auto;\r\n    background-color: #D9D9D9;\r\n    height: 3px;\r\n}\r\n.reset{\r\n    color:#138C50 ;\r\n    font-weight: 700;\r\n    text-transform: uppercase; \r\n    text-decoration: none;\r\n    border: none;\r\n    background:none;\r\n    outline: none;\r\n}\r\n.reset:hover{\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activateAccount": `activeAccount_activateAccount__A1Zy5`,
	"separater": `activeAccount_separater__+k721`,
	"reset": `activeAccount_reset__udf7G`
};
export default ___CSS_LOADER_EXPORT___;
