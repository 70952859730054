import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Loader from './Loader';
import RequestError from './RequestError';
import { getTotalWasteGenerated } from '../../../services/AdminApis';
import LineChart from './LineChart';
import { createWasteDataSet, dataset } from '../utils/dataset';
import { createUniqueMonths } from '../utils/getUniqueMonths';
import { monthsToDays } from '../utils/monthsToDays';

const TotalWaste = () => {
	const [timeline, setTimeline] = useState('past 6 months');
	const memoizedQueryKey = [
		'totalWasteGenerated',
		{ days: monthsToDays[timeline] },
	];

	useEffect(() => {
		if (
			localStorage.getItem(
				'Total waste generated by all users in each category by month'
			)
		) {
			setTimeline(
				localStorage.getItem(
					'Total waste generated by all users in each category by month'
				)
			);
		}
	}, []);

	const { data, isLoading, isError, error } = useQuery(
		memoizedQueryKey,
		getTotalWasteGenerated,
		{ retry: 0 }
	);

	if (isLoading) {
		return <Loader />;
	}
	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}
	if (Object.entries(data).length === 0 && data.constructor === Object) {
		return <RequestError message="No data found" />;
	}
	//new data

	// Step 1: Extract unique months
	const uniqueMonthss = Array.from(
		new Set(data?.data?.map((item) => item.created_at_month))
	);

	// Step 2: Create five empty arrays corresponding to each waste_name type
	const eWasteData = [];
	const plasticData = [];
	const paperData = [];
	const hazardousData = [];
	const fabricData = [];
	// Step 3: Loop through the uniqueMonths and populate the arrays with values
	uniqueMonthss.forEach((month) => {
		const eWasteItem = data?.data?.find(
			(item) => item.created_at_month === month && item.waste_name === 'E-Waste'
		);
		eWasteData.push(eWasteItem ? eWasteItem.waste_total : null);

		const plasticItem = data?.data?.find(
			(item) => item.created_at_month === month && item.waste_name === 'Plastic'
		);
		plasticData.push(plasticItem ? plasticItem.waste_total : null);

		const paperItem = data?.data?.find(
			(item) =>
				item.created_at_month === month && item.waste_name === 'Paper & Cartons'
		);
		paperData.push(paperItem ? paperItem.waste_total : null);

		const hazardousItem = data?.data?.find(
			(item) =>
				item.created_at_month === month && item.waste_name === 'Hazardous waste'
		);
		hazardousData.push(hazardousItem ? hazardousItem.waste_total : null);

		const fabricItem = data?.data?.find(
			(item) =>
				item.created_at_month === month && item.waste_name === 'Fabric Pieces'
		);
		fabricData.push(fabricItem ? fabricItem.waste_total : null);
	});

	// Step 4: Prepare the final object with the desired format

	//ne data

	const wasteDataSetArray = [
		dataset(eWasteData, 'E-waste', '#138c50', '#138c50'),
		dataset(plasticData, 'Plastic', 'skyblue', 'skyblue'),
		dataset(paperData, 'Paper & cartons', '#ffb300', '#ffb300'),
		dataset(hazardousData, 'Hazardous', '#f65b3c', '#f65b3c'),
		dataset(fabricData, 'Fabric pieces', 'purple', 'purple'),
	];

	return (
		<LineChart
			title="Total waste generated by all users in each category by month"
			dataSetArray={wasteDataSetArray}
			labels={uniqueMonthss}
			timeline={timeline}
			setTimeline={setTimeline}
		/>
	);
};

export default TotalWaste;
