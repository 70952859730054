import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box, FormControl, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import './style.css';
import { getValueByKeyAndTimeline } from '../../admin/utils/getTimeline';
import './select.css';
import { useTranslation } from 'react-i18next';
import { useMasterDataHook } from '../api_hooks/api_hooks';

const theme = createTheme({
	components: {
		MuiSelect: {
			styleOverrides: {
				root: {
					// [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
					// 	backgroundColor: 'white',
					// },
					[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: '#A6A6A6',
						borderWidth: '1px',
					},
				},
				notchedOutline: {
					borderColor: '#A6A6A6',
					color: '#383838',
					'&:hover': {
						borderColor: 'transparent', // Set the desired border color on hover
					},
				},
			},
		},
	},
});

function SelectRows({
	startKey,
	endKey,
	apiTimeline,
	setApiTimeline,
	visibility,
}) {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('(max-width:600px)');
	const [startMonth, setStartMonth] = useState('January');
	const [startYear, setStartYear] = useState(2020);
	const [endMonth, setEndMonth] = useState(null);
	const [endYear, setEndYear] = useState(null);
	const { status, data, isLoading: masterdataLoading } = useMasterDataHook();

	function capitalizeString(inputString) {
		return inputString.charAt(0).toUpperCase() + inputString.slice(1);
	}

	function calculateEndMonthAndYear(startMonth, startYear) {
		// Create an array of month names
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		// Find the index of the start month in the monthNames array
		const startMonthIndex = monthNames.indexOf(startMonth);

		if (startMonthIndex === -1) {
			// Handle invalid start month
			return null;
		}
		// Calculate the end month index (3 months apart) and end year
		let endMonthIndex = startMonthIndex + 3;
		let endYear = startYear;

		if (endMonthIndex >= 12) {
			// Adjust the end month and year if it crosses the year boundary
			endMonthIndex -= 12;
			endYear += 1;
		}
		// Get the end month name based on the end month index
		const endMonth = monthNames[endMonthIndex];

		return { endMonth, endYear };
	}

	useEffect(() => {
		if (startKey == 'all') {
			setApiTimeline({
				start_date_waste: formatDate(startMonth, startYear),
				end_date_waste: formatDate(endMonth, endYear),
				start_date_energy: formatDate(startMonth, startYear),
				end_date_energy: formatDate(endMonth, endYear),
				start_date_material: formatDate(startMonth, startYear),
				end_date_material: formatDate(endMonth, endYear),
				start_date_revenue: formatDate(startMonth, startYear),
				end_date_revenue: formatDate(endMonth, endYear),
				start_date_full_report: formatDate(startMonth, startYear),
				'end_date_full_report ': formatDate(endMonth, endYear),
				start_date_input_log: formatDate(startMonth, startYear),
				end_date_input_log: formatDate(endMonth, endYear),
				company_name: 'skillablersssss',
			});
		} else {
			setApiTimeline({
				...apiTimeline,
				[startKey]: formatDate(startMonth, startYear),
				[endKey]: formatDate(endMonth, endYear),
			});
		}
	}, [startMonth, startYear, endMonth, endYear]);

	useEffect(() => {
		const startingTimline = JSON.parse(data?.data['START_DATE']);
		setStartYear(startingTimline[0]?.start_year);
		setStartMonth(capitalizeString(startingTimline[0]?.start_month));
	}, []);

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const years = Array.from({ length: 11 }, (_, index) => 2020 + index);

	function formatDate(month, year) {
		const months = {
			January: 0,
			February: 1,
			March: 2,
			April: 3,
			May: 4,
			June: 5,
			July: 6,
			August: 7,
			September: 8,
			October: 9,
			November: 10,
			December: 11,
		};

		const monthValue = months[month];

		const date = new Date(year, monthValue, 1);

		const formattedDate = `${date.getFullYear()}-${String(
			date.getMonth() + 1
		).padStart(2, '0')}-01 00:00:00`;

		return formattedDate;
	}

	const handleStartYearClick = (e) => {
		setStartYear(e.target.value);
		// localStorage.setItem(`startYear.${startKey}`, e.target.value);
	};

	const handleEndYearClick = (e) => {
		if (
			startYear == e.target.value &&
			months.indexOf(endMonth) - months.indexOf(startMonth) < 3
		) {
			setEndMonth(months[months.indexOf(startMonth) + 2]);
		}
		setEndYear(e.target.value);
		// localStorage.setItem(`endYear.${endKey}`, e.target.value);
	};

	const handleEndMonthClick = (e) => {
		setEndMonth(e.target.value);
		// localStorage.setItem(`endMonth.${endKey}`, e.target.value);
	};

	const handleStartMonthClick = (e) => {
		setStartMonth(e.target.value);
		// localStorage.setItem(`startMonth.${startKey}`, e.target.value);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				display="flex"
				alignItems="center"
				style={{ opacity: visibility ? 1 : 0.3 }}
			>
				<FormControl
					sx={{ m: isMobile ? 0.3 : 0.7, minWidth: isMobile ? 60 : 160 }}
				>
					<Select
						className="output-select"
						size="small"
						value={startYear}
						onChange={handleStartYearClick}
						displayEmpty
						style={{ fontSize: '14px' }}
						inputProps={{ 'aria-label': 'Without label' }}
						disabled={visibility ? false : true}
					>
						{/* <MenuItem sx={{ fontSize: '14px' }} value={startYear}>
							Starting Year
						</MenuItem> */}
						{years
							?.filter((year) => year >= startYear)
							.map((year) => (
								<MenuItem sx={{ fontSize: '14px' }} value={year} key={year}>
									{year}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl
					sx={{ m: isMobile ? 0.3 : 0.7, minWidth: isMobile ? 50 : 160 }}
				>
					<Select
						className="output-select"
						size="small"
						value={startMonth}
						onChange={handleStartMonthClick}
						displayEmpty
						style={{ fontSize: '14px' }}
						inputProps={{ 'aria-label': 'Without label' }}
						disabled={visibility ? false : true}
					>
						{/* <MenuItem sx={{ fontSize: '14px' }} value={startMonth}>
							Starting Month
						</MenuItem> */}
						{months.map((month) => (
							<MenuItem sx={{ fontSize: '14px' }} value={month} key={month}>
								{isMobile ? month.substring(0, 3) : month}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<b
					style={{
						color: 'black',
						margin: isMobile ? '0px 4px' : '0px 10px',
						fontSize: isMobile ? '10px' : 'inherit',
					}}
				>
					{t('output.to')}
				</b>
				<FormControl
					sx={{
						m: isMobile ? 0.3 : 0.7,
						minWidth: isMobile ? 60 : 160,
						width: 60,
					}}
				>
					<Select
						className="output-select"
						size="small"
						value={endYear}
						onChange={handleEndYearClick}
						displayEmpty
						style={{ fontSize: '14px' }}
						inputProps={{ 'aria-label': 'Without label' }}
						disabled={visibility ? false : true}
					>
						<MenuItem sx={{ fontSize: '14px' }} value={endYear}>
							Ending Year
						</MenuItem>
						{years
							?.filter((year) => year >= startYear)
							.map((year) => (
								<MenuItem sx={{ fontSize: '14px' }} value={year} key={year}>
									{year}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl
					sx={{
						m: isMobile ? 0.3 : 0.7,
						minWidth: isMobile ? 60 : 160,
						width: 60,
					}}
				>
					<Select
						className="output-select"
						size="small"
						value={endMonth}
						onChange={handleEndMonthClick}
						displayEmpty
						style={{ fontSize: '14px' }}
						inputProps={{ 'aria-label': 'Without label' }}
						disabled={visibility ? false : true}
					>
						<MenuItem sx={{ fontSize: '14px' }} value={endMonth}>
							Ending Month
						</MenuItem>
						{months.map((month) => (
							<MenuItem
								sx={{
									fontSize: '14px',
								}}
								value={month}
								key={month}
								disabled={
									startYear == endYear &&
									months.indexOf(month) - months.indexOf(startMonth) < 2
								}
							>
								{isMobile ? month.substring(0, 3) : month}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</ThemeProvider>
	);
}

export default SelectRows;
