// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound_header__qREmW {
    background-color: #f2f2f2;
    max-height: 100px;
    box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 25%);
    margin: 0px;
    padding: 5px 0;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 50;
}

.notfound_logo__Hq-NJ {
    width: 60px;
}

.notfound_loginIcon__rZiK6 {
    display: inline-block;
}

.notfound_loginIcon__rZiK6 img {
    max-width: 100%;
    width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/frontend/pageNotFound/notfound.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,4CAA4C;IAC5C,WAAW;IACX,cAAc;IACd,eAAe;IACf,QAAQ;IACR,OAAO;IACP,MAAM;IACN,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".header {\r\n    background-color: #f2f2f2;\r\n    max-height: 100px;\r\n    box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 25%);\r\n    margin: 0px;\r\n    padding: 5px 0;\r\n    position: fixed;\r\n    right: 0;\r\n    left: 0;\r\n    top: 0;\r\n    z-index: 50;\r\n}\r\n\r\n.logo {\r\n    width: 60px;\r\n}\r\n\r\n.loginIcon {\r\n    display: inline-block;\r\n}\r\n\r\n.loginIcon img {\r\n    max-width: 100%;\r\n    width: 40px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `notfound_header__qREmW`,
	"logo": `notfound_logo__Hq-NJ`,
	"loginIcon": `notfound_loginIcon__rZiK6`
};
export default ___CSS_LOADER_EXPORT___;
