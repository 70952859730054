// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.output-select:hover {
	background-color: #d9d9d9;
}
.output-select {
	background-color: #f2f2f2;
}
.dialog {
	padding: 10px !important;
}
.MuiDialogActions-root {
	padding: 120px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/frontend/output/style.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;AACA;CACC,yBAAyB;AAC1B;AACA;CACC,wBAAwB;AACzB;AACA;CACC,yBAAyB;AAC1B","sourcesContent":[".output-select:hover {\r\n\tbackground-color: #d9d9d9;\r\n}\r\n.output-select {\r\n\tbackground-color: #f2f2f2;\r\n}\r\n.dialog {\r\n\tpadding: 10px !important;\r\n}\r\n.MuiDialogActions-root {\r\n\tpadding: 120px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
