import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const WasteGenerated = ({
    WasteGeneratedJson,
    wasteGeneratedInputChange,
    saveWasteGenerated,
    completeWasteGenerated,
    netWasteRevenue,
    totalDisposalCost,
    otherWasteGeneratedInput,
    WasteIncomplete
}) => {

    let langId = localStorage.getItem("lanId")
    const {t} = useTranslation();
    return (
        <>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="separator"></div>
                </div>
            </div>

            <div className={style.section + ' ' + style.sec_3}>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3><span>3</span>{t("monthlylogpage.WasteHeaderText")}</h3>
                        <p>{t("monthlylogpage.WasteSubText")}</p>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-md-6 ">
                        {WasteGeneratedJson.map((item, index) => <div key={index}>
                            <div className='row mb3'>
                                <div className='col-12'>
                                    <label>{item.waste_name}</label>
                                </div>
                                <div className="col-md-8 mb-3">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            name="quantity"
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control monthlylogInput"
                                            placeholder={completeWasteGenerated === 1 && item.quantity === null || item.quantity === '' ? t("monthlylogpage.IncompleteField") : ''}
                                            value={item.quantity === null ? '' : item.quantity}
                                            onChange={(e) => wasteGeneratedInputChange(e, index)}
                                            style={{
                                                border: item.quantity === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                    item.quantity === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.quantity === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                        item.quantity === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                            "1px solid #138C50"
                                            }}
                                        />
                                        <span
                                            className="input-group-text spanBorderinput"
                                            style={{
                                                border: item.quantity === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                    item.quantity === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.quantity === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                        item.quantity === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                            "1px solid #138C50",
                                                            padding:"8px 46px 8px 10px"
                                            }}
                                        >{item.unit_name}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3"></div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-12'><p>{t("monthlylogpage.outOfwaste")}</p></div>
                                <div style={{display:"flex",alignItems:"baseline",justifyContent:"space-between"}}>
                                <div className='col-4 col-lg-3' style={{width:"30%"}}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label style={{fontSize:langId === '1'?"":"10px"}}>{t("monthlylogpage.Openburning")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                name="open_burning"
                                                onWheel={(e) => e.target.blur()}
                                                className="form-control monthlylogInput"
                                                //placeholder={completeWasteGenerated === 1 && item.open_burning === null || item.open_burning ===''  ? "Incomplete field!":''} 
                                                value={item.open_burning == null ? '' : item.open_burning}
                                                onChange={(e) => wasteGeneratedInputChange(e, index)}
                                                style={{
                                                    border: item.open_burning === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.open_burning === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                            item.open_burning === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                            item.open_burning === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                                "1px solid #138C50"
                                                }}
                                            />
                                            <span
                                                className="input-group-text spanBorderinput"
                                                style={{
                                                    border: item.open_burning === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.open_burning === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                            item.open_burning === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                            item.open_burning === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                                "1px solid #138C50"
                                                }}
                                            >%</span>
                                        </div>
                                        {completeWasteGenerated === 1 && item.open_burning === null || item.open_burning === '' ? <div className={style.incompleError}>{t("monthlylogpage.IncompleteField")}</div> : null}
                                    </div>
                                </div>

                                <div className='col-4 col-lg-3' style={{width:"30%"}}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label style={{fontSize:langId === '1'?"":"10px"}}>{t("monthlylogpage.landfiling")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                name="land_filling"
                                                onWheel={(e) => e.target.blur()}
                                                className="form-control monthlylogInput"
                                                //placeholder={completeWasteGenerated === 1 && item.land_filling === null || item.land_filling ===''  ? "Incomplete field!":''} 
                                                value={item.land_filling == null ? '' : item.land_filling}
                                                onChange={(e) => wasteGeneratedInputChange(e, index)}
                                                style={{
                                                    border: item.land_filling === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.land_filling === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                            item.land_filling === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                            item.land_filling === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                                "1px solid #138C50"
                                                }}
                                            />
                                            <span
                                                className="input-group-text spanBorderinput"
                                                style={{
                                                    border: item.land_filling === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.land_filling === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                            item.land_filling === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                            item.land_filling === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                                "1px solid #138C50"
                                                }}
                                            >%</span>
                                        </div>
                                        {completeWasteGenerated === 1 && item.land_filling === null || item.land_filling === '' ? <div className={style.incompleError}>{t("monthlylogpage.IncompleteField")}</div> : null}
                                    </div>
                                </div>

                                <div className='col-4 col-lg-3' style={{width:"30%"}}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label style={{fontSize:langId === '1'?"":"10px"}}>{t("monthlylogpage.recycling")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                name="recycling"
                                                onWheel={(e) => e.target.blur()}
                                                className="form-control monthlylogInput"
                                                //placeholder={completeWasteGenerated === 1 && item.recycling === null || item.recycling ===''  ? "Incomplete field!":''}  
                                                value={item.recycling == null ? '' : item.recycling}
                                                onChange={(e) => wasteGeneratedInputChange(e, index)}
                                                style={{
                                                    border: item.recycling === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.recycling === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                            item.recycling === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                            item.recycling === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                                "1px solid #138C50"
                                                }}
                                            />
                                            <span
                                                className="input-group-text spanBorderinput"
                                                style={{
                                                    border: item.recycling === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                        item.recycling === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                            item.recycling === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                            item.recycling === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                                "1px solid #138C50"
                                                }}
                                            >%</span>
                                        </div>
                                        {completeWasteGenerated === 1 && item.recycling === null || item.recycling === '' ? <div className={style.incompleError}>{t("monthlylogpage.IncompleteField")}</div> : null}
                                    </div>
                                </div>
                                </div>
                                <div className='col-12'>
                                    <p className='opacity-50' style={{fontWeight:"500",fontSize:"13px",marginTop:"0px"}}>{t("generalText.NOTE")} ({t("monthlylogpage.Percentage")})</p>
                                </div>
                            </div>
                        </div>)}

                        <div className="row">
                            <div className="col-md-12">
                                <div className="separatorSM"></div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <p>{t("monthlylogpage.wasteSubTextTwo")}</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label>{langId === '1' ?"Total revenue from waste disposal (All waste)":"အမှိုက်စွန့်ပစ်ရာမှ စုစုပေါင်းရရှိသည့် အမြတ်"}</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control monthlylogInput"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={completeWasteGenerated === 1 && netWasteRevenue === null || netWasteRevenue === '' ? t("monthlylogpage.IncompleteField") : ''}
                                        name="net_revenue_from_waste_generation"
                                        value={netWasteRevenue == null ? '' : netWasteRevenue}
                                        onChange={(e) => otherWasteGeneratedInput(e)}
                                        style={{
                                            border: netWasteRevenue === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                netWasteRevenue === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                    netWasteRevenue === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                    netWasteRevenue === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                        "1px solid #138C50"
                                        }}
                                    />
                                    <span
                                        className="input-group-text spanBorderinput"
                                        style={{
                                            border: netWasteRevenue === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                netWasteRevenue === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                    netWasteRevenue === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                    netWasteRevenue === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                        "1px solid #138C50",
                                                        padding:"8px 46px 8px 10px"
                                        }}
                                    >Ks.</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label>{langId === "1"?"Cost of waste disposal (All waste)":"အမှိုက်စွန့်ပစ်မှု ကုန်ကျစရိတ်"}</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name='total_disposal_cost'
                                        onWheel={(e) => e.target.blur()}
                                        className="form-control monthlylogInput"
                                        placeholder={completeWasteGenerated === 1 && totalDisposalCost === null || totalDisposalCost === '' ? t("monthlylogpage.IncompleteField") : ''}
                                        value={totalDisposalCost == null ? '' : totalDisposalCost}
                                        onChange={(e) => otherWasteGeneratedInput(e)}
                                        style={{
                                            border: totalDisposalCost === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                totalDisposalCost === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                    totalDisposalCost === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                    totalDisposalCost === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                        "1px solid #138C50"
                                        }}
                                    />
                                    <span
                                        className="input-group-text spanBorderinput"
                                        style={{
                                            border: totalDisposalCost === null && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                totalDisposalCost === '' && completeWasteGenerated === 1 ? "1px solid #F65B3C" :
                                                    totalDisposalCost === null && completeWasteGenerated === 0 ? "1px solid #A6A6A6" :
                                                    totalDisposalCost === '' && completeWasteGenerated === 0 ? "1px solid red" :
                                                        "1px solid #138C50",
                                                        padding:"8px 46px 8px 10px"
                                        }}
                                    >Ks.</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3 pe-5 pe-md-0 paddingButtonRight" 
                                style={{ 
                                    display: 'flex', 
                                    alignItems: window.innerWidth>768?'end':'center', 
                                    justifyContent: window.innerWidth>768?"start":"end",
                                }}>
                                {completeWasteGenerated === 1 && WasteIncomplete === 1 ? <p className='float-start d-block d-md-none form_msg'>{t("monthlylogpage.completedSection")}</p> : null
                                }
                                <button
                                    className={
                                        completeWasteGenerated == 0 ?
                                            "btn btn-primary mw-11 float-md-start float-end" : "btn btn-primary_disable mw-11 float-md-start float-end"}
                                    type="button"
                                    onClick={saveWasteGenerated}
                                    style={{fontSize:langId === '1'?"13px":"10px",padding:"6px 6px"}}
                                >{t("monthlylogpage.SaveButton")}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 d-none d-md-block position-relative'>
                        {completeWasteGenerated === 1 && WasteIncomplete === 1 ? <p className='form_msg' style={{height:langId!=='1'?36:"auto",display:"flex",alignItems:"baseline"}}>{t("monthlylogpage.completedSection")}</p> : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default WasteGenerated;