import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Switch as SwitchComponent, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { changeUserStatus } from '../../../services/AdminApis';
import { toast } from 'react-toastify';

const Switch = ({ status, userId }) => {
	const queryClient = useQueryClient();
	const { data, isLoading, isError, error, mutate } = useMutation(
		'changeUserStatus',
		changeUserStatus,
		{
			retry: 2,
			onSuccess: () => {
				toast.success('Successfully updated user status.');
				queryClient.invalidateQueries('userList');
			},
		}
	);

	//toggle states
	const [checked, setChecked] = useState(status);
	useEffect(() => {
		const newStatus = status === 1 ? 'inactive' : 'active';
		setChecked(newStatus);
	}, [status]);

	const handleChange = (status) => {
		const newStatus = status === 'active' ? 'inactive' : 'active';
		setChecked(newStatus);
		let apiStatusData = newStatus == 'active' ? 0 : 1;

		mutate({ user_id: userId, status: apiStatusData });
	};

	const AntSwitch = styled(SwitchComponent)(({ theme }) => ({
		width: 48,
		height: 16,
		padding: 0,
		display: 'flex',
		'&:active': {
			'& .MuiSwitch-thumb': {
				width: 15,
			},
			'& .MuiSwitch-switchBase.Mui-checked': {
				transform: 'translateX(18px)',
			},
		},
		'& .MuiSwitch-switchBase': {
			padding: 2,
			'&.Mui-checked': {
				transform: 'translateX(32px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor: '#F65B3C',
				},
			},
		},
		'& .MuiSwitch-thumb': {
			boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
			width: 12,
			height: 12,
			borderRadius: 6,
			transition: theme.transitions.create(['width'], {
				duration: 100,
			}),
		},
		'& .MuiSwitch-track': {
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor: '#138C50',
			boxSizing: 'border-box',
		},
	}));
	return (
		<Box
			onClick={() => handleChange(checked)}
			width="50px"
			display="flex"
			alignItems="center"
			gap="40px"
			paddingLeft="4px"
		>
			<AntSwitch checked={checked === 'active' ? true : false} />{' '}
			<Typography
				fontWeight="bold"
				variant="subtitle2"
				color={checked !== 'active' ? 'primary' : 'error'}
			>
				{checked !== 'active' ? 'Live' : 'Blocked'}
			</Typography>
		</Box>
	);
};

export default Switch;
