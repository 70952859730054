import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import rpStyles from '../resetPassword/resetPassword.module.css';
import { useEmailVerificationRequest} from "../api_hooks/api_hooks";
import { useTranslation } from "react-i18next";
import { Oval } from 'react-loader-spinner';

const EmailConformation = () => {
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    let tokenType = searchParams.get('token')
    const { mutate: emailTokenverify, data, isLoading } = useEmailVerificationRequest();
    const callEmailVerifyToken = (e) => {
            let passData = {
                "token": tokenType,
            }
            emailTokenverify(passData);
    }
    useEffect(() => {
        callEmailVerifyToken();
    }, [])
    return (
        <>
            <div className="mainWrapper">
                <div className={rpStyles.resetPassword}>
                    <div className="container pt-4">
                        {isLoading === false?<div className="row justify-content-center">
                            {data != undefined?<div className="col-md-6 col-lg-5 col-xl-4">
                                <h4>{t("createNewAccount.accountCreated")}</h4>
                                <p className="mb-4">{t("createNewAccount.accountCreateDesc")}</p>
                                <Link to="/"><button className="btn btn-primary w-100" type="submit">{t("createNewAccount.Gobacktohomepage")}</button></Link>
                            </div>:
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <h4>{t("createNewAccount.EmailVerifailed")}</h4>
                                <p className="mb-4"></p>
                                <Link to="/"><button className="btn btn-primary w-100" type="submit">{t("createNewAccount.Gobacktohomepage")}</button></Link>
                            </div>}
                        </div>:
                        <div style={{justifyContent:"center",display:"flex"}}><Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        /></div>}
                    </div>
                </div>
            </div>
        </>
    );
}
export default EmailConformation;