import React, { useState } from 'react';
import { OutlinedInput, FormControl, InputAdornment } from '@mui/material';
import search from '../../../assets/admin/images/search.png';

const Search = ({ users, setUsers, usersRef }) => {
	const [searchQuery, setSearchQuery] = useState('');

	let key = '';
	const handleKeyDown = (e) => {
		key = e.key;
	};
	const filterTable = ({ target: { value } }) => {
		const trimmedValue = value.toString().trim();

		setSearchQuery(trimmedValue);

		const upperCaseValue = trimmedValue.toUpperCase();

		if (trimmedValue.length > 0) {
			if (key === 'Backspace') {
				if (usersRef.current[trimmedValue.length] === undefined) {
					setUsers(users);
				} else {
					setUsers(usersRef.current[trimmedValue.length]);
				}
			} else {
				const filteredResources = users?.filter(
					(resource) =>
						resource?.name?.toUpperCase().includes(upperCaseValue) ||
						resource?.email?.toUpperCase().includes(upperCaseValue) ||
						String(resource?.phonenumber)?.includes(upperCaseValue)
				);
				setUsers(filteredResources);
				usersRef.current = {
					...usersRef.current,
					[trimmedValue.length]: [...filteredResources],
				};
			}
		} else {
			setUsers(usersRef.current[0]);
		}
	};

	return (
		<FormControl sx={{ width: '400px', mb: 5 }}>
			<OutlinedInput
				placeholder="Search by name, email ID or phone number"
				size="small"
				onKeyDown={(e) => handleKeyDown(e)}
				value={searchQuery}
				onChange={filterTable}
				startAdornment={
					<InputAdornment position="start">
						<img src={search} alt="Search Icon" width="25px" height="25px" />
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

export default Search;
