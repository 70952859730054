import React, { useState, useEffect } from 'react';
import LineChart from './LineChart';
import { dataset } from '../utils/dataset';
import Loader from './Loader';
import RequestError from './RequestError';
import { getNetRevenueData } from '../../../services/AdminApis';
import { useQuery } from 'react-query';
import { monthsToDays } from '../utils/monthsToDays';
import { toast } from 'react-toastify';
import { createUniqueMonthsAgain } from '../utils/newUniqueMonths';

const NetRevenue = () => {
	const [timeline, setTimeline] = useState('past 6 months');

	useEffect(() => {
		if (localStorage.getItem('Net revenue from waste for all users by month')) {
			setTimeline(
				localStorage.getItem('Net revenue from waste for all users by month')
			);
		}
	}, []);

	const memoizedQueryKey = ['netRevenue', { days: monthsToDays[timeline] }];

	const { data, isLoading, isError, error } = useQuery(
		memoizedQueryKey,
		getNetRevenueData,
		{ retry: 0 }
	);

	if (isLoading) {
		return <Loader />;
	}
	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	//removed the notification for checking for the data avialbility and shohwing the notification for no data available bcoz data was being displayed from the cache and a bit coding effort is need to work it out to show nofification and it's not worth it since there's only one user(admin)

	// Step 1: Sort the data based on created_at_month in ascending order
	const sortedData = createUniqueMonthsAgain(data?.data);

	// Step 2: Extract unique months and years
	const uniqueMonths = Array.from(
		new Set(sortedData.map((item) => item.created_at_month))
	);

	// Step 3: Create arrays for each type of data (total_revenue, total_disposal_cost, total_waste_generated)
	const total_revenue = [];
	const totalDisposalCost = [];
	const totalWasteGenerated = [];

	uniqueMonths.forEach((month) => {
		const matchingData = sortedData.find(
			(item) => item.created_at_month === month
		);

		if (matchingData) {
			total_revenue.push(matchingData.total_revenue);
			totalDisposalCost.push(matchingData.total_disposal_cost);
			totalWasteGenerated.push(matchingData.total_waste_generated);
		} else {
			total_revenue.push(null);
			totalDisposalCost.push(null);
			totalWasteGenerated.push(null);
		}
	});

	console.log(total_revenue);

	const disposalMethodDatasetArray = [
		dataset(totalDisposalCost, 'Disposal cost', '#f65b3c', '#f65b3c'),
		dataset(total_revenue, 'Total Revenue', '#ffb300', '#ffb300'),

		dataset(totalWasteGenerated, 'Total Waste Generated', '#138c50', '#138c50'),
	];
	console.log(uniqueMonths);

	return (
		<LineChart
			title="Net revenue from waste for all users by month"
			dataSetArray={disposalMethodDatasetArray}
			labels={uniqueMonths}
			timeline={timeline}
			setTimeline={setTimeline}
		/>
	);
};

export default NetRevenue;
