import React, { useState, useEffect } from 'react';
import LineChart from './LineChart';
import { dataset } from '../utils/dataset';
import Loader from './Loader';
import RequestError from './RequestError';
import { specificMaterialConsumption } from '../../../services/AdminApis';
import { useQuery } from 'react-query';
import { createUniqueMonths } from '../utils/getUniqueMonths';
import { toast } from 'react-toastify';
import { monthsToDays } from '../utils/monthsToDays';
import { createUniqueMonthsAgain } from '../utils/newUniqueMonths';

const MaterialConsumption = () => {
	const [timeline, setTimeline] = useState('past 6 months');

	useEffect(() => {
		if (
			localStorage.getItem(
				'Specific material consumption of all users by month'
			)
		) {
			setTimeline(
				localStorage.getItem(
					'Specific material consumption of all users by month'
				)
			);
		}
	}, []);

	const memoizedQueryKey = [
		'materialConsumption',
		{ days: monthsToDays[timeline] },
	];

	const { data, isLoading, isError, error } = useQuery(
		memoizedQueryKey,
		specificMaterialConsumption,
		{ retry: 0 }
	);

	if (isLoading) {
		return <Loader />;
	}
	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	//new data
	// Step 1: Sort the data based on created_at_month in ascending order
	const sortedData = createUniqueMonthsAgain(data?.data);

	// Step 2: Extract unique months and years
	const uniqueMonths = Array.from(
		new Set(sortedData.map((item) => item.created_at_month))
	);

	// Step 3: Create arrays for each type of data (total_revenue, total_disposal_cost, total_waste_generated)
	const totalMaterialConsumption = [];

	uniqueMonths.forEach((month) => {
		const matchingData = sortedData.find(
			(item) => item.created_at_month === month
		);

		if (matchingData) {
			totalMaterialConsumption.push(matchingData.total_material_consumption);
		} else {
			totalMaterialConsumption.push(null);
		}
	});

	//newdata

	const specificMaterialConsumtion = [
		dataset(
			totalMaterialConsumption,
			'Specific Material Consumption',
			'#138c50',
			'#138c50'
		),
	];
	return (
		<LineChart
			title="Specific material consumption of all users by month"
			dataSetArray={specificMaterialConsumtion}
			labels={uniqueMonths}
			timeline={timeline}
			setTimeline={setTimeline}
		/>
	);
};

export default MaterialConsumption;
