import React from 'react';
import footerStyles from './footer.module.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import footerImg from '../../../assets/frontend/images/switchAsiaSmallicon.png';
const Footer=()=>{
    let location = useLocation();
    let currentPath = location.pathname;
    let langId = localStorage.getItem("lanId");
    const {t} = useTranslation();
    const date = new Date();
    let CurrentYear = date.getFullYear()
    return( 
    <div 
      className={footerStyles.footer}
      style={{position:currentPath === '/dashboard'&&window.innerWidth>1700?"fixed":"unset",
       bottom:"0px",
       right:"0px",
       left:"0px"
      }}
    >
        <div className="container-fluid">
            <div className="row py-4">
                <div className="col-md-6 col-lg-8" style={{fontSize:window.innerWidth>1700?"14px":"13px",lineHeight:langId === '1'?"17px":"22px"}}>
                    <strong>© {CurrentYear} Prevent Plastics. All rights reserved.</strong><br/>
                    <span style={{marginBottom:"10px"}}>Licensed to the European Union under conditions.</span>
                    <p style={{
                        lineHeight:"17px",
                        fontSize:window.innerWidth>1700?"14px":"12px",
                        textAlign:"left",
                        color:window.innerWidth<768?"#A6A6A6":"#383838"
                        }}>
                       This platform was created and maintained with the financial support of the European Union. Its contents are the sole responsibility of Prevent Plastics & STENUM Asia and do not necessarily reflect the views of the European Union.
                    </p>
                </div> 
                <div className="col-md-6 col-lg-4 pt-0 pt-md-2 pt-md-0 footerDisplay">
                    <div className='footerImage'>
                        <img src={footerImg} alt="switch asia image"/>
                    </div>
                </div> 
            </div>
        </div> 
    </div> 
    );
}
export default Footer;