export function getValueByKeyAndTimeline(key, timeline) {
	let data = {};
	try {
		data = JSON.parse(localStorage.getItem('selectedTimeline'));
	} catch (error) {
		console.log(error);
	}
	console.log(data);

	const dateKey = `${timeline}`;
	const dateValue = data[timeline];

	console.log(dateValue);

	if (!dateValue) {
		return null;
	}

	if (key === 'year') {
		return dateValue.substring(0, 4);
	} else if (key === 'month') {
		const monthNumber = parseInt(dateValue.substring(5, 7), 10);
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		return monthNames[monthNumber - 1];
	} else {
		// You can add more timeline formats as needed (e.g., day, hour, minute, second)
		// You may need to adjust the dateFormats object accordingly
		// For now, we'll return the original value for other timelines
		return dateValue.substring(8, 10);
	}
}
