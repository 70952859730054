import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './setup.module.css';
import add from '../../../assets/frontend/images/add_green.png';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../../frontend_context/contextProvider';
import { useMasterdata } from '../customHooks/masterDatahook';
import { useAddBoilerHook, useAddMaterialHook, useDeleteBoilerHook, useDeleteMaterialHook } from '../api_hooks/api_hooks';
import remove from '../../../assets/frontend/images/remove.svg';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const InputResources = () => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    const { RawMaterialList, BoilerfuelList } = useContext(Context);
    const [showModalMaterial, setshowModalMaterial] = useState(false);
    const [showModalBoiler, setShowModalBoiler] = useState(false);
    const { unitList, boilerList } = useMasterdata();
    const [unitListArray, setUnitListArray] = useState([]);
    const [boilerListArray, setboilerListArray] = useState([]);
    const [disabledConv, setDisableConv] = useState(true);
    const [ErrorRawList, setErrorRawList] = useState({});
    const [deleteMaterialIndex, setDeleteMaterialIndex] = useState();
    const [deleteBoilerIndex,setDeleteBoilerIndex] = useState();
    const [RawMaterialValues, setRawMaterialValues] = useState({
        rawMaterial: '',
        conversionFactor: 1,
        unitId: 1
    })
    const [currentUnit, setCurrentUnit] = useState('')
    const [BoilerValue, setBoilerValue] = useState({
        boilerFuel_id: ''
    });
    const materialRef = useRef();
    const materialLabelRef = useRef();
    const convFacRef = useRef();
    const handleCloseModalMaterial = () => {
        setshowModalMaterial(false);
        setRawMaterialValues({
            rawMaterial: '',
            conversionFactor: 1,
            unitId: 1
        })
        setDisableConv(true)
    }
    const handleShowMaterial = () => {
        setDeleteMaterialIndex()
        if (RawMaterialList == null) {
            setshowModalMaterial(true);
            setUnitListArray(unitList!==null&&unitList)
        }
        else if (RawMaterialList.length < 12) {
            setshowModalMaterial(true);
            setUnitListArray(unitList!==null&&unitList)
        }
        else {
            toast.warning(t("validationMSG.maxRawMaterial"));
        }
    }

    const handleCloseModalBoiler = () => {
        setShowModalBoiler(false);
        setBoilerValue({
            boilerFuel_id:''
        })
        setCurrentUnit('')
    }
    const handleShowModalBoiler = () => {
        setDeleteBoilerIndex()
        if(BoilerfuelList == null){
            setboilerListArray(boilerList!==null && boilerList)
            setShowModalBoiler(true);
        }
        
        else if(BoilerfuelList.length < 2){
            setboilerListArray(boilerList!==null && boilerList)
            setShowModalBoiler(true);
        }
        else{
            toast.warning(t("validationMSG.maxBoilerFuel"));
        }
    }
    const handleBoilerChange = (e) => {
        let val = e.target.value
        var options = e.target.options;
        var Id = options[options.selectedIndex].id;
        setBoilerValue({
            boilerFuel_id: val
        })
        setCurrentUnit(Id)
    }
    //Raw material input change
    const handleInputRawMateChange = (e) => {
        const { name, value } = e.target;
        setRawMaterialValues({ ...RawMaterialValues, [name]: value })
        if(name === "rawMaterial"){
            if(value.length>0){
                materialRef.current.style.border = "1px solid #138C50"
                materialLabelRef.current.style.color="#138C50"
            }
            else{
                materialRef.current.style.border = "1px solid #9E9E9E"
                materialLabelRef.current.style.color="#383838"
            }
            
        }
        if(name ==='conversionFactor'){
            if(value.length>0){
                setErrorRawList({})
                convFacRef.current.style.border = "1px solid #138C50"
            }
            else{
                convFacRef.current.style.border = "1px solid #9E9E9E"
            }   
        }
    }
    //Raw material select change
    const handleSelectMaterial = (e) => {
        setDisableConv(false)
        if(e.target.value === 1){
            setRawMaterialValues({
                ...RawMaterialValues, unitId: e.target.value, conversionFactor: 1
            })
            setDisableConv(true)
        }
        else{
            setRawMaterialValues({
                ...RawMaterialValues, unitId: e.target.value, conversionFactor: ''
            })
        }
    }
    const { mutate: addMaterial } = useAddMaterialHook();
    //Add Material
    const AddMaterialFunction = () => {
        let ErrorValues = ValidationMaterialFunction();
        setErrorRawList(ErrorValues)
        if (Object.keys(ErrorValues).length === 0) {
            let MaterialData = {
                "raw_material": RawMaterialValues.rawMaterial,
                "conversion_factor": RawMaterialValues.conversionFactor,
                "unit_id": RawMaterialValues.unitId
            }
            addMaterial(MaterialData)
            setshowModalMaterial(false)
            setRawMaterialValues({
                rawMaterial: '',
                conversionFactor: 1,
                unitId: 1
            })
            setDisableConv(true)
        }
    }
    //Delete material api call
    const { mutate: deleteMateral } = useDeleteMaterialHook();
    //Delete Material function
    const DeleteMaterialFunction = (e,index) => {
        e.stopPropagation();
        setDeleteMaterialIndex(index)
    }
    const MaterialDelete = (e,material_id) => {
        e.stopPropagation();
        let id = material_id;
        let delData = {
            "raw_material_id": id
        }
        deleteMateral(delData)
        setDeleteMaterialIndex()
    }
    //Raw material Validation
    const ValidationMaterialFunction = () => {
        let error = {}
        if (!RawMaterialValues.rawMaterial) {
            error.RawName = "Add material name"
        }
        if (!RawMaterialValues.conversionFactor) {
            error.RawCon = t("monthlylogpage.materialConvFactValid");
            convFacRef.current.style.border = "1px solid red"
            convFacRef.current.style.color="red"
        }
        return error;
    }
    //add boiler fuel api call
    const { mutate: addBoilerFuel } = useAddBoilerHook();
    //save boiler fuel
    const saveBoilerFuel = () => {
        let boilerData = {
            "boiler_fuel_id": BoilerValue.boilerFuel_id
        }
        addBoilerFuel(boilerData);
        setShowModalBoiler(false)
    }

    const{mutate:deleteBoiler}=useDeleteBoilerHook();
    const bilerDeleteFunction =(e,index)=>{
        e.stopPropagation();
        setDeleteBoilerIndex()
        setDeleteBoilerIndex(index)
    }
    const BoilderDelete=(e,boiler_id)=>{
            e.stopPropagation();
            let id = boiler_id;
            let delData = {
                "boiler_fuel_id":id
            }
            deleteBoiler(delData)
            setDeleteBoilerIndex()
    }
    const disableRemovebtn =()=>{
        setDeleteMaterialIndex()
        setDeleteBoilerIndex()
    }
    useEffect(()=>{
        document.body.addEventListener('click',disableRemovebtn)
    },[])
  
    return (
        <div className={style.setupWrapper}>
            <div>
                <div className="row">
                    <div className="col-md-6">
                        {langId === '1' ? <p>Input Resources consists of two sections - <b>Raw Materials & Boiler Fuels (Energy).</b></p>:
                         <p>သွင်းကုန်ပစ္စည်းများတွင် အပိုင်းခွဲနှစ်ခုပါဝင်သည်- <b>ကုန်ကြမ်းနှင့် ဘွိုင်လာလောင်စာ (စွမ်းအင်)</b>
                        </p>}
                    </div>
                </div>
                <div className="row">
                    <div className='col-12'>
                        <div className='separator'></div>
                    </div>
                    <div className="col-md-6">
                        <h5>{t("setuppage.InputResourcesTabRawMaterialsText")}</h5>
                        {langId === '1'? <p>Add the <b>raw materials</b> (max. 12) that you consume, and set up their unit conversions if they aren’t measured in kg.</p>:
                        <p>သင်အသုံးပြုသော <b>ကုန်ကြမ်းများ</b> (အများဆုံး ၁၂ မျိုး) ကို ပေါင်းထည့်ကာ ကီလိုဂရမ်ဖြင့် မတိုင်းတာရသေးပါက
                        ယူနစ်ပြောင်းလဲမှုများ ပြုလုပ်ပေးပါ။</p>}
                        <p><b>{t("generalText.NOTE")}</b> {t("setuppage.InputResourcesTabNoteText")}</p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <button
                            className={RawMaterialList != null && RawMaterialList.length > 11 ? "add_btn_disable buttonFlex" : "add_btn buttonFlex"}
                            onClick={handleShowMaterial}
                            type="button"
                            disabled={RawMaterialList != null && RawMaterialList.length > 11 ? true : false}
                        >
                             {RawMaterialList == null ||(RawMaterialList.length> 0 && RawMaterialList.length !=12) ?<img src={add} alt="REMS" />:null}
                            {RawMaterialList == null ||(RawMaterialList.length> 0 && RawMaterialList.length !=12) ?<div>{RawMaterialList != null && RawMaterialList.length > 0 ? <span style={{fontSize:'14px',fontWeight:"400"}}>{"("}<b>{RawMaterialList != null &&RawMaterialList.length}</b>{"/12 " +t("setuppage.rowmaterialAdded")}{")"}</span>: t("setuppage.InputResourcesTabAddRawButton")}</div>:
                            <div className='py-2 py-md-0' style={{color:"#A6A6A6", fontSize:"11px"}}>{t("validationMSG.maxRawMaterial")}</div>}
                        </button>
                        <Modal className="modalStyle setUpmodalInner" show={showModalMaterial} onHide={handleCloseModalMaterial} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                            <CloseIcon className={style.closeIconPop} onClick={()=>setshowModalMaterial(false)}/>
                            <Modal.Header><h2 style={{paddingTop:"10px"}}>{t("setuppage.AddRawMaterialPopUpHeader")}</h2></Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12 px-2">
                                        <p className='mb-0'>{t("setuppage.AddRawMaterialPopUpSubText")}</p>
                                    </div>
                                    <div className="col-12 mb-3 px-2">
                                        <form>
                                            <div className="floting-form">
                                                <input
                                                    type="text"
                                                    name="rawMaterial"
                                                    className="form-input"
                                                    placeholder="Raw materials name"
                                                    autoComplete="off"
                                                    value={RawMaterialValues.rawMaterial}
                                                    onChange={(e) => handleInputRawMateChange(e)}
                                                    style={{paddingLeft:"6px"}}
                                                    ref={materialRef}
                                                />
                                                <label htmlFor="email" className="form-label fl-white" ref={materialLabelRef}
                                                  style={{left:"4px",fontWeight:"600",transform:"scale(0.9)"}}
                                                >{t("setuppage.AddRawMaterialPopUpPlaceHolder")}</label>
                                            </div>
                                            <div className='text-danger errorText'>{ErrorRawList.RawName}</div>
                                        </form>
                                    </div>
                                    <div className="col-12 px-2">
                                        <p>{t("setuppage.AddRawMaterialPopUpParaOne")}</p>
                                    </div>
                                    <div className="col-12 mb-4 px-2">
                                        <div className="row">
                                            <div className="col-5 pe-0">
                                                <input
                                                    type="number"
                                                    name="conversionFactor"
                                                    className={"form-control convPlace "+style.inputBgColor}
                                                    placeholder={t("generalText.conversionFactor")}
                                                    value={RawMaterialValues.conversionFactor}
                                                    onChange={(e) => handleInputRawMateChange(e)}
                                                    disabled={disabledConv}
                                                    style={{padding:"5px 8px",textAlign:"right"}}
                                                    ref={convFacRef}
                                                />
                                            </div>
                                            <div className="col-4 pe-0">
                                                <select
                                                    className={'form-select '+style.selectHover}
                                                    value={RawMaterialValues.unitId}
                                                    onChange={(e) => handleSelectMaterial(e)}
                                                    style={{padding:"6px 8px",fontWeight:"600",fontSize:"13px"}}

                                                >
                                                    {unitListArray.length > 0 && unitListArray.map((item, index) => <option key={index} value={item.unit_id}>{item.unit_name}</option>)}
                                                </select>
                                            </div>
                                            <div className="col-3 pt-2"><b>= 1 kg</b></div>
                                        </div>
                                        <div className='text-danger errorText'>{ErrorRawList.RawCon}</div>
                                    </div>
                                    <div className="col-md-12 text-center px-2">
                                        <button
                                            className={RawMaterialValues.rawMaterial === '' ? "disable_primary w-100" : "btn_primary w-100"}
                                            type="button"
                                            onClick={AddMaterialFunction}
                                            disabled={RawMaterialValues.rawMaterial === '' ? true : false}
                                        >{t("setuppage.SaveRawMaterial")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12 px-4 px-md-1'>
                        <div className={style.productsList}>
                            {RawMaterialList != null && RawMaterialList.length > 0 && RawMaterialList.map((item, index) => <div className={style.card} key={index}>
                                {deleteMaterialIndex === index ? <div className={style.deletcard}><div className={style.deletcardHolder}>
                                    <div
                                        onClick={(e) => MaterialDelete(e,item.raw_material_id)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img src={remove} alt="REMS" /> {t("generalText.remove")}
                                    </div></div></div> :
                                    null}
                                <div>
                                    <h6>{item.raw_material}</h6>
                                    <p style={{textTransform:"lowercase"}}>{item.conversion_factor} {item.unit_name} = 1 kg</p>
                                </div>
                                <button
                                    type="button"
                                    onClick={(e) => DeleteMaterialFunction(e,index)}
                                ></button>
                            </div>)}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className='col-12'>
                        <div className='separator'></div>
                    </div>
                    <div className="col-md-6">
                        <h5>{t("setuppage.InputResourcesTabBoilerFuelsText")}</h5>
                        {langId === '1'?<p>Add the <b>boiler fuels (Energy)</b> (max. 2) that you use in your boilers from the list provided. Relevant units will be automatically selected for you depending on what you choose. The inputs for electricity will be asked later in the process, during the <b>Monthly Entry</b> stage.</p>:
                        <p>ပေးထားသောစာရင်းမှ သင့်ဘွိုင်လာများတွင် သင်အသုံးပြုသော <b>ဘွိုင်လာလောင်စာများ (စွမ်းအင်)</b> (အများဆုံး ၂) ကို ထည့်ပါ။ သင်ရွေးချယ်သည့်အပေါ်မူတည်၍ သက်ဆိုင်ရာယူနစ်များကို သင့်အတွက် အလိုအလျောက်ရွေးချယ်ပါမည်။ 
                        <b>လစဉ်ဒေတာများဖြည့်သွင်းသည့်အဆင့်တွင်</b> လျှပ်စစ်ရရှိမှုအတွက် အသုံးပြုသောလောင်စာများကို နောက်ပိုင်းတွင် မေးမြန်းမည်ဖြစ်ပါသည်။</p>
                         }
                        <p><b>{t("generalText.NOTE")} </b>{t("setuppage.InputResourcesTabNoteBoilerText")}</p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <button 
                            onClick={handleShowModalBoiler}
                            className={BoilerfuelList != null && BoilerfuelList.length > 1 ? "add_btn_disable buttonFlex" : "add_btn buttonFlex"}
                            type="button"
                            disabled={BoilerfuelList != null && BoilerfuelList.length > 1 ? true : false}
                        >
                        {BoilerfuelList == null ||(BoilerfuelList.length> 0 && BoilerfuelList.length !=2) ?<img src={add} alt="REMS" />:null}
                        {BoilerfuelList == null ||(BoilerfuelList.length> 0 && BoilerfuelList.length !=2) ?<div>{BoilerfuelList != null && BoilerfuelList.length > 0 ? <span style={{fontSize:'14px',fontWeight:"400"}}>{"("}<b>{BoilerfuelList != null &&BoilerfuelList.length}</b>{"/2 "+ t("setuppage.boilderFuleAdded")}{")"}</span>  : t("setuppage.InputResourcesTabAddBoilerButton")}</div>:
                        <div className='py-2 py-md-0' style={{color:"#A6A6A6", fontSize:"11px"}}>{t("validationMSG.maxBoilerFuel")}</div>}
                        </button>
                        <Modal className="modalStyle setUpmodalInner" show={showModalBoiler} onHide={handleCloseModalBoiler} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                            <CloseIcon className={style.closeIconPop} onClick={()=>setShowModalBoiler(false)}/>
                            <Modal.Header><h2 style={{paddingTop:"10px"}}>{t("setuppage.AddBoilerPopUpHeader")}</h2></Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12 px-2">
                                        <p>{t("setuppage.AddBoilerPopUpSubtext")}</p>
                                    </div>
                                    <div className="col-12 mb-4 px-2">
                                        <select
                                            className={'form-select '+style.selectHover}
                                            value={BoilerValue.boilerFuel_id}
                                            onChange={(e) => handleBoilerChange(e)}
                                            style={{padding:"6px",fontWeight:"600",fontSize:"13px",textAlign:"left"}}
                                        >
                                            <option value="" disabled>{t("setuppage.AddBoilerPopUpSelectText")}</option>
                                            {boilerListArray.length > 0 && boilerListArray.map((item, index) => <option key={index} id={item.unit_name} value={item.boiler_fuel_master}>{item.boiler_fuel_name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-12 mb-4 px-2">
                                        <p>{t("setuppage.AddBoilerPopUpParaOne")}</p>
                                    </div>
                                    <div className='col-12 mb-4 px-2'>
                                        <div className={currentUnit === '' ? style.unitBox : style.unitBoxActive}>{currentUnit !== '' ? currentUnit : t("generalText.unit")}</div>
                                    </div>
                                    <div className="col-md-12 text-center px-2">
                                        <button
                                            className={BoilerValue.boilerFuel_id === '' ? "disable_primary w-100" : "btn_primary w-100"}
                                            type="button"
                                            onClick={saveBoilerFuel}
                                            disabled={BoilerValue.boilerFuel_id === '' ? true : false}
                                        >{t("setuppage.AddBoilerPopUpSaveButton")}
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 px-4 px-md-1'>
                        <div className={style.productsList}>
                            {BoilerfuelList != null && BoilerfuelList.length > 0 && BoilerfuelList.map((item, index) => <div className={style.card} key={index}>
                            {deleteBoilerIndex === index ? <div className={style.deletcard}><div className={style.deletcardHolder}>
                                    <div
                                        onClick={(e) => BoilderDelete(e,item.boiler_fuel_id)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img src={remove} alt="REMS" /> {t("generalText.remove")}
                                    </div></div></div> :
                                    null}
                                <div>
                                    <h6>{item.boiler_fuel_name}</h6>
                                    <p style={{textTransform:"lowercase"}}>(in {item.unit_name})</p>
                                </div>
                                <button 
                                   type="button"
                                   onClick = {(e)=>bilerDeleteFunction(e,index)}
                                ></button>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InputResources;