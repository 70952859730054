import {
	Backdrop,
	Box,
	Button,
	OutlinedInput,
	FormControl,
	Paper,
	Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { validateEmail } from '../utils/validateEmail';
import Loader from '../components/Loader';
import RequestError from '../components/RequestError';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	addAdminUser,
	getAdminUsers,
	removeAdminUser,
} from '../../../services/AdminApis';
import CloseIcon from '@mui/icons-material/Close';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import ErrorIcon from '@mui/icons-material/Error';

const AdminManager = () => {
	const [adminOne, setAdminOne] = useState('');
	const [adminTwo, setAdminTwo] = useState('');
	const [superAdminOne, setSuperAdminOne] = useState('');
	const [superAdminTwo, setSuperAdminTwo] = useState('');
	const [isEmailValid, setIsEmailValid] = useState({
		adminOne: false,
		adminTwo: false,
		superAdminOne: false,
		superAdminTwo: false,
	});
	const [currentAdminName, setCurrentAdminName] = useState('');
	const [isAdminActive, setIsAdminActive] = useState({
		adminOne: false,
		adminTwo: false,
		superAdminOne: false,
		superAdminTwo: false,
	});
	const [clickedAdminEmail, setClickedAdminEmail] = useState('');
	const [clickedAdminName, setClickedAdminName] = useState('');
	const [backdropOpen, setBackdropOpen] = useState(false);
	const queryClient = useQueryClient();

	const {
		data: adminUsers,
		isLoading,
		isError,
		error,
	} = useQuery('adminUsers', getAdminUsers);

	const {
		isError: adminAddErrorBoolean,
		error: adminAddError,
		mutate,
	} = useMutation('adminUsers', addAdminUser, {
		retry: 0,
		onSuccess: () => {
			toast.success('Admin added successfully.');
			queryClient.invalidateQueries('adminUsers');
		},
	});

	const { mutate: removeAdmin } = useMutation('adminUsers', removeAdminUser, {
		retry: 0,
		onError: (error) => {
			console.log(error);
		},
		onSuccess: () => {
			queryClient.invalidateQueries('adminUsers');
		},
	});

	useEffect(() => {
		if (adminUsers && adminUsers.length) {
			// Reset states to their initial values
			setAdminOne('');
			setAdminTwo('');
			setSuperAdminOne('');
			setSuperAdminTwo('');
			setIsAdminActive({
				adminOne: false,
				adminTwo: false,
				superAdminOne: false,
				superAdminTwo: false,
			});

			// Process each user
			adminUsers[0]?.forEach((user) => {
				switch (user.name) {
					case 'ADMIN1':
						setAdminOne(user.email);
						setIsAdminActive((prev) => ({ ...prev, adminOne: true }));
						break;
					case 'ADMIN2':
						setAdminTwo(user.email);
						setIsAdminActive((prev) => ({ ...prev, adminTwo: true }));
						break;
					case 'SUPERADMIN1':
						setSuperAdminOne(user.email);
						setIsAdminActive((prev) => ({ ...prev, superAdminOne: true }));
						break;
					case 'SUPERADMIN2':
						setSuperAdminTwo(user.email);
						setIsAdminActive((prev) => ({ ...prev, superAdminTwo: true }));
						break;
					default:
						// Handle any users that don't match the specified roles
						break;
				}
			});
		}
	}, [adminUsers]);

	useEffect(() => {
		if (localStorage.getItem('adminToken')) {
			try {
				const creds = jwtDecode(JSON.parse(localStorage.getItem('adminToken')));
				setCurrentAdminName(creds?.user_name);
			} catch (error) {
				toast.error('Error parsing current admin info');
			}
		}
	}, []);

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		isEmailValidHandler(name, value);
		if (name === 'adminOne') {
			setAdminOne(value);
		} else if (name === 'adminTwo') {
			setAdminTwo(value);
		} else if (name === 'superAdminOne') {
			setSuperAdminOne(value);
		} else if (name === 'superAdminTwo') {
			setSuperAdminTwo(value);
		}
	};

	const isEmailValidHandler = (name, value) => {
		if (validateEmail(value)) {
			setIsEmailValid({ ...isEmailValid, [name]: true });
		} else {
			setIsEmailValid({ ...isEmailValid, [name]: false });
		}
	};

	const isCurrentAdmin = (name) => {
		return name === currentAdminName;
	};

	if (
		currentAdminName !== 'SUPERADMIN1' &&
		currentAdminName !== 'SUPERADMIN2'
	) {
		return (
			<RequestError message={'You are not authorized to access this page'} />
		);
	}

	const handleAddAdmin = (name, email) => {
		if (!validateEmail(email)) {
			toast.error('Invalid email address');
			return;
		}
		try {
			mutate({ adminName: name, adminEmail: email });
		} catch (error) {
			toast.error('Error adding admin');
		}
	};

	const removeAdminClick = (email, name) => {
		setClickedAdminEmail(email);
		setBackdropOpen(true);
		setClickedAdminName(name);
	};

	const handleRemoveAdmin = () => {
		const adminId = getAdminId(clickedAdminEmail);

		if (!adminId) {
			toast.error('Error removing admin');
			return;
		}
		setBackdropOpen(false);
		try {
			removeAdmin({ adminId, adminEmail: clickedAdminEmail });
			toast.success('Admin removed successfully.');
			setIsEmailValid({ ...isEmailValid, [clickedAdminName]: false });
			setIsAdminActive({ ...isAdminActive, [clickedAdminName]: false });
		} catch (error) {
			console.log(error);
			toast.error('Error removing admin');
		}
	};

	const getAdminId = (adminEmail) => {
		const admin = adminUsers[0]?.find((admin) => admin.email === adminEmail);
		return admin?.id;
	};

	return (
		<Box padding="45px 65px 6px">
			<Typography
				variant="h5"
				fontWeight="bold"
				sx={{ color: 'text.primary', marginBottom: '25px' }}
			>
				Manage your admins
			</Typography>
			<Box display="flex" justifyContent="flex-start" alignItems="flex-start">
				<Box sx={{ width: '50%' }}>
					<Typography
						variant="h6"
						fontWeight="bold"
						sx={{ color: 'text.primary', marginBottom: '20px' }}
					>
						Super Admins
					</Typography>
					<Typography fontSize={14} color="#383838" sx={{ mb: 4 }}>
						Super Admins have access to all admin panel functionalities.
					</Typography>
					<Paper
						className="AdminUserBox"
						elevation={1}
						sx={{
							border: '1px solid #383838',
							borderRadius: '8px',
							padding: '20px',
							width: '75%',
							mb: 4,
							'&&:hover': {
								border: isCurrentAdmin('SUPERADMIN1')
									? '1px solid #383838'
									: '2px solid #138c50',
							},
						}}
					>
						<Typography
							variant="h6"
							fontWeight="bold"
							sx={{ color: 'text.primary', marginBottom: '20px' }}
						>
							Super Admin 1
						</Typography>
						<Box display="flex" alignItems="center" gap="20px">
							<FormControl variant="outlined" fullWidth>
								<OutlinedInput
									size="small"
									name="superAdminOne"
									value={superAdminOne}
									onChange={handleChange}
									type="email"
									placeholder="Enter email ID"
									autoComplete="new-admin-one-email"
									disabled={isAdminActive?.superAdminOne}
									sx={{
										backgroundColor: isAdminActive?.superAdminOne
											? 'lightgray'
											: 'white',
										flex: 1,
									}}
								/>
							</FormControl>
							{isAdminActive?.superAdminOne ? (
								<Button
									disabled={isCurrentAdmin('SUPERADMIN1')}
									fontSize={14}
									sx={{
										color: '#F65B3C',
										textTransform: 'capitalize',
									}}
									onClick={() =>
										removeAdminClick(superAdminOne, 'superAdminOne')
									}
								>
									<CloseIcon
										sx={{
											color: isCurrentAdmin('SUPERADMIN1')
												? '#A6A6A6'
												: '#F65B3C',
										}}
									/>{' '}
									Remove
								</Button>
							) : (
								<Button
									fontSize={14}
									disabled={!isEmailValid?.superAdminOne}
									sx={{
										color: isEmailValid?.superAdminOne ? '#138C50' : '#A6A6A6',
										textTransform: 'capitalize',
									}}
									onClick={() => handleAddAdmin('SUPERADMIN1', superAdminOne)}
								>
									Assign Role{' '}
									<ArrowForwardIcon
										sx={{
											color: isEmailValid?.superAdminOne
												? '#138C50'
												: '#A6A6A6',
										}}
									/>
								</Button>
							)}
						</Box>
					</Paper>
					<Paper
						className="AdminUserBox"
						elevation={1}
						sx={{
							border: '1px solid #383838',
							borderRadius: '8px',
							padding: '20px',
							width: '75%',

							'&&:hover': {
								border: isCurrentAdmin('SUPERADMIN2')
									? '1px solid #383838'
									: '2px solid #138c50',
							},
						}}
					>
						<Typography
							variant="h6"
							fontWeight="bold"
							sx={{ color: 'text.primary', marginBottom: '20px' }}
						>
							Super Admin 2
						</Typography>
						<Box display="flex" alignItems="center" gap="20px">
							<FormControl variant="outlined" fullWidth>
								<OutlinedInput
									size="small"
									name="superAdminTwo"
									value={superAdminTwo}
									onChange={handleChange}
									type="email"
									placeholder="Enter email ID"
									autoComplete="new-admin-two-email"
									sx={{
										backgroundColor: isAdminActive?.superAdminTwo
											? 'lightgray'
											: 'white',
										flex: 1,
									}}
									disabled={isAdminActive?.superAdminTwo}
								/>
							</FormControl>
							{isAdminActive?.superAdminTwo ? (
								<Button
									disabled={isCurrentAdmin('SUPERADMIN2')}
									fontSize={14}
									sx={{
										color: '#F65B3C',
										textTransform: 'capitalize',
									}}
									onClick={() =>
										removeAdminClick(superAdminTwo, 'superAdminTwo')
									}
								>
									<CloseIcon
										sx={{
											color: isCurrentAdmin('SUPERADMIN2')
												? '#A6A6A6'
												: '#F65B3C',
										}}
									/>{' '}
									Remove
								</Button>
							) : (
								<Button
									fontSize={14}
									disabled={!isEmailValid?.superAdminTwo}
									sx={{
										color: isEmailValid?.superAdminTwo ? '#138C50' : '#A6A6A6',
										textTransform: 'capitalize',
									}}
									onClick={() => handleAddAdmin('SUPERADMIN2', superAdminTwo)}
								>
									Assign Role{' '}
									<ArrowForwardIcon
										sx={{
											color: isEmailValid?.superAdminTwo
												? '#138C50'
												: '#A6A6A6',
										}}
									/>
								</Button>
							)}
						</Box>
					</Paper>
				</Box>
				<Box sx={{ width: '50%' }}>
					<Typography
						variant="h6"
						fontWeight="bold"
						sx={{ color: 'text.primary', marginBottom: '20px' }}
					>
						Admins
					</Typography>
					<Typography fontSize={14} color="#383838" sx={{ mb: 4 }}>
						Admins have access to only limited admin panel functionalities.
					</Typography>
					<Paper
						className="AdminUserBox"
						elevation={1}
						sx={{
							border: '1px solid #383838',
							borderRadius: '8px',
							padding: '20px',
							width: '75%',
							mb: 4,
							'&&:hover': {
								border: '2px solid #138c50',
							},
						}}
					>
						<Typography
							variant="h6"
							fontWeight="bold"
							sx={{ color: 'text.primary', marginBottom: '20px' }}
						>
							Admin 1
						</Typography>
						<Box display="flex" alignItems="center" gap="20px">
							<FormControl
								variant="outlined"
								fullWidth
								sx={{ p: 0, m: 0, flex: 1 }}
							>
								<OutlinedInput
									size="small"
									name="adminOne"
									value={adminOne}
									onChange={handleChange}
									type="email"
									placeholder="Enter email ID"
									autoComplete="new-admin-three-email"
									sx={{
										backgroundColor: isAdminActive?.adminOne
											? 'lightgray'
											: 'white',
									}}
									disabled={isAdminActive?.adminOne}
								/>
							</FormControl>
							{isAdminActive?.adminOne ? (
								<Button
									fontSize={14}
									sx={{
										color: '#F65B3C',
										textTransform: 'capitalize',
									}}
									onClick={() => removeAdminClick(adminOne, 'adminOne')}
								>
									<CloseIcon sx={{ color: '#F65B3C' }} /> Remove
								</Button>
							) : (
								<Button
									fontSize={14}
									disabled={!isEmailValid?.adminOne}
									sx={{
										color: isEmailValid?.adminOne ? '#138C50' : '#A6A6A6',
										textTransform: 'capitalize',
									}}
									onClick={() => handleAddAdmin('ADMIN1', adminOne)}
								>
									Assign Role{' '}
									<ArrowForwardIcon
										sx={{
											color: isEmailValid?.adminOne ? '#138C50' : '#A6A6A6',
										}}
									/>
								</Button>
							)}
						</Box>
					</Paper>
					<Paper
						className="AdminUserBox"
						elevation={1}
						sx={{
							border: '1px solid #383838',
							borderRadius: '8px',
							padding: '20px',
							width: '75%',
							'&&:hover': {
								border: '2px solid #138c50',
							},
						}}
					>
						<Typography
							variant="h6"
							fontWeight="bold"
							sx={{ color: 'text.primary', marginBottom: '20px' }}
						>
							Admin 2
						</Typography>
						<Box display="flex" alignItems="center" gap="20px">
							<FormControl
								variant="outlined"
								fullWidth
								sx={{ p: 0, m: 0, flex: 1 }}
							>
								<OutlinedInput
									size="small"
									name="adminTwo"
									value={adminTwo}
									onChange={handleChange}
									type="email"
									placeholder="Enter email ID"
									autoComplete="new-admin-four-email"
									sx={{
										backgroundColor: isAdminActive?.adminTwo
											? 'lightgray'
											: 'white',
									}}
									disabled={isAdminActive?.adminTwo}
								/>
							</FormControl>
							{isAdminActive?.adminTwo ? (
								<Button
									fontSize={14}
									sx={{
										color: '#F65B3C',
										textTransform: 'capitalize',
									}}
									onClick={() => removeAdminClick(adminTwo, 'adminTwo')}
								>
									<CloseIcon sx={{ color: '#F65B3C' }} /> Remove
								</Button>
							) : (
								<Button
									fontSize={14}
									disabled={!isEmailValid?.adminTwo}
									sx={{
										color: isEmailValid?.adminTwo ? '#138C50' : '#A6A6A6',
										textTransform: 'capitalize',
									}}
									onClick={() => handleAddAdmin('ADMIN2', adminTwo)}
								>
									Assign Role{' '}
									<ArrowForwardIcon
										sx={{
											color: isEmailValid?.adminTwo ? '#138C50' : '#A6A6A6',
										}}
									/>
								</Button>
							)}
						</Box>
					</Paper>
				</Box>
			</Box>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
				onClick={() => setBackdropOpen(false)}
			>
				<Box
					sx={{
						border: '2px solid green',
						backgroundColor: 'white',
						borderRadius: '10px',
						padding: '20px 50px',
						textAlign: 'center',
					}}
				>
					<Box display="flex" justifyContent="center" mb="10px">
						<ErrorIcon sx={{ color: '#138C50', fontSize: 50 }} />
					</Box>

					<Typography fontSize="18px" color="#383838" fontWeight={700}>
						Are you sure you want to <br /> remove this admin?
					</Typography>
					<Box
						display="flex"
						justifyContent="center"
						mt="15px"
						gap="20px"
						sx={{ mb: 2 }}
					>
						<Button
							variant="contained"
							style={{
								cursor: 'pointer',
								minWidth: '90px',
								color: 'white',
								textTransform: 'capitalize',
							}}
							onClick={handleRemoveAdmin}
						>
							Yes
						</Button>
						<Button
							variant="outlined"
							style={{
								cursor: 'pointer',
								minWidth: '90px',
								color: '#138C50',
								textTransform: 'capitalize',
							}}
							onClick={() => setBackdropOpen(false)}
						>
							No
						</Button>
					</Box>
				</Box>
			</Backdrop>
		</Box>
	);
};

export default AdminManager;
