import React, { useEffect, useRef, useState } from "react";
import headerStyle from './header.module.css';
import Logo from '../../../assets/frontend/images/logo.png';
import loginIcon from '../../../assets/frontend/images/login_icon.png';
import { useLanguageChange } from "../customHooks/lan_hook";
import { useLocation } from "react-router-dom";
import profileIcon from '../../../assets/frontend/images/profile_icon.png';
import { Link } from 'react-router-dom';
import AuthUser from "../authUser/authuser";
import Modal from 'react-bootstrap/Modal';
import { HeaderProfileHide } from "../utility/utility";
import { useEditUserHook, useGetUserHook, useMasterDataHook } from "../api_hooks/api_hooks";
import { toast } from "react-toastify";
import { Oval, ThreeDots } from 'react-loader-spinner';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { HeaderUtil } from "./headerUtil";
import { FrontendTokenExpiry } from "../authUser/frontendTokenExpiry";

const Header = () => {
    const editRef = useRef(null)
    const [langParam, setLangParams] = useState(localStorage.getItem('lan'));
    const { logout } = AuthUser();
    const [vNumber, setVersionNumber] = useState(null);
    const { t } = useTranslation();
    useLanguageChange();
    useMasterDataHook();
    const location = useLocation();
    const Currentpath = location.pathname;
    const CurrentpathText = location.pathname.split('/')[1]
    const [userData, setUserData] = useState([])
    const [logoutPopup, setLogoutpopup] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editValue, setEditValue] = useState('');
    const [profileOpen, setprofileOpen] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = async() => {
        setShowModal(true);
        if(userData !== null){
            setEditValue(userData.name)
            setTimeout(()=>{
                focusFunction();
            },1000)
        }
        setprofileOpen(false)
    }
    const focusFunction = ()=>{
        editRef.current.focus();
    }
    const handleChangeLanguage = (lang_param) => {
        setLangParams(lang_param);
        i18next.changeLanguage(lang_param);
        localStorage.setItem('lan', lang_param);
        window.location.reload(true);
        if (lang_param === 'en') {
            i18next.changeLanguage(lang_param);
            localStorage.setItem('lanId', 1);
            window.location.reload(true);
        } else {
            localStorage.setItem('lanId', 2);
            i18next.changeLanguage(lang_param);
            window.location.reload(true);
        }
    };
    const handleLogOut = () => {
        setLogoutpopup(true)
    }
    const logoutStatus = (status) => {
        if (status == 'yes') {
            logout();
            setLogoutpopup(false);
            closeMenu();
        } else {
            setLogoutpopup(false);
        }
    };
   
   const closeMenu =()=>{
       setprofileOpen(false)
   }
    const { mutate: getUser, status, data, isLoading: profileLoading } = useGetUserHook();
    const profileClick = () => {
        setprofileOpen(true)
        getUser({});
        let localData = JSON.parse(localStorage.getItem("masterData"));
        let AppVers = localData && localData.VERSION_NUMBER;
        let AppVersion = JSON.parse(AppVers);
        setVersionNumber(AppVersion);
    }
    const { mutate: editUserFunction, isLoading: profileEditLoading } = useEditUserHook();
    const handleEditInput = (e) => {
        const value = editRef.current.value;
        setEditValue(value)
    }
    const handleEditUserclick = () => {
        if (editValue !== '') {
            let editdata = {
                "name": editValue
            }
            editUserFunction(editdata)
            setShowModal(profileEditLoading)
            setEditValue('')
        }
        else {
            toast.error(t("HeaderProfile.enterUserName"));
        }
    }
    useEffect(() => {
        if (status === 'success') {
            let userdata = data.data[0]
            setUserData(userdata)
        }
    }, [status, data, vNumber])
    return (
        <div className={headerStyle.header} style={{boxShadow:CurrentpathText==='monthlylog'&&"none"}}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-3">
                        <Link to={!FrontendTokenExpiry(localStorage.getItem('expiry'))?"/dashboard":"/"} onClick={()=>setprofileOpen(false)}><img src={Logo} alt="Ref-Track" className={headerStyle.logo} /></Link>
                    </div>
                    {window.innerWidth < 768 ? (
                        <>
                            <div className={HeaderUtil.includes(CurrentpathText)?"col-9 text-center":"col-6 text-center"}>
                                <div 
                                   className="btn-group" 
                                   role="group" aria-label="Basic radio toggle button group"
                                   style={{
                                    float:HeaderUtil.includes(CurrentpathText)?"right":"unset"
                                   }}
                                   >
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="language"
                                        id="english"
                                        onChange={() => handleChangeLanguage('en')}
                                        checked={langParam == "en" && true}
                                    />
                                    <label className="btn btn-outline-dark" htmlFor="english">ENG</label>

                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="language"
                                        id="burmese"
                                        onChange={() => handleChangeLanguage('bu')}
                                        checked={langParam == "bu" && true}
                                    />
                                    <label className="btn btn-outline-dark" htmlFor="burmese">မြန်မာ</label>
                                </div>
                            </div>
                            {Currentpath === "/" && <div className="col-3 text-end">
                                <a href="#loginSection" className={headerStyle.loginIcon}><img src={loginIcon} /></a>
                            </div>}
                            <div className="col-3">
                                {!HeaderProfileHide.includes(Currentpath) && <div className={headerStyle.dropdown}>
                                    <button
                                        className={headerStyle.dropdown_toggle}
                                        type="button"
                                        onClick={profileClick}
                                        style={{zIndex:profileOpen===true?"24":"0"}}
                                    >
                                        <AccountCircleIcon style={{color:profileOpen === true?"#138c50":"#383838"}}/>
                                    </button>
                                    {profileOpen === true && <>
                                        <div className="backShadow" onClick={()=>setprofileOpen(false)} style={{zIndex:profileOpen===true?"23":"0"}}></div>
                                        <ul className={'dropdown-menu-header text-center' + ' ' + headerStyle.dropdown_menu}
                                            style={{ padding: "34px 0px",zIndex:profileOpen===true?"23":"0"}}
                                        >
                                            <li style={{ borderBottom: "1px solid #00000029" }}>
                                                {profileLoading === false ? <div className={'row justify-content-center' + ' ' + headerStyle.profileWrapper}>
                                                    <div className={headerStyle.closeincon} onClick={() => setprofileOpen(false)}><CloseIcon /></div>
                                                    <div className="col-3"><img src={profileIcon} alt="REMS" /></div>
                                                    <div className="col-8 text-start">
                                                        <button type="button" onClick={handleShow}>{userData !== null ? userData.name : ''}</button>
                                                        <p className={headerStyle.email}>{userData !== null ? userData.email : ''}</p>
                                                        <p className={headerStyle.phone}>{userData !== null ? userData.phonenumber : ''}</p>
                                                    </div>
                                                </div> : <Oval
                                                    height={80}
                                                    width={80}
                                                    color="#4fa94d"
                                                    wrapperStyle={{
                                                        justifyContent: "center"
                                                    }}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#4fa94d"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                />}
                                            </li>
                                            <li style={{ paddingTop: "10px" }}><Link to="/help" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.HelpText")}</Link></li>
                                            <li style={{ borderBottom: "1px solid #00000029", paddingBottom: "10px" }}><Link to="/contact" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.ContactUsButton")}</Link></li>
                                            <li className="py-4" style={{ borderBottom: "1px solid #00000029", paddingBottom: "10px" }}>
                                                <button
                                                    type="button"
                                                    className="btn_primary"
                                                    onClick={handleLogOut}
                                                >{t("HeaderProfile.LogOutButton")}</button>
                                            </li>
                                            <li className="py-2">
                                                <Link to="/privacy" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.PrivacyText")}</Link>
                                                <span style={{ borderRight: "1px solid" }}></span>
                                                <Link to="/terms" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.TermsText")}</Link>
                                            </li>
                                            {vNumber !== null && <li className="pt-3"><p>{vNumber.length > 0 && "Version " + vNumber[0].versionnumber}</p></li>}
                                        </ul>
                                    </>}
                                </div>}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={HeaderUtil.includes(CurrentpathText)?"col-9 text-center":"col-6 text-center"}
                                style={{position:Currentpath=='/'?"relative":"absolute",right:"0px"}}
                            >
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group"
                                    style={{
                                        float:HeaderUtil.includes(CurrentpathText)?"right":"unset"
                                    }}
                                >
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="language"
                                        id="english"
                                        onChange={() => handleChangeLanguage('en')}
                                        checked={langParam == "en" && true}
                                    />
                                    <label className="btn btn-outline-dark" htmlFor="english">ENG</label>

                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="language"
                                        id="burmese"
                                        onChange={() => handleChangeLanguage('bu')}
                                        checked={langParam == "bu" && true}
                                    />
                                    <label className="btn btn-outline-dark" htmlFor="burmese">မြန်မာ</label>
                                </div>
                            </div>
                            <div className="col-3 text-end">
                                {Currentpath === "/" && <a href="#loginSection" className="btn_primary">{t("landingpage.LoginButton")}</a>}
                                {!HeaderProfileHide.includes(Currentpath) && <div className={headerStyle.dropdown}>
                                    <button
                                        className={headerStyle.dropdown_toggle}
                                        type="button"
                                        onClick={profileClick}
                                        style={{zIndex:profileOpen===true?"24":"0"}}
                                    >
                                        <AccountCircleIcon style={{color:profileOpen === true?"#138c50":"#383838"}}/>
                                    </button>
                                    {profileOpen === true && <>
                                        <div className="backShadow" onClick={()=>setprofileOpen(false)} style={{zIndex:profileOpen===true?"23":"0"}}></div>
                                        <ul className={'dropdown-menu-header text-center' + ' ' + headerStyle.dropdown_menu}
                                            style={{ padding: "34px 0px",zIndex:profileOpen===true?"23":"0"}}>
                                            <li style={{ borderBottom: "1px solid #00000029", paddingBottom: "16px" }}>
                                                {profileLoading === false ? <div className={'row justify-content-center' + ' ' + headerStyle.profileWrapper}
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <div className={headerStyle.closeincon} onClick={() => setprofileOpen(false)}><CloseIcon /></div>
                                                    <div className="col-3"><img src={profileIcon} alt="REMS" /></div>
                                                    <div className="col-8 text-start">
                                                        {/* <h5>{userData !== null ? userData.name : ''}</h5> */}
                                                        <button type="button" onClick={handleShow}>{userData !== null ? userData.name : ''}</button>
                                                        <p className={headerStyle.email}>{userData !== null ? userData.email : ''}</p>
                                                        <p className={headerStyle.phone}>{userData !== null ? userData.phonenumber : ''}</p>
                                                    </div>
                                                </div> : <Oval
                                                    height={80}
                                                    width={80}
                                                    color="#4fa94d"
                                                    wrapperStyle={{
                                                        justifyContent: "center"
                                                    }}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#4fa94d"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                />}
                                            </li>
                                            <li style={{ paddingTop: "10px" }}><Link to="/help" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.HelpText")}</Link></li>
                                            <li style={{ borderBottom: "1px solid #00000029", paddingBottom: "10px" }}><Link to="/contact" onClick={()=>setprofileOpen(false)} className={headerStyle.quicklinks}>{t("landingpage.ContactUsButton")}</Link></li>
                                            <li className="py-4" style={{ borderBottom: "1px solid #00000029", paddingBottom: "10px" }}>
                                                <button
                                                    type="button"
                                                    className="btn_primary"
                                                    onClick={handleLogOut}
                                                >{t("HeaderProfile.LogOutButton")}</button></li>
                                            <li>
                                                <Link to="/privacy" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.PrivacyText")}</Link>
                                                <span style={{ borderRight: "1px solid" }}></span>
                                                <Link to="/terms" className={headerStyle.quicklinks} onClick={()=>setprofileOpen(false)}>{t("landingpage.TermsText")}</Link>
                                            </li>
                                            {vNumber !== null && <li className="pt-3"><p>{vNumber.length > 0 && "Version " + vNumber[0].versionnumber}</p></li>}
                                        </ul>
                                    </>}
                                </div>}
                            </div>
                        </>
                    )}
                </div>

            </div>
            <Modal
                className="modalStyle text-center"
                show={logoutPopup}
                onHide={() => setLogoutpopup(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="mb-4 mt-1">
                        <h6 className="mb-4 text-left">{t("HeaderProfile.LogoutConform")}</h6>
                        <div className=" text-center">
                            <button
                                className="btn btn-primary me-2 mw-8"
                                onClick={() => logoutStatus('yes')}
                            >
                                {t("generalText.yes")}
                            </button>
                            <button
                                className="btn btn-light mw-8"
                                onClick={() => logoutStatus('no')}
                            >
                                {t("generalText.no")}
                            </button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal
                className="modalStyle text-center modelEditUser"
                show={showModal} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static" size="sm"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="mb-4 mt-1">
                        <h5 className="mb-3">{t("editUserName.heading")}</h5>
                        <p style={{textAlign:"left",color:"#707070"}}>{t("editUserName.paraOne")}</p>
                        <div className="floting-form mb-3">
                            <input
                                type="text"
                                name="editName"
                                className="form-input"
                                placeholder="none"
                                ref={editRef}
                                value={editValue}
                                onChange={(e) => handleEditInput(e)}
                            />
                            <label htmlFor="yourName" className="form-label fl-white">{t("createnewaccountpage.CreateANewAccountNamePlaceholder")}</label>
                        </div>
                        <div className="">
                            <button
                                className="btn btn-primary w-100"
                                onClick={handleEditUserclick}
                            >{t("editUserName.Saveusername")}</button>

                        </div>
                        {profileEditLoading && <div style={{ marginTop: "8px" }}><ThreeDots
                            height="14"
                            width="60"
                            radius="6"
                            color="#24B270"
                            ariaLabel="three-dots-loading"
                            visible={true}
                        /></div>}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default Header;