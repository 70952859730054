	import axios from 'axios';
	import { baseUrl, apiPath, app_key, app_name } from '../constant';

	const getHeaders = () => {
		let get_token = JSON.parse(localStorage.getItem('token'));
		let token = 'Bearer ' + get_token;
		return {
			'Content-Type': 'application/json',
			Authorization: token,
			'app-key': app_key,
			'app-name': app_name,
			'accept-language': languageId == null ? 2 : languageId,
		};
	};
	let languageId = localStorage.getItem('lanId');
	const LoginHeaders = {
		'content-type': 'application/json',
		'app-key': app_key,
		'app-name': app_name,
		'accept-language': languageId == null ? 2 : languageId,
	};

	const Client = axios.create({
		baseURL: baseUrl,
		withCredentials: true,
	});

	// Start your Frontend api module
	export const userLoginCall = async (loginData) => {
		return await Client.post(apiPath.userLogin, loginData, {
			headers: LoginHeaders,
		});
	};
	export const userRegistrationCall = async (formData) => {
		return await Client.post(apiPath.userRegistration, formData, {
			headers: LoginHeaders,
		});
	};
	export const userResetPasswordRequestCall = async (formData) => {
		return await Client.post(apiPath.resetPasswordRequest, formData, {
			headers: LoginHeaders,
		});
	};
	export const userResetPasswordCall = async (formData) => {
		return await Client.post(apiPath.resetPassword, formData, {
			headers: LoginHeaders,
		});
	};
	//register new user email verification
	export const newUserVerificationRequest = async (formData)=>{
        return await Client.post(apiPath.newUserVerificationReq, formData,{
			headers: LoginHeaders,
		})
	}
	//email verification
	export const emailVerification = async (formData)=>{
		return await Client.post(apiPath.emailVerification, formData,{
			headers: LoginHeaders,
		})
	}
	export const MasterDataCall = async () => {
		return await Client.post(
			apiPath.masterData,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	export const DeviceInfoCall = async (deviceData) => {
		return await Client.post(apiPath.deviceInfo, deviceData, {
			headers: getHeaders(),
		});
	};
	export const AddproductCall = async (productData) => {
		return await Client.post(apiPath.addProducts, productData, {
			headers: getHeaders(),
		});
	};
	export const GetAllSetUpdata = async () => {
		return await Client.post(
			apiPath.getAllsetup,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	export const DeleteProdunct = async (delProduct) => {
		return await Client.post(apiPath.deleteProduct, delProduct, {
			headers: getHeaders(),
		});
	};
	export const AddRawMaterial = async (RawMaterialData) => {
		return await Client.post(apiPath.addRawMaterials, RawMaterialData, {
			headers: getHeaders(),
		});
	};
	export const AddBoilerfuel = async (BoilerfuelData) => {
		return await Client.post(apiPath.addBoilerfuel, BoilerfuelData, {
			headers: getHeaders(),
		});
	};
	export const DeleteMaterial = async (delMaterial) => {
		return await Client.post(apiPath.deleteMaterial, delMaterial, {
			headers: getHeaders(),
		});
	};
	export const DeleteBoiler = async (delBoiler) => {
		return await Client.post(apiPath.deleteBoiler, delBoiler, {
			headers: getHeaders(),
		});
	};
	export const AddWasteGeneration = async (WasteData) => {
		return await Client.post(apiPath.addWaste, WasteData, {
			headers: getHeaders(),
		});
	};
	export const DeleteWaste = async (delWaste) => {
		return await Client.post(apiPath.deleteWaste, delWaste, {
			headers: getHeaders(),
		});
	};
	export const UpdateSetupStatus = async () => {
		return await Client.post(
			apiPath.updateSetupStatus,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	export const ResetSetupData = async () => {
		return await Client.post(
			apiPath.resetSetUpdata,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	export const editUserCall = async (data) => {
		return await Client.post(apiPath.editUser, data, {
			headers: getHeaders(),
		});
	};
	export const getUserCall = async () => {
		return await Client.post(
			apiPath.getUser,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	export const startMonthlyEntry = async (startData) => {
		return await Client.post(apiPath.startMonthlyEntryPeriod, startData, {
			headers: getHeaders(),
		});
	};
	export const monthlyAddproductCall = async (data) => {
		return await Client.post(apiPath.monthlyAddproduct, data, {
			headers: getHeaders(),
		});
	};
	export const getMonthlyDataCall = async () => {
		return await Client.post(
			apiPath.getMonthlyData,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	export const monthlyAddRawMaterialCall = async (data) => {
		return await Client.post(apiPath.monthlyAddRawMaterial, data, {
			headers: getHeaders(),
		});
	};
	export const monthlyAddBoilerfuelResourceCall = async (data) => {
		return await Client.post(apiPath.addBoilerfuelResource, data, {
			headers: getHeaders(),
		});
	};
	export const monthlyAddWasteGeneratedCall = async (data) => {
		return await Client.post(apiPath.addMonthlyWaste, data, {
			headers: getHeaders(),
		});
	};
	export const monthlyAddCommentCall = async (data) => {
		return await Client.post(apiPath.addMontlyComment, data, {
			headers: getHeaders(),
		});
	};

	export const generateOutput = async (timeline) => {
		return await Client.post(apiPath.generateOutput, timeline, {
			headers: getHeaders(),
		});
	};

	export const getInputLog = async (timeline) => {
		return await Client.post(apiPath.getInputLog, timeline, {
			headers: getHeaders(),
		});
	};
