import { useTranslation } from 'react-i18next';
import StaticTab from './staticTab';
import style from './style.module.css';
import { useEffect } from 'react';

const Privacy = () => {
    const {t} = useTranslation();
    useEffect(()=>{
        window.scrollTo({
            top: 3,
            left: 0,
          });
    },[])
    return (
        <div className={"py-4 "+style.height}>
              <div style={{marginTop:window.innerWidth >768?"78px":"90px"}}>
               <StaticTab/>
            </div>
            <div className={"container "+style.privacy}>
                <ul>
                    <li><b>{t("privacypage.AcceptanceHeaderText")}</b>
                        <p className='pt-3'>{t("privacypage.AcceptanceSubText")}<b>{t("privacypage.AcceptSubtexttwo")}</b></p>
                    </li>
                    <li><b>{t("privacypage.DefinitionOfPersonalInformationHeaderText")}</b>
                        <p className='pt-3'>{t("privacypage.DefinitionOfPersonalInformationSubText")}</p>
                    </li>
                    <li><b>{t("privacypage.WhyDoWeCollectTheAboveDefinedPersonalInformationHeaderText")}</b>
                        <p className='pt-3'>{t("privacypage.WhyDoWeCollectTheAboveDefinedPersonalInformationSubText")}</p>
                    </li>
                    <li><b>{t("privacypage.SecurityHeadertext")}</b>
                        <p className='pt-3'>{t("privacypage.SecuritySubtext")}</p>
                    </li>
                    <li><b>{t("privacypage.GeneralDisclaimerHeaderText")}</b>
                        <p className='pt-3'>{t("privacypage.GeneralDisclaimerSubText")}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Privacy;