import React, { useEffect, useRef, useState } from 'react';
import FPStyles from './createNewAccount.module.css';
import { Link, useNavigate } from 'react-router-dom';
import flag from '../../../assets/frontend/images/burmese_flag.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNewUserEmailverificationRequest, useRegistrationHook } from '../api_hooks/api_hooks';
import DoneIcon from '@mui/icons-material/Done';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ThreeDots } from 'react-loader-spinner';
import { site_key } from '../../../constant';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Register = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    let langId = localStorage.getItem("lanId")
    const [passwordType, setPasswordType] = useState(true);
    const [ConformpasswordType, setConformpasswordType] = useState(true)
    const [CaptchaValue, setCaptchaValue] = useState(null)
    const [userDetails, setUserDetails] = useState({
        userName: '',
        usereEmail: '',
        userPhonenumber: '',
        countrycode: 95,
        password: '',
        conformPassword: ''
    })
    const [errorValue, setErrorvalue] = useState({});
    const [accept, setAccept] = useState(false);
    const [passwordStrong, setPasswordStrong] = useState({
        totalcharCount: false,
        NumberCount: false,
        AlphaCount: false
    })

    const userNameRef = useRef();
    const userLabelRef = useRef();
    const userEmailRef = useRef();
    const userEmailLabelRef = useRef();
    const userPhonenumberRef = useRef();
    const userPhonenumberLabelRef = useRef();
    const passwordRef = useRef();
    const passwordLabelRef = useRef();
    const conformPasswordRef = useRef();
    const conformPasswordLabelRef = useRef();

    const { mutate: userRegister, status, isLoading } = useRegistrationHook();
    const { mutate: verificationRequest,isSuccess} = useNewUserEmailverificationRequest();
    const PasswordShow = () => {
        setPasswordType(!passwordType);
    }
    const conformPasswordShow = () => {
        setConformpasswordType(!ConformpasswordType)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setUserDetails({ ...userDetails, password: value });
            let PassLength = value.length >= 9
            let numericList = /[0-9]/
            let alphabets = /[a-zA-Z]/
            let numericValue = numericList.test(value);
            let AlphaValue = alphabets.test(value);
            setPasswordStrong({
                ...passwordStrong,
                totalcharCount: PassLength,
                NumberCount: numericValue,
                AlphaCount: AlphaValue
            })
        }
        if (name === "userPhonenumber") {
            if (/[a-z]/gi.test(value)) return
            setUserDetails({ ...userDetails, userPhonenumber: value });

        }
        setUserDetails({ ...userDetails, [name]: value });
        if (name === "userName") {
            if (value.length > 0) {
                userNameRef.current.style.border = "1px solid #138C50"
                userLabelRef.current.style.color = "#138C50"
            }
            else {
                userNameRef.current.style.border = "1px solid #A6A6A6"
                userLabelRef.current.style.color = "#383838"
            }

        }

        if (name === "usereEmail") {
            if (value.length > 0) {
                userEmailRef.current.style.border = "1px solid #138C50"
                userEmailLabelRef.current.style.color = "#138C50"
            }
            else {
                userEmailRef.current.style.border = "1px solid #A6A6A6"
                userEmailLabelRef.current.style.color = "#383838"
            }

        }
        if (name === "userPhonenumber") {
            if (value.length > 0) {
                userPhonenumberRef.current.style.border = "1px solid #138C50"
                userPhonenumberLabelRef.current.style.color = "#138C50"
            }
            else {
                userPhonenumberRef.current.style.border = "1px solid #A6A6A6"
                userPhonenumberLabelRef.current.style.color = "#383838"
            }

        }
        if (name === "userPhonenumber") {
            if (value.length > 0) {
                userPhonenumberRef.current.style.border = "1px solid #138C50"
                userPhonenumberLabelRef.current.style.color = "#138C50"
            }
            else {
                userPhonenumberRef.current.style.border = "1px solid #A6A6A6"
                userPhonenumberLabelRef.current.style.color = "#383838"
            }

        }
        if (name === "password") {
            if (value.length > 0) {
                passwordRef.current.style.border = "1px solid #138C50"
                passwordLabelRef.current.style.color = "#138C50"
            }
            else {
                passwordRef.current.style.border = "1px solid #A6A6A6"
                passwordLabelRef.current.style.color = "#383838"
            }

        }
        if (name === "password") {
            if (value.length > 0) {
                passwordRef.current.style.border = "1px solid #138C50"
                passwordLabelRef.current.style.color = "#138C50"
            }
            else {
                passwordRef.current.style.border = "1px solid #A6A6A6"
                passwordLabelRef.current.style.color = "#383838"
            }

        }
        if (name === "conformPassword") {
            if (value.length > 0) {
                conformPasswordRef.current.style.border = "1px solid #138C50"
                conformPasswordLabelRef.current.style.color = "#138C50"
            }
            else {
                conformPasswordRef.current.style.border = "1px solid #A6A6A6"
                conformPasswordLabelRef.current.style.color = "#383838"
            }

        }
    }

    const submitUserDetails = async (e) => {
        e.preventDefault();
        let Errors = inputValidation();
        setErrorvalue(Errors)
        if (Object.keys(Errors).length === 0 && accept === true && CaptchaValue !== null) {
            let userRgDta = {
                "name": userDetails.userName,
                "email": userDetails.usereEmail,
                "phonenumber": userDetails.userPhonenumber,
                "countrycode": userDetails.countrycode,
                "password": btoa(userDetails.password)
            }
            userRegister(userRgDta)
           
        }
    }
    const inputValidation = () => {
        let error = {};
        const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        //const regex_phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!userDetails.userName) {
            error.name = t("CreatenewAccount.validName")
        }
        if (!userDetails.usereEmail || !regex_email.test(userDetails.usereEmail)) {
            error.email = t("CreatenewAccount.validEmail")
        }
        if (!userDetails.userPhonenumber || userDetails.userPhonenumber.length < 7) {
            error.phone = t("CreatenewAccount.validNumber")
        }
        if (!userDetails.password) {
            error.password = t("CreatenewAccount.validPassword")
        }
        if (!userDetails.conformPassword) {
            error.conformPassword = t("CreatenewAccount.validConform")
        }
        if (userDetails.conformPassword !== userDetails.password) {
            error.conformPassword = t("CreatenewAccount.validConform")
        }

        if(passwordStrong.totalcharCount === true){
            if(passwordStrong.NumberCount === false){
                error.passnotStrong = t("CreatenewAccount.invalidPassword")
                passwordRef.current.style.border = "1px solid #F65B3C"
                passwordLabelRef.current.style.color = "#F65B3C"
                conformPasswordRef.current.style.border = "1px solid #F65B3C"
                conformPasswordLabelRef.current.style.color = "#F65B3C"
                //toast.error(t("CreatenewAccount.invalidPassword"))
            }
            if(passwordStrong.AlphaCount === false){
                error.passnotStrong = t("CreatenewAccount.invalidPassword")
                passwordRef.current.style.border = "1px solid #F65B3C"
                passwordLabelRef.current.style.color = "#F65B3C"
                conformPasswordRef.current.style.border = "1px solid #F65B3C"
                conformPasswordLabelRef.current.style.color = "#F65B3C"
                //toast.error(t("CreatenewAccount.invalidPassword"))
            }
        }
        if(userDetails.password.length>0){
            if(passwordStrong.totalcharCount === false){
                error.passnotStrong = t("CreatenewAccount.invalidPassword")
                passwordRef.current.style.border = "1px solid #F65B3C"
                passwordLabelRef.current.style.color = "#F65B3C"
                conformPasswordRef.current.style.border = "1px solid #F65B3C"
                conformPasswordLabelRef.current.style.color = "#F65B3C"
                //toast.error(t("CreatenewAccount.invalidPassword"))
            }
        }
        if (CaptchaValue == null) {
            toast.error(t("CreatenewAccount.validCaptcha"))
        }
        return error;
        
    }
    const handleCheckBox = (e) => {
        let checkValue = e.target.checked;
        if (checkValue) {
            setAccept(true)
        }
        else {
            setAccept(false)
        }
    }
    const captchaChange = (value) => {
        setCaptchaValue(value)
    }
    useEffect(() => {
        window.scrollTo({
            top: 3,
            left: 0,
        });
        if (status === 'success') { 
           
            verificationRequest({
                email:userDetails.usereEmail
            })
            navigate(`/active-your-account?email=${userDetails.usereEmail}`)
        }
    }, [status, isLoading])
    return (
        <div className="mainWrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                        <h4>{t("CreatenewAccount.heading")}</h4>
                        <p className="mb-4"
                            style={{
                                lineHeight: langId === '1' ? "20px" : "26px",
                                fontSize: window.innerWidth < 768 ? "12px" : "13px"
                            }}
                        >{t("CreatenewAccount.subtext")}</p>
                        <form onSubmit={submitUserDetails} autoComplete='off' style={{ lineHeight: "20px" }}>
                            <div className="floting-form">
                                <input
                                    type="text"
                                    name="userName"
                                    // className="form-input"
                                    placeholder='none'
                                    value={userDetails.userName}
                                    onChange={(e) => handleInputChange(e)}
                                    autoComplete='off'
                                    className={errorValue.name ? 'form-input invalid' : 'form-input'}
                                    ref={userNameRef}
                                />
                                <label htmlFor="name" ref={userLabelRef} className="form-label fl-white">{t("CreatenewAccount.yourName")}</label>
                            </div>
                            <div className="text-danger errorText">{errorValue.name}</div>

                            <div className="floting-form">
                                <input
                                    type="text"
                                    name="usereEmail"
                                    // className="form-input"
                                    placeholder="none"
                                    value={userDetails.usereEmail}
                                    onChange={(e) => handleInputChange(e)}
                                    autoComplete='off'
                                    className={errorValue.email ? 'form-input invalid' : 'form-input'}
                                    ref={userEmailRef}
                                />
                                <label htmlFor="email" ref={userEmailLabelRef} className="form-label fl-white">{t("CreatenewAccount.yourEmail")}</label>
                            </div>
                            <div className="text-danger errorText">{errorValue.email}</div>
                            <small className="form-text text-muted" >{t("CreatenewAccount.thisEmail")}</small>

                            <div className='row align-items-center' style={{ display: "flex", alignItems: "center" }}>
                                <div className='col-5 col-md-5 col-lg-4 pt-2' style={{ display: "flex", alignItems: "center" }}>
                                    <img src={flag} alt="REMS" width={40} /><span style={{ marginLeft: "16px", fontSize: "17px" }}>+</span><span style={{ marginTop: "2px" }}>{userDetails.countrycode}</span>
                                </div>
                                <div className='col-7 col-md-7 col-lg-8'>
                                    <div className="floting-form">
                                        <input
                                            type="text"
                                            maxLength={10}
                                            name="userPhonenumber"
                                            // className="form-input"
                                            placeholder="none"
                                            value={userDetails.userPhonenumber}
                                            onChange={(e) => handleInputChange(e)}
                                            autoComplete='off'
                                            className={errorValue.phone ? 'form-input invalid' : 'form-input'}
                                            ref={userPhonenumberRef}
                                        />
                                        <label htmlFor="number" ref={userPhonenumberLabelRef} className="form-label fl-white">{t("CreatenewAccount.YourMobNumber")}</label>
                                    </div>
                                    <div className="text-danger errorText">{errorValue.phone}</div>
                                </div>
                            </div>

                            <div className="floting-form">
                                <input
                                    type={passwordType === true ? "password" : "text"}
                                    name="password"
                                    placeholder="none"
                                    value={userDetails.password}
                                    onChange={(e) => handleInputChange(e)}
                                    autoComplete='off'
                                    className={errorValue.password ? 'form-input invalid fromPassin' : 'form-input fromPassin'}
                                    style={{ paddingRight: "34px" }}
                                    ref={passwordRef}
                                />
                                <label htmlFor="newpassword" ref={passwordLabelRef} className="form-label fl-white fromPassLabel">{t("CreatenewAccount.newPass")}</label>
                                {passwordType === true ? <VisibilityOffIcon className='eyeIcon'
                                    onClick={PasswordShow} /> :
                                    <RemoveRedEyeIcon
                                        onClick={PasswordShow}
                                        className='eyeIcon'
                                    />}
                            </div>
                            <div className="text-danger errorText">{errorValue.password}</div>
                            <div className="floting-form">
                                <input
                                    type={ConformpasswordType === true ? "password" : "text"}
                                    name="conformPassword"
                                    // className="form-input"
                                    placeholder="none"
                                    value={userDetails.conformPassword}
                                    onChange={(e) => handleInputChange(e)}
                                    autoComplete='off'
                                    className={errorValue.conformPassword ? 'form-input invalid fromPassin' : 'form-input fromPassin'}
                                    style={{ paddingRight: "34px" }}
                                    ref={conformPasswordRef}
                                />
                                <label htmlFor="confirmpassword" ref={conformPasswordLabelRef} className="form-label fl-white fromPassLabel">{t("CreatenewAccount.conformPass")}</label>
                                {ConformpasswordType === true ? <VisibilityOffIcon className='eyeIcon'

                                    onClick={conformPasswordShow} /> :
                                    <RemoveRedEyeIcon
                                        onClick={conformPasswordShow}
                                        className='eyeIcon'
                                    />}
                            </div>
                            <div className="text-danger errorText">{errorValue.conformPassword}</div>
                            <div className="text-danger errorText">{errorValue.passnotStrong}</div>
                            <small className="form-text text-muted" >
                                <ul className={FPStyles.pswMatches}>
                                    <li
                                        style={{
                                            color: passwordStrong.totalcharCount === true ? "#138C50" : "#D9D9D9",
                                            position: "relative"
                                        }}
                                    >{t("CreatenewAccount.EightChar")}
                                        <DoneIcon
                                            style={{
                                                fontSize: 16,
                                                position: "absolute",
                                                right: 0,
                                                color: passwordStrong.totalcharCount === true ? "#138C50" : "#D9D9D9"
                                            }}
                                        />
                                    </li>
                                    <li
                                        style={{
                                            position: "relative",
                                            color: passwordStrong.NumberCount === true && passwordStrong.AlphaCount
                                                ? "#138C50" : "#D9D9D9"
                                        }}
                                    >{t("CreatenewAccount.combPass")}
                                        <DoneIcon
                                            style={{
                                                fontSize: 16,
                                                position: "absolute",
                                                right: 0,
                                                color: passwordStrong.NumberCount === true && passwordStrong.AlphaCount ? "#138C50" : "#D9D9D9"
                                            }}
                                        />
                                    </li>
                                </ul>
                                {/* <div className="text-danger errorText">{errorValue.passnotStrong}</div> */}
                                
                            </small>
                            <div className="form-check mb-3" style={{ display: "flex", alignItems: "center", marginTop: "26px" }}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={accept}
                                    id='checkBox'
                                    onChange={(e) => handleCheckBox(e)}
                                    style={{
                                        border: accept === false ? "2px solid #D9D9D9" : "2px solid #138C50",
                                        width: "21px",
                                        height: "21px",
                                        cursor: "pointer"
                                    }}
                                />
                                <label
                                    className="form-check-label"

                                    htmlFor="checkBox"
                                    style={{ color: accept === false ? "#383838" : "#138C50", marginTop: "4px", cursor: "pointer" }}
                                >
                                    {t("CreatenewAccount.accept")}</label>
                            </div>
                            <div className='captchaClass'>
                                <ReCAPTCHA
                                    sitekey={site_key}
                                    onChange={captchaChange}
                                />
                            </div>
                            <div
                                className="d-grid"
                                style={{ marginTop: "20px" }}
                            >
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={!accept}
                                >{t("CreatenewAccount.createMyAcc")}</button>
                            </div>
                            <div style={{ marginTop: "10px" }}>{isLoading && <ThreeDots
                                height="14"
                                width="60"
                                radius="6"
                                color="#24B270"
                                ariaLabel="three-dots-loading"
                                visible={true}
                            />}</div>
                        </form>
                    </div>
                </div>
                <div className="pt-3">
                    <ul className="staticPageLink">
                        <li>
                            <Link to="/help#">{t('landingpage.HelpText')}</Link>
                        </li>
                        <li>
                            <Link to="/privacy#">{t('landingpage.PrivacyText')}</Link>
                        </li>
                        <li>
                            <Link to="/terms#">{t('landingpage.TermsText')}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default Register;