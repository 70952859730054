import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import greenArrow from '../../../assets/frontend/images/greenArrow.png';
import blackArrow from '../../../assets/frontend/images/blackArrow.png';
import './PieChart.css';
import { useMediaQuery } from '@mui/material';

const PieChart = ({
	title,
	percentageOne,
	labelOne,
	percentageTwo,
	labelTwo,
}) => {
	const isMobile = useMediaQuery('(max-width:600px)');

	let percentageThree = (percentageTwo || percentageOne) == 100 ? 0 : 2;
	let percentageFour = (percentageTwo || percentageOne) == 100 ? 0 : 2;
	const PieData = {
		// labels: [
		// 	// labelOne + '(' + percentageOne + ')',
		// 	// labelTwo + '(' + percentageTwo + ')',
		// 	labelOne,
		// 	labelTwo,
		// ],
		datasets: [
			{
				label: title,
				data: [percentageOne, percentageThree, percentageTwo, percentageFour],
				// backgroundColor: [
				// 	'transparent',
				// 	'transparent',
				// 	'transparent',
				// 	'transparent',
				// ], // Set the background color to transparent
				backgroundColor: ['#24B270', '#F1F1F1', '#38383', '#F1F1F1'], // Set the background color to transparent
				// borderColor: ['#138C50', '#A6A6A6'], // Set the border color to desired colors
				// borderWidth: [70], // Set the width of the border to control the size of the "tyre" and make the center transparent
				borderWidth: 1, // Border thickness
				borderColor: 'transparent', // Border color
			},
		],
	};

	const options = {
		plugins: {
			title: {
				display: false,
				text: title,
				color: '#383838',

				font: {
					size: 26,
					color: '#383838',
					weight: 'bold',
				},
			},
		},
		cutout: '90%', // Adjust the cutout percentage to control the size of the hole in the center
		legend: {
			display: true, // Hide the legends
		},
	};

	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					width: '335px',
					height: '300px',
					// width: '100%',
					// height: '100%',

					borderRadius: '10px',
					padding: '20px',
					margin: 'auto',
				}}
			>
				<h4
					style={{ color: 'black', fontWeight: 'bold', marginBottom: '20px' }}
				>
					{title}
				</h4>
				<div
					style={{
						position: 'absolute',
						left: '60px',
						top: '169px',
						width: '100px',
					}}
				>
					<h5 style={{ color: '#24B270', fontSize: '20px' }}>
						{parseFloat(percentageOne).toFixed(1)}%
					</h5>
					<p style={{ lineHeight: '1.2', color: '#24B270' }}>{labelOne}</p>
				</div>

				{/* <div
					style={{
						position: 'absolute',
						height: '25px',
						width: '1px',
						left: '114px',
						top: '99px',
					}}
				>
					<img src={blackArrow} />
				</div> */}
				<div
					style={{
						width: isMobile ? '190px' : '200px',
						height: isMobile ? '190px' : '200px',
						textAlign: 'center',
					}}
				>
					<Doughnut
						data={PieData}
						options={options}
						style={{
							margin: 'auto',
							width: '100%',
							height: '100%',
							marginLeft: '50px',
							transform: 'rotate(-90deg)',
							marginTop: '1px',
						}}
					/>
				</div>
				{/* <div
					style={{
						position: 'absolute',
						height: '25px',
						width: '1px',
						right: '165px',
						top: '197px',
					}}
				>
					<img src={greenArrow} />
				</div> */}
				<div
					style={{
						position: 'absolute',
						right: '30px',
						top: '169px',
						width: '120px',
					}}
				>
					<h5 style={{ color: '#383838', fontSize: '20px' }}>
						{parseFloat(percentageTwo).toFixed(1)}%
					</h5>
					<p style={{ lineHeight: '1.2', color: '#383838' }}>{labelTwo}</p>
				</div>
			</div>
			{/* <div class="doughnut-chart">
				<div class="segment major-segment-1"></div>
				<div class="segment separator-1"></div>
				<div class="segment major-segment-2"></div>
				<div class="segment separator-2"></div>

				<div class="inner-circle"></div>

				<div class="label label-1">25%</div>
				<div class="label label-2">25%</div>
				<div class="label label-3">25%</div>
				<div class="label label-4">25%</div>
			</div> */}
			{/* <Pie
				style={{
					width: '320px',
					height: '280px',
					backgroundColor: '#F1F1F1',
					borderRadius: '10px',
					padding: '20px',
				}}
				data={PieData}
				options={{
					plugins: {
						title: {
							display: true,
							text: `${title}`,
							font: {
								size: 24,
							},
						},
					},

					legend: {
						position: 'top', // Set the position of the legend
						align: 'start',
						labels: {
							boxWidth: 15,
							boxHeight: 15,
						},
					},
				}}
			/> */}
			{/* <div
						style={{
							margin: '20px',
							position: 'relative',
						}}
					>
						<div></div>
						<div style={{ padding: '5px' }}>{labelOne}</div>
						<div
							style={{
								width: '100px',
								height: '1px',
								backgroundColor: 'green',
							}}
						></div>
						<div style={{ padding: '5px' }}>{labelTwo}</div>
					</div> */}
		</div>
	);
};

export default PieChart;
