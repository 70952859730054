import { Box, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getDatabaseData } from '../../../services/AdminApis';
import Loader from '../components/Loader';
import RequestError from '../components/RequestError';
import EditableComponent from '../components/EditableComponent';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

const DatabaseManager = () => {
	const [language, setLanguage] = useState(2);

	const memoizedQueryKey = ['DatabaseData', { language_id: language }];
	const [currentAdminName, setCurrentAdminName] = useState('');

	const { data, isLoading, isError, error, isSuccess } = useQuery(
		memoizedQueryKey,
		getDatabaseData,
		{ retry: 2 }
	);

	useEffect(() => {
		if (localStorage.getItem('adminToken')) {
			try {
				const creds = jwtDecode(JSON.parse(localStorage.getItem('adminToken')));
				setCurrentAdminName(creds?.user_name);
			} catch (error) {
				toast.error('Error parsing current admin info');
			}
		}
	}, []);

	let unitList = [];
	let wasteList = [];
	let boilerList = [];

	const wasteListTracking = [
		'E-waste',
		'Plastic',
		'Paper & Cartons',
		'Fabric Pieces',
		'Hazardous waste',
	];

	const handleChange = (lan) => {
		setLanguage(lan);
	};

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		return <RequestError message={error?.response?.data?.error?.message} />;
	}
	if (isSuccess) {
		unitList = JSON.parse(data?.DATABASE_MANAGER_CONTENT[0].UNIT_LIST);
		wasteList = JSON.parse(data?.DATABASE_MANAGER_CONTENT[0].WASTE_LIST);
		console.log(wasteList);
		boilerList = JSON.parse(
			data?.DATABASE_MANAGER_CONTENT[0].BOILER_FUELS_LIST
		);
	}

	if (
		currentAdminName !== 'SUPERADMIN1' &&
		currentAdminName !== 'SUPERADMIN2'
	) {
		return (
			<RequestError message={'You are not authorized to access this page'} />
		);
	}

	function sortWasteData(wasteData, wasteListTracking) {
		const order = new Map(
			wasteListTracking.map((waste, index) => [waste, index])
		);
		return wasteData.sort((a, b) => {
			const indexA = order.has(a.waste_name)
				? order.get(a.waste_name)
				: Infinity;
			const indexB = order.has(b.waste_name)
				? order.get(b.waste_name)
				: Infinity;
			return indexA - indexB;
		});
	}

	wasteList = sortWasteData(wasteList, wasteListTracking);

	return (
		<Box padding="45px 65px 6px">
			<Typography
				variant="h5"
				fontWeight="bold"
				sx={{ color: 'text.primary', marginBottom: '20px' }}
			>
				Edit your database
			</Typography>
			<Box display="flex" marginBottom="30px">
				<Button
					variant="outlined"
					sx={{
						px: 4,
						py: 1,
						minWidth: '100px',
						backgroundColor: language == 1 ? 'black' : 'white',
					}}
					color="warning"
					onClick={() => handleChange(1)}
					style={{
						backgroundColor: language == 1 ? '#383838' : 'white',
						color: language == 1 ? 'white' : '#383838',
						borderRadius: '5px',
					}}
				>
					Eng
				</Button>
				<Button
					variant="outlined"
					sx={{
						px: 4,
						py: 1,
						minWidth: '100px',
					}}
					style={{
						backgroundColor: language == 2 ? '#383838' : 'white',
						color: language == 2 ? 'white' : '#383838',
						borderRadius: '5px',
					}}
					color="warning"
					onClick={() => handleChange(2)}
				>
					မြန်မာ
				</Button>
			</Box>

			<Box
				display="flex"
				justifyContent="space-between"
				border="2px solid gray"
			>
				<Box flex="1" border="1px solid gray" padding="10px 30px">
					<Typography
						variant="h6"
						fontWeight="bold"
						sx={{ color: 'text.primary', marginBottom: '10px' }}
					>
						List of units
					</Typography>
					<div
						style={{
							border: '1px solid gray',
							width: '737%',
							marginLeft: '-18%',
							marginBottom: '10px',
						}}
					></div>
					{unitList?.map((unit, index) => (
						<EditableComponent
							key={index}
							value={unit.unit_name}
							label="unit List"
							id={unit.unit_id}
							object_key="unit_name"
							themeObject={unit}
							theme="units"
							editStatus={unit.edit_status}
						/>
					))}
				</Box>
				<Box flex="3.5" border="1px solid gray" padding="10px 30px">
					<Box display="flex">
						{' '}
						<Box flex="2.5">
							<Typography
								variant="h6"
								fontWeight="bold"
								sx={{ color: 'text.primary', marginBottom: '20px' }}
							>
								List of boiler fuels
							</Typography>
							<Box>
								{boilerList?.map((boiler, index) => (
									<EditableComponent
										key={index}
										label="fuel name"
										theme="boiler"
										value={boiler.boiler_fuel_name}
										id={boiler.boiler_fuel_master}
										object_key="boiler_fuel_name"
										themeObject={boiler}
										editStatus={boiler.edit_status}
									/>
								))}
							</Box>
						</Box>
						<Box flex="1">
							<Typography
								variant="h6"
								fontWeight="bold"
								sx={{ color: 'text.primary', marginBottom: '20px', flex: 1 }}
							>
								Units
							</Typography>
							{boilerList?.map((boiler, index) => (
								<Box
									key={index}
									display="flex"
									alignItems="center"
									justifyContent="start"
								>
									<EditableComponent
										value={boiler.unit_name}
										id={boiler.boiler_fuel_master}
										object_key="unit_name"
										label="units"
										themeObject={boiler}
										theme="boiler"
										editStatus={boiler.edit_status}
									/>
								</Box>
							))}
						</Box>
						<Box flex="1.25">
							<Typography
								variant="h6"
								fontWeight="bold"
								sx={{
									color: 'text.primary',
									marginBottom: '20px',
									flex: 1,
								}}
							>
								Conv. factor
							</Typography>
							{boilerList?.map((boiler, index) => (
								<Box
									key={index}
									display="flex"
									alignItems="center"
									justifyContent="start"
								>
									<EditableComponent
										value={boiler.conversion_factor}
										label="conversion"
										id={boiler.boiler_fuel_master}
										object_key="conversion_factor"
										themeObject={boiler}
										theme="boiler"
										editStatus={boiler.edit_status}
									/>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
				<Box flex="1.75" border="1px solid gray" padding="10px 30px">
					{' '}
					<Typography
						variant="h6"
						fontWeight="bold"
						sx={{
							color: 'text.primary',
							marginBottom: '20px',
							marginLeft: '7px',
						}}
					>
						List of waste types
					</Typography>
					{wasteList?.map((waste, index) => (
						<Box key={index} style={{ opacity: index < 5 ? '0.6' : 1 }}>
							<EditableComponent
								value={waste.waste_name}
								label="waste type"
								id={waste.waste_id}
								object_key="waste_name"
								themeObject={waste}
								theme="waste"
								editStatus={waste.edit_status}
							/>
							{index == 4 && (
								<span style={{ fontWeight: '900', marginLeft: '10px' }}>
									...
								</span>
							)}
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default DatabaseManager;
