import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const CurrentProductList = ({
    ProdunctJson,
    productInputChange,
    saveProduct,
    completeProductSection,
    ProductIncomplete
}) => {
    let langId = localStorage.getItem("lanId")
    const { t } = useTranslation();
    return (
        <div className={style.section + ' ' + style.sec_1}>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h3><span>1</span>{t("monthlylogpage.ProductsHeadertext")}</h3>
                    <p>{t("monthlylogpage.ProductsSubtext")}</p>
                </div>
            </div>

            <div className="row justify-content-end">
                <div className="col-md-6 ">
                    <div className='row'>
                        {ProdunctJson.length > 0 && ProdunctJson.map((product, proIndex) => <div key={proIndex} className="col-md-8 mb-3">
                            <div>
                                <label>{product.product_name}</label>
                            </div>
                            <div className="input-group">
                                <input
                                    onWheel={(e) => e.target.blur()}
                                    type="number"
                                    name="quantity"
                                    className="form-control monthlylogInput"
                                    placeholder={(completeProductSection === 1 && product.quantity === null) || (product.quantity === '') ? t("monthlylogpage.IncompleteField") : ''}
                                    value={product.quantity === null ? '' : product.quantity}
                                    onChange={(e) => productInputChange(e, proIndex, "product")}
                                    style={{
                                        border: product.quantity === null && completeProductSection === 1 ? "1px solid #F65B3C" :
                                            product.quantity === '' && completeProductSection === 1 ? "1px solid #F65B3C" :
                                                product.quantity === null && completeProductSection === 0 ? "1px solid #A6A6A6" :
                                                product.quantity === '' && completeProductSection === 0?"1px solid red":
                                                    "1px solid #138C50"
                                    }}
                                />
                                <span
                                    className="input-group-text spanBorderinput"
                                    style={{
                                        border: product.quantity === null && completeProductSection === 1 ? "1px solid #F65B3C" :
                                            product.quantity === '' && completeProductSection === 1 ? "1px solid #F65B3C" :
                                                product.quantity === null && completeProductSection === 0 ? "1px solid #A6A6A6" :
                                                product.quantity === '' && completeProductSection === 0?"1px solid red":
                                                    "1px solid #138C50",
                                        padding: "8px 46px 8px 10px"
                                    }}
                                ><span>{product.unit_name}</span></span>
                            </div>
                        </div>)}
                        <div 
                           className="col-md-4 mb-3 pe-5 pe-md-0 paddingButtonRight" 
                           style={{ display: 'flex', 
                           alignItems: window.innerWidth>768?'end':'center', 
                           justifyContent: window.innerWidth>768?"start":"end",
                           position:"relative",
                           }}>
                            {completeProductSection === 1 && ProductIncomplete === 1 ?
                                <p className='float-start d-block d-md-none form_msg'>{t("monthlylogpage.completedSection")}</p> : null}
                            <button
                                className={
                                    completeProductSection == 0 ?
                                        "btn btn-primary mw-11 float-md-start float-end" : "btn btn-primary_disable mw-11 float-md-start float-end"}
                                type="button"
                                onClick={saveProduct}
                                style={{ fontSize: langId == 1 ? "13px" : "10px",padding:"6px 6px" }}
                            >{completeProductSection == 0 ? t("monthlylogpage.EnergyInputSaveButton") : t("monthlylogpage.SaveButton")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 d-none d-md-block position-relative'>
                    {completeProductSection === 1 && ProductIncomplete === 1 ? <p className='form_msg' style={{height:langId!=='1'?36:"auto",display:"flex",alignItems:"baseline"}}>{t("monthlylogpage.completedSection")}</p> : null
                    }
                </div>
            </div>
        </div>
    )
}
export default CurrentProductList;