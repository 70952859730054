import React, { useState, useRef } from 'react';
import {
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	Box,
	Button,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header from '../components/Header';
import { validateEmail } from '../utils/validateEmail';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { useMutation } from 'react-query';
import { forgotPassword, login } from '../../../services/AdminApis';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const [showPassword, setShowPassword] = useState(false);
	const usernameRef = useRef(null);
	const passwordRef = useRef(null);
	const [usernameState, setUsernameState] = useState('');
	const [passwordState, setPasswordState] = useState('');
	const [passwordFocus, setPasswordFocus] = useState(false);
	const [usernameFocus, setUsernameFocus] = useState(false);

	const navigate = useNavigate();

	const { isLoading, mutate } = useMutation('login', login, {
		onSuccess: (data) => {
			const adminCreds = jwtDecode(data.accessToken);

			localStorage.setItem('adminTokenExpiry', JSON.stringify(adminCreds.exp));
			localStorage.setItem('adminToken', JSON.stringify(data.accessToken));
			navigate('/admin/dashboard');
		},
		onError: () => {
			setPasswordFocus(false);
			setUsernameFocus(false);
		},
	});

	const forgotPasswordMutation = useMutation('forgotPassword', forgotPassword, {
		onSuccess: (data) => {},
	});

	const handleTogglePassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleForgotPasswordClick = () => {
		if (usernameRef.current.value === '') {
			toast.error('Please enter your email ID.');
			return;
		}
		forgotPasswordMutation.mutate({ email: usernameRef.current.value });
		navigate(`/admin/forgot-password?email=${usernameRef.current.value}`);
	};

	const handleLogin = async () => {
		// Get the values from the input fields
		const username = usernameRef.current.value;
		const password = passwordRef.current.value;
		// Perform validation
		if (!username) {
			toast.error('Email cannot be empty.');
			return;
		}
		if (!password) {
			toast.error('Password cannot be empty.');
			return;
		}
		if (!validateEmail(username)) {
			toast.error('Please enter a valid email.');
			return;
		}
		mutate({ email: username, password: password });
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Header />

			<Box
				sx={{ height: '86vh' }}
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Stack>
					<Typography
						variant="h5"
						fontWeight="bold"
						sx={{ color: 'primary.main', marginBottom: '20px' }}
					>
						Admin console
					</Typography>
					<Typography
						variant="h6"
						fontWeight="bold"
						sx={{ color: 'text.primary' }}
						gutterBottom
					>
						Log into your admin account.
					</Typography>
					<TextField
						inputRef={usernameRef}
						onChange={(e) => setUsernameState(e.target.value)}
						id="outlined-basic-username"
						label="Your email ID"
						variant="outlined"
						size="small"
						sx={{ width: '350px', mb: 2 }}
						onFocus={() => setUsernameFocus(true)}
						onBlur={() => usernameState.length == 0 && setUsernameFocus(false)}
						focused={usernameFocus}
					/>
					<TextField
						inputRef={passwordRef}
						id="outlined-basic-password"
						label="Your password"
						onChange={(e) => setPasswordState(e.target.value)}
						variant="outlined"
						size="small"
						type={showPassword ? 'text' : 'password'}
						sx={{ width: '350px' }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleTogglePassword} edge="end">
										{!showPassword ? (
											<VisibilityOff style={{ color: '#a6a6a6' }} />
										) : (
											<Visibility style={{ color: '#138C50' }} />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						onFocus={() => setPasswordFocus(true)}
						onBlur={() => passwordState.length == 0 && setPasswordFocus(false)}
						focused={passwordFocus}
					/>
					<Box
						style={{
							textAlign: 'right',
							color: '#138C50',
							fontWeight: 'bold',
							cursor: 'pointer',
						}}
						sx={{ '&:hover': { textDecoration: 'underline' } }}
						onClick={handleForgotPasswordClick}
					>
						Forgot password?
					</Box>
					<Button
						sx={{
							mt: 4,
							textTransform: 'capitalize',
							'&:hover': { textDecoration: 'underline' },
						}}
						variant="contained"
						onClick={handleLogin}
					>
						Log in
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default Login;
